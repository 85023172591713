import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EdmsError } from '@app/types/error';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ActionDTO } from '@data/models/action-dto';
import { DDSChecklistDTO } from '@data/models/dds-checklist-dto';
import { FileDTO } from '@data/models/file-dto';
import { ActionTrackerService } from '@data/services/action-tracker.service';
import { TranslateService } from '@ngx-translate/core';
import { AZURE_ACTION_PATH, AZURE_NOTES_PATH } from '@shared/constants/azure.constants';
import { confirmBeforeLeavingConfig } from '@shared/constants/confirm-dialog';
import { GLOBAL_MESSAGE_5SEC_DURATION } from '@shared/constants/global-message';
import { AzureBlobStorageService } from '@shared/services/azure-blob-storage.service';
import { ConfirmDialogService } from '@shared/services/confirm-dialog.service';
import { GlobalMessageService } from '@shared/services/global-message.service';
import { ProgressSpinnerDialogService } from '@shared/services/progress-spinner-dialog.service';
import { ActionComponent } from '../components/action/action.component';
import { InformationComponent } from '../components/information/information.component';
import { LocationComponent } from '../components/location/location.component';
import { StepperComponent } from '@shared/components/stepper/stepper.component';
import { ItemStepper } from '@data/models/item-stepper';
import { ManagementServiceService } from '@data/services/management-service.service';
import { finalize } from 'rxjs/operators';
// import { OthersComponent } from '../components/others/others.component';
// import { PlanningComponent } from '../components/planning/planning.component';

@Component({
  templateUrl: './new-action-tracker-dialog.component.html'
})
export class NewActionTrackerDialogComponent implements OnInit {
  @ViewChild(ActionComponent)
  public actionComponent: ActionComponent;

  @ViewChild(LocationComponent)
  public locationComponent: LocationComponent;

  @ViewChild(InformationComponent)
  public informationComponent: InformationComponent;

  @ViewChild(StepperComponent)
  public myStepper: StepperComponent;

  // @ViewChild(PlanningComponent)
  // public planningComponent: PlanningComponent;

  // @ViewChild(OthersComponent)
  // public othersComponent: OthersComponent;

  public error: EdmsError;

  public tabIndex = 0;

  public labels: { [key: string]: string } = {
    title: marker('apps.appsLanding.other.dialog.create'),
    next: marker('global.next'),
    back: marker('global.back'),
    save: marker('apps.appsLanding.other.dialog.add')
  };

  public subtitle: ItemStepper[] = [
    {
      index: '1',
      description: marker('apps.appsLanding.other.dialog.subtitle.action'),
      icon: ''
    }
  ];

  public files: FileDTO[][] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      parentAction: number;
      model: ActionDTO;
      type?: string;
      ddsChecklist?: DDSChecklistDTO;
      files?: FileDTO[][];
      isMetricsDDS?: boolean;
    },
    private actionTrackerService: ActionTrackerService,
    private spinnerService: ProgressSpinnerDialogService,
    private dialog: MatDialog,
    private globalMessage: GlobalMessageService,
    private translate: TranslateService,
    private azure: AzureBlobStorageService,
    private confirmDialog: ConfirmDialogService,
    private managementService: ManagementServiceService
  ) {}

  ngOnInit() {
    if (this.data.type !== 'CL') {
      this.subtitle.push(
        {
          index: '2',
          description: marker('apps.appsLanding.other.dialog.subtitle.location'),
          icon: ''
        },
        {
          index: '3',
          description: marker('apps.appsLanding.other.dialog.subtitle.information'),
          icon: ''
        }
      );
    } else {
      this.subtitle.push({
        index: '2',
        description: marker('apps.appsLanding.other.dialog.subtitle.information'),
        icon: ''
      });
    }
  }

  get isCurrentFormValid(): boolean {
    let result = false;

    switch (this.tabIndex) {
      case 0:
        result = this.actionComponent?.isFormValid;
        break;
      case 1:
        result = this.locationComponent?.isFormValid;
        break;
      case 2:
        result = this.informationComponent?.isFormValid;
        break;
      // case 3:
      //   result = this.planningComponent?.isFormValid;
      //   break;
      // case 4:
      //   result = this.othersComponent?.isFormValid;
      //   break;
      default:
        break;
    }

    return result;
  }

  public changeIndex(event: number) {
    if (this.data.type === 'CL' && event === 1) {
      event = 2;
    }
    this.tabIndex = event;
    // this.onTabChange(this.tabIndex);
  }

  get allFormsAreValid(): boolean {
    if (this.actionComponent) {
      this.actionComponent.isMetricsDDS = this.data.isMetricsDDS;
    }
    if (this.informationComponent) {
      this.informationComponent.isMetricsDDS = this.data.isMetricsDDS;
      if (this.informationComponent.isMetricsDDS) {
        return this.informationComponent.isMetricsDDS;
      }
    }

    if (this.data.type !== 'DS') {
      return (
        this.actionComponent?.isFormValid && this.locationComponent?.isFormValid && this.informationComponent?.isFormValid //&&
        // this.planningComponent?.isFormValid &&
        // this.othersComponent?.isFormValid
      );
    }
    if (this.data.type === 'DS') {
      return this.actionComponent?.isFormValid && this.informationComponent.isFormValid;
    }
  }

  public next(): void {
    if (this.data.type === 'CL' && this.tabIndex === 0) {
      this.tabIndex = 2;
    } else {
      if (this.tabIndex < this.subtitle.length - 1) {
        this.tabIndex++;
      }
    }
    if (this.tabIndex === 2) {
      this.informationComponent.workingAreaSelected();
    }
    this.myStepper.next();
  }

  public back(): void {
    if (this.tabIndex > 0) {
      this.tabIndex--;
    }
  }

  public async save(): Promise<void> {
    if (this.files && this.files?.length > 0) {
      this.data.model.actionNotes = [
        {
          id: null,
          notes: null,
          fileInfos: this.files[0]?.map((file) => ({ id: null, azureId: file?.data?.name }))
        }
      ];
    }

    if (this.data.parentAction) {
      this.data.model.parentAction = {
        id: this.data.parentAction
      };
    }

    if (this.data.type === 'CL') {
      this.dialog.getDialogById('new-action-tracker-dialog-id').close(this.data.model);
    } else {
      const spinner = this.spinnerService.show();
      this.actionTrackerService.save(this.data.model).subscribe(
        (data) => {
          this.spinnerService.hide(spinner);

          if (this.files && this.files.length > 0) {
            this.azure.getClient().subscribe(async (client) => {
              const fileSpinner = this.spinnerService.show();
              let filestoUpload = this.files.length - 1;

              for await (const file of this.files) {
                for (const file of this.files) {
                }
                this.globalMessage.showLoading({
                  message: this.translate.instant(marker('azure.uploading'), { filename: file[0]?.data?.name })
                });

                client.upload(
                  `${AZURE_ACTION_PATH}/${data.id}${AZURE_NOTES_PATH}/${data.actionNotes[0].id}`,
                  file[0].data,
                  file[0].data.name,
                  () => {
                    if (filestoUpload-- === 0) {
                      this.spinnerService.hide(fileSpinner);
                      this.globalMessage.dismiss();
                      this.closeDialogSuccess();
                    }
                  }
                );
              }
            });
          } else {
            this.closeDialogSuccess();
          }
        },
        (error: EdmsError) => {
          this.error = error;
        }
      );
    }
  }

  /* QUESTA SALVA LE IMG A LIVELLO SUPERIORE */

  public saveDDSMetrics() {
    const spinner = this.spinnerService.show();

    if (!this.data.model.title) {
      this.data.model.title = 'Action DDS';
    }

    /*   if (!this.data.model.actionTracker) {
      this.data.model.trackerAction = null;
    }
 */
    this.managementService
      .addMetricsActions(this.data.ddsChecklist.id, this.data.model)
      .pipe(
        finalize(() => {
          this.spinnerService.hide(spinner);
        })
      )
      .subscribe({
        next: () => {
          this.closeDialogSuccess();
        }
      });
  }

  public saveDDS() {
    if (this.data.ddsChecklist) {
      if (!this.data.ddsChecklist?.actions || this.data.ddsChecklist?.actions === null) {
        this.data.ddsChecklist.actions = [];
      }
      this.data.ddsChecklist.actions.push(this.data.model);

      if (
        this.data.files &&
        this.data.files.length > 0 &&
        this.data.files[this.data.files.length - 1] &&
        this.data.files[this.data.files.length - 1].length > 0
      ) {
        this.data.model.filesInfos = [];
        this.data.files[this.data.files.length - 1].forEach((file) => {
          this.data.model.filesInfos.push({ id: null, azureId: file.data.name });
        });
      }

      this.closeDialogSuccess();
    }
  }
  /*
  public saveDDS() {
    if (this.data.ddsChecklist) {
      if (!this.data.ddsChecklist?.actions || this.data.ddsChecklist?.actions === null) {
        this.data.ddsChecklist.actions = [];
      }
      this.data.ddsChecklist.actions.push(this.data.model);

      if (
        this.data.files &&
        this.data.files.length > 0 &&
        this.data.files[this.data.files.length - 1] &&
        this.data.files[this.data.files.length - 1].length > 0
      ) {
        this.data.model.actionNotes = [
          {
            id: null,
            notes: null,
            fileInfos: this.data.files[this.data.files.length - 1].map((file) => ({ id: null, azureId: file.data.name }))
          }
        ];
      }
      this.closeDialogSuccess();
    }
  } */

  public close(): void {
    this.confirmDialog.open(confirmBeforeLeavingConfig).subscribe((result) => {
      if (result) {
        this.dialog.getDialogById('new-action-tracker-dialog-id').close();
      }
    });
  }

  private closeDialogSuccess(): void {
    this.dialog.getDialogById('new-action-tracker-dialog-id').close(true);
    this.globalMessage.showSuccess({
      message: this.translate.instant(marker('global.messages.actionSuccesfullyCreated'), {
        entity: this.translate.instant(marker('entity.action'))
      }),
      duration: GLOBAL_MESSAGE_5SEC_DURATION
    });
  }
}
