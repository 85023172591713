import { Env } from '@app/types/env';
import { NgxLoggerLevel } from 'ngx-logger';

export const environment: Env = {
  appVersion: `${require('../../package.json').version}-dev`,
  production: true,
  apiBaseUrl: 'https://dev-apim.integration.bacardiapps.com:443/v1/transaction/checklists',
  apiBaseUrlIdea: 'https://dev-apim.integration.bacardiapps.com:443/v01/ideapp',
  apiManagmentKey: 'gtwkXCX1nGHKsALqRHeprEMG1kRaWV3D',
  logLevel: NgxLoggerLevel.ERROR,
  clientId: '1ac140d9-1b38-4688-a2bb-453dcfa61fbf',
  authority: 'https://login.microsoftonline.com/9e16780e-719d-496b-a880-24ac20c30a60',
  azureRoot: 'dev',
  clientSecretOauth: 'SS4E9kpGoBYuKt1M',
  endpointAccessToken: 'https://dev-apim.integration.bacardiapps.com/v1/oauthv2/cors/token',
  powerApplink: '',
  ideaUid: '',
  clientIdGraphs: 'acca9fb1-6f23-4a07-b3cb-2fcb4439ebe2',
  clientSecretGraphs: 'A5d7Q~TcST3x6wb3btVgBXIDnLU6gvFtpAC2D',
  endpointGraphsAccessToken: 'https://login.microsoftonline.com/9e16780e-719d-496b-a880-24ac20c30a60/oauth2/token'
};
