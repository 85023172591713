import { InjectionToken } from '@angular/core';
// import { GigyaService } from '@app/security/gigya.service';
import { Env } from '@app/types/env';
import { APP_TYPE_ENUM } from '@shared/constants/app-type.constants';
import { LANG_EN } from '@shared/constants/language.constants';

export const DEFAULT_I18N_LANG = LANG_EN;
export const ENV = new InjectionToken<Env>('ENV');
// export const GIGYA_SERVICE = new InjectionToken<GigyaService>('GIGYA_SERVICE');
export const NAVIGATOR = new InjectionToken('NAVIGATOR');
export const SUPER_USER = 1000;
export const STAKEHOLDER = 1100;
export const OPERATOR = 10;
export const TECNICHIAN = 20;
export const GLOBAL_SUPER_USER = 1200;
export const LINE_LEAD = 30;
export const PROCESS_LEAD = 30;
export const TEAM_LEAD = 20;
export const SAFETY_TRIGGER_CHECKLIST_APP_ID = 1;
export const MEGABYTE = 1048576;

export const MAX_FILE_SIZE = 5 * MEGABYTE;
export const MAX_VIDEO_SIZE = 200 * MEGABYTE;
export enum PROJECT_ENUM {
  'ebos' = 1,
  'idea' = 2
}
export enum LEVEL_CATEGORY_ENUM {
  'OC' = 1,
  'location' = 2,
  'department' = 3
}

export enum CL_PARAMETER_TYPES {
  'SHIFT' = 1,
  'CHANGEOVER' = 2,
  'ELECTRICAL' = 3
}
export const APP_SETUP = new Map([
  [
    APP_TYPE_ENUM.DATA_GATHERING,
    {
      group: { visible: true, predefinedValue: '' },
      hyperlink: {
        visible: false,
        predefinedValue: ''
      },
      roles: {
        visible: true,
        predefinedValue: 'admin'
      },
      schedule: {
        visible: true,
        predefinedValue: ''
      },
      appName: {
        visible: true,
        predefinedValue: ''
      },
      abbreviation: {
        visible: true,
        predefinedValue: ''
      },
      local: {
        visible: false,
        predefinedValue: ''
      },
      result: {
        visible: true,
        predefinedValue: ''
      },
      parameter: {
        visible: false,
        predefinedValue: ''
      },
      warning: {
        visible: false,
        predefinedValue: ''
      },
      questions: {
        visible: false,
        predefinedValue: ''
      },
      stops: {
        visible: false,
        predefinedValue: ''
      },
      info: {
        visible: false,
        predefinedValue: ''
      },
      location: {
        visible: false,
        predefinedValue: ''
      }
    }
  ],
  [
    APP_TYPE_ENUM.HYPERLINK,
    {
      group: { visible: true, predefinedValue: '' },
      hyperlink: {
        visible: true,
        predefinedValue: ''
      },
      roles: {
        visible: true,
        predefinedValue: 'admin'
      },
      schedule: {
        visible: false,
        predefinedValue: '7'
      },
      appName: {
        visible: true,
        predefinedValue: ''
      },
      abbreviation: {
        visible: true,
        predefinedValue: ''
      },
      local: {
        visible: false,
        predefinedValue: ''
      },
      result: {
        visible: false,
        predefinedValue: ''
      },
      parameter: {
        visible: false,
        predefinedValue: ''
      },
      warning: {
        visible: false,
        predefinedValue: ''
      },
      questions: {
        visible: false,
        predefinedValue: ''
      },
      stops: {
        visible: false,
        predefinedValue: ''
      },
      info: {
        visible: false,
        predefinedValue: ''
      },
      location: {
        visible: false,
        predefinedValue: ''
      }
    }
  ],
  [
    APP_TYPE_ENUM.SAFETY_TRIGGER,
    {
      group: { visible: false, predefinedValue: 'safety' },
      hyperlink: {
        visible: false,
        predefinedValue: ''
      },
      roles: {
        visible: true,
        predefinedValue: 'admin'
      },
      schedule: {
        visible: false,
        predefinedValue: '1'
      },
      appName: {
        visible: true,
        predefinedValue: 'Safety trigger'
      },
      abbreviation: {
        visible: true,
        predefinedValue: 'ST'
      },
      local: {
        visible: false,
        predefinedValue: ''
      },
      result: {
        visible: false,
        predefinedValue: 'true'
      },
      parameter: {
        visible: false,
        predefinedValue: ''
      },
      warning: {
        visible: false,
        predefinedValue: ''
      },
      questions: {
        visible: false,
        predefinedValue: ''
      },
      stops: {
        visible: false,
        predefinedValue: ''
      },
      info: {
        visible: false,
        predefinedValue: ''
      },
      location: {
        visible: false,
        predefinedValue: ''
      }
    }
  ],
  [
    APP_TYPE_ENUM.HEALTH_CHECK,
    {
      group: { visible: true, predefinedValue: '' },
      hyperlink: {
        visible: false,
        predefinedValue: ''
      },
      roles: {
        visible: true,
        predefinedValue: 'admin'
      },
      schedule: {
        visible: true,
        predefinedValue: ''
      },
      appName: {
        visible: true,
        predefinedValue: 'Health Check'
      },
      abbreviation: {
        visible: true,
        predefinedValue: 'HC'
      },
      local: {
        visible: false,
        predefinedValue: ''
      },
      result: {
        visible: false,
        predefinedValue: 'true'
      },
      parameter: {
        visible: false,
        predefinedValue: ''
      },
      warning: {
        visible: false,
        predefinedValue: ''
      },
      questions: {
        visible: false,
        predefinedValue: ''
      },
      stops: {
        visible: false,
        predefinedValue: ''
      },
      info: {
        visible: false,
        predefinedValue: ''
      },
      location: {
        visible: false,
        predefinedValue: ''
      }
    }
  ],
  [
    APP_TYPE_ENUM.FORMS,
    {
      group: { visible: true, predefinedValue: '' },
      hyperlink: {
        visible: false,
        predefinedValue: ''
      },
      roles: {
        visible: true,
        predefinedValue: 'admin'
      },
      schedule: {
        visible: true,
        predefinedValue: ''
      },
      appName: {
        visible: true,
        predefinedValue: ''
      },
      abbreviation: {
        visible: true,
        predefinedValue: ''
      },
      local: {
        visible: false,
        predefinedValue: ''
      },
      result: {
        visible: false,
        predefinedValue: 'false'
      },
      parameter: {
        visible: false,
        predefinedValue: ''
      },
      warning: {
        visible: false,
        predefinedValue: ''
      },
      questions: {
        visible: false,
        predefinedValue: ''
      },
      stops: {
        visible: false,
        predefinedValue: ''
      },
      info: {
        visible: false,
        predefinedValue: ''
      },
      location: {
        visible: false,
        predefinedValue: ''
      }
    }
  ],
  [
    APP_TYPE_ENUM.DEFECT_HANDLING,
    {
      group: { visible: true, predefinedValue: 'production' },
      hyperlink: {
        visible: false,
        predefinedValue: ''
      },
      roles: {
        visible: true,
        predefinedValue: 'admin'
      },
      schedule: {
        visible: true,
        predefinedValue: ''
      },
      appName: {
        visible: true,
        predefinedValue: 'Defect Handling'
      },
      abbreviation: {
        visible: true,
        predefinedValue: 'DH'
      },
      local: {
        visible: false,
        predefinedValue: ''
      },
      result: {
        visible: false,
        predefinedValue: 'false'
      },
      parameter: {
        visible: false,
        predefinedValue: ''
      },
      warning: {
        visible: false,
        predefinedValue: ''
      },
      questions: {
        visible: false,
        predefinedValue: ''
      },
      stops: {
        visible: false,
        predefinedValue: ''
      },
      info: {
        visible: false,
        predefinedValue: ''
      },
      location: {
        visible: false,
        predefinedValue: ''
      }
    }
  ],
  [
    APP_TYPE_ENUM.CIL,
    {
      group: { visible: false, predefinedValue: 'production' },
      hyperlink: {
        visible: false,
        predefinedValue: ''
      },
      roles: {
        visible: true,
        predefinedValue: ''
      },
      schedule: {
        visible: false,
        predefinedValue: '1'
      },
      appName: {
        visible: true,
        predefinedValue: 'Cleaning, Inspection and Lubrication'
      },
      abbreviation: {
        visible: true,
        predefinedValue: 'CIL'
      },
      local: {
        visible: false,
        predefinedValue: ''
      },
      result: {
        visible: false,
        predefinedValue: 'true'
      },
      parameter: {
        visible: false,
        predefinedValue: ''
      },
      warning: {
        visible: false,
        predefinedValue: ''
      },
      questions: {
        visible: false,
        predefinedValue: ''
      },
      stops: {
        visible: false,
        predefinedValue: ''
      },
      info: {
        visible: false,
        predefinedValue: ''
      },
      location: {
        visible: false,
        predefinedValue: ''
      }
    }
  ],
  [
    APP_TYPE_ENUM.CENTER_LINE,
    {
      group: { visible: false, predefinedValue: 'production' },
      hyperlink: {
        visible: false,
        predefinedValue: ''
      },
      roles: {
        visible: true,
        predefinedValue: ''
      },
      schedule: {
        visible: true,
        predefinedValue: '1'
      },
      appName: {
        visible: false,
        predefinedValue: 'Centerline'
      },
      abbreviation: {
        visible: false,
        predefinedValue: 'CL'
      },
      local: {
        visible: false,
        predefinedValue: ''
      },
      result: {
        visible: true,
        predefinedValue: 'true'
      },
      parameter: {
        visible: true,
        predefinedValue: ''
      },
      warning: {
        visible: true,
        predefinedValue: ''
      },
      questions: {
        visible: true,
        predefinedValue: ''
      },
      stops: {
        visible: false,
        predefinedValue: ''
      },
      info: {
        visible: false,
        predefinedValue: ''
      },
      location: {
        visible: false,
        predefinedValue: ''
      }
    }
  ],
  [
    APP_TYPE_ENUM.DDS,
    {
      group: { visible: true, predefinedValue: '' },
      hyperlink: {
        visible: false,
        predefinedValue: ''
      },
      roles: {
        visible: true,
        predefinedValue: ''
      },
      schedule: {
        visible: true,
        predefinedValue: ''
      },
      appName: {
        visible: true,
        predefinedValue: ''
      },
      abbreviation: {
        visible: true,
        predefinedValue: ''
      },
      local: {
        visible: false,
        predefinedValue: ''
      },
      result: {
        visible: false,
        predefinedValue: 'false'
      },
      parameter: {
        visible: false,
        predefinedValue: ''
      },
      warning: {
        visible: false,
        predefinedValue: ''
      },
      questions: {
        visible: false,
        predefinedValue: ''
      },
      stops: {
        visible: true,
        predefinedValue: ''
      },
      info: {
        visible: true,
        predefinedValue: ''
      },
      location: {
        visible: false,
        predefinedValue: ''
      }
    }
  ],
  [
    APP_TYPE_ENUM.TABLE_EQUIPMENT,
    {
      group: { visible: true, predefinedValue: '' },
      hyperlink: {
        visible: false,
        predefinedValue: ''
      },
      roles: {
        visible: true,
        predefinedValue: ''
      },
      schedule: {
        visible: true,
        predefinedValue: ''
      },
      appName: {
        visible: true,
        predefinedValue: ''
      },
      abbreviation: {
        visible: true,
        predefinedValue: ''
      },
      local: {
        visible: false,
        predefinedValue: ''
      },
      result: {
        visible: false,
        predefinedValue: 'false'
      },
      parameter: {
        visible: false,
        predefinedValue: ''
      },
      warning: {
        visible: false,
        predefinedValue: ''
      },
      questions: {
        visible: false,
        predefinedValue: ''
      },
      stops: {
        visible: false,
        predefinedValue: ''
      },
      info: {
        visible: false,
        predefinedValue: ''
      },
      location: {
        visible: true,
        predefinedValue: ''
      }
    }
  ],
  [
    APP_TYPE_ENUM.TABLE_LOG,
    {
      group: { visible: true, predefinedValue: '' },
      hyperlink: {
        visible: false,
        predefinedValue: ''
      },
      roles: {
        visible: true,
        predefinedValue: ''
      },
      schedule: {
        visible: true,
        predefinedValue: ''
      },
      appName: {
        visible: true,
        predefinedValue: ''
      },
      abbreviation: {
        visible: true,
        predefinedValue: ''
      },
      local: {
        visible: false,
        predefinedValue: ''
      },
      result: {
        visible: false,
        predefinedValue: 'false'
      },
      parameter: {
        visible: false,
        predefinedValue: ''
      },
      warning: {
        visible: false,
        predefinedValue: ''
      },
      questions: {
        visible: false,
        predefinedValue: ''
      },
      stops: {
        visible: false,
        predefinedValue: ''
      },
      info: {
        visible: false,
        predefinedValue: ''
      },
      location: {
        visible: false,
        predefinedValue: ''
      }
    }
  ],
  [
    APP_TYPE_ENUM.IDEA,
    {
      group: { visible: true, predefinedValue: '' },
      hyperlink: {
        visible: false,
        predefinedValue: ''
      },
      roles: {
        visible: false,
        predefinedValue: ''
      },
      schedule: {
        visible: false,
        predefinedValue: '7'
      },
      appName: {
        visible: true,
        predefinedValue: ''
      },
      abbreviation: {
        visible: true,
        predefinedValue: ''
      },
      local: {
        visible: false,
        predefinedValue: ''
      },
      result: {
        visible: false,
        predefinedValue: 'false'
      },
      parameter: {
        visible: false,
        predefinedValue: ''
      },
      warning: {
        visible: false,
        predefinedValue: ''
      },
      questions: {
        visible: false,
        predefinedValue: ''
      },
      stops: {
        visible: false,
        predefinedValue: ''
      },
      info: {
        visible: false,
        predefinedValue: ''
      }
    }
  ],
  [
    APP_TYPE_ENUM.PST,
    {
      group: { visible: true, predefinedValue: '' },
      hyperlink: {
        visible: false,
        predefinedValue: ''
      },
      roles: {
        visible: true,
        predefinedValue: 'all'
      },
      schedule: {
        visible: false,
        predefinedValue: '7'
      },
      allowInvestigation: {
        visible: true,
        predefinedValue: false
      },
      appName: {
        visible: true,
        predefinedValue: ''
      },
      abbreviation: {
        visible: true,
        predefinedValue: ''
      },
      local: {
        visible: false,
        predefinedValue: ''
      },
      result: {
        visible: false,
        predefinedValue: 'false'
      },
      parameter: {
        visible: false,
        predefinedValue: ''
      },
      warning: {
        visible: false,
        predefinedValue: ''
      },
      questions: {
        visible: false,
        predefinedValue: ''
      },
      stops: {
        visible: false,
        predefinedValue: ''
      },
      info: {
        visible: false,
        predefinedValue: ''
      }
    }
  ],
  [
    APP_TYPE_ENUM.QR,
    {
      group: { visible: true, predefinedValue: '' },
      hyperlink: {
        visible: false,
        predefinedValue: ''
      },
      roles: {
        visible: true,
        predefinedValue: 'all'
      },
      schedule: {
        visible: false,
        predefinedValue: '7'
      },
      allowInvestigation: {
        visible: false,
        predefinedValue: false
      },
      appName: {
        visible: true,
        predefinedValue: ''
      },
      abbreviation: {
        visible: true,
        predefinedValue: ''
      },
      local: {
        visible: false,
        predefinedValue: ''
      },
      result: {
        visible: false,
        predefinedValue: 'false'
      },
      parameter: {
        visible: false,
        predefinedValue: ''
      },
      warning: {
        visible: false,
        predefinedValue: ''
      },
      questions: {
        visible: false,
        predefinedValue: ''
      },
      stops: {
        visible: false,
        predefinedValue: ''
      },
      info: {
        visible: false,
        predefinedValue: ''
      }
    }
  ]
]);
