import { ACTION_TYPE_ENUM } from '@shared/constants/action-type.constants';
import { userAnswerValueDTO } from './answer-value-dto';
import UserDTO from './user-dto';

export class PossibleAnswerDTO {
  // questo campo sarà da sostituire con la action inserita dall'utente in fase di risposta
  // @jsonIgnore()
  // public actionAdded: boolean;

  public id?: number;
  // da rimuovere dopo merge con CIL/CL
  // public tmpId: number; // usato solo in fase di creazione quando non ho ancora l'id effettivo

  public possibleAnswerType?: number; // enum // per domande di tipo yes/no, 0/3, low/med/high
  public order?: number;
  public possibleAnswer: string;
  public actionRequired?: number; //enum ACTION_TYPES
  public expectedAnswer?: boolean;
  public answerString?: string;
  public answerNumber?: number;
  public usersAnswer?: userAnswerValueDTO[];
  // public get idOrTmpId(): number {
  //   // da rimuovere dopo merge con CIL/CL
  //   return this.id ?? this.tmpId;
  // }

  constructor(possibleAnswerType?: number, possibleAnswer?: string, order?: number) {
    this.possibleAnswer = possibleAnswer;
    this.possibleAnswerType = possibleAnswerType;
    this.order = order;

    this.actionRequired = ACTION_TYPE_ENUM.NOACTION;
    this.expectedAnswer = false;
  }
}
