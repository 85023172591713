export enum RouteConstants {
  // Common
  EMPTY = '',
  CREATE = 'create',
  CLONE = 'clone',
  EDIT = 'edit',
  DISPLAY = 'display',
  BACK = 'back',
  OTHER = '**',

  // Route Params
  ID = '/:id',

  // Login
  LOGIN = 'login',

  // Dashboard
  DASHBOARD = 'dashboard',
  DASHBOARD_IDEA = 'dashboard-idea',
  DASHBOARD_IDEA_MOBILE = 'dashboard-idea-mobile',

  // Management
  MANAGEMENT = 'management',
  SAFETY_TRIGGER_TEMPLATES = 'safety-trigger-templates',
  TEMPLATES = 'templates',
  BPST_TEMPLATE = 'template-bpst',
  STANDARD_TEMPLATE = 'template-standard',

  // Apps
  APPS = 'apps',
  APP_LANDING = 'app-landing',
  SAFETY_TRIGGER_CHECKLIST = 'safety-trigger-checklist',
  ACTION_TRACKER = 'action-tracker',
  ARCHIVE_LATEST_INFORMATION = 'archive-latest-information',
  ARCHIVE_TEMPLATES = 'archive-templates',
  ARCHIVE_CHECKLIST = 'archive-checklist',
  YOUR_ACTIONS = 'your-actions',

  //Latest Information
  LATEST_INFORMATION = 'latest-information',

  // Your checklists
  YOUR_CHECKLISTS = 'your-checklists',

  // Administration
  ADMINISTRATION = 'administration',
  OPERATION_CENTERS = 'operation-centers',
  USERS = 'users',
  WORKING_AREAS = 'working-areas',
  SHIFTS = 'shifts',
  FORMATS = 'formats',
  SOURCES = 'sources',
  CALENDAR = 'calendar',
  WEEKLYPLANNING = 'weekly-planning',
  SAPHIERARCHY = 'sap-hierarchy', //Safety-trigger
  SAFETY_TRIGGER = 'safety-trigger',
  CHECKLIST_MANAGEMENT = 'retention-management',
  AREAMANAGER = 'area-manager',

  // Administration Idea
  IDEA_GLOBAL_CATEGORIES = 'global-categories',
  IDEA_CATEGORIES = 'categories',
  IDEA_DEPARTMENT_HEAD = 'department-head',
  IDEA_CATEGORY_APPROVER_EVALUATOR = 'category-approver-evaluator',
  IDEAPP = 'ideapp',
  GENERAL_SETTINGS_IDEA = 'general-settings',

  //Idea
  YOUR_IDEA = 'your-ideas',
  IDEA_ACTION_TRACKER = 'idea-tracker',
  IDEA_TRACKER = 'idea-tracker',
  IDEA_DETAIL = 'idea-detail',
  IDEA_LOCATION = 'idea-location',
  IDEA_FILL = 'fill-idea'
}
