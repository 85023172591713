import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { GlobalMessageConfig } from '@shared/services/global-message.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './global-message.component.html',
  styleUrls: ['./global-message.component.scss']
})
export class GlobalMessageComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<GlobalMessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: GlobalMessageConfig
  ) {}
}
