import { AbstractControl, ValidatorFn } from '@angular/forms';

export const requireCheckboxesToBeCheckedValidator = (): ValidatorFn => {
  const validate = (control: AbstractControl) => {
    if (control.value?.length > 0) {
      return null;
    }
    return { noOptionsSelected: true };
  };

  return validate;
};
