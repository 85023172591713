<div #target></div>
<form autocomplete="off" class="location-component__form" *ngIf="formGroup" [formGroup]="formGroup" (change)="updateModel()">
    <div fxLayout="column" fxLayoutAlign="space-evenly stretch">
        <mat-label>{{ labels.department | translate }}</mat-label>
        <mat-button-toggle-group [formControl]="departmentControl">
            <mat-button-toggle
                *ngFor="let userDepartment of departmentList; index as i"
                [value]="userDepartment.department.id"
                (change)="onDepartmentSelect(i)"
                >{{ userDepartment.department.literal.translateValue }}</mat-button-toggle
            >
        </mat-button-toggle-group>
        <mat-error class="error" *ngIf="departmentControl.touched && departmentControl.invalid">{{
            labels.requiredError | translate
        }}</mat-error>

        <!-- Working area -->
        <mat-label>
            {{ labels.workingArea | translate }}
            <span class="muted">({{ labels.optional | translate | lowercase }})</span>
        </mat-label>
        <mat-button-toggle-group [formControl]="workingAreaControl" (change)="toggleChange($event)" multiple>
            <mat-button-toggle
                *ngFor="let userWorkingArea of workingAreaList; index as i"
                [value]="userWorkingArea.workingArea.id"
                (change)="onWorkingAreaSelect(i)"
                >{{ userWorkingArea.workingArea.literal.translateValue }}</mat-button-toggle
            >
        </mat-button-toggle-group>

        <mat-error class="error" *ngIf="workingAreaControl.touched && workingAreaControl.invalid">{{
            labels.requiredError | translate
        }}</mat-error>

        <!-- Equipment -->
        <mat-label *ngIf="workingAreaControl.valid && workingAreaControl?.value?.length <= 1"
            >{{ labels.equipment | translate }}
            <span class="muted">({{ labels.optional | translate | lowercase }})</span></mat-label
        >
        <mat-form-field
            *ngIf="workingAreaControl.valid && equipmentList && equipmentList?.length && workingAreaControl?.value?.length === 1"
        >
            <mat-select [formControl]="equipmentControl" (blur)="updateModel()">
                <mat-option *ngFor="let userEquipment of equipmentList" [value]="userEquipment?.equipment?.id">{{
                    userEquipment?.equipment?.literal?.translateValue
                }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</form>
