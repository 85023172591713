import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV } from '@app/constants/global.constants';
import { Env } from '@app/types/env';
import { EdmsError } from '@app/types/error';
import { BlobServiceClient } from '@azure/storage-blob';
import { AzureCredentialsDTO } from '@data/models/azure-credentials-dto';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AzureClient } from '../utils/azure-client';

@Injectable()
export class AzureBlobStorageService {
  private readonly GET_AZURE_SAS = '/api/azureStorage/getHash';

  constructor(@Inject(ENV) private env: Env, private http: HttpClient) {}

  public getClient(): Observable<AzureClient> {
    return this.http.get<AzureCredentialsDTO>(`${this.env.apiBaseUrl}${this.GET_AZURE_SAS}`).pipe(
      map(
        (credentials) =>
          new AzureClient(
            this.env.azureRoot,
            new BlobServiceClient(
              // eslint-disable-next-line max-len
              `https://${credentials.accountName}.blob.core.windows.net${credentials.url}`
            )
          )
      ),
      catchError((error) => throwError(error as EdmsError))
    );
  }
}
