<div class="android-how2">
    <div class="Message" (click)="a2hs.addToHomeScreen()">
        <div fxLayout="row" fxLayoutAlign="space-between center" style="width:  100%">
            <span class="Definition">
                <mat-icon fontSet="edms">basics-alerts-information-circle</mat-icon>
                <span>Add Shortcut To Home/Apps
                    Screen</span>
            </span>
            <mat-icon fontSet="edms" class="mat-icon notranslate material-icons  close-icon"
                (click)="close();$event.stopPropagation();">icon-close</mat-icon>
        </div>
    </div>
</div>