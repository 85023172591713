import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ConfirmDialogConfig } from '../components/confirm-dialog/confirm-dialog.component';

export const confirmBeforeLeavingConfig: ConfirmDialogConfig = {
  title: marker('confirmdialog.title.unsavedChanges'),
  message: marker('confirmdialog.message.areYouSureYouWantToLeave'),
  yesActionText: marker('confirmdialog.yesActionText.leaveAnyway'),
  noActionText: marker('confirmdialog.noActionText.cancel')
};

export const confirmBeforeQuitConfig: ConfirmDialogConfig = {
  title: marker('confirmdialog.message.closePopup'),
  message: marker('confirmdialog.message.areYouSureYouWantToLeaveShort'),
  yesActionText: marker('confirmdialog.yesActionText.leave'),
  noActionText: marker('confirmdialog.noActionText.cancel')
};
