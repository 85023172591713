import { PriorityDTO } from '@data/models/priority-dto';
import { FileDTO } from '@data/models/file-dto';
import { CheckListItemDTO } from '@data/models/checklist-item-dto';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { PossibleAnswerDTO } from '@data/models/possible-answer-dto';
import { QuestionDTO } from '@data/models/question-dto';
import { ACTION_TYPE_ENUM } from '@shared/constants/action-type.constants';
import { TranslateService } from '@ngx-translate/core';
import {
  ANSWER_TYPE_ENUM,
  POSSIBLE_ANSWERS_ZERO_TO_THREE,
  POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH,
  POSSIBLE_ANSWERS_YES_NO
} from '@shared/constants/answer-type.constants';
import { APP_TYPE_ENUM } from '@shared/constants/app-type.constants';
import { CreateActionDialogComponent } from '../create-action-dialog/create-action-dialog.component';
import { FileRefDTO } from '@data/models/file-ref-dto';
import { ViewImmagesDialogComponent } from '../view-immages-dialog/view-immages-dialog.component';
import { AzureBlobStorageService } from '@shared/services/azure-blob-storage.service';
import {
  AZURE_CHECKLIST_PATH,
  AZURE_QUESTION_PATH,
  AZURE_SIGNATURE_PATH,
  AZURE_TEMPLATE_PATH
} from '@shared/constants/azure.constants';
import { MAX_FILE_SIZE } from '@app/constants/global.constants';
import { GlobalMessageService } from '@shared/services/global-message.service';
import { AnswerValueDTO, userAnswerValueDTO } from '@data/models/answer-value-dto';
import { AdministrationService } from '@data/services/administration.service';
import { ProgressSpinnerDialogService } from '@shared/services/progress-spinner-dialog.service';
import { EquipmentDTO } from '@data/models/equipment-dto';
import { WorkingAreaDTO } from '@data/models/workingarea-dto';
import { ManagementServiceService } from '@data/services/management-service.service';
import { finalize, take } from 'rxjs/operators';
import { ENTITY_TYPES_ENUM } from '@shared/constants/entities.constants';
import { UserAD, UserADResponse } from '@data/models/user-AD';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { AppTypeDTO } from '@data/models/app-type-dto';
import { TemplateBpstDTO } from '@data/models/template-bpst-dto';
import { ChecklistBpstDTO, StageAnswerDTO } from '@data/models/checklist-bpst-dto';
// eslint-disable-next-line max-len
import { NewActionTrackerDialogComponent } from '@modules/dashboard/custom_apps/app-landing/others-app/action-tracker/dialog/new/new-action-tracker-dialog.component';
import { NoteDTO } from '@data/models/note-dto';
import { ActionTrackerDTO } from '@data/models/action-tracker-dto';
import { ActionTrackerFilterDTO } from '@data/models/action-tracker-filters-dto';
import { ActionDTO } from '@data/models/action-dto';
import { ACTION_TRACKER_PRIORITIES_ENUM } from '@shared/constants/status.constants';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { QuestionsBpstPreviewComponent } from './questions-bpst-preview.component';
import { MatSelectionListChange } from '@angular/material/list';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-questions-template',
  templateUrl: './questions-template.html',
  styleUrls: ['./questions-bpst-preview.component.scss']
})
export class QuestionsTemplateComponent implements OnInit, AfterViewInit {
  @ViewChild('signature')
  public signaturePad: SignaturePadComponent;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input()
  questions: QuestionDTO[];
  @Input() Dates: Date[];
  @Input() stage: StageAnswerDTO;
  @Input() files: FileDTO[] = [];
  @Input() stageN: number;
  @Input() problemStatement: string;
  @Input() pdfView: boolean;
  @Input() tabActive: number;
  @Input() checkQuestions: QuestionDTO[];
  @Input() chosenAnswer: Map<string, PossibleAnswerDTO>;
  @Input() chosenAnswers: Map<string, PossibleAnswerDTO[]>;
  @Input() appTypeId: number;
  @Input() appName: string;
  @Input() appId: number;
  @Input() checklistId: number;
  @Input() isPreview: boolean;
  @Input() isReadOnly: boolean;
  @Input() checklistItems: CheckListItemDTO[];
  @Input() template: TemplateBpstDTO;
  @Input() priorityList: PriorityDTO[];
  @Input() appResults: boolean;
  @Input() templateId: number;
  @Input() checklist?: ChecklistBpstDTO;
  @Input() actions: ActionDTO[];
  @Input() questionFiles: Map<string, FileDTO[]>;
  @Input() get questionFilesTemplate(): Map<string, FileDTO[]> {
    return this.param;
  }
  set questionFilesTemplate(value: Map<string, FileDTO[]>) {
    this.param = value;
  }

  public myDate = new Date();
  public labels: { [key: string]: string } = {
    stage2subtitle: marker('management.templatesDialog.stage2subtitle'),
    actions: marker('management.templatesDialog.actions'),
    status: marker('management.templatesDialog.status'),
    noActions: marker('management.templatesDialog.noActions'),
    addAction: marker('management.templatesDialog.addAction'),
    summary: marker('management.templatesDialog.summary'),
    summarySubtitle: marker('management.templatesDialog.summarySubtitle'),
    actionPlanStage: marker('management.templatesDialog.actionPlanStage'),
    actionPlanStageSubtitle: marker('management.templatesDialog.actionPlanStageSubtitle'),
    problemRefocus: marker('management.templatesDialog.problemRefocus'),
    problemRefocusSubtitle: marker('management.templatesDialog.problemRefocusSubtitle'),
    stage: marker('management.templatesDialog.Stage'),
    titleFill: marker('management.templatesDialog.titleFill'),
    problemStatement: marker('management.templatesDialog.problemStatement'),
    problemStatementSubtitle: marker('management.templatesDialog.Subtitle'),
    investigation: marker('management.templatesDialog.investigation'),
    investigationSubtitle: marker('management.templatesDialog.investigationSubtitle'),
    title: marker('management.templatesDialog.title'),
    subtitle: marker('management.templatesDialog.subtitle'),
    select: marker('management.templatesDialog.select'),
    min: marker('management.templatesDialog.min'),
    max: marker('management.templatesDialog.max'),
    userCanAddAction: marker('management.templatesDialog.userCanAddAction'),
    editList: marker('management.templatesDialog.editList'),
    answerRequired: marker('management.templatesDialog.answerRequired'),
    notApplicable: marker('management.templatesDialog.notApplicable'),
    commentToAnswer: marker('management.templatesDialog.commentToAnswer'),
    emptyGroup: marker('management.templatesDialog.emptyGroup'),
    enterAnswer: marker('management.templatesDialog.enterAnswer'),
    optionalAction: marker('management.templatesDialog.optionalAction'),
    requiredAction: marker('management.templatesDialog.requiredAction'),
    editAction: marker('management.templatesDialog.editAction'),
    addComment: marker('management.templatesDialog.addComment'),
    optional: marker('global.optional'),
    describeIssueBtn: marker('management.templatesDialog.describeIssueBtn'),
    browseFiles: marker('global.browseFiles'),
    fileInfo: marker('management.templatesDialog.fileSub'),
    optionalWithBraces: marker('management.appDialog.optional'),
    file: marker('management.templatesDialog.file'),
    fileSub: marker('management.templatesDialog.fileSub'),
    attachSignature: marker('management.appDialog.attachSignature'),
    signatureSubtitle: marker('management.templatesDialog.signatureSubtitle'),
    signatureTitle: marker('management.templatesDialog.signatureTitle')
  };

  public stringFormControl = new FormControl('');
  public answerTypeEnum = ANSWER_TYPE_ENUM;
  public actionTypeEnum = ACTION_TYPE_ENUM;
  public appTypeEnum = APP_TYPE_ENUM;
  public fileToSave: FileDTO[];

  public actionsList: ActionDTO[] = [];

  public actionsStage1: ActionDTO[] = [];
  public actionsStage2: ActionDTO[] = [];

  public currentActions: ActionDTO[] = [];
  public followUpActions: ActionDTO[] = [];
  public prioritiesEnum = ACTION_TRACKER_PRIORITIES_ENUM;
  //new types
  public equipmentList: EquipmentDTO[] = [];
  public workingAreaList: WorkingAreaDTO[] = [];
  public newTypes = false;
  public commentVisible = [] as boolean[];
  public attachmentVisible = [] as boolean[];
  public notApplicableOption: PossibleAnswerDTO = new PossibleAnswerDTO(-1, this.labels.notApplicable, 999);
  public fileRefs: FileRefDTO[] = [];
  public usersSelected = [] as boolean[];
  public questionsActionsFiles: {
    questionId: number;
    actionFiles: FileDTO[][];
    fileRefs: FileRefDTO[][];
  }[] = [];
  public suggestions: UserADResponse;

  public DatesAndTime = [] as Date[];
  public TimesAndDate = [] as string[];
  public notApplicableChecked: Map<string, boolean> = new Map();
  public comment: string[] = [];
  public actionAdded: Map<number, boolean> = new Map();
  public signature: FileDTO;
  public signaturePadOptions: NgSignaturePadOptions = {
    // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 600,
    canvasHeight: 70,
    backgroundColor: 'white'
  };

  // public questionFiles: Map<string, FileDTO[]> = new Map();
  // public actionTypeId: number;
  private param: Map<string, FileDTO[]>;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      //model: TemplateDTO;
      editMode: boolean;
      copyMode: boolean;
      appType: AppTypeDTO;
      appResults: boolean;
      plantId: number;
      operationCentersId: number;
    },

    private dialog: MatDialog,
    private azure: AzureBlobStorageService,
    private translateService: TranslateService,
    private globalMessage: GlobalMessageService,
    private administrationService: AdministrationService,
    private spinnerService: ProgressSpinnerDialogService,
    private managementService: ManagementServiceService
  ) {}

  public getDate(date: string) {
    return new Date(date);
  }

  public drawStart(event: MouseEvent | Touch) {}

  public drawClear() {
    this.signaturePad.clear();
    this.signature = {
      data: this.dataURItoBlob(this.signaturePad.toDataURL().replace('data:image/png;base64,', '')),
      SavedOnAzure: false
    } as FileDTO;
  }

  public drawComplete(event: MouseEvent | Touch) {
    this.signature = {
      data: this.dataURItoBlob(this.signaturePad.toDataURL().replace('data:image/png;base64,', '')),
      SavedOnAzure: false
    } as FileDTO;
  }
  public addSignatureFiles(file: FileList, attachmentindex?: number): void {
    this.signature = {
      data: file[0],
      SavedOnAzure: false
    } as FileDTO;

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = () => {
      this.signaturePad.fromDataURL(reader.result.toString());
    };
  }
  public ngAfterViewInit() {
    // this.signaturePad is now available
    if (!!this.signaturePad) {
      this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
      this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    }
  }

  ngOnInit() {}

  public input(index: number, option: EquipmentDTO) {
    //DEBUG
    if (this.checklistItems?.length) {
      if (this.checklistItems[index]?.answerValue?.equipmentsAnswer) {
        this.checklistItems[index].answerValue.equipmentsAnswer.push(option);
      } else {
        this.checklistItems[index].answerValue.equipmentsAnswer = [option];
      }
    }
  }
  public getPlantDetails(): void {
    const spinner = this.spinnerService.show();

    this.managementService
      .getFillByEntity(this.appId, this.template.filledBy.entityType, this.templateId)
      .pipe(
        finalize(() => {
          this.spinnerService.hide(spinner);
        })
      )
      .subscribe({
        next: (result) => {
          if (this.template.filledBy.entityType < 3) {
            if (this.template.filledBy.entityType === ENTITY_TYPES_ENUM.DEPARTMENT) {
              if (this.checklist) {
                for (const dep of this.template.filledBy.filledByItems.filter(
                  (x) => x.entityId === this.checklist.filledByItem.entityId
                )) {
                  this.workingAreaList.push(...result[0].departments.find((x) => x.id === dep.entityId).workingAreas);
                }
              } else {
                for (const dep of this.template.filledBy.filledByItems) {
                  this.workingAreaList.push(...result[0].departments.find((x) => x.id === dep.entityId).workingAreas);
                }
              }

              for (const wa of this.workingAreaList) {
                this.equipmentList.push(...wa.equipments);
              }
            } else if (this.template.filledBy.entityType === ENTITY_TYPES_ENUM.PLANT) {
              for (const dep of result[0].departments) {
                this.workingAreaList.push(...dep.workingAreas);
                for (const wa of dep.workingAreas) {
                  this.equipmentList.push(...wa.equipments);
                }
              }
            } else {
              //solo eq per specifica wa
              if (this.checklist) {
                for (const wa of this.template.filledBy.filledByItems.filter(
                  (x) => x.entityId === this.checklist.filledByItem.entityId
                )) {
                  for (const dep of result[0].departments) {
                    for (const was of dep.workingAreas) {
                      if (wa.entityId === was.id) {
                        this.equipmentList.push(...was.equipments);
                      }
                    }
                  }
                }
              } else {
                for (const wa of this.template.filledBy.filledByItems) {
                  for (const dep of result[0].departments) {
                    for (const was of dep.workingAreas) {
                      if (wa.entityId === was.id) {
                        this.equipmentList.push(...was.equipments);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
  }

  public searchUsers(userName: string, el: MatIcon): void {
    const fullName = userName;
    if (fullName && fullName.length >= 2) {
      const spinner = this.spinnerService.show();
      this.administrationService
        .getUser(fullName)
        .pipe(
          finalize(() => {
            this.spinnerService.hide(spinner);
          })
        )
        .subscribe((data: UserADResponse) => {
          this.suggestions = data;
          for (const s of this.suggestions.value) {
            this.usersSelected.push(false);
            this.isChecked();
          }

          // eslint-disable-next-line  no-underscore-dangle
          el._elementRef.nativeElement.nextElementSibling.focus();
        });
    }
  }

  public displayUserAD(user: UserAD): string {
    return user ? (user?.surname && user?.displayName ? `${user?.displayName} ${user?.surname}` : `${user?.displayName}`) : '';
  }

  public show(i: number) {
    // this.setAnswersAndAnctions();
    this.commentVisible[i] = !this.commentVisible[i];
  }

  public showAttachment(i: number) {
    this.attachmentVisible[i] = !this.attachmentVisible[i];
  }
  public getArrayIndexBase(indexBase: number): number {
    // indexBase += this.questions.length;
    // return this.getArrayIndexBase(indexBase);
    return indexBase;
  }

  public checkConditions(question: QuestionDTO) {
    // no conditions === visible
    if (
      !question ||
      !question.conditions ||
      (question && question.conditions && question.conditions.length === 0) ||
      question.conditions === null ||
      this.checkQuestions.length === 0
    ) {
      return false;
    } else {
      //  console.log('question to show title, question : ', question.title, question.guid);
      const hidden = [];
      for (const condition of question.conditions) {
        if (condition.deleted === false) {
          let testQuest = [];
          testQuest = this.getQuestionFromKey(condition.questionGuid, this.checkQuestions);

          if (testQuest.length === 0) {
            hidden.push(true);
          } else {
            let answer;

            if (this.stageN !== undefined) {
              answer = this.getChosenAnswerStage(testQuest[0], 0);
            } else {
              answer = this.getChosenAnswer(testQuest[0]);
            }

            // console.log(answer);

            if (!!answer) {
              // must be shown
              if (condition.questionAnswerOption === answer.order) {
                hidden.push(false);
              } else {
                hidden.push(true);
              }
            } else {
              hidden.push(true);
            }
          }
        }
      }

      for (const val of hidden) {
        if (val === false) {
          return false;
        }
      }

      return true;
    }
  }

  public onNotApplicablePressed(question: QuestionDTO) {
    this.notApplicableChecked.set(
      this.getChosenAnswerKey(question),
      !this.notApplicableChecked.get(this.getChosenAnswerKey(question))
    );
    this.setChosenAnswer(question, {} as PossibleAnswerDTO);
  }

  public onPossibleAnswerPressed(question: QuestionDTO, option: PossibleAnswerDTO) {
    if (question.answerType.answerType === ANSWER_TYPE_ENUM.MULTIPLE_OPTIONS) {
      const index = this.getChosenAnswers(question).findIndex((x) => x.id === option.id);
      if (index >= 0) {
        this.getChosenAnswers(question).splice(index, 1);
      } else {
        this.getChosenAnswers(question).push(option);
      }
      this.notApplicableChecked.set(this.getChosenAnswerKey(question), this.getChosenAnswers(question).length === 0);
    } /*if (question.answerType.answerType !== ANSWER_TYPE_ENUM.FREE_TEXT)*/ else {
      const newOpt = JSON.parse(JSON.stringify(option));
      if (question.answerType.answerType === ANSWER_TYPE_ENUM.ONE_OPTION) {
        const value = JSON.parse(JSON.stringify(option.order));
        newOpt.possibleAnswerType = value;
      }
      // this.questionGroups[questionGroupIndex].questions[questionIndex].chosenAnswer = option;
      // question.chosenAnswer = option;
      this.setChosenAnswer(question, newOpt);
      this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
    }
  }

  public compareONE_OPTION(a: PossibleAnswerDTO, b: PossibleAnswerDTO): boolean {
    return a.order === b.order;
  }
  public compareEQUIPMENT(a: EquipmentDTO, b: EquipmentDTO): boolean {
    if (Array.isArray(a) && Array.isArray(b)) {
      return a[0].id === b[0].id;
    } else {
      return a.id === b.id;
    }
  }

  public compareWORKINGAREA(a: WorkingAreaDTO[], b: WorkingAreaDTO[]): boolean {
    return a[0].id === b[0].id;
  }
  public compareUSER(a: UserAD, b: UserAD): boolean {
    return a.mail === b.mail;
  }

  public isOptionalAction(question: QuestionDTO): boolean {
    return (
      // (
      //   (
      //     question.answerType.answerType === this.answerTypeEnum.FREE_TEXT ||
      //     question.answerType.answerType === this.answerTypeEnum.NUMBER ||
      //     question.answerType.answerType === this.answerTypeEnum.RANGE
      //   ) &&
      //   question.answerType?.actionRequired === this.actionTypeEnum.OPTIONAL
      // ) ||
      ((question.answerType.answerType === this.answerTypeEnum.FREE_TEXT ||
        question.answerType.answerType === this.answerTypeEnum.ZERO_TO_THREE ||
        question.answerType.answerType === this.answerTypeEnum.YES_NO ||
        question.answerType.answerType === this.answerTypeEnum.LOW_MEDIUM_HIGH ||
        question.answerType.answerType === this.answerTypeEnum.ONE_OPTION ||
        question.answerType.answerType === this.answerTypeEnum.MULTIPLE_OPTIONS) &&
        ((question.answerType.answerType === this.answerTypeEnum.FREE_TEXT &&
          question?.answerType?.actionRequired === this.actionTypeEnum.OPTIONAL) ||
          this.getChosenAnswer(question)?.actionRequired === this.actionTypeEnum.OPTIONAL)) ||
      (question.answerType.answerType === this.answerTypeEnum.RANGE &&
        question.answerType?.actionRequired === this.actionTypeEnum.OPTIONAL &&
        // question?.chosenAnswer?.answerNumber &&
        // (question?.chosenAnswer?.answerNumber > question.answerType.rangeMax ||
        //   question?.chosenAnswer?.answerNumber < question.answerType.rangeMin))
        this.getChosenAnswer(question)?.answerNumber &&
        (this.getChosenAnswer(question)?.answerNumber > question.answerType.rangeMax ||
          this.getChosenAnswer(question)?.answerNumber < question.answerType.rangeMin))
    );
  }

  public isMandatoryAction(question: QuestionDTO): boolean {
    return (
      // (
      //   (
      //     question.answerType.answerType === this.answerTypeEnum.FREE_TEXT ||
      //     question.answerType.answerType === this.answerTypeEnum.NUMBER ||
      //     question.answerType.answerType === this.answerTypeEnum.RANGE
      //   ) &&
      //   question.answerType?.actionRequired === this.actionTypeEnum.REQUIRED
      // ) ||
      ((question.answerType.answerType === this.answerTypeEnum.FREE_TEXT ||
        question.answerType.answerType === this.answerTypeEnum.ZERO_TO_THREE ||
        question.answerType.answerType === this.answerTypeEnum.YES_NO ||
        question.answerType.answerType === this.answerTypeEnum.LOW_MEDIUM_HIGH ||
        question.answerType.answerType === this.answerTypeEnum.ONE_OPTION ||
        question.answerType.answerType === this.answerTypeEnum.MULTIPLE_OPTIONS) &&
        // question.chosenAnswer?.actionRequired === this.actionTypeEnum.REQUIRED) ||
        this.getChosenAnswer(question)?.actionRequired === this.actionTypeEnum.REQUIRED) ||
      (question.answerType.answerType === this.answerTypeEnum.RANGE &&
        question.answerType?.actionRequired === this.actionTypeEnum.REQUIRED &&
        // question?.chosenAnswer?.answerNumber &&
        // (question?.chosenAnswer?.answerNumber > question.answerType.rangeMax ||
        //   question?.chosenAnswer?.answerNumber < question.answerType.rangeMin))
        this.getChosenAnswer(question)?.answerNumber &&
        (this.getChosenAnswer(question)?.answerNumber > question.answerType.rangeMax ||
          this.getChosenAnswer(question)?.answerNumber < question.answerType.rangeMin))
    );
  }

  public isMandatoryActionButtonVisible(question: QuestionDTO): boolean {
    return this.isMandatoryAction(question) && !this.actionAdded.get(question.id);
  }

  public isOptionalActionButtonVisible(question: QuestionDTO): boolean {
    return this.isOptionalAction(question) && !this.actionAdded.get(question.id);
  }

  public isEditActionButtonVisible(question: QuestionDTO): boolean {
    return (this.isMandatoryAction(question) || this.isOptionalAction(question)) && this.actionAdded.get(question.id);

    // return (
    //   (
    //     (
    //       (
    //         question.answerType.answerType === this.answerTypeEnum.FREE_TEXT ||
    //         question.answerType.answerType === this.answerTypeEnum.NUMBER ||
    //         question.answerType.answerType === this.answerTypeEnum.RANGE
    //       ) &&
    //       (
    //         question.answerType?.actionRequired === this.actionTypeEnum.OPTIONAL ||
    //         question.answerType?.actionRequired === this.actionTypeEnum.REQUIRED
    //       )
    //     ) ||
    //     (
    //       (
    //         question.answerType.answerType === this.answerTypeEnum.ZERO_TO_THREE ||
    //         question.answerType.answerType === this.answerTypeEnum.YES_NO ||
    //         question.answerType.answerType === this.answerTypeEnum.LOW_MEDIUM_HIGH ||
    //         question.answerType.answerType === this.answerTypeEnum.ONE_OPTION ||
    //         question.answerType.answerType === this.answerTypeEnum.MULTIPLE_OPTIONS
    //       ) &&
    //       (
    //         question.chosenAnswer?.actionRequired === this.actionTypeEnum.OPTIONAL ||
    //         question.chosenAnswer?.actionRequired === this.actionTypeEnum.REQUIRED
    //       )
    //     )
    //   ) &&
    //   question.answerType.actionAdded === false
    // );
  }

  public get isFormValid(): boolean {
    for (const question of this.questions) {
      for (const ck of this.checklistItems) {
        if (ck.question?.answerType?.answerType === ANSWER_TYPE_ENUM.DATE && this.Dates.findIndex((x) => x !== null) === -1) {
          if (!ck.answerValue.dateAnswer && !question.notApplicable) {
            return false;
          }
        }
        if (ck.question?.answerType?.answerType === ANSWER_TYPE_ENUM.DATE_AND_TIME) {
          if (
            !ck.answerValue.datetimeAnswer &&
            !question.notApplicable &&
            this.DatesAndTime.findIndex((x) => x !== null) === -1
          ) {
            return false;
          }
        }
        if (ck.question?.answerType?.answerType === ANSWER_TYPE_ENUM.TIME) {
          if (!ck.answerValue.timeAnswer && !question.notApplicable) {
            return false;
          }
        }
        if (
          ck.question.answerType?.answerType === ANSWER_TYPE_ENUM.EQUIPMENT ||
          ck.question.answerType?.answerType === ANSWER_TYPE_ENUM.EQUIPMENT_MULTIPLE
        ) {
          if (!ck.answerValue?.equipmentsAnswer?.length && !question.notApplicable) {
            return false;
          }
        }
        if (
          ck.question.answerType?.answerType === ANSWER_TYPE_ENUM.WORKINGAREA ||
          ck.question.answerType?.answerType === ANSWER_TYPE_ENUM.WORKINGAREA_MULTIPLE
        ) {
          if (!ck.answerValue?.equipmentsAnswer?.length && !question.notApplicable) {
            return false;
          }
        }
        if (
          ck.question?.answerType?.answerType === ANSWER_TYPE_ENUM.USER ||
          ck.question?.answerType?.answerType === ANSWER_TYPE_ENUM.USER_MULTIPLE
        ) {
          if (!ck.answerValue?.usersAnswer?.length && !question.notApplicable) {
            return false;
          }
        }
      }

      if (
        (question.answerRequired &&
          !this.getChosenAnswer(question)?.possibleAnswerType &&
          this.getChosenAnswer(question)?.possibleAnswerType !== 0 &&
          !this.getChosenAnswer(question)?.answerString &&
          !this.getChosenAnswer(question)?.answerNumber &&
          this.getChosenAnswer(question)?.answerNumber !== 0 &&
          (!this.getChosenAnswers(question) || this.getChosenAnswers(question).length === 0) &&
          // !question.notApplicableChecked) ||
          !this.notApplicableChecked.get(this.getChosenAnswerKey(question))) ||
        //action obbligatoria
        (this.isMandatoryAction(question) && !this.actionAdded.get(question.id))
      ) {
        return false;
      }
    }
    return true;
  }

  public deleteUser(users: userAnswerValueDTO[], index: number) {
    users.splice(index, 1);
    this.isChecked();
  }

  public isChecked() {
    for (const ck of this.checklistItems) {
      for (let i = 0; i < this.suggestions?.value?.length; i++) {
        if (ck?.answerValue?.usersAnswer) {
          if (ck?.answerValue?.usersAnswer?.findIndex((x) => x.email === this.suggestions.value[i].mail) > -1) {
            this.usersSelected[i] = true;
          } else {
            this.usersSelected[i] = false;
          }
        }
      }
    }
  }

  public getUserValue(users: userAnswerValueDTO[]) {
    let value = '';
    if (users) {
      for (const user of users) {
        value += user.fullName + ' ';
      }
    }
    return value;
  }

  public setUser(opt: UserAD, question: QuestionDTO, questionType: number) {
    let users = this.getChosenAnswer(question).usersAnswer;
    if (!users) {
      const chosenAnswer = {} as PossibleAnswerDTO;
      chosenAnswer.usersAnswer = [];
      this.setChosenAnswer(question, chosenAnswer);
      users = this.getChosenAnswer(question).usersAnswer;
    }
    if (users.findIndex((x) => x.email === opt.mail) > -1) {
      this.deleteUser(
        users,
        users.findIndex((x) => x.email === opt.mail)
      );
    } else {
      users.push({ fullName: opt?.displayName, email: opt?.mail });
    }
    this.isChecked();
  }

  public getQuestionActionDescritpion(): QuestionDTO {
    for (const question of this.questions) {
      if (question.actionDescription) {
        return question;
      }
    }
    return null;
  }
  public viewImmages(immages: FileDTO[]) {
    this.dialog.open(ViewImmagesDialogComponent, {
      data: {
        model: {
          files: immages
        }
      },
      panelClass: 'image-view-modal'
    });
  }

  public getQuestionFiles(question: QuestionDTO, template: boolean): FileDTO[] {
    if (template) {
      if (this.questionFilesTemplate) {
        return this.questionFilesTemplate.get(this.getQuestionUniqueKey(question)) ?? [];
      }
      return [];
    } else {
      if (this.questionFiles) {
        return this.questionFiles.get(this.getQuestionUniqueKey(question)) ?? [];
      }
      return [];
    }
  }

  public getQuestionFromKey(guid: string, questions: QuestionDTO[]): QuestionDTO[] {
    return questions.filter((x) => x.guid === guid);
  }
  public getQuestionUniqueKey(question: QuestionDTO): string {
    return question.guid;
  }
  public setChosenAnswer(question: QuestionDTO, answer: PossibleAnswerDTO) {
    // console.log('this.stageN', this.stageN);
    if (this.stageN !== undefined) {
      // console.log('setChosenAnswer stagebn', this.stageN);
      this.chosenAnswer.set(this.getChosenAnswerKeyStage(this.stageN, question), answer);
    } else {
      this.chosenAnswer.set(this.getChosenAnswerKey(question), answer);
    }
  }

  public getChosenAnswer(question: QuestionDTO): PossibleAnswerDTO {
    if (this.stageN !== undefined) {
      return this.chosenAnswer.get(this.getChosenAnswerKeyStage(this.stageN, question));
    } else {
      return this.chosenAnswer.get(this.getChosenAnswerKey(question));
    }
  }

  public getChosenAnswerStage(question: QuestionDTO, forcedStageCheck: number): PossibleAnswerDTO {
    if (forcedStageCheck !== null) {
      return this.chosenAnswer.get(this.getChosenAnswerKeyStage(forcedStageCheck, question));
    }
  }

  public setChosenAnswers(question: QuestionDTO, answers: PossibleAnswerDTO[]) {
    if (this.stageN !== undefined) {
      this.chosenAnswers.set(this.getChosenAnswerKeyStage(this.stageN, question), answers);
    } else {
      this.chosenAnswers.set(this.getChosenAnswerKey(question), answers);
    }
  }
  public getChosenAnswers(question: QuestionDTO): PossibleAnswerDTO[] {
    console.log('getChosenAnswers');
    console.log(this.chosenAnswers, this.stageN);
    if (this.stageN !== undefined) {
      this.chosenAnswers.get(this.getChosenAnswerKeyStage(this.stageN, question));
    } else {
      return this.chosenAnswers.get(this.getChosenAnswerKey(question));
    }
  }
  public getChosenAnswerKey(question: QuestionDTO): string {
    if (question && question.order) {
      return '' + question.order;
    } else {
      return '' + 0;
    }
  }

  public getChosenAnswerKeyStage(stageN: number, question: QuestionDTO): string {
    // console.log('getChosenAnswerKeyStage', this.stageN, question);
    return stageN + '_' + question.order;
  }
  public addFiles(question: QuestionDTO, fileList: FileList, attachmentindex?: number): void {
    for (let i = 0; i < fileList.length; i++) {
      const file: File = fileList.item(i);
      if (file.size > MAX_FILE_SIZE) {
        this.globalMessage.showError({ message: this.translateService.instant('error.fileSizeLimit', { filename: file.name }) });
      } else {
        if (!this.getQuestionFiles(question, false)) {
          this.questionFiles.set(this.getQuestionUniqueKey(question), []);
        }
        const list = this.getQuestionFiles(question, false);
        list.push({
          data: file,
          thumbnail: file.type.startsWith('image')
            ? (window.URL || window.webkitURL).createObjectURL(file)
            : './assets/img/default-file@3x.png',
          template: true,
          SavedOnAzure: false
        });
        this.questionFiles.set(this.getQuestionUniqueKey(question), list);
      }
    }
    if (fileList.length > 0 && attachmentindex > -1) {
      this.attachmentVisible[attachmentindex] = true;
    }
  }

  public deleteFile(question: QuestionDTO, file: FileDTO, attachmentindex?: number) {
    if (file.SavedOnAzure) {
      // se è già salvato lo cancello dallo storage
      this.azure.getClient().subscribe((client) => {
        const key = this.getQuestionUniqueKey(question);
        client.delete(`${AZURE_CHECKLIST_PATH}${AZURE_QUESTION_PATH}/${key}/${this.checklistId}`, file.data.name, () => {});
      });
    }
    const idxToRemove = this.getQuestionFiles(question, false).indexOf(file);
    this.getQuestionFiles(question, false).splice(idxToRemove, 1);
    if (this.getQuestionFiles(question, false).length === 0 && attachmentindex > -1) {
      //  this.attachmentVisible[attachmentindex] = false;
    }
  }

  public splitActionsFromDate() {
    this.currentActions = [];
    this.followUpActions = [];
    if (this.actions?.length > 0) {
      for (const action of this.actions) {
        if (typeof action.trackerAction.dueDate === 'string') {
          if (new Date(action.trackerAction.dueDate).getDate() === this.myDate.getDate()) {
            this.currentActions.push(action);
          } else {
            this.followUpActions.push(action);
          }
        } else {
          if (action.trackerAction.dueDate.getDate() === this.myDate.getDate()) {
            this.currentActions.push(action);
          } else {
            this.followUpActions.push(action);
          }
        }
      }
    }
  }

  public onCreateAction(question: QuestionDTO): void {
    if (!this.isPreview) {
      // let actions = [] as ActionDTO[];
      // {
      //   actionTracker: true,
      //   trackerAction: {
      //     // actionType: {
      //     //   id: this.actionTypeId
      //     // },
      //     // owner: {
      //     //   id: this.owner
      //     // }
      //   }
      // } as ActionDTO;

      // recupero checklistItem relativo
      // if (this.checklistItems) {

      //   this.checklistItems[checklistItemIndex] &&
      //   this.checklistItems[checklistItemIndex].actions &&
      //   this.checklistItems[checklistItemIndex].actions.length > 0
      // ) {
      //   this.checklistItems[checklistItemIndex].actions = [];
      // }
      // }
      this.dialog
        .open(CreateActionDialogComponent, {
          id: 'create-action-dialog-component',
          panelClass: ['create-action-dialog', 'full-screen-modal'],
          data: {
            model: {
              checklistItem: this.checklist.stageAnswers[this.stageN],
              actions: this.actionsList,
              questionId: question.id,
              questionTitle: 'Actions stage ' + (this.stageN + 1),
              // owner: this.owner,
              // actionTypeId: this.actionTypeId,
              editDisabled: this.actionAdded.get(question.id), // question.answerType.actionAdded,
              isReadOnly: this.isReadOnly,
              priorityList: this.priorityList,
              appTypeId: this.appTypeId,
              appName: this.appName,
              appId: this.appId
            }
          }
        })
        .afterClosed()
        .subscribe(
          (dialogResult: {
            actions: ActionDTO[];
            checklistItem: CheckListItemDTO;
            actionFiles: FileDTO[][];
            fileRefs: FileRefDTO[][];
          }) => {
            if (dialogResult) {
              this.checklistItems[question.order] = dialogResult.checklistItem;
              // checklistItem.actions = dialogResult.actions;
              this.questionsActionsFiles.push({
                questionId: question.id,
                actionFiles: dialogResult.actionFiles,
                fileRefs: dialogResult.fileRefs
              });

              // question.answerType.actionAdded = true;
              this.actionAdded.set(question.id, true);
            }
          }
        );
    }
  }

  public onCreateActionNew(): void {
    this.dialog
      .open(NewActionTrackerDialogComponent, {
        panelClass: ['new-action-tracker-dialog', 'full-screen-modal'],
        id: 'new-action-tracker-dialog-id',
        data: {
          model: {
            id: null,
            title: '',
            actionTracker: true,
            actionNotes: [] as NoteDTO[],
            trackerAction: {} as ActionTrackerDTO
          } as ActionDTO,
          type: this.checklist.checklistType,
          ddsChecklist: this.checklist,
          files: this.fileToSave
        }
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult) {
          console.log(this.checklist);
          console.log(dialogResult);
          console.log(this.fileToSave);
          this.getActions();
          this.splitActionsFromDate();
        }
      });
  }

  private getQuestionFilesTemplateFromAzure(templateId: number): void {
    if (!this.questionFilesTemplate) {
      this.questionFilesTemplate = new Map<string, FileDTO[]>();
    }
    this.azure.getClient().subscribe((client) => {
      this.questions.forEach((question: QuestionDTO) => {
        const key = this.getQuestionUniqueKey(question);
        if (!!key) {
          client.list(`${AZURE_TEMPLATE_PATH}${AZURE_QUESTION_PATH}/${key}`).then((list) => {
            if (list.length > 0) {
              this.questionFilesTemplate.set(this.getQuestionUniqueKey(question), list);
            }
          });
        }
      });
    });
  }

  private getQuestionFilesFromAzure(checklistId: number): void {
    if (!this.questionFiles) {
      this.questionFiles = new Map<string, FileDTO[]>();
    }
    this.azure.getClient().subscribe((client) => {
      this.questions.forEach((question: QuestionDTO, index) => {
        const key = this.getQuestionUniqueKey(question);
        if (!!key) {
          client.list(`${AZURE_CHECKLIST_PATH}${AZURE_QUESTION_PATH}/${key}/${checklistId}`).then((list) => {
            if (list.length > 0) {
              this.questionFiles.set(this.getQuestionUniqueKey(question), list);
              this.attachmentVisible[this.getArrayIndexBase(0) + index] = true;
            }
          });
          if (question.answerType.answerType === ANSWER_TYPE_ENUM.SIGNATURE) {
            client.list(`${AZURE_CHECKLIST_PATH}${AZURE_SIGNATURE_PATH}/${key}/${checklistId}`).then((list) => {
              if (list.length > 0) {
                this.signaturePad.fromDataURL(list[0].thumbnail.toString());
              }
              this.questionFiles.set(key, list);
            });
          }
        }
      });
    });
  }
  private dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  private getActions(size?: number, page?: number, sort?: string, filters?: ActionTrackerFilterDTO): void {
    this.actionsList = this.actions;
    this.splitActionsFromDate();
    this.fileToSave = this.files;
    /* const spinner = this.spinnerService.show();

    this.actionTrackerService
      .searchActionsByPage(size, page, sort, filters)
      .pipe(
        finalize(() => {
          this.spinnerService.hide(spinner);
          this.isLoading = false;
        })
      )
      .subscribe({
        // TODO (FIX): Why `any` type? Doesn't `searchActionsByPage` returns `ActionTrackerDTO[]`?
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        next: (response: any) => {
          this.actionsList = response.content as ActionDTO[];
          this.numberOfElements = response.numberOfElements;
        },
        error: (error: EdmsError) => {
          this.error = error;
        }
      }); */
  }
}
