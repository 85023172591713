<mat-horizontal-stepper class="mat-step-header" [linear]="true" (selectionChange)="metodo($event.selectedIndex)">
    <mat-step *ngFor="let step of data; let i = index" [label]="step.description | translate" [stepControl]="forms[i]"
        [state]="!!step.icon ? step.icon : step.index">
    </mat-step>
    <ng-template *ngFor="let icons of data" [matStepperIcon]="!!icons.icon ? icons.icon : icons.index">
        <div class="mat-icon-wrapper icon" *ngIf="!!icons.icon">
            <mat-icon fontSet="edms">{{ icons.icon }}</mat-icon>
        </div>
        <div *ngIf="!!!icons.icon">
            {{ icons.index }}
        </div>
    </ng-template>
    <ng-template matStepperIcon="edit">
        <div>{{ matStepper?.selected?.state }}</div>
    </ng-template>
</mat-horizontal-stepper>