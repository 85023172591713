import { TemplateDTO } from './../models/template-dto';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV } from '@app/constants/global.constants';
import { Env } from '@app/types/env';
import { EdmsError } from '@app/types/error';
import { AppDetailDTO, AppParameterTypeDTO } from '@data/models/app-detail-dto';
import { AppTypeDTO } from '@data/models/app-type-dto';
import { GroupDTO } from '@data/models/group-dto';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import UserDTO from '@data/models/user-dto';
import { TemplateEntitiesDTO } from '@data/models/template-entities-dto';
import { PlantDTO } from '@data/models/plant-dto';
import { AppScheduleDTO } from '@data/models/app-schedule-dto';
import { CILTemplateDTO } from '@data/models/cil-template-dto';
import { WorkingAreaDTO } from '@data/models/workingarea-dto';
import { EquipmentDTO } from '@data/models/equipment-dto';
import { FormatDTO } from '@data/models/format-dto';
import { FillByDTO } from '@data/models/fill-by-dto';
import { DDSTemplateDTO, KpiDTO } from '@data/models/dds-template-dto';
import { DDSHistoryItemDTO, DateDTO } from '@data/models/dds-history-dto';
import { CLTemplateDTO } from '@data/models/cl-template-dto';
import { ShiftDTO } from '@data/models/shift-dto';
import { IdeaTemplateDTO } from '@data/models/Idea-template-dto';
import { IdeaQuestionDTO } from '@data/models/idea-question-dto';
import { CheckListItemDTO } from '@data/models/checklist-item-dto';
import { QuestionDTO } from '@data/models/question-dto';
import { ActionDTO } from '@data/models/action-dto';

@Injectable({
  providedIn: 'root'
})
export class ManagementServiceService {
  private readonly NEW_APP_PATH_1 = '/api/v1/admin/plants/';
  private readonly NEW_APP_PATH_2 = '/groups/';
  private readonly APP_GROUPS = '/api/v1/admin/groups';
  private readonly APP_TYPES = '/api/v1/admin/appTypes';
  private readonly APP_TYPES_LIST = 'apps/types';
  private readonly GET_APPS_BY_GROUP_1 = '/api/v1/admin/plants/';
  private readonly GET_APPS_OTHERS = '/api/v1/admin/apps/plants/';
  private readonly GET_APPS_BY_GROUP_2 = '/groups/';
  private readonly UPDATE_APP_BY_ID = '/api/v1/admin/plants/';
  private readonly DISABLE_APP = '/api/v1/admin/plants/';
  private readonly ENABLE_APP = '/api/v1/admin/plants/';
  private readonly GET_APPS_BY_APPID = '/api/v1/admin/plants/';
  private readonly GET_TEMPLATES_BY_APPID = '/api/v1/admin/templates/';
  private readonly GET_TEMPLATE_BY_ID = '/api/v1/admin/templates/';
  private readonly CREATE_NEW_TEMPLATE = '/api/v1/admin/templates/';
  private readonly UPDATE_TEMPLATE = '/api/v1/admin/templates';
  private readonly ENABLE_TEMPLATE = '/api/v1/admin/template';
  private readonly GET_USERS = '/api/admin/';
  private readonly GET_TEMPLATES_BY_APP = '/api/v1/admin/templates/apps/';
  private readonly GET_TEMPLATES_IDEA = '/api/v1/templates';
  private readonly GET_SCHEDULES = '/api/v1/admin/appTypes/';
  private readonly GET_FORMAT_FROM_WA_EQ = '/api/admin/formats/_search';
  private readonly GET_KPIs = '/api/v1/admin/kpis/selectable';
  private readonly GET_DDS_TEMPLATES = '/api/v1/admin/templates/apps/';
  private readonly GET_DDS_HISTORY = '/api/v1/admin/checklists/';
  private readonly DELETE_APP = '/api/v1/admin/plants/';
  private readonly DELETE_TEMPLATE = '/api/v1/admin/templates/';
  private readonly GET_APP_PARAMETERS_TYPES = '/api/v1/admin/appParameterTypes/';
  private readonly GET_LAST_DDS_UTIL_DAY = '/api/admin/weeklyPlanning/lastShift';
  private readonly GET_DDS_KPI_TEMPLATES = '/api/v1/admin/kpis/availableTemplates/';
  private readonly GET_LAST_SHIFT_UTIL_DAY = '/api/admin/weeklyPlanning/previousDay';
  private readonly API_TEMPLATE = '/api/v1/admin/templates/';
  private readonly UPDATE_COMMENT_DDS = '/api/v1/admin/checklists/dds/items';
  constructor(@Inject(ENV) private env: Env, private http: HttpClient) {}

  //APP
  public createNewApp(plant: number, groups: number, app: AppDetailDTO): Observable<AppDetailDTO> {
    return this.http
      .post<AppDetailDTO>(`${this.env.apiBaseUrl}${this.NEW_APP_PATH_1}${plant}${this.NEW_APP_PATH_2}${groups}/apps`, app)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public updateApp(plant: number, groups: number, id: number, app: AppDetailDTO): Observable<AppDetailDTO> {
    return this.http
      .patch<AppDetailDTO>(`${this.env.apiBaseUrl}${this.UPDATE_APP_BY_ID}${plant}/groups/${groups}/apps/${id}`, app)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public deleteApp(plant: number, groups: number, id: number): Observable<boolean> {
    return this.http
      .delete<boolean>(`${this.env.apiBaseUrl}${this.DELETE_APP}${plant}/groups/${groups}/apps/${id}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public disableApp(plant: number, groups: number, id: number): Observable<void> {
    const body = {};
    return this.http
      .patch<void>(`${this.env.apiBaseUrl}${this.DISABLE_APP}${plant}/groups/${groups}/apps/${id}/disable`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public EnableApp(plant: number, groups: number, id: number): Observable<void> {
    const body = {};
    return this.http
      .patch<void>(`${this.env.apiBaseUrl}${this.ENABLE_APP}${plant}/groups/${groups}/apps/${id}/enable`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getAppsById(plant: number, groups: number, appId: number): Observable<AppDetailDTO> {
    return this.http
      .get<AppDetailDTO>(`${this.env.apiBaseUrl}${this.GET_APPS_BY_APPID}${plant}/groups/${groups}/apps/${appId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getGroups(plantId: number): Observable<GroupDTO[]> {
    return this.http
      .get<GroupDTO[]>(`${this.env.apiBaseUrl}${this.APP_GROUPS}/main?plantId=${plantId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getGroupsMain(plantId: number): Observable<GroupDTO[]> {
    return this.http
      .get<GroupDTO[]>(`${this.env.apiBaseUrl}${this.APP_GROUPS}/main?plantId=${plantId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getGroupsOthers(plantId: number): Observable<GroupDTO[]> {
    return this.http
      .get<GroupDTO[]>(`${this.env.apiBaseUrl}${this.APP_GROUPS}/other?plantId=${plantId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getAppsOthers(plantId: number): Observable<AppDetailDTO[]> {
    return this.http
      .get<AppDetailDTO[]>(`${this.env.apiBaseUrl}${this.GET_APPS_OTHERS}/${plantId}/others`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public addNewGroup(plantId: number, newGroup: GroupDTO[]): Observable<GroupDTO[]> {
    return this.http
      .post<GroupDTO[]>(`${this.env.apiBaseUrl}${this.APP_GROUPS}/add?plantId=${plantId}`, newGroup)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public editGroup(groupId: number, group: GroupDTO): Observable<GroupDTO[]> {
    return this.http
      .put<GroupDTO[]>(`${this.env.apiBaseUrl}${this.APP_GROUPS}/${groupId}`, group)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public deleteGroup(groupId: number): Observable<GroupDTO[]> {
    return this.http
      .delete<GroupDTO[]>(`${this.env.apiBaseUrl}${this.APP_GROUPS}/${groupId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getAppsByGroups(plant: number, groupId: number): Observable<AppDetailDTO[]> {
    return this.http
      .get<AppDetailDTO[]>(`${this.env.apiBaseUrl}${this.GET_APPS_BY_GROUP_1}${plant}${this.GET_APPS_BY_GROUP_2}${groupId}/apps`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getAppsByGroupsWithChecklists(plant: number, groupId: number): Observable<AppDetailDTO[]> {
    return this.http
      .get<AppDetailDTO[]>(
        `${this.env.apiBaseUrl}${this.GET_APPS_BY_GROUP_1}${plant}${this.GET_APPS_BY_GROUP_2}${groupId}/apps/type/userMode`
      )
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getAppsByGroupsWithChecklistsOthers(plant: number): Observable<AppDetailDTO[]> {
    return this.http
      .get<AppDetailDTO[]>(`${this.env.apiBaseUrl}${this.GET_APPS_OTHERS}${plant}/others/userMode`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getAppsByGroupsWithDetails(plant: number, groupId: number): Observable<AppDetailDTO[]> {
    return this.http
      .get<AppDetailDTO[]>(
        `${this.env.apiBaseUrl}${this.GET_APPS_BY_GROUP_1}${plant}${this.GET_APPS_BY_GROUP_2}${groupId}/apps/type/detail`
      )
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getParameterTypes(): Observable<AppParameterTypeDTO[]> {
    return this.http
      .get<AppParameterTypeDTO[]>(`${this.env.apiBaseUrl}${this.GET_APP_PARAMETERS_TYPES}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  //APP TYPE
  public getTypes(): Observable<AppTypeDTO[]> {
    return this.http
      .get<AppTypeDTO[]>(`${this.env.apiBaseUrl}${this.APP_TYPES}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getTypesList(plantId: number, groupId: number): Observable<AppTypeDTO[]> {
    return this.http
      .get<AppTypeDTO[]>(`${this.env.apiBaseUrl}/api/v1/admin/plants/${plantId}/groups/${groupId}/${this.APP_TYPES_LIST}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getSchedules(typeId: number): Observable<AppScheduleDTO[]> {
    return this.http
      .get<AppScheduleDTO[]>(`${this.env.apiBaseUrl}${this.GET_SCHEDULES}${typeId}/schedules/`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  // TEMPLATES
  public getTemplatesByAppId(plantId: number, appId: number): Observable<TemplateDTO[]> {
    return this.http
      .get<TemplateDTO[]>(`${this.env.apiBaseUrl}${this.GET_TEMPLATES_BY_APPID}?plantId=${plantId}&appId=${appId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getTemplateById(templateId: number): Observable<TemplateDTO> {
    return this.http
      .get<TemplateDTO>(`${this.env.apiBaseUrl}${this.GET_TEMPLATE_BY_ID}${templateId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getMetricsActions(checklistId: number): Observable<ActionDTO[]> {
    return this.http
      .get<ActionDTO[]>(`${this.env.apiBaseUrl}${this.GET_DDS_HISTORY}${checklistId}/ddsActions`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public addMetricsActions(checklistId: number, action: ActionDTO): Observable<ActionDTO[]> {
    return this.http
      .post<ActionDTO[]>(`${this.env.apiBaseUrl}${this.GET_DDS_HISTORY}${checklistId}/ddsActions`, action)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public createTemplate(template: TemplateDTO): Observable<TemplateDTO> {
    return this.http
      .post<TemplateDTO>(`${this.env.apiBaseUrl}${this.CREATE_NEW_TEMPLATE}`, template)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public createTemplateCL(template: CLTemplateDTO): Observable<CLTemplateDTO> {
    return this.http
      .post<CLTemplateDTO>(`${this.env.apiBaseUrl}${this.CREATE_NEW_TEMPLATE}`, template)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public createTemplateDDS(template: DDSTemplateDTO): Observable<TemplateDTO> {
    return this.http
      .post<TemplateDTO>(`${this.env.apiBaseUrl}${this.CREATE_NEW_TEMPLATE}`, template)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public updateTemplate(template: TemplateDTO): Observable<TemplateDTO> {
    return this.http
      .patch<TemplateDTO>(`${this.env.apiBaseUrl}${this.UPDATE_TEMPLATE}/${template.id}`, template)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public updateTemplateCL(template: CLTemplateDTO): Observable<CLTemplateDTO> {
    return this.http
      .patch<CLTemplateDTO>(`${this.env.apiBaseUrl}${this.UPDATE_TEMPLATE}/${template.id}`, template)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public updateTemplateDDS(template: DDSTemplateDTO): Observable<TemplateDTO> {
    return this.http
      .patch<TemplateDTO>(`${this.env.apiBaseUrl}${this.UPDATE_TEMPLATE}/${template.id}`, template)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public updateTemplateCIL(template: CILTemplateDTO): Observable<CILTemplateDTO> {
    return this.http
      .patch<CILTemplateDTO>(`${this.env.apiBaseUrl}${this.UPDATE_TEMPLATE}/${template.id}`, template)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public deleteTemplateById(templateId: number): Observable<boolean> {
    return this.http
      .delete<boolean>(`${this.env.apiBaseUrl}${this.DELETE_TEMPLATE}${templateId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getUsers(id: number, type: string, maxLevel?: number): Observable<UserDTO[]> {
    return this.http
      .get<UserDTO[]>(`${this.env.apiBaseUrl}${this.GET_USERS}${type}/${id}/users${maxLevel ? '?maxLevel=' + maxLevel : ''}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getTemplatesByApp(id: number): Observable<TemplateEntitiesDTO> {
    return this.http
      .get<TemplateEntitiesDTO>(`${this.env.apiBaseUrl}${this.API_TEMPLATE}apps/${id}/listByEntities`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getFillByEntity(appId: number, entityId: number, templateId?: number): Observable<PlantDTO[]> {
    return this.http
      .get<PlantDTO[]>(
        `${this.env.apiBaseUrl}/api/v1/admin/apps/${appId}/filledByAvailable/entityTypes/${entityId}${
          templateId ? '?templateId=' + templateId : '/'
        }`
      )
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public disableTemplateById(templateId: number): Observable<void> {
    const body = {};
    return this.http
      .patch<void>(`${this.env.apiBaseUrl}${this.UPDATE_TEMPLATE}/${templateId}/disable`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public enableTemplateById(templateId: number): Observable<void> {
    const body = {};
    return this.http
      .patch<void>(`${this.env.apiBaseUrl}${this.UPDATE_TEMPLATE}/${templateId}/enable`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public enableTemplate(template: TemplateDTO): Observable<TemplateDTO> {
    return this.http
      .patch<TemplateDTO>(`${this.env.apiBaseUrl}${this.UPDATE_TEMPLATE}/${template.id}/enable`, template)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public createTemplateCIL(template: CILTemplateDTO): Observable<CILTemplateDTO> {
    return this.http.post<CILTemplateDTO>(`${this.env.apiBaseUrl}${this.CREATE_NEW_TEMPLATE}`, template);
  }

  /*public createTemplateCIL1(template: CILTemplateDTO): Observable<CILTemplateDTO> {
    return this.http.post<CILTemplateDTO>(`${this.env.apiBaseUrl}${this.CREATE_NEW_TEMPLATE}`, template);
  }*/

  // formats centerline
  public getFormats(workingAreas: WorkingAreaDTO[], equipments: EquipmentDTO[]): Observable<FormatDTO[]> {
    const body = { workingAreas, equipments };
    return this.http
      .post<FormatDTO[]>(`${this.env.apiBaseUrl}${this.GET_FORMAT_FROM_WA_EQ}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  //kpis
  public getKpis(id: number, fillby: FillByDTO[]): Observable<KpiDTO[]> {
    return this.http
      .post<KpiDTO[]>(`${this.env.apiBaseUrl}${this.GET_KPIs}?appId=${id}`, fillby)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getTemplatesDDS(id: number): Observable<DDSTemplateDTO[]> {
    return this.http
      .get<DDSTemplateDTO[]>(`${this.env.apiBaseUrl}${this.GET_DDS_TEMPLATES}${id}/dds`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getHystoryDDS(id: number): Observable<DDSHistoryItemDTO[]> {
    return this.http
      .get<DDSHistoryItemDTO[]>(`${this.env.apiBaseUrl}${this.GET_DDS_HISTORY}${id}/dds/history`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getlastShiftDDS(week: number, fillByItem: FillByDTO): Observable<ShiftDTO> {
    return this.http
      .post<ShiftDTO>(
        `${this.env.apiBaseUrl}${this.GET_LAST_DDS_UTIL_DAY}?week=${week}&year=${new Date().getFullYear()}`,
        fillByItem
      )
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getDDSKpisTemplates(fillByItem: FillByDTO): Observable<TemplateDTO[]> {
    return this.http
      .post<TemplateDTO[]>(`${this.env.apiBaseUrl}${this.GET_DDS_KPI_TEMPLATES}`, [fillByItem])
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getDDSKpisTemplatesQuestions(idTemplate: number): Observable<QuestionDTO[]> {
    return this.http
      .get<QuestionDTO[]>(`${this.env.apiBaseUrl}${this.GET_DDS_KPI_TEMPLATES}${idTemplate}/questions`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getPreviousShiftDDS(date: string, fillByItem: FillByDTO): Observable<DateDTO> {
    //edit for new API chiara
    return this.http
      .post<DateDTO>(`${this.env.apiBaseUrl}${this.GET_LAST_SHIFT_UTIL_DAY}?date=${date}`, fillByItem)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public updateDDSComments(item: CheckListItemDTO): Observable<void> {
    return this.http
      .patch<void>(`${this.env.apiBaseUrl}${this.UPDATE_COMMENT_DDS}`, [item])
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
}
