import { UserDelegationKey } from '@azure/storage-blob';
import { ActionPlanDTO } from './action-plan-dto';
import { ApproverDTO } from './approver-dto';
import { CommentDTO } from './comment-dto';
import { EvaluateDTO } from './evaluate-dto';
import { EvaluationDTO } from './evaluation-dto';
import { FileDTO } from './file-dto';
import { IdeaTemplateDTO } from './Idea-template-dto';
import { ImplementationDTO } from './implementation-dto';
import { MoreInfoDTO } from './more-info.dto';
import { QuestionAnswerDTO } from './question-answer-dto';
import { TemplateDTO } from './template-dto';
import UserDTO from './user-dto';

export class IdeaDTO {
  id?: number;
  template?: IdeaTemplateDTO;
  submittedUser?: UserDTO;
  submittedDate?: string;
  answers?: QuestionAnswerDTO[];
  status?: number;
  ideaInformation?: string;
  rejectInformation?: string;
  rejectUser?: string;
  rejectDate?: string;
  approvalInformation?: string;
  cancelInformation?: cancelInformationDTO;
  onHoldDate?: string;
  onHoldInformation?: string;
  moreInformation?: MoreInfoDTO[];
  implementor?: UserDTO;
  approver?: ApproverDTO;
  file?: FileDTO;
  implementationAnalysis?: ImplementationDTO;
  evaluation?: EvaluationDTO;
  comment?: CommentDTO[];
  actionPlan?: ActionPlanDTO;
  includeOtherApprovers?: boolean;
  includeEvaluation?: boolean;
  approvalDate?: string;
  toCheck?: boolean;
  skipEvaluation?: boolean;
  fastApproval?: boolean;
}

export type IdeaFlowDTO = {
  submit: UserDateDTO;
  approvalLevels: ApprovalLevelDTO[];
  implementor: UserDateDTO;
  evaluator: UserDateDTO;
};

export type UserDateDTO = {
  user: UserDTO;
  eventDate: string;
};

export type ApprovalLevelDTO = {
  id: number;
  approvals: ApproverDTO[];
  level: number;
};

export type IdeaStatusDTO = {
  id: number;
  name: string;
};

export enum IdeaStatus {
  DRAFT = 0,
  SUBMITTED = 1,
  APPROVED = 2,
  REJECTED = 3,
  ON_HOLD = 4,
  CANCELLED = 5,
  // ON_GOING = 6,
  IMPLEMENTED = 7,
  CLOSED = 8
}

export type cancelInformationDTO = {
  reason: string;
  fullName: string;
  createDate: string;
};
