import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV } from '@app/constants/global.constants';
import { Env } from '@app/types/env';
import { EdmsError } from '@app/types/error';
import { GenericResponseDTO } from '@data/models/generic-response-dto';
import { GenericUserDTO } from '@data/models/generic-user-dto';
import { IdeaApproverDTO } from '@data/models/idea-approver-dto';
import { IdeaQuestionDTO } from '@data/models/idea-question-dto';
import { IdeaQuestionGroupDTO } from '@data/models/idea-question-group-dto';
import { IdeaTemplateDTO } from '@data/models/Idea-template-dto';
import { IdeaDetailDTO } from '@data/models/IdeaDetailDTO';
import { IdeaDTO, IdeaFlowDTO } from '@data/models/IdeaDTO';
import { NotificationDTO } from '@data/models/notification-dto';
import { QuestionGroupDTO } from '@data/models/question-group-dto';
import { TemplateDTO } from '@data/models/template-dto';
import { ANSWER_TYPE_IDEA_ENUM } from '@shared/constants/answer-type.constants';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManagementIdeaServiceService {
  private readonly GET_ALL_IDEAS = '/api/v1/ideas';
  private readonly GET_IDEA_FLOW = '/api/v1/ideas';
  private readonly GET_TEMPLATES_IDEA = '/api/v1/templates';

  constructor(@Inject(ENV) private env: Env, private http: HttpClient) {}

  public getAllIdeas(): Observable<IdeaDTO[]> {
    return this.http
      .get<IdeaDTO[]>(`${this.env.apiBaseUrlIdea}${this.GET_ALL_IDEAS}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getIdeabyId(id: number): Observable<IdeaDTO> {
    return this.http
      .get<IdeaDTO>(`${this.env.apiBaseUrlIdea}${this.GET_ALL_IDEAS}/${id}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getDraftIdeas(id: number): Observable<IdeaDTO[]> {
    return this.http
      .get<IdeaDTO[]>(`${this.env.apiBaseUrlIdea}${this.GET_ALL_IDEAS}/draft?appId=${id}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getIdeaFlow(id: number): Observable<IdeaFlowDTO> {
    return this.http
      .get<IdeaFlowDTO>(`${this.env.apiBaseUrlIdea}${this.GET_IDEA_FLOW}/${id}/ideaFlow`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public SaveIdea(idea: IdeaDTO): Observable<IdeaDTO> {
    if (!!idea.id) {
      return this.http
        .patch<IdeaDTO>(`${this.env.apiBaseUrlIdea}${this.GET_ALL_IDEAS}/${idea.id}`, idea)
        .pipe(catchError((error) => throwError(error as EdmsError)));
    } else {
      return this.http
        .post<IdeaDTO>(`${this.env.apiBaseUrlIdea}${this.GET_ALL_IDEAS}`, idea)
        .pipe(catchError((error) => throwError(error as EdmsError)));
    }
  }
  public deleteIdeaDraft(id: number) {
    return this.http
      .patch<IdeaDTO>(`${this.env.apiBaseUrlIdea}${this.GET_ALL_IDEAS}/${id}/deleteDraft`, null)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  //#region idea
  public createTemplateIDEA(template: IdeaTemplateDTO): Observable<IdeaTemplateDTO> {
    return this.http.post<IdeaTemplateDTO>(`${this.env.apiBaseUrlIdea}${this.GET_TEMPLATES_IDEA}`, template);
  }
  public getTemplatesIdeaByApp(id: number): Observable<IdeaTemplateDTO[]> {
    return this.http
      .get<IdeaTemplateDTO[]>(`${this.env.apiBaseUrlIdea}${this.GET_TEMPLATES_IDEA}?appId=${id}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public deleteTemplateIdeaById(templateId: number): Observable<boolean> {
    return this.http
      .delete<boolean>(`${this.env.apiBaseUrlIdea}${this.GET_TEMPLATES_IDEA}/${templateId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public disableTemplateById(templateId: number): Observable<void> {
    const body = {};
    return this.http
      .patch<void>(`${this.env.apiBaseUrlIdea}${this.GET_TEMPLATES_IDEA}/${templateId}/disable`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public enableTemplateById(templateId: number): Observable<void> {
    const body = {};
    return this.http
      .patch<void>(`${this.env.apiBaseUrlIdea}${this.GET_TEMPLATES_IDEA}/${templateId}/enable`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public updateTemplateIdea(template: IdeaTemplateDTO): Observable<IdeaTemplateDTO> {
    return this.http
      .patch<IdeaTemplateDTO>(`${this.env.apiBaseUrlIdea}${this.GET_TEMPLATES_IDEA}/${template.id}`, template)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getFixedQuestions() {
    return this.http
      .get<IdeaQuestionDTO[]>(`${this.env.apiBaseUrlIdea}${this.GET_TEMPLATES_IDEA}/fixedQuestions`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getTemplateIdeaById(templateId: number): Observable<IdeaTemplateDTO> {
    return this.http
      .get<IdeaTemplateDTO>(`${this.env.apiBaseUrlIdea}${this.GET_TEMPLATES_IDEA}/${templateId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getIdeaDetail(id: number): Observable<IdeaDetailDTO> {
    return this.http
      .get<IdeaDetailDTO>(`${this.env.apiBaseUrlIdea}/api/v1/ideas/${id}/detail`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getIdeaDetailApprover(id: number): Observable<IdeaApproverDTO> {
    return this.http
      .get<IdeaApproverDTO>(`${this.env.apiBaseUrlIdea}/api/v1/ideas/${id}/detailApprover`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getNotifications(): Observable<NotificationDTO[]> {
    return this.http
      .get<NotificationDTO[]>(`${this.env.apiBaseUrlIdea}/api/v1/notifications/_search`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getNotificationsFiltered(): Observable<NotificationDTO[]> {
    return this.http
      .get<NotificationDTO[]>(`${this.env.apiBaseUrlIdea}/api/v1/notifications/_search?checked=false`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getNotificationsCount(): Observable<GenericResponseDTO> {
    return this.http
      .get<GenericResponseDTO>(`${this.env.apiBaseUrlIdea}/api/v1/notifications/unchecked/count`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public readAllNotifications() {
    return this.http
      .post(`${this.env.apiBaseUrlIdea}/api/v1/notifications/readAll`, null)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public readNotification(id: number) {
    return this.http
      .post(`${this.env.apiBaseUrlIdea}/api/v1/notifications/${id}/check`, null)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public checkNotifications(notificationUserId: number): Observable<NotificationDTO[]> {
    return this.http
      .patch<NotificationDTO[]>(`${this.env.apiBaseUrlIdea}/api/v1/notifications/${notificationUserId}/check`, null)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public UncheckNotifications(notificationUserId: number): Observable<NotificationDTO[]> {
    return this.http
      .patch<NotificationDTO[]>(`${this.env.apiBaseUrlIdea}/api/v1/notifications/${notificationUserId}/uncheck`, null)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public markReadAll(): Observable<NotificationDTO[]> {
    return this.http
      .get<NotificationDTO[]>(`${this.env.apiBaseUrlIdea}/api/v1/notifications/readAll`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public editApprover(id: number, ideaId: number, approver: GenericUserDTO) {
    return this.http
      .put(`${this.env.apiBaseUrlIdea}${this.GET_IDEA_FLOW}/${ideaId}/approvers/${id}`, approver)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public editImplementor(id: number, ideaId: number, implementor: GenericUserDTO) {
    return this.http
      .put(`${this.env.apiBaseUrlIdea}${this.GET_IDEA_FLOW}/${ideaId}/implementors/${id}`, implementor)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  //#endregion
}
