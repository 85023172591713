<mat-drawer-container hasBackdrop="true" class="mat-app-background" [ngClass]="{ 
'mat-app-background' : !fillBackground,
'mat-app-background-empty' : fillBackground }">
    <mat-drawer-content>
        <div class="dashboard-idea-layout-component" [ngClass]="{ 'dashboard-idea-layout-component__landing': isLanding, 
        'fill-idea-background' : fillBackground }">
            <mat-toolbar color="primary">
                <div class="dashboard-idea-layout-component__toolbar content-wrapper">
                    <!-- TODO (FUTURE): This logo and it's "alt" text should come from the Operation Center of the logged user -->


                    <span class="logo">
                        <img class="logo__header" alt="IdeApp" src="/assets/img/new/idea/logo-header.svg" />
                    </span>

                    <div class="dashboard-idea-layout-component__menu">
                        <!-- Home -->
                        <button mat-button (click)="redirectToDashboard()" id="main-menu-home">
                            <mat-icon svgIcon="factory"></mat-icon>
                            {{ labels.menuHomeButton | translate }}
                        </button>
                        <!-- Idea Tracker -->
                        <button mat-button (click)="redirectToActionTracker()" id="main-menu-home">
                            <mat-icon fontSet="edms">icon_light</mat-icon>
                            {{ labels.menuIdeaTrackerButton | translate }}
                        </button>

                        <!-- Power Bi -->
                        <button mat-button (click)="redirectToPowerBi()" id="main-menu-home" *ngIf="isEbosUser()">
                            <mat-icon fontSet="edms">external_link</mat-icon>
                            {{ labels.menuPowerBiButton | translate }}
                        </button>

                        <!-- Ebos Dashboard -->
                        <button mat-button (click)="redirectToEbosDashboard()" id="main-menu-home" *ngIf="isEbosUser()">
                            <mat-icon fontSet="edms">external_link</mat-icon>
                            {{ labels.menuEbosButton | translate }}
                        </button>
                    </div>

                    <div class="dashboard-idea-layout-component__actions">
                        <button mat-stroked-button color="primary" (click)="redirectToAddIdea()" id="main-menu-home"
                            *ngIf="seeButton">
                            {{ labels.menuAddIdea | translate }}
                        </button>
                        <app-logout-idea-button></app-logout-idea-button>
                    </div>
                </div>
            </mat-toolbar>

            <div class="dashboard-idea-layout-component__content">
                <router-outlet></router-outlet>
            </div>
        </div>
    </mat-drawer-content>

    <mat-drawer #drawer mode="over" position="end">
        <div class="dashboard-idea-layout-component__drawer">
            <app-user-filters [drawer]="drawer"></app-user-filters>
        </div>
    </mat-drawer>
</mat-drawer-container>