import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { MatExpansionModule } from '@angular/material/expansion';
// import { MdePopoverModule } from '@material-extended/mde';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTableModule } from '@angular/material/table';
import { MatCarouselModule } from '@ngbmodule/material-carousel';
import { MatStepperModule } from '@angular/material/stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';

@NgModule({
  declarations: [],
  imports: [
    MatButtonModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatMenuModule,
    MatSelectModule,
    MatTabsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatChipsModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatBadgeModule,
    MatTableModule,
    MatCarouselModule,
    MatStepperModule,
    CdkStepperModule
  ],
  exports: [
    MatButtonModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatMenuModule,
    MatSelectModule,
    MatTabsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatChipsModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatBadgeModule,
    MatTableModule,
    MatCarouselModule,
    MatStepperModule,
    CdkStepperModule
  ]
})
export class MaterialModule {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.registerSvgMatIcons();
    matIconRegistry.registerFontClassAlias('edms', 'edms');
  }

  private registerSvgMatIcons(): void {
    this.matIconRegistry.addSvgIcon(
      'factory',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/icons-factory-off.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'logout',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/basics-on-off-power-button.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'profile',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/icons-profile.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'filters',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/layout-module-copy.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'close',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/basics-add-plus.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'alert-exclamation',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/basics-alerts-alert-circle.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'risk-medium',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/basics-alerts-alert-circle.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'risk-low',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/basics-form-validation-check-circle-1.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'risk-high',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/basics-alerts-alert-diamond.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'risk-noinfo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/multimedia-controls-button-pause.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'settings',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/basics-setting-hammer-wench.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'safety-trigger',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/outline-security-check.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ebos-checkbox',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/checkbox-small-on-copy-2.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ebos-checkbox-checked',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/checkbox-small-on.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'radio-on',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/controls-radiobutton-on.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'radio-off',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/controls-radiobutton-default.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'state-unscheduled',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/bold-basics-date-calendar-calendar-add.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'state-inProgress',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/bold-basics-loading-loading-half.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'state-rejected',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/outline-select-hand-drag-alternate.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'state-closed',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/outline-basics-form-validation-check-star.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'left-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/navigation-left-circle.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'right-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/navigation-right-circle.svg')
    );
  }
}
