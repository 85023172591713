import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
// import { GIGYA_SERVICE } from '@app/constants/global.constants';
// import { GigyaResponse, GigyaService } from '@app/security/gigya.service';
import { ServiceResponse } from '@app/types/service-response';
import UserDTO from '@data/models/user-dto';
import { UserService } from '@data/services/user.service';
import { forkJoin, Observable, pipe, Subject, throwError, of } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, takeUntil, tap, mapTo } from 'rxjs/operators';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
  SilentRequest
} from '@azure/msal-browser';
import { Router } from '@angular/router';
import { RouteConstants } from '@app/constants/route.constants';
import { ProgressSpinnerDialogService } from '@shared/services/progress-spinner-dialog.service';
import { GlobalMessageService } from '@shared/services/global-message.service';
import { EdmsError } from '@app/types/error';
import jwt_decode from 'jwt-decode';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ENV, PROJECT_ENUM } from '@app/constants/global.constants';
import { Env } from '@app/types/env';
import { isJSDocThisTag } from 'typescript';
import { OauthToken } from '@data/models/OauthToken';
import { MatDialog } from '@angular/material/dialog';
import { UserDefaultSettingsComponent } from '@shared/components/user-default-settings/user-default-settings.component';

import { I } from '@angular/cdk/keycodes';
import { UserFiltersService } from '@data/services/user-filters.service';
import { CookieService } from 'ngx-cookie-service';
import { JwtError } from '@data/models/jtw-token-error';

export type LoginResponse = {
  jwt: string;
  uid: string;
};

export type jwtToken = {
  exp: number;
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public readonly UID_KEY = 'uid_' + window.location.hostname;
  public readonly JWT_KEY = 'jwt_' + window.location.hostname;
  public readonly ACCESS_TOKEN = 'access_token_' + window.location.hostname;
  public readonly EXPIRATION_TOKEN = 'expires_in_' + window.location.hostname;
  public readonly EXPIRATION_JWT_TOKEN = 'jwt_expires_' + window.location.hostname;
  public readonly PROJECT_KEY = 'project';
  public barerStopLight = true;
  public jwtStopLight = true;
  // public internalLogin$: Observable<LoginResponse>;
  private readonly destroying$ = new Subject<void>();
  private readonly HTTP_OPTIONS = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  };
  // private internalLoginSubject: Subject<LoginResponse> = new Subject<LoginResponse>();
  private uid: string;
  private jwt: string;
  private spinner: string;

  constructor(
    // @Inject(GIGYA_SERVICE) private gigyaService: GigyaService,
    private userService: UserService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private spinnerService: ProgressSpinnerDialogService,
    private userFiltersService: UserFiltersService,
    private router: Router,
    private ngZone: NgZone,
    private globalMessageService: GlobalMessageService,
    private dialog: MatDialog,
    @Inject(ENV) private env: Env,
    private http: HttpClient //private cookieService: CookieService
  ) {
    // this.internalLogin$ = this.internalLoginSubject.asObservable();

    const checkingInterval = setInterval(() => {
      if (this.getJWT() !== null) {
        const now = new Date();
        // || new Date(this.getExpirationJWTToken()) < new Date(now.setMinutes(now.getMinutes() + 10)) commentato per inutilità
        if (this.tokenJWTExpired()) {
          console.log('=== Token refresh ===');
          this.authService.acquireTokenSilent({ ...this.msalGuardConfig.authRequest } as SilentRequest).subscribe(
            (payload: AuthenticationResult) => {
              this.storeUserCredentials({
                jwt: payload.accessToken,
                uid: this.hashFnv32a(payload.account.username, true)
              });
            },

            (error: JwtError) => {
              if (error.errorMessage.includes('AADSTS50058')) {
                this.logout();
              }
            }
          );
        }
      }
    }, 30000); // 5 min

    const checkingIntervalOuaht = setInterval(() => {
      if (this.getExpirationToken() !== null) {
        const now = new Date();
        if (this.tokenExpired()) {
          //|| new Date(this.getExpirationToken()) < new Date(now.setMinutes(now.getMinutes() + 10))
          console.log('=== Token Bearer refresh ===');
          const body = new HttpParams()
            .set('client_id', this.env.apiManagmentKey)
            .set('client_secret', this.env.clientSecretOauth)
            .set('grant_type', 'client_credentials');
          // this.removeToken();
          this.http.post<OauthToken>(this.env.endpointAccessToken, body, this.HTTP_OPTIONS).subscribe((res: OauthToken) => {
            this.saveToken(res.access_token);
            this.saveExpirationToken(res.expires_in);
          });
        }
      }
    }, 30000); // 5 min

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.destroying$)
      )
      .subscribe(() => {
        // this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        )
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        const payload = result.payload as AuthenticationResult;

        this.authService.instance.setActiveAccount(payload.account);

        this.storeUserCredentials({
          jwt: payload.accessToken,
          uid: this.hashFnv32a(payload.account.username, true)
        });
        /*  forkJoin([this.userService.getLoggedUserInfo(), this.userService.getLoggedUserInfoIdea()])
          .pipe(
            map(([user, userIdea]) => ({ user, userIdea })),
            finalize(() => {})
          )
          .subscribe((response) => {
            if (!response.user.error) {
              this.storeUserProject(PROJECT_ENUM.ebos);
              this.loginSuccess(response.user.data);
            } else if (!response.userIdea.error) {
              this.storeUserProject(PROJECT_ENUM.idea);
              this.loginSuccess(response.userIdea.data);
            } else {
              this.loginError(response.userIdea.error);
            }
          });*/

        this.userService.getLoggedUserInfo().subscribe({
          next: (response: ServiceResponse<UserDTO>) => {
            if (!response.error) {
              if (!!!this.getProject()) {
                this.storeUserProject(PROJECT_ENUM.ebos);
                this.loginSuccess(response.data);
              }
            } else {
              this.userService.getLoggedUserInfoIdea().subscribe({
                next: (response: ServiceResponse<UserDTO>) => {
                  if (!response.error) {
                    this.userFiltersService.setUser(response.data);
                    this.storeUserProject(PROJECT_ENUM.idea);
                    this.loginSuccess(response.data);
                  } else {
                    this.redirectRegistration({} as UserDTO);
                  }
                },
                error: (error) => {
                  if (error.status === 404) {
                    this.redirectRegistration({} as UserDTO);
                  } else {
                    this.loginError(error);
                  }
                }
              });
            }
          },
          error: (error) => {
            if (error.status === 404) {
              this.userService.getLoggedUserInfoIdea().subscribe({
                next: (response: ServiceResponse<UserDTO>) => {
                  if (!response.error) {
                    this.userFiltersService.setUser(response.data);
                    this.storeUserProject(PROJECT_ENUM.idea);
                    this.loginSuccess(response.data);
                  } else {
                    if (response.error.status === 404) {
                      this.redirectRegistration({} as UserDTO);
                    } else {
                      this.loginError(response.error);
                    }
                  }
                },
                error: (error) => {
                  if (error.status === 404) {
                    this.redirectRegistration({} as UserDTO);
                  } else {
                    this.loginError(error);
                  }
                }
              });
            } else {
              this.loginError(error);
            }
          }
        });
      });

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGOUT_END))
      .subscribe((result: EventMessage) => {
        console.log(result);
        this.spinnerService.hide(this.spinner);
        this.router.navigate([RouteConstants.LOGIN]);
      });

    this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => {
        // this.setLoginDisplay();
      });
  }

  /**
   * Stores a JWT token
   *
   * @param jwt JWT token to be stored
   */
  saveJWT(jwt: string): void {
    // this.cookieService.set(this.JWT_KEY, jwt, 1);
    localStorage.setItem(this.JWT_KEY, jwt);
  }

  /**
   * Retrieves the saved JWT token
   *
   * @returns saved JWT token
   */
  getJWT(): string {
    return localStorage.getItem(this.JWT_KEY);
    // return this.cookieService.get(this.JWT_KEY);
  }

  /**
   * Remove the saved JWT token
   */
  removeJWT(): void {
    localStorage.removeItem(this.JWT_KEY);
    // this.cookieService.delete(this.JWT_KEY);
  }

  /**
   * Stores the user UID
   *
   * @param uid UID to be stored
   */
  saveUID(uid: string) {
    localStorage.setItem(this.UID_KEY, uid);
    //this.cookieService.set(this.UID_KEY, uid);
  }

  /**
   * Retrieves the saved user UID
   *
   * @returns saved user UID
   */
  getUID() {
    return localStorage.getItem(this.UID_KEY);
    //return this.cookieService.get(this.UID_KEY);
  }

  /**
   * Remove the saved user UID
   */
  removeUID() {
    localStorage.removeItem(this.UID_KEY);
    //this.cookieService.delete(this.UID_KEY);
  }

  /**
   * Stores the user UID
   *
   * @param uid UID to be stored
   */
  saveProject(project: number) {
    localStorage.setItem(this.PROJECT_KEY, project.toString());
    // this.cookieService.set(this.PROJECT_KEY, project.toString());
  }

  /**
   * Retrieves the saved user UID
   *
   * @returns saved user UID
   */
  getProject() {
    return localStorage.getItem(this.PROJECT_KEY);
    // return this.cookieService.get(this.PROJECT_KEY);
  }

  /**
   * Remove the saved user UID
   */
  removeProject() {
    localStorage.removeItem(this.PROJECT_KEY);
    //this.cookieService.delete(this.PROJECT_KEY);
  }

  getAccessToken(): string {
    return localStorage.getItem(this.ACCESS_TOKEN);
    // return this.cookieService.get(this.ACCESS_TOKEN);
  }

  getExpirationToken(): string {
    return localStorage.getItem(this.EXPIRATION_TOKEN);
    //return this.cookieService.get(this.EXPIRATION_TOKEN);
  }

  getExpirationJWTToken(): string {
    return localStorage.getItem(this.EXPIRATION_JWT_TOKEN);
    //  return this.cookieService.get(this.EXPIRATION_JWT_TOKEN);
  }

  saveToken(token: string): void {
    localStorage.setItem(this.ACCESS_TOKEN, token);
    // this.cookieService.set(this.ACCESS_TOKEN, token, 1);
  }

  saveExpirationToken(expirationToken: string): void {
    const now = new Date();
    localStorage.setItem(this.EXPIRATION_TOKEN, new Date(now.setSeconds(now.getSeconds() + Number(expirationToken))).toString());

    /* this.cookieService.set(
      this.EXPIRATION_TOKEN,
      new Date(now.setSeconds(now.getSeconds() + Number(expirationToken))).toString(),
      1
    ); */
  }
  saveExpirationJWTToken(expirationToken: number): void {
    localStorage.setItem(this.EXPIRATION_JWT_TOKEN, new Date(Number(expirationToken) * 1000).toString());
    //this.cookieService.set(this.EXPIRATION_JWT_TOKEN, new Date(Number(expirationToken) * 1000).toString(), 1);
    //Date.now() tolto per data di scadenza errata
  }

  removeToken(): void {
    localStorage.removeItem(this.ACCESS_TOKEN);
    //   this.cookieService.delete(this.ACCESS_TOKEN);
  }

  /**
   * Retrieves current logged user UID/JWT
   */
  // getLoggedUserTokens(): { jwt: string; uid: string } {
  //   return {
  //     [this.JWT_KEY]: this.getJWT(),
  //     [this.UID_KEY]: this.getUID()
  //   };
  // }

  /**
   * Check if there is a logged in user
   */
  isUserLogged(): boolean {
    return !!this.getUID() && !!this.getJWT() && !!this.getProject() && !this.tokenExpired() && !this.tokenJWTExpired();
  }

    /**
     * Check if there is a logged in user and refresh access token
     */
    isUserLoggedGuard(): Observable<boolean> {
      if(this.isUserLogged()) {
        return this.getTokenSubscribe().pipe(
          tap((res) => {
            this.setTokenSubscribe(res);
          }),
          mapTo(true)
        );
      } else {
        return of(false);
      }
    }

  /**
   * Log the current user out by removing credentials and log out from Gigya
   */
  logout(): void {
    this.spinner = this.spinnerService.show();
    this.removeJWT();
    this.removeUID();
    this.removeProject();
    this.userService.clearLoggedUser();
    // this.gigyaService.logout();
    // this.authService.logoutPopup();
    this.removeToken();
    this.authService.logoutRedirect();
  }
  public tokenExpired(): boolean {
    return new Date() > new Date(this.getExpirationToken());
  }
  public tokenJWTExpired(): boolean {
    return new Date() > new Date(this.getExpirationJWTToken());
  }

  /**
   * Performs a login for an internal user, storing user credentials if ok.
   * It use the Gigya Service under the curtains.
   *
   * @returns Observable with eDMS user info on success, or the Gigya error on error
   */
  // internalLogin(): Observable<ServiceResponse<UserDTO>> {
  //   const internalLogin$ = this.internalLogin$.pipe(
  //     tap((loginResponse) => {
  //       this.storeUserCredentials(loginResponse);
  //     }),
  //     switchMap(() => this.userService.getLoggedUserInfo()),
  //     catchError((error) => {
  //       this.logout();

  //       return throwError(error);
  //     })
  //   );

  //   this.gigyaService.internalLogin();

  //   return internalLogin$;
  // }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  internalLogin(): void {
    // const internalLogin$ = this.internalLogin$.pipe(
    //   tap((loginResponse) => {
    //     this.storeUserCredentials(loginResponse);

    //     this.userService.getLoggedUserInfo().subscribe({
    //       next: (response: ServiceResponse<UserDTO>) => {
    //         if (!response.error) {
    //           this.loginSuccess(response.data);
    //         } else {
    //           this.loginError(response.error);
    //         }
    //       },
    //       error: (error) => {
    //         this.loginError(error);
    //       }
    //     });
    //   }),
    //   //switchMap(() => this.userService.getLoggedUserInfo()),
    //   catchError((error) => {
    //     this.logout();

    //     return throwError(error);
    //   })
    // );

    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);

      // this.authService
      //   .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
      //   .subscribe((response: AuthenticationResult) => {
      //     this.authService.instance.setActiveAccount(response.account);
      //   });
    } else {
      this.authService.loginPopup().subscribe((response: AuthenticationResult) => {
        this.authService.instance.setActiveAccount(response.account);
      });
    }

    // return internalLogin$;
  }
  public getToken() {
    const body = new HttpParams()
      .set('client_id', this.env.apiManagmentKey)
      .set('client_secret', this.env.clientSecretOauth)
      .set('grant_type', 'client_credentials');

    return this.http.post<OauthToken>(this.env.endpointAccessToken, body, this.HTTP_OPTIONS).pipe(
      tap((res) => {
        this.saveToken(res.access_token);
        this.saveExpirationToken(res.expires_in);
      }),
      catchError((error) => throwError(error as EdmsError))
    );
  }
  public getTokenSubscribe() {
    // const now = new Date();
    //if (this.tokenExpired()) {

    const body = new HttpParams()
      .set('client_id', this.env.apiManagmentKey)
      .set('client_secret', this.env.clientSecretOauth)
      .set('grant_type', 'client_credentials');

    return this.http.post<OauthToken>(this.env.endpointAccessToken, body, this.HTTP_OPTIONS);
    //  }
  }

  public setTokenSubscribe(res: OauthToken) {
    this.saveToken(res.access_token);
    this.saveExpirationToken(res.expires_in);
  }

  public getGraphToken() {
    const body = new HttpParams()
      .set('client_id', this.env.clientIdGraphs)
      .set('client_secret', this.env.clientSecretGraphs)
      .set('grant_type', 'client_credentials')
      .set('scope', 'https://graph.microsoft.com/.default');

    return this.http.post<OauthToken>(this.env.endpointGraphsAccessToken, body, this.HTTP_OPTIONS);
  }

  public getJWTToken() {
    this.jwtStopLight = false;
    return this.authService.acquireTokenSilent({ ...this.msalGuardConfig.authRequest } as SilentRequest).subscribe(
      (payload: AuthenticationResult) => {
        this.storeUserCredentials({
          jwt: payload.accessToken,
          uid: this.hashFnv32a(payload.account.username, true)
        });
        this.jwtStopLight = true;
      },
      (error: JwtError) => {
        if (error.errorMessage.includes('AADSTS50058') || error.errorMessage.includes('AADSTS700084')) {
          this.logout();
        }
      }
    );
  }
  public getJWTTokenWithoutSub() {
    this.jwtStopLight = false;
    return this.authService.acquireTokenSilent({ ...this.msalGuardConfig.authRequest } as SilentRequest);
  }

  public getJWTTokenRefresh() {
    const now = new Date();

    this.jwtStopLight = false;
    return this.authService.acquireTokenSilent({ ...this.msalGuardConfig.authRequest } as SilentRequest);
  }

  public setJWTTokenRefresh(payload: AuthenticationResult) {
    this.storeUserCredentials({
      jwt: payload.accessToken,
      uid: this.hashFnv32a(payload.account.username, true)
    });
    this.jwtStopLight = true;
  }

  public loginSuccess(edmsUser: UserDTO): void {
    // TODO (RESEARCH): Check ngZone usage. Why?
    this.ngZone.run(() => {
      // TODO (FUTURE): Redirect to appropiate page:
      // * Redirect to dashboard if the user already has a preferred language.
      // * Otherwhise, redirect to language selection page (to be developed)
      if (this.getProject() === PROJECT_ENUM.ebos.toString()) {
        if (window.location.hostname.toUpperCase().includes('IDEAPP')) {
          this.router.navigate([RouteConstants.DASHBOARD_IDEA]);
        } else {
          this.router.navigate([RouteConstants.DASHBOARD]);
        }
      } else if (this.getProject() === PROJECT_ENUM.idea.toString()) {
        if (window.location.hostname.toUpperCase().includes('IDEAPP')) {
          this.router.navigate([RouteConstants.DASHBOARD_IDEA]);
        } else {
          this.logout();
          //this.router.navigate([RouteConstants.DASHBOARD_IDEA]);
        }
      }

      // this.router.navigate([RouteConstants.DASHBOARD_IDEA]);
    });
  }
  public storeUserProject(project: PROJECT_ENUM): void {
    this.saveProject(project);
  }
  private redirectRegistration(edmsUser: UserDTO): void {
    // TODO (RESEARCH): Check ngZone usage. Why?
    this.ngZone.run(() => {
      // TODO (FUTURE): Redirect to appropiate page:
      // * Redirect to dashboard if the user already has a preferred language.
      // * Otherwhise, redirect to language selection page (to be developed)

      this.dialog
        .open(UserDefaultSettingsComponent, {
          panelClass: UserDefaultSettingsComponent.MODAL_PANEL_CLASS,
          id: UserDefaultSettingsComponent.MODAL_ID,
          data: {
            user: edmsUser
          }
        })
        .afterClosed()
        .subscribe((dialogResult) => {
          this.router.navigate([RouteConstants.DASHBOARD_IDEA]);
         /* if (dialogResult) {
            this.router.navigate([RouteConstants.DASHBOARD_IDEA]);
            //    this.getChecklistsByUser(true);
            //   this.getChecklistsByUser(false);
          }*/
          //this.spinnerService.hide(spinner);
        });
    });
  }

  private loginError(error: EdmsError): void {
    this.ngZone.run(() => {
      this.globalMessageService.showError(error);
    });
  }

  private hashFnv32a(str: string, asString: boolean): string {
    /*jshint bitwise:false */
    let hval = 0x811c9dc5;
    for (let i = 0, l = str.length; i < l; i++) {
      // eslint-disable-next-line no-bitwise
      hval ^= str.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    // Convert to 8 digit hex string
    // eslint-disable-next-line no-bitwise
    return ('0000000' + (hval >>> 0).toString(16)).substring(-8);
  }

  // logout2(popup?: boolean) {
  //   if (popup) {
  //     this.authService.logoutPopup({
  //       mainWindowRedirectUri: '/'
  //     });
  //   } else {
  //     this.authService.logoutRedirect();
  //   }
  // }

  private storeUserCredentials(loginResponse: LoginResponse): void {
    if (loginResponse) {
      this.saveJWT(loginResponse.jwt);
      const jwtDecoded: jwtToken = jwt_decode(loginResponse.jwt);
      this.saveExpirationJWTToken(jwtDecoded.exp);
      this.saveUID(loginResponse.uid);
    }
  }
}
