import { ActionDTO } from './../../../data/models/action-dto';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EdmsError } from '@app/types/error';
import { ProgressSpinnerDialogService } from '@shared/services/progress-spinner-dialog.service';
import { MAX_FILE_SIZE, MAX_VIDEO_SIZE } from '@app/constants/global.constants';
import { FileDTO } from '@data/models/file-dto';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AzureBlobStorageService } from '@shared/services/azure-blob-storage.service';
import { GlobalMessageService } from '@shared/services/global-message.service';
import { TranslateService } from '@ngx-translate/core';
import { GLOBAL_MESSAGE_5SEC_DURATION } from '@shared/constants/global-message';
import { NoteDTO } from '@data/models/note-dto';
import { AZURE_ACTION_PATH, AZURE_NOTES_PATH } from '@shared/constants/azure.constants';
import { FileRefDTO } from '@data/models/file-ref-dto';
import { ActionTrackerService } from '@data/services/action-tracker.service';
import { PriorityDTO } from '@data/models/priority-dto';
import { AppTypeDTO } from '@data/models/app-type-dto';
import { APP_TYPE_ENUM } from '@shared/constants/app-type.constants';

@Component({
  selector: 'app-create-action-item-dialog',
  templateUrl: './create-action-item-dialog.component.html',
  styleUrls: ['./create-action-item-dialog.component.scss']
})
export class CreateActionItemDialogComponent implements OnInit {
  // public formGroup: FormGroup;
  // public notesControl: FormControl = new FormControl();

  public maxActionTitle = 240;
  public action: ActionDTO;

  public get saveButtonDisabled(): boolean {
    if (
      // descrizione vuota
      this.action.title.trim().length === 0 ||
      // descrizione troppo lunga
      this.action.title.length > this.maxActionTitle ||
      // se non è safety trigger e non è stata scelta la priorità
      (this.displayPriority() && !this.action.trackerAction.priority?.id)
    ) {
      return true;
    }
    return false;
  }

  public labels: { [key: string]: string } = {
    title: marker('createActionItem.dialog.title'),
    files: marker('global.files'),
    browseFiles: marker('global.browseFiles'),
    fileSizeLimit: marker('global.messages.fileSizeLimit'),
    saveAction: marker('createActionItem.dialog.saveAction'),
    optional: marker('global.optional'),
    priority: marker('createActionItem.dialog.priority'),
    action: marker('createActionItem.dialog.action'),
    textareaPlaceholder: marker('addActionDescription.dialog.textareaPlaceholder')
  };

  public priorityList: PriorityDTO[] = [];
  public error: EdmsError;
  public appTypeEnum = APP_TYPE_ENUM;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      model: {
        action: ActionDTO;
        priorityList: PriorityDTO[];
        appTypeId: number;
        files: FileDTO[];
        fileRefs: FileRefDTO[];
      };
    },
    private actionTrackerService: ActionTrackerService,
    private azure: AzureBlobStorageService,
    private spinnerService: ProgressSpinnerDialogService,
    // private fb: FormBuilder,
    private dialog: MatDialog,
    private globalMessage: GlobalMessageService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.action = this.data.model.action;

    this.priorityList = this.data.model.priorityList;

    // const prioritySpinner = this.spinnerService.show();

    // this.actionTrackerService.getPriorities().subscribe((data) => {
    //   this.priorityList = data;
    //   this.spinnerService.hide(prioritySpinner);
    // });
  }

  public updateModel(): void {
    // this.data.model.notes = this.notesControl.value;
  }

  public addFiles(fileList: FileList): void {
    for (let i = 0; i < fileList.length; i++) {
      const file: File = fileList.item(i);

      if (
        (file.type.startsWith('video') && file.size > MAX_VIDEO_SIZE) ||
        (!file.type.startsWith('video') && file.size > MAX_FILE_SIZE)
      ) {
        this.globalMessage.showError({ message: this.translate.instant('error.fileSizeLimit', { filename: file.name }) });
      } else {
        this.data.model.files.push({
          data: file,
          thumbnail:
            file.type.startsWith('video') || file.type.startsWith('image')
              ? (window.URL || window.webkitURL).createObjectURL(file)
              : './assets/img/default-file@3x.png',
          template: false,
          SavedOnAzure: false
        });
      }
    }
  }

  public removeFile(index: number): void {
    this.data.model.files.splice(index, 1);
  }

  public setPriority(priority: PriorityDTO) {
    this.action.trackerAction.priority = priority;
  }

  public async save(): Promise<void> {
    // this.action.filesInfos = this.files.map((file) => ({ id: null, azureId: file.data.name } as FileRefDTO));
    this.data.model.fileRefs = this.data.model.files.map((file) => ({ id: null, azureId: file.data.name } as FileRefDTO));
    // this.action.actionNotes = [
    //   {
    //     fileInfos: this.files.map((file) => ({ id: null, azureId: file.data.name } as FileRefDTO))
    //   }
    // ] as NoteDTO[];

    this.closeDialogSuccess();

    // const spinner = this.spinnerService.show();

    // if (this.files && this.files.length > 0) {
    //   const fileSpinner = this.spinnerService.show();
    //   let filestoUpload = this.files.length - 1;
    //   this.azure.getClient().subscribe({
    //     next: async (client) => {
    //       for await (const file of this.files) {
    //         this.globalMessage.showLoading({
    //           message: this.translate.instant(marker('azure.uploading'), { filename: file.data.name })
    //         });
    //         client.upload(
    //           // `${AZURE_ACTION_PATH}/${this.data.model.action.id}${AZURE_NOTES_PATH}/${data.id}`,
    //           `${AZURE_ACTION_PATH}/test`,
    //           file.data,
    //           file.data.name,
    //           () => {
    //             if (filestoUpload-- === 0) {
    //               this.spinnerService.hide(fileSpinner);
    //               this.globalMessage.dismiss();
    //               this.closeDialogSuccess();
    //             }
    //           }
    //         );
    //       }
    //     }
    //   });
    // }
  }

  // private createFormGroup(): void {
  //   this.formGroup = this.fb.group({
  //     source: this.notesControl
  //   });
  // }

  checkDescription(): void {}

  public displayPriority(): boolean {
    return this.data.model.appTypeId !== this.appTypeEnum.SAFETY_TRIGGER;
  }

  private closeDialogSuccess(): void {
    this.dialog.getDialogById('create-action-item-dialog-component').close({
      action: this.action,
      files: this.data.model.files,
      fileRefs: this.data.model.fileRefs
    });
    // this.globalMessage.showSuccess({
    //   message: this.translate.instant(marker('global.messages.entitySuccesfullyCreated'), {
    //     entity: this.translate.instant(marker('entity.note'))
    //   }),
    //   duration: GLOBAL_MESSAGE_5SEC_DURATION
    // });
  }
}
