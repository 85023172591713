import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV } from '@app/constants/global.constants';
import { Env } from '@app/types/env';
import { EdmsError } from '@app/types/error';
import { PageableDTO } from '@data/models/pageable-dto';
import UserDTO from '@data/models/user-dto';
import UserIdeaDTO from '@data/models/user-idea-dto';
import { off } from 'process';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { forEachChild } from 'typescript';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  public readonly USERS_PATH = '/api/users/_search';
  public readonly USERS_IDEA_PATH = '/api/v1/users';
  public readonly REGISTRATION_IDEA_PATH = '/registration';

  constructor(@Inject(ENV) private env: Env, private http: HttpClient) {}

  /*public getUsers(plantId: number, filter: string, sortDirection: string, pageNumber: number, pageSize: number) {
    const usersEbos = this.getUsersEbos(plantId, filter, sortDirection, pageNumber, pageSize);
    const usersIdea = this.getUsersIdea(plantId, filter, sortDirection, pageNumber, pageSize);
    return usersIdea;
  }*/

  public getUsersEbos(
    plantId: number,
    filter: string,
    sortDirection: string,
    pageNumber: number,
    pageSize: number
  ): Observable<PageableDTO<UserDTO>> {
    return this.http
      .post<PageableDTO<UserDTO>>(
        `${this.env.apiBaseUrl}${this.USERS_PATH}`,
        { plant: plantId, search: filter },
        {
          params: new HttpParams()
            .set('filter', filter)
            .set('sort', sortDirection)
            .set('page', pageNumber.toString())
            .set('size', pageSize.toString())
        }
      )
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getUsersIdea(
    ocId: number,
    plantId: number,
    filter: string,
    sortDirection: string,
    pageNumber: number,
    pageSize: number
  ): Observable<PageableDTO<UserIdeaDTO>> {
    return this.http
      .post<PageableDTO<UserIdeaDTO>>(
        `${this.env.apiBaseUrlIdea}${this.USERS_IDEA_PATH}/_search`,
        {
          operationCenterId: ocId,
          plantId: plantId,
          search: filter
        },
        {
          params: new HttpParams()
            .set('filter', filter)
            .set('sort', sortDirection)
            .set('page', pageNumber.toString())
            .set('size', pageSize.toString())
        }
      )
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  // DISABLE SAFETY TRIGGER TEMPLATE //
  public saveUser(user: UserIdeaDTO): Observable<UserIdeaDTO> {
    return this.http
      .post<UserIdeaDTO>(`${this.env.apiBaseUrlIdea}${this.REGISTRATION_IDEA_PATH}/addUser`, user)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
}
