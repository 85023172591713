import { Component, OnInit } from '@angular/core';
import { A2hsService } from '@data/services/a2hs.service';

@Component({
  selector: 'app-a2hs',
  templateUrl: './a2hs.component.html',
  styleUrls: ['./a2hs.component.scss']
})
export class A2hsComponent implements OnInit {
  public a2hs: A2hsService;
  constructor(private a2hs1: A2hsService) {
    this.a2hs = a2hs1;
  }

  ngOnInit(): void {}
}
