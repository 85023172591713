import { HttpErrorResponse } from '@angular/common/http';
export class EdmsError {
  /**
   * Error message
   */
  error?: EdmsError;
  message: string;
  // error?: string;
  status?: number;

  public constructor(error: HttpErrorResponse) {
    // this.error = error.error;

    if (error.error) {
      if (error.error.message) {
        this.message = error.error.message;
      }
    } else if (error.message) {
      this.message = error.message;
    }

    this.status = error.status;
  }
}

// export type InternalError = {
//   error: string;
//   message: string;
//   path: string;
//   status: number;
//   timestamp: string;
// };
