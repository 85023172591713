<div class="view-header">
    <mat-icon svgIcon="close" mat-dialog-close></mat-icon>
</div>
<div class="image-content">
    <mat-carousel #carousel [autoplay]="false" [useKeyboard]="true" [maintainAspectRatio]="false" slideHeight="480px">
        <mat-carousel-slide *ngFor="let slide of data.model.files" [hideOverlay]="true">
            <img [src]="slide.thumbnail | safe" />
        </mat-carousel-slide>
    </mat-carousel>
</div>
<div class="view-footer" *ngIf="data.model.files.length > 1">
    <div class="swipe-buttons">
        <mat-icon svgIcon="left-arrow" (click)="carousel.previous()"></mat-icon>
        <h3>{{ carousel.currentIndex + 1 }} / {{ this.data.model.files.length }}</h3>
        <mat-icon svgIcon="right-arrow" (click)="carousel.next()"></mat-icon>
    </div>
</div>
