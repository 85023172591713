import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { PROJECT_ENUM } from '@app/constants/global.constants';
import { RouteConstants } from '@app/constants/route.constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardEbos implements CanActivate {
  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authenticationService.isUserLoggedGuard().pipe(
      map(isLogged => {
        if (isLogged) {
          if (this.authenticationService.getProject() === PROJECT_ENUM.idea.toString()) {
            if (window.innerWidth > 768) {
              return this.router.parseUrl(RouteConstants.DASHBOARD_IDEA);
            } else {
              return this.router.parseUrl(RouteConstants.DASHBOARD_IDEA_MOBILE);
            }
          } else {
            return true;
          }
        } else {
          return this.router.parseUrl(RouteConstants.LOGIN);
        }
      })
    );
  }
}
