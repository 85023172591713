import { users } from '@shared/test-data/owners-data';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV } from '@app/constants/global.constants';
import { Env } from '@app/types/env';
import { EdmsError } from '@app/types/error';
import { ActionDTO } from '@data/models/action-dto';
import { ActionTrackerFilterDTO } from '@data/models/action-tracker-filters-dto';
import { FormatCompleteDTO } from '@data/models/format-complete-dto';
import { LossCategoryDTO } from '@data/models/loss-category-dto';
import { SourceDTO } from '@data/models/source-dto';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActionTypeDTO } from '../models/action-type-dto';
import { NoteDTO } from '../models/note-dto';
import { PriorityDTO } from '../models/priority-dto';
import UserDTO from '@data/models/user-dto';
import { GenericSourceDTO, SourceBaseDTO } from '@data/models/generic-source-dto';
import { AppDetailDTO } from '@data/models/app-detail-dto';

@Injectable({
  providedIn: 'root'
})
export class ActionTrackerService {
  public static readonly ACTIONS_OWNERS_FILTER_ALL = 'ALL';
  public static readonly ACTIONS_OWNERS_FILTER_ENABLE = 'ENABLE';
  public static readonly ACTIONS_OWNERS_FILTER_DISABLE = 'DISABLE';

  public readonly DEFAULT_SIZE = '6';
  public readonly DEFAULT_SIZE_ACTION = '20';
  public readonly DEFAULT_PAGE = '0';
  public readonly DEFAULT_ORDER = 'trackerActionDueDate,desc';

  public readonly ACTIONS_PATH = '/api/trackerActions/search';
  public readonly GET_SOURCES_PATH = '/api/trackerActions/sources';
  // public readonly GET_SOURCES_PATH = '/api/v1/trackerActions/archive/sources';
  public readonly GET_PRIORITIES_PATH = '/api/trackerActions/priorities';
  public readonly GET_ACTION_TYPES_PATH = '/api/trackerActions/actionTypes';
  public readonly GET_ACTION_OWNERS_PATH = '/api/trackerActions/owners';
  public readonly GET_ACTION_SUBMITTED_BY_PATH = '/api/trackerActions/users';
  public readonly GET_LOSS_CATEGORIES_PATH = '/api/trackerActions/lossCategories';
  public readonly GET_FORMATS_PATH = '/api/admin/formats';
  public readonly SAVE = '/api/trackerActions/add';
  public readonly ADD_NOTE = '/api/trackerActions/actions/';
  // public readonly DOWNLOAD_EXCEL = '/api/trackerActions/finishExport';
  // public readonly REQUEST_DOWNLOAD_EXCEL = '/api/trackerActions/export';
  public readonly DOWNLOAD_EXCEL = '/api/export/finishExport';
  public readonly REQUEST_DOWNLOAD_EXCEL = '/api/export/trackerActions';
  public readonly WA_RELATED_FORMATS = '/api/admin/formats/_searchBySpec';

  public readonly DEFAULT_FILTER: ActionTrackerFilterDTO = {
    dateFilter: '',
    openDate: [],
    closeDate: [],
    ownersFilter: null,
    submittedByFilter: null,
    statusFilter: [1],
    workingAreas: [],
    sourceFilter: null,
    sourceFilterSelectedText: null
  };

  private filters: ActionTrackerFilterDTO;

  constructor(@Inject(ENV) private env: Env, private http: HttpClient) {}

  public save(model: ActionDTO): Observable<ActionDTO> {
    return this.http
      .post<ActionDTO>(`${this.env.apiBaseUrl}${this.SAVE}`, model)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getSources(): Observable<GenericSourceDTO> {
    return this.http
      .get<GenericSourceDTO>(`${this.env.apiBaseUrl}${this.GET_SOURCES_PATH}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getGenericSources(): Observable<SourceBaseDTO[]> {
    return new Observable<SourceBaseDTO[]>((observer) => {
      this.getSources().subscribe((genericSource) => {
        let source = [] as SourceBaseDTO[];
        if (genericSource.sources) {
          source = source.concat(
            genericSource.sources?.map(
              (s: SourceDTO) =>
                ({
                  id: s.id,
                  description: s.literal?.translateValue,
                  type: 'Action'
                } as SourceBaseDTO)
            )
          );
        }
        if (genericSource.apps) {
          source = source.concat(
            genericSource.apps?.map(
              (a: AppDetailDTO) =>
                ({
                  id: a.id,
                  description: a.name,
                  type: 'Template'
                } as SourceBaseDTO)
            )
          );
        }
        observer.next(source);
        observer.complete();
      });
    });
  }

  public getPriorities(): Observable<PriorityDTO[]> {
    return this.http
      .get<PriorityDTO[]>(`${this.env.apiBaseUrl}${this.GET_PRIORITIES_PATH}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getActionTypes(): Observable<ActionTypeDTO[]> {
    return this.http
      .get<ActionTypeDTO[]>(`${this.env.apiBaseUrl}${this.GET_ACTION_TYPES_PATH}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getActionOwners(filter: string = ActionTrackerService.ACTIONS_OWNERS_FILTER_ALL): Observable<UserDTO[]> {
    return this.http
      .get<UserDTO[]>(`${this.env.apiBaseUrl}${this.GET_ACTION_OWNERS_PATH}/${filter}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getActionSubmittedBy(): Observable<UserDTO[]> {
    return this.http
      .get<UserDTO[]>(`${this.env.apiBaseUrl}${this.GET_ACTION_SUBMITTED_BY_PATH}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getLossCategories(): Observable<LossCategoryDTO[]> {
    return this.http
      .get<LossCategoryDTO[]>(`${this.env.apiBaseUrl}${this.GET_LOSS_CATEGORIES_PATH}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getFormats(
    workingAreaId: number
  ): Observable<{ equipmentFormats: FormatCompleteDTO[]; workingAreasFormat: FormatCompleteDTO[] }> {
    return this.http
      .get<{ equipmentFormats: FormatCompleteDTO[]; workingAreasFormat: FormatCompleteDTO[] }>(
        `${this.env.apiBaseUrl}${this.GET_FORMATS_PATH}/?workingAreaId=${workingAreaId}`
      )
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public searchActionsByPage(
    size?: number,
    page?: number,
    sort?: string,
    filters?: ActionTrackerFilterDTO
  ): Observable<ActionDTO[]> {
    const params = new HttpParams({
      fromObject: {
        size: size ? size.toString() : this.DEFAULT_SIZE_ACTION,
        page: page ? page.toString() : this.DEFAULT_PAGE,
        sort: sort ? sort : this.DEFAULT_ORDER
      }
    });

    let users: UserDTO[] = null;
    if (filters?.submittedByFilter) {
      users = [filters?.submittedByFilter];
    }

    const sources = {
      apps: filters?.sourceFilter?.apps,
      sources: filters?.sourceFilter?.sources
    } as GenericSourceDTO;

    const body = {
      dueDate: filters?.dateFilter,
      openDate: filters?.openDate,
      closeDate: filters?.closeDate,
      owner: filters?.ownersFilter?.id,
      states: filters?.statusFilter,
      workingAreas: filters?.workingAreas,
      sources,
      users
    };

    return this.http
      .post<ActionDTO[]>(`${this.env.apiBaseUrl}${this.ACTIONS_PATH}`, body, { params })
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public setFilters(filters: ActionTrackerFilterDTO): void {
    this.filters = filters;
  }

  public getFilters(): ActionTrackerFilterDTO {
    return this.filters;
  }

  public requestDownloadExcel(
    size?: number,
    page?: number,
    sort?: string,
    filters?: ActionTrackerFilterDTO
  ): Observable<{ name: string }> {
    const params = new HttpParams({
      fromObject: {
        size: size ? size.toString() : this.DEFAULT_SIZE,
        page: page ? page.toString() : this.DEFAULT_PAGE,
        sort: sort ? sort : this.DEFAULT_ORDER
      }
    });

    let users: UserDTO[] = null;
    if (filters?.submittedByFilter) {
      users = [filters?.submittedByFilter];
    }

    const body = {
      dueDate: filters?.dateFilter,
      openDate: filters?.openDate,
      closeDate: filters?.closeDate,
      owner: filters?.ownersFilter?.id,
      states: filters?.statusFilter,
      workingAreas: filters?.workingAreas,
      sources: filters?.sourceFilter,
      users
    };

    return this.http
      .post<{ name: string }>(`${this.env.apiBaseUrl}${this.REQUEST_DOWNLOAD_EXCEL}`, body, { params })
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public downloadExcel(file?: { name: string }): Observable<Blob> {
    const body = file;

    return this.http
      .post(`${this.env.apiBaseUrl}${this.DOWNLOAD_EXCEL}`, body, { responseType: 'blob' })
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public relatedFormats(
    formatId: number,
    eqFormatId?: number
  ): Observable<{ equipmentFormats: FormatCompleteDTO[]; workingAreasFormat: FormatCompleteDTO[] }> {
    if (!eqFormatId) {
      return this.http
        .post<{ equipmentFormats: FormatCompleteDTO[]; workingAreasFormat: FormatCompleteDTO[] }>(
          `${this.env.apiBaseUrl}${this.WA_RELATED_FORMATS}`,
          {
            workingAreasFormat: {
              id: formatId
            }
          }
        )
        .pipe(catchError((error) => throwError(error as EdmsError)));
    } else {
      return this.http
        .post<{ equipmentFormats: FormatCompleteDTO[]; workingAreasFormat: FormatCompleteDTO[] }>(
          `${this.env.apiBaseUrl}${this.WA_RELATED_FORMATS}`,
          {
            workingAreasFormat: {
              id: formatId
            },
            equipments: [
              {
                id: eqFormatId
              }
            ]
          }
        )
        .pipe(catchError((error) => throwError(error as EdmsError)));
    }
  }

  public addNote(model: NoteDTO): Observable<NoteDTO> {
    return this.http
      .post<NoteDTO>(`${this.env.apiBaseUrl}${this.ADD_NOTE}${model.action.id}/addNotes`, model)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public editNote(model: NoteDTO): Observable<NoteDTO> {
    return this.http
      .put<NoteDTO>(`${this.env.apiBaseUrl}${this.ADD_NOTE}${model.action.id}/editNotes`, model)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
}
