export const RISKS: { [key: number]: string } = {
  0: 'low',
  1: 'medium',
  2: 'high',
  999: 'noinfo'
};

export enum RISKS_ENUM {
  'LOW' = 0,
  'MEDIUM' = 1,
  'HIGH' = 2,
  'NOINFO' = 999
}

export enum ACTION_TRACKER_STATUSES_ENUM {
  'UNSCHEDULED' = 0,
  'INPROGRESS' = 1,
  'REJECTED' = 2,
  'CLOSED' = 3
}

export const ACTION_TRACKER_STATUSES: { [key: number]: string } = {
  0: 'unscheduled',
  1: 'inProgress',
  2: 'rejected',
  3: 'closed'
};

export const ACTION_TRACKER_PRIORITIES: { [key: number]: string } = {
  1: 'low',
  2: 'medium',
  3: 'high'
};

export enum ACTION_TRACKER_PRIORITIES_ENUM {
  'LOW' = 1,
  'MEDIUM' = 2,
  'HIGH' = 3
}

export const RESULTS: { [key: number]: string } = {
  0: 'low',
  1: 'medium',
  2: 'high'
};

export enum RESULTS_ENUM {
  'LOW' = 0,
  'MEDIUM' = 1,
  'HIGH' = 2
}

export enum CHECKLIST_STATE_ENUM {
  'NEW' = 0,
  'COMPLETED' = 1,
  'DRAFT' = 2
}
