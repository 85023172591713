<div class="create-action-item-dialog">
    <div class="create-action-item-dialog__title">
        <h1 class="mat-title">
            {{ labels.title | translate }}
        </h1>
        <mat-icon [mat-dialog-close]="false">close</mat-icon>
    </div>

    <div class="description-container" fxLayout="column" fxLayoutAlign="space-evenly stretch">
        <mat-label class="field-title">
            {{ labels.action | translate | uppercase }}
        </mat-label>

        <div class="description-container-inner" fxLayout="column" fxLayoutAlign="start end"
            [ngClass]="{ error: action.title && action.title.length > maxActionTitle }">
            <mat-form-field class="action-description" appearance="none">
                <textarea class="action-description__textarea" placeholder="{{labels.textareaPlaceholder | translate}}"
                    matInput [(ngModel)]="action.title" (blur)="(false)" #questionTitleField></textarea>
            </mat-form-field>
            <span class="text-length">
                {{action.title ? action.title.length : 0}}/{{maxActionTitle}}
            </span>
        </div>

        <div *ngIf="displayPriority()" class="field-title-margin">
            <mat-label class="field-title">
                {{ labels.priority | translate | uppercase }}
            </mat-label>

            <div class="numeric-field-box" fxLayout="row" fxLayoutAlign="start center">
                <button type="button" mat-stroked-button *ngFor="let priority of priorityList" [value]="priority.id"
                    [ngClass]="{
                        option: true,
                        'option-selected-green': priority.id === 1 && action.trackerAction.priority.id === priority.id,
                        'option-selected-orange': priority.id === 2 && action.trackerAction.priority.id === priority.id,
                        'option-selected-red': priority.id === 3 && action.trackerAction.priority.id === priority.id
                    }" (click)="setPriority(priority)">
                    {{ priority.literal.translateValue }}
                </button>
            </div>
        </div>

        <mat-label class="field-title field-title-margin">
            {{ labels.files | translate | uppercase}} {{ labels.optional | translate | uppercase}}
        </mat-label>

        <input class="hidden" type="file" #file (change)="addFiles($event.target.files)" multiple />
        <button type="button" mat-stroked-button color="primary" class="browse-files-content-button"
            (click)="file.value = ''; file.click()">
            {{ labels.browseFiles | translate }}
        </button>

        <p class="file-limit">{{ labels.fileSizeLimit | translate }}</p>
        <div class="thumbnail_list" *ngIf="data.model.files && data.model.files.length > 0" fxLayout="row"
            fxLayoutAlign="start start" fxLayout="row wrap">
            <div class="thumbnail" *ngFor="let file of data.model.files; let index = index">
                <img *ngIf="!file.data.type.startsWith('video')" [src]="file.thumbnail | safe"
                    alt="{{ file.data.name }}" />
                <video *ngIf="file.data.type.startsWith('video')" controls preload="metadata">
                    <source *ngIf="file.thumbnail" [src]="file.thumbnail | safe" type="{{ file.data.type }}" />
                    <track label="edms-video" kind="descriptions" />
                    <track label="subtitles" kind="subtitles" srclang="en" src="" />
                </video>
                <span *ngIf="!file.data.type.startsWith('video') && !file.data.type.startsWith('image')"
                    class="filename">{{
                    file.data.name
                    }}</span>
                <span class="delete" (click)="removeFile(index)"> <mat-icon fontSet="edms">icons-trash</mat-icon></span>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="create-action-dialog__actions">
        <button mat-flat-button color="primary" [disabled]="saveButtonDisabled" class="save" (click)="save()">
            {{ labels.saveAction | translate }}
        </button>
    </div>
</div>