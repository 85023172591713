import { PriorityDTO } from '@data/models/priority-dto';
import { FileDTO } from '@data/models/file-dto';
import { CheckListItemDTO } from '@data/models/checklist-item-dto';
import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { PossibleAnswerDTO } from '@data/models/possible-answer-dto';
import { QuestionDTO } from '@data/models/question-dto';
import { ACTION_TYPE_ENUM } from '@shared/constants/action-type.constants';
import { TranslateService } from '@ngx-translate/core';
import { MatHorizontalStepper, MatStepperModule } from '@angular/material/stepper';
// eslint-disable-next-line max-len
import { NewActionTrackerDialogComponent } from '@modules/dashboard/custom_apps/app-landing/others-app/action-tracker/dialog/new/new-action-tracker-dialog.component';
import {
  ANSWER_TYPE_ENUM,
  POSSIBLE_ANSWERS_ZERO_TO_THREE,
  POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH,
  POSSIBLE_ANSWERS_YES_NO
} from '@shared/constants/answer-type.constants';
import { APP_TYPE_ENUM } from '@shared/constants/app-type.constants';
import { FileRefDTO } from '@data/models/file-ref-dto';
import { ViewImmagesDialogComponent } from '../view-immages-dialog/view-immages-dialog.component';
import { AzureBlobStorageService } from '@shared/services/azure-blob-storage.service';
import {
  AZURE_CHECKLIST_PATH,
  AZURE_QUESTION_PATH,
  AZURE_SIGNATURE_PATH,
  AZURE_TEMPLATE_PATH
} from '@shared/constants/azure.constants';
import { MAX_FILE_SIZE } from '@app/constants/global.constants';
import { GlobalMessageService } from '@shared/services/global-message.service';
import { AnswerValueDTO, userAnswerValueDTO } from '@data/models/answer-value-dto';
import { AdministrationService } from '@data/services/administration.service';
import { ProgressSpinnerDialogService } from '@shared/services/progress-spinner-dialog.service';
import { EquipmentDTO } from '@data/models/equipment-dto';
import { WorkingAreaDTO } from '@data/models/workingarea-dto';
import { ManagementServiceService } from '@data/services/management-service.service';
import { finalize, take } from 'rxjs/operators';
import { ENTITY_TYPES_ENUM } from '@shared/constants/entities.constants';
import { UserAD, UserADResponse } from '@data/models/user-AD';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { AppTypeDTO } from '@data/models/app-type-dto';
import { TemplateBpstDTO } from '@data/models/template-bpst-dto';
import {
  BatBoneAnswerDTO,
  BatboneQuestionDTO,
  ChecklistBpstDTO,
  InformationAnswerDTO,
  StageAnswerDTO,
  WhyAnswersDTO
} from '@data/models/checklist-bpst-dto';
import { ActionDTO } from '@data/models/action-dto';
import { ACTION_TRACKER_PRIORITIES_ENUM } from '@shared/constants/status.constants';
import { ActionTrackerFilterDTO } from '@data/models/action-tracker-filters-dto';
import domtoimage from 'dom-to-image';
import jsPDF, { jsPDFOptions, RGBAData } from 'jspdf';
import { AppCategoryDTO } from '@data/models/app-category-dto';
import { QuestionsTemplateComponent } from './questions-template';
import { MatSelectionListChange } from '@angular/material/list';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { EventEmitter } from 'stream';
import { MatTabGroup } from '@angular/material/tabs';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

type pdfImagesType = Array<{
  width: number;
  height: number;
  data: string;
}>;

@Component({
  selector: 'app-questions-bpst-preview',
  templateUrl: './questions-bpst-preview.component.html',
  styleUrls: ['./questions-bpst-preview.component.scss']
})
export class QuestionsBpstPreviewComponent implements OnInit {
  @Input() actions: ActionDTO[];
  @Input() questions: QuestionDTO[];
  @Input() investigationQuestions: CheckListItemDTO[];
  @Input() informationQuestions: QuestionDTO[];
  @Input() appTypeId: number;
  @Input() appName: string;
  @Input() appId: number;
  @Input() checklistId: number;
  @Input() isPreview: boolean;
  @Input() isReadOnly: boolean;
  @Input() checklistItems: CheckListItemDTO[];
  @Input() batboneAnswers: BatBoneAnswerDTO[];
  @Input() whyAnswers: WhyAnswersDTO[];
  @Input() stageAnswers: StageAnswerDTO[];
  @Input() investigationAnswers: CheckListItemDTO[];
  @Input() informationAnswers: InformationAnswerDTO[];
  @Input() categories: AppCategoryDTO[];
  @Input() template: TemplateBpstDTO;
  @Input() priorityList: PriorityDTO[];
  @Input() appResults: boolean;
  @Input() templateId: number;
  @Input() tabActive: number;
  @Input() checklist?: ChecklistBpstDTO;
  @Input() questionFiles: Map<string, FileDTO[]>;
  @Input() get questionFilesTemplate(): Map<string, FileDTO[]> {
    return this.param;
  }
  set questionFilesTemplate(value: Map<string, FileDTO[]>) {
    this.param = value;
  }
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild(QuestionsTemplateComponent)
  public questionsTemplateComponent: QuestionsTemplateComponent;
  @ViewChild('signature')
  public signaturePad: SignaturePadComponent;
  @ViewChild(NewActionTrackerDialogComponent)
  public NewActionTrackerDialogComponent: NewActionTrackerDialogComponent;
  @ViewChild('myCanvas', { static: false }) myCanvas: ElementRef;
  @ViewChild('dataToExport', { static: false }) public dataToExport: ElementRef;
  @ViewChild('tabGroup', { static: false }) public tabGroup: MatHorizontalStepper;

  public pdfImages: pdfImagesType = [];
  public context: CanvasRenderingContext2D;
  public myDate = new Date();
  public viewBatbone = false;
  public tabBatboneVisible: boolean;
  public tabWhyVisible: boolean;
  public tabCauseVisible: boolean;
  public labels: { [key: string]: string } = {
    selectRootCausesIdentification: marker('management.templatesDialog.selectRootCausesIdentification'),
    why: marker('management.templatesDialog.why'),
    whyQuestion: marker('management.templatesDialog.whyQuestion'),
    rootCause: marker('management.templatesDialog.rootCause'),
    whySubtitle: marker('management.templatesDialog.rootCauseSubtitle'),
    rootCauseSubtitle: marker('management.templatesDialog.rootCauseSubtitle'),
    causeEffect: marker('management.templatesDialog.causeEffect'),
    causeEffectSubtitle: marker('management.templatesDialog.causeEffectSubtitle'),
    drawBatbone: marker('management.templatesDialog.drawBatbone'),
    Batbone: marker('management.templatesDialog.Batbone'),
    BatboneSubtitle: marker('management.templatesDialog.BatboneSubtitle'),
    Pending: marker('management.templatesDialog.Pending'),
    Draft: marker('management.templatesDialog.Draft'),
    ActionsCompleted: marker('management.templatesDialog.ActionsCompleted'),
    AnalysisCompleted: marker('management.templatesDialog.AnalysisCompleted'),
    Completed: marker('management.templatesDialog.Completed'),
    stage1: marker('management.templatesDialog.stage1'),
    stage2: marker('management.templatesDialog.stage2'),
    general: marker('management.templatesDialog.general'),
    ipsStatus: marker('management.templatesDialog.ipsStatus'),
    status: marker('management.templatesDialog.status'),
    complete: marker('management.templatesDialog.completeIfAny'),
    titleFill: marker('management.templatesDialog.titleFill'),
    problemStatement: marker('management.templatesDialog.problemStatement'),
    problemStatementSubtitle: marker('management.templatesDialog.Subtitle'),
    interim: marker('management.templatesDialog.interim'),
    interimSubtitle: marker('management.templatesDialog.interimSubtitle'),
    investigation: marker('management.templatesDialog.investigation'),
    investigationSubtitle: marker('management.templatesDialog.investigationSubtitle'),
    category: marker('management.templatesDialog.category'),
    closingDate: marker('management.templatesDialog.closingDate'),
    additionalInformation: marker('management.templatesDialog.additionalInformation'),
    title: marker('management.templatesDialog.title'),
    subtitle: marker('management.templatesDialog.subtitle'),
    select: marker('management.templatesDialog.select'),
    min: marker('management.templatesDialog.min'),
    max: marker('management.templatesDialog.max'),
    userCanAddAction: marker('management.templatesDialog.userCanAddAction'),
    editList: marker('management.templatesDialog.editList'),
    answerRequired: marker('management.templatesDialog.answerRequired'),
    notApplicable: marker('management.templatesDialog.notApplicable'),
    commentToAnswer: marker('management.templatesDialog.commentToAnswer'),
    emptyGroup: marker('management.templatesDialog.emptyGroup'),
    enterAnswer: marker('management.templatesDialog.enterAnswer'),
    optionalAction: marker('management.templatesDialog.optionalAction'),
    requiredAction: marker('management.templatesDialog.requiredAction'),
    editAction: marker('management.templatesDialog.editAction'),
    addComment: marker('management.templatesDialog.addComment'),
    optional: marker('global.optional'),
    describeIssueBtn: marker('management.templatesDialog.describeIssueBtn'),
    browseFiles: marker('global.browseFiles'),
    fileInfo: marker('management.templatesDialog.fileSub'),
    optionalWithBraces: marker('management.appDialog.optional'),
    file: marker('management.templatesDialog.file'),
    fileSub: marker('management.templatesDialog.fileSub'),
    bpstTool: marker('management.templatesDialog.bpstTool'),
    attachSignature: marker('management.appDialog.attachSignature'),
    signatureSubtitle: marker('management.templatesDialog.signatureSubtitle'),
    signatureTitle: marker('management.templatesDialog.signatureTitle')
  };

  public typesOfRootCausesIdentification: {
    label: string;
    value: string;
  }[] = [
    { label: this.labels.Batbone, value: 'batbone' },
    { label: this.labels.why, value: 'why' },
    { label: this.labels.causeEffect, value: 'cause' }
  ];
  public stringFormControl = new FormControl('');
  public answerTypeEnum = ANSWER_TYPE_ENUM;
  public actionTypeEnum = ACTION_TYPE_ENUM;
  public appTypeEnum = APP_TYPE_ENUM;
  public fileToSave: FileDTO[];

  public actionsList: ActionDTO[] = [];

  public currentActions: ActionDTO[] = [];
  public followUpActions: ActionDTO[] = [];
  public prioritiesEnum = ACTION_TRACKER_PRIORITIES_ENUM;
  //new types
  public equipmentList: EquipmentDTO[] = [];
  public workingAreaList: WorkingAreaDTO[] = [];
  public newTypes = false;
  public commentVisible = [] as boolean[];
  public attachmentVisible = [] as boolean[];
  public notApplicableOption: PossibleAnswerDTO = new PossibleAnswerDTO(-1, this.labels.notApplicable, 999);
  public files: FileDTO[] = [];
  public fileRefs: FileRefDTO[] = [];
  public usersSelected = [] as boolean[];
  public questionsActionsFiles: {
    questionId: number;
    actionFiles: FileDTO[][];
    fileRefs: FileRefDTO[][];
  }[] = [];
  public suggestions: UserADResponse;

  public Dates = [] as Date[];
  public spinner: string;
  public DatesAndTime = [] as Date[];
  public TimesAndDate = [] as string[];
  public notApplicableChecked: Map<string, boolean> = new Map();
  public chosenAnswer: Map<string, PossibleAnswerDTO> = new Map();
  public chosenAnswers: Map<string, PossibleAnswerDTO[]> = new Map();
  public chosenAnswerInvestigations: Map<string, PossibleAnswerDTO> = new Map();
  public chosenAnswersInvestigations: Map<string, PossibleAnswerDTO[]> = new Map();
  public chosenAnswerInformations: Map<string, InformationAnswerDTO> = new Map();
  public chosenAnswersInformations: Map<string, InformationAnswerDTO[]> = new Map();
  public chosenAnswerBatbone: Map<string, BatBoneAnswerDTO> = new Map();
  public chosenAnswersBatbone: Map<string, BatBoneAnswerDTO[]> = new Map();
  public chosenAnswerStage: Map<string, PossibleAnswerDTO> = new Map();
  public chosenAnswersStage: Map<string, PossibleAnswerDTO[]> = new Map();
  public comment: string[] = [];
  public actionAdded: Map<number, boolean> = new Map();
  public signature: FileDTO;

  public pdfView = false;
  public maxWidth = 0;
  public maxHeight = 0;
  public pdf: jsPDF;
  public signaturePadOptions: NgSignaturePadOptions = {
    // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 600,
    canvasHeight: 70,
    backgroundColor: 'white'
  };
  // public questionFiles: Map<string, FileDTO[]> = new Map();
  // public actionTypeId: number;
  private param: Map<string, FileDTO[]>;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      //model: TemplateDTO;
      editMode: boolean;
      copyMode: boolean;
      appType: AppTypeDTO;
      appResults: boolean;
      plantId: number;
      operationCentersId: number;
    },
    private dialog: MatDialog,
    private azure: AzureBlobStorageService,
    private translateService: TranslateService,
    private globalMessage: GlobalMessageService,
    private administrationService: AdministrationService,
    private spinnerService: ProgressSpinnerDialogService,
    private managementService: ManagementServiceService
  ) {}
  public setRootCausesIdentification(event: MatSelectionListChange) {
    // eslint-disable-next-line no-underscore-dangle
    if (event.source._value.indexOf('batbone') !== -1) {
      this.tabBatboneVisible = true;
    } else {
      this.tabBatboneVisible = false;
    }
    // eslint-disable-next-line no-underscore-dangle
    if (event.source._value.indexOf('why') !== -1) {
      this.tabWhyVisible = true;
    } else {
      this.tabWhyVisible = false;
    }
    // eslint-disable-next-line no-underscore-dangle
    if (event.source._value.indexOf('cause') !== -1) {
      this.tabCauseVisible = true;
    } else {
      this.tabCauseVisible = false;
    }
  }
  public getDate(date: string) {
    return new Date(date);
  }

  public drawBatbone() {
    this.viewBatbone = true;
    this.context = this.myCanvas.nativeElement.getContext('2d');

    const image = new Image(820, 500);
    image.onload = () => {
      this.context.drawImage(image, 0, 0, image.width, image.height);
      this.drawText();
    };
    image.src = '/assets/img/batbone.jpg';
  }

  public drawText() {
    this.myCanvas.nativeElement.getContext('2d').fillStyle = '#000';
    this.myCanvas.nativeElement.getContext('2d').font = ' 12px sans-serif';

    let x = 350;
    let y = 50;

    //method
    if (this.chosenAnswerBatbone.get('0') && this.chosenAnswerBatbone.get('0').answer !== undefined) {
      this.myCanvas.nativeElement.getContext('2d').fillText(this.chosenAnswerBatbone.get('0').answer, x, y);
    }

    //machine
    if (this.chosenAnswerBatbone.get('1') && this.chosenAnswerBatbone.get('1').answer !== undefined) {
      x = 380;
      y = 120;
      this.myCanvas.nativeElement.getContext('2d').fillText(this.chosenAnswerBatbone.get('1').answer, x, y);
    }

    //people
    if (this.chosenAnswerBatbone.get('2') && this.chosenAnswerBatbone.get('2').answer !== undefined) {
      x = 380;
      y = 390;
      this.myCanvas.nativeElement.getContext('2d').fillText(this.chosenAnswerBatbone.get('2').answer, x, y);
    }

    // materials
    if (this.chosenAnswerBatbone.get('3') && this.chosenAnswerBatbone.get('3').answer !== undefined) {
      x = 355;
      y = 505;
      this.myCanvas.nativeElement.getContext('2d').fillText(this.chosenAnswerBatbone.get('3').answer, x, y);
    }

    //measurement
    if (this.chosenAnswerBatbone.get('4') && this.chosenAnswerBatbone.get('4').answer !== undefined) {
      x = 40;
      y = 160;
      this.myCanvas.nativeElement.getContext('2d').fillText(this.chosenAnswerBatbone.get('4').answer, x, y);
    }

    //environment
    if (this.chosenAnswerBatbone.get('5') && this.chosenAnswerBatbone.get('5').answer !== undefined) {
      x = 50;
      y = 390;
      this.myCanvas.nativeElement.getContext('2d').fillText(this.chosenAnswerBatbone.get('5').answer, x, y);
    }

    //problem statement
    if (!!this.checklist.problemStatement) {
      x = 670;
      y = 250;
      this.myCanvas.nativeElement.getContext('2d').fillText(this.checklist.problemStatement, x, y);
    }
  }

  public drawStart(event: MouseEvent | Touch) {}

  public drawClear() {
    this.signaturePad.clear();
    this.signature = {
      data: this.dataURItoBlob(this.signaturePad.toDataURL().replace('data:image/png;base64,', '')),
      SavedOnAzure: false
    } as FileDTO;
  }

  public drawComplete(event: MouseEvent | Touch) {
    this.signature = {
      data: this.dataURItoBlob(this.signaturePad.toDataURL().replace('data:image/png;base64,', '')),
      SavedOnAzure: false
    } as FileDTO;
  }
  public addSignatureFiles(file: FileList, attachmentindex?: number): void {
    this.signature = {
      data: file[0],
      SavedOnAzure: false
    } as FileDTO;

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = () => {
      this.signaturePad.fromDataURL(reader.result.toString());
    };
  }

  ngOnInit() {
    let index = 0;

    const ckiList = [] as CheckListItemDTO[];

    //from pending to draft
    /*if (this.checklist.state === 0) {
      this.checklist.state = 1;
    }*/

    // this.checklist.category = new AppCategoryDTO();

    if (!this.checklistItems) {
      this.checklistItems = [];
    }
    for (const question of this.questions) {
      this.setChosenAnswer(question, {} as PossibleAnswerDTO);
      this.setChosenAnswers(question, []);
      this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
      this.comment.push(null);
      this.commentVisible.push(false);
      this.attachmentVisible.push(question?.allowAddFiles || false);
    }

    this.getQuestionFilesTemplateFromAzure(this.templateId);
    this.getQuestionFilesFromAzure(this.checklistId);

    if (this.checklistItems) {
      if (!this.isPreview && !this.isReadOnly) {
        // this.actionTrackerService.getActionTypes().subscribe((data) => {
        //   if (data) {
        //     this.actionTypeId = data[0].id; //non so se sia giusto
        //     // this.spinnerService.hide(actionTypeSpinner);
        //   }
        // });
      }

      // this.chosenAnswer[qgi] = [];
      // this.chosenAnswers[qgi] = [];
      // this.notApplicableChecked[qgi] = [];
      // this.comment[qgi] = [];
      for (const question of this.questions) {
        if (!this.checklistItems.length) {
          const cki = {} as CheckListItemDTO;
          cki.question = question;
          cki.answerValue = {} as AnswerValueDTO;
          cki.answerValue.workingAreasAnswer = [];
          cki.answerValue.equipmentsAnswer = [];
          cki.answerValue.usersAnswer = [];
          cki.answerValue.dateAnswer = '';
          cki.answerValue.datetimeAnswer = '';
          cki.answerValue.timeAnswer = null;
          cki.question = JSON.parse(JSON.stringify(question));
          ckiList.push(cki);
        }
        const answer = this.checklistItems.find((x) => x.question.id === question.id)?.answerValue;

        const na = this.checklistItems.find((x) => x.question.id === question.id)?.question.notApplicable;
        this.notApplicableChecked.set(this.getChosenAnswerKey(question), !!na);
        this.setChosenAnswer(question, {} as PossibleAnswerDTO);
        let chosenAnswer = {} as PossibleAnswerDTO; //question.answerType.possibleAnswers.find((x)=> x.id == answer.)
        this.commentVisible.push(false);
        this.attachmentVisible.push(question?.allowAddFiles || false);
        // if (answer?.values?.length > 0) {
        //   this.multipleSelectionControl.setValue(answer.values);
        // }
        if (this.checklistItems.find((x) => x.question.id === question.id)?.actions?.length > 0) {
          // question.answerType.actionAdded = true;
          this.actionAdded.set(question.id, true);
        }

        if (question.answerType.answerType === ANSWER_TYPE_ENUM.MULTIPLE_OPTIONS) {
          if (answer?.values?.length > 0) {
            this.setChosenAnswers(
              question,
              question.answerType.possibleAnswers.filter((x) => answer.values.findIndex((y) => y.value === x.order) !== -1)
            );

            this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
          } else {
            this.setChosenAnswers(question, [] as PossibleAnswerDTO[]);
          }
        } else {
          if (
            question.answerType.answerType === ANSWER_TYPE_ENUM.NUMBER ||
            question.answerType.answerType === ANSWER_TYPE_ENUM.RANGE ||
            question.answerType.answerType === ANSWER_TYPE_ENUM.PERCENTAGE
          ) {
            if (!!answer?.numberAnswer) {
              chosenAnswer.answerNumber = answer?.numberAnswer;
              this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
              this.setChosenAnswer(question, chosenAnswer);
            }
          } else if (question.answerType.answerType === ANSWER_TYPE_ENUM.LOW_MEDIUM_HIGH) {
            if (answer?.fixedAnswerValue != null) {
              chosenAnswer = POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH[answer?.fixedAnswerValue];
              chosenAnswer.expectedAnswer = question.answerType.possibleAnswers[answer?.fixedAnswerValue].expectedAnswer;
              this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
              this.setChosenAnswer(question, chosenAnswer);
            }
          } else if (question.answerType.answerType === ANSWER_TYPE_ENUM.ONE_OPTION) {
            if (answer?.fixedAnswerValue != null) {
              chosenAnswer = question.answerType.possibleAnswers.filter((x) => x.order === answer?.fixedAnswerValue)[0];
              const value = JSON.parse(JSON.stringify(chosenAnswer.order));
              chosenAnswer.possibleAnswerType = value;

              this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
              this.setChosenAnswer(question, chosenAnswer);
            }
          } else if (question.answerType.answerType === ANSWER_TYPE_ENUM.YES_NO) {
            if (answer?.fixedAnswerValue != null) {
              chosenAnswer = POSSIBLE_ANSWERS_YES_NO[answer?.fixedAnswerValue];
              chosenAnswer.expectedAnswer = question.answerType.possibleAnswers[answer?.fixedAnswerValue].expectedAnswer;
              this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
              this.setChosenAnswer(question, chosenAnswer);
            }
          } else if (question.answerType.answerType === ANSWER_TYPE_ENUM.ZERO_TO_THREE) {
            if (answer?.fixedAnswerValue != null) {
              chosenAnswer = POSSIBLE_ANSWERS_ZERO_TO_THREE[answer?.fixedAnswerValue];
              this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
              this.setChosenAnswer(question, chosenAnswer);
            }
          } else if (question.answerType.answerType === ANSWER_TYPE_ENUM.FREE_TEXT) {
            if (!!answer?.stringAnswer) {
              chosenAnswer.answerString = answer?.stringAnswer;
              this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
              this.setChosenAnswer(question, chosenAnswer);
            }
          } else if (
            question.answerType.answerType === ANSWER_TYPE_ENUM.USER ||
            question.answerType.answerType === ANSWER_TYPE_ENUM.USER_MULTIPLE
          ) {
            if (!!answer?.usersAnswer) {
              chosenAnswer.usersAnswer = [];
              answer?.usersAnswer.forEach((user) => {
                chosenAnswer.usersAnswer.push(user);
              });
              this.setChosenAnswer(question, chosenAnswer);
            } else {
              chosenAnswer.usersAnswer = [];
              this.setChosenAnswer(question, chosenAnswer);
            }
          }
        }

        this.comment[this.getArrayIndexBase(question.order)] = answer?.comment;
      }
    }
    console.log('this.chosenAnswer', this.chosenAnswer);

    this.newTypes = true;
    if (!this.checklistItems.length) {
      this.checklistItems = JSON.parse(JSON.stringify(ckiList));
    }
    for (const cki of this.checklistItems) {
      if (cki?.answerValue?.dateAnswer) {
        this.Dates[cki.question.id] = new Date(cki?.answerValue?.dateAnswer);
      } else {
        // this.Dates.push(null);
      }
      /*
        if (cki?.answerValue?.datetimeAnswer) {
          const date = new Date(cki?.answerValue?.datetimeAnswer.split('T')[0]);
          const time =
            '' +
            (new Date(cki?.answerValue?.datetimeAnswer).getHours() - 1) +
            ':' +
            new Date(cki?.answerValue?.datetimeAnswer).getMinutes();

          this.TimesAndDate.push(time);
          this.DatesAndTime.push(date);
        } else {
          this.TimesAndDate.push('');
          this.DatesAndTime.push(null);
        }*/
    }
    this.isChecked();
    this.getPlantDetails();

    // INIT ANSWERS

    index = 0;
    this.tabWhyVisible = false;
    while (index < 5) {
      const whyAnswer = {} as WhyAnswersDTO;
      if (!this.checklist.whyAnswers[index]) {
        whyAnswer.answer = '';
        this.checklist.whyAnswers.push(whyAnswer);
      } else if (this.checklist.whyAnswers[index].answer) {
        this.tabWhyVisible = true;
        whyAnswer.answer = this.whyAnswers[index].answer;
      }
      index++;
    }

    if (!this.checklist.rootCause) {
    } else {
    }

    if (!this.checklist.stageAnswers[0]) {
      this.checklist.stageAnswers[0] = {} as StageAnswerDTO;
      this.checklist.stageAnswers[0].summary = '';
      this.checklist.stageAnswers[0].actions = [];
      this.checklist.stageAnswers[0].problemStatement = '';
    }
    if (!this.checklist.stageAnswers[1]) {
      this.checklist.stageAnswers[1] = {} as StageAnswerDTO;
      this.checklist.stageAnswers[1].summary = '';
      this.checklist.stageAnswers[1].actions = [];
      this.checklist.stageAnswers[1].problemStatement = '';
    }

    let i = 0;
    for (const stage of this.template.stages) {
      for (const question of stage.questions) {
        // this.setChosenAnswersStage(i, question, {} as PossibleAnswerDTO[]);
        let chosenAnswer = {} as PossibleAnswerDTO; //question.answerType.possibleAnswers.find((x)=> x.id == answer.)
        //check se c'e' risposta  da DB
        const answer =
          this.stageAnswers[i] && this.stageAnswers[i].checklistItems
            ? this.stageAnswers[i].checklistItems.find((x) => x.question.id === question.id)?.answerValue
            : null;

        this.setChosenAnswerStage(i, question, {} as PossibleAnswerDTO);

        if (
          question.answerType.answerType === ANSWER_TYPE_ENUM.NUMBER ||
          question.answerType.answerType === ANSWER_TYPE_ENUM.RANGE ||
          question.answerType.answerType === ANSWER_TYPE_ENUM.PERCENTAGE
        ) {
          if (!!answer?.numberAnswer) {
            chosenAnswer.answerNumber = answer?.numberAnswer;
            this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
            this.setChosenAnswerStage(i, question, chosenAnswer);
          }
        } else if (question.answerType.answerType === ANSWER_TYPE_ENUM.LOW_MEDIUM_HIGH) {
          if (answer?.fixedAnswerValue != null) {
            chosenAnswer = POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH[answer?.fixedAnswerValue];
            chosenAnswer.expectedAnswer = question.answerType.possibleAnswers[answer?.fixedAnswerValue].expectedAnswer;
            this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
            this.setChosenAnswerStage(i, question, chosenAnswer);
          }
        } else if (question.answerType.answerType === ANSWER_TYPE_ENUM.ONE_OPTION) {
          if (answer?.fixedAnswerValue != null) {
            chosenAnswer = question.answerType.possibleAnswers.filter((x) => x.order === answer?.fixedAnswerValue)[0];
            const value = JSON.parse(JSON.stringify(chosenAnswer.order));
            chosenAnswer.possibleAnswerType = value;

            this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
            this.setChosenAnswerStage(i, question, chosenAnswer);
          }
        } else if (question.answerType.answerType === ANSWER_TYPE_ENUM.YES_NO) {
          if (answer?.fixedAnswerValue != null) {
            chosenAnswer = POSSIBLE_ANSWERS_YES_NO[answer?.fixedAnswerValue];
            chosenAnswer.expectedAnswer = question.answerType.possibleAnswers[answer?.fixedAnswerValue].expectedAnswer;
            this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
            this.setChosenAnswerStage(i, question, chosenAnswer);
          }
        } else if (question.answerType.answerType === ANSWER_TYPE_ENUM.ZERO_TO_THREE) {
          if (answer?.fixedAnswerValue != null) {
            chosenAnswer = POSSIBLE_ANSWERS_ZERO_TO_THREE[answer?.fixedAnswerValue];
            this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
            this.setChosenAnswerStage(i, question, chosenAnswer);
          }
        } else if (question.answerType.answerType === ANSWER_TYPE_ENUM.FREE_TEXT) {
          if (!!answer?.stringAnswer) {
            chosenAnswer.answerString = answer?.stringAnswer;
            this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);

            this.setChosenAnswerStage(i, question, chosenAnswer);
          }
        }
      }
      i++;
    }

    for (const question of this.template.investigationQuestions) {
      this.setChosenAnswerInvestigations(question, {} as PossibleAnswerDTO);
      let chosenAnswer = {} as PossibleAnswerDTO; //question.answerType.possibleAnswers.find((x)=> x.id == answer.)

      //check se c'e' risposta data
      const answer = this.investigationAnswers
        ? this.investigationAnswers.find((x) => x.question.id === question.id)?.answerValue
        : null;

      if (
        question.answerType.answerType === ANSWER_TYPE_ENUM.NUMBER ||
        question.answerType.answerType === ANSWER_TYPE_ENUM.RANGE ||
        question.answerType.answerType === ANSWER_TYPE_ENUM.PERCENTAGE
      ) {
        if (!!answer?.numberAnswer) {
          chosenAnswer.answerNumber = answer?.numberAnswer;
          this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
          this.setChosenAnswerInvestigations(question, chosenAnswer);
        }
      } else if (question.answerType.answerType === ANSWER_TYPE_ENUM.LOW_MEDIUM_HIGH) {
        if (answer?.fixedAnswerValue != null) {
          chosenAnswer = POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH[answer?.fixedAnswerValue];
          chosenAnswer.expectedAnswer = question.answerType.possibleAnswers[answer?.fixedAnswerValue].expectedAnswer;
          this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
          this.setChosenAnswerInvestigations(question, chosenAnswer);
        }
      } else if (question.answerType.answerType === ANSWER_TYPE_ENUM.ONE_OPTION) {
        if (answer?.fixedAnswerValue != null) {
          chosenAnswer = question.answerType.possibleAnswers.filter((x) => x.order === answer?.fixedAnswerValue)[0];
          const value = JSON.parse(JSON.stringify(chosenAnswer.order));
          chosenAnswer.possibleAnswerType = value;

          this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
          this.setChosenAnswerInvestigations(question, chosenAnswer);
        }
      } else if (question.answerType.answerType === ANSWER_TYPE_ENUM.YES_NO) {
        if (answer?.fixedAnswerValue != null) {
          chosenAnswer = POSSIBLE_ANSWERS_YES_NO[answer?.fixedAnswerValue];
          chosenAnswer.expectedAnswer = question.answerType.possibleAnswers[answer?.fixedAnswerValue].expectedAnswer;
          this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
          this.setChosenAnswerInvestigations(question, chosenAnswer);
        }
      } else if (question.answerType.answerType === ANSWER_TYPE_ENUM.ZERO_TO_THREE) {
        if (answer?.fixedAnswerValue != null) {
          chosenAnswer = POSSIBLE_ANSWERS_ZERO_TO_THREE[answer?.fixedAnswerValue];
          this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
          this.setChosenAnswerInvestigations(question, chosenAnswer);
        }
      } else if (question.answerType.answerType === ANSWER_TYPE_ENUM.FREE_TEXT) {
        if (!!answer?.stringAnswer) {
          chosenAnswer.answerString = answer?.stringAnswer;
          this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);

          this.setChosenAnswerInvestigations(question, chosenAnswer);
        }
      } else if (
        question.answerType.answerType === ANSWER_TYPE_ENUM.USER ||
        question.answerType.answerType === ANSWER_TYPE_ENUM.USER_MULTIPLE
      ) {
        if (!!answer?.usersAnswer) {
          chosenAnswer.usersAnswer = [];
          answer?.usersAnswer.forEach((user) => {
            chosenAnswer.usersAnswer.push(user);
          });
          this.setChosenAnswer(question, chosenAnswer);
        } else {
          chosenAnswer.usersAnswer = [];
          this.setChosenAnswer(question, chosenAnswer);
        }
      }
    }
    for (const question of this.template.informationQuestions) {
      const chosenAnswer = {} as InformationAnswerDTO;
      const answer = this.informationAnswers ? this.informationAnswers.find((x) => x.question.id === question.id)?.answer : null;
      chosenAnswer.question = question;
      chosenAnswer.answer = answer;
      if (!!answer) {
        this.setChosenAnswerInformations(question, chosenAnswer);
      } else {
        this.setChosenAnswerInformations(question, {} as InformationAnswerDTO);
      }
      this.setChosenAnswersInformations(question, []);
    }

    this.tabBatboneVisible = false;
    this.tabCauseVisible = false;

    for (const question of this.template.batboneQuestions) {
      const chosenAnswer = {} as BatBoneAnswerDTO;
      const answer = this.checklist.batboneAnswers
        ? this.checklist.batboneAnswers.find((x) => x.question.id === question.id)?.answer
        : null;
      chosenAnswer.question = question;
      chosenAnswer.answer = answer;
      if (!!answer) {
        this.setChosenAnswerBatbone(question, chosenAnswer);
        this.tabBatboneVisible = true;
        // this.drawBatbone();
      } else {
        this.setChosenAnswerBatbone(question, {} as BatBoneAnswerDTO);
      }
    }

    console.log('this.chosenAnswer', this.chosenAnswer);
    console.log('this.template', this.template);
  }

  public input(index: number, option: EquipmentDTO) {
    //DEBUG
    if (this.checklistItems?.length) {
      if (this.checklistItems[index]?.answerValue?.equipmentsAnswer) {
        this.checklistItems[index].answerValue.equipmentsAnswer.push(option);
      } else {
        this.checklistItems[index].answerValue.equipmentsAnswer = [option];
      }
    }
  }

  public getQuestionFromKeyQ(guid: string, questions: QuestionDTO[]): QuestionDTO[] {
    return questions.filter((x) => x.guid === guid);
  }

  public checkConditionsStage(stage: number, question: QuestionDTO, checkQuestions: QuestionDTO[]) {
    // no conditions === visible
    if (
      !question ||
      !question.conditions ||
      (question && question.conditions && question.conditions.length === 0) ||
      question.conditions === null ||
      checkQuestions.length === 0
    ) {
      return true;
    } else {
      // if (debug) console.log('question to show title, question : ', question.title, question.guid);
      let hidden = false;
      for (const condition of question.conditions) {
        if (condition.deleted === false) {
          let testQuest = [];
          testQuest = this.getQuestionFromKeyQ(condition.questionGuid, checkQuestions);

          if (testQuest.length === 0) {
            hidden = false;
          } else {
            // eslint-disable-next-line max-len
            // if (debug) console.log('condition checking guid - option : ',
            // condition.questionGuid, condition.questionAnswerOption);

            // eslint-disable-next-line max-len

            // console.log(testQuest[0].title, stage, testQuest[0].order);

            const answer = this.getChosenAnswerStage(stage, testQuest[0]);
            //  if (debug) console.log('testQuest title guid :', testQuest[0].title, testQuest[0].guid);
            //    if (debug) console.log('answer testQuest : ', answer, answer.order);

            if (answer && answer.order !== undefined) {
              // must be shown
              // console.log('questionAnswerOption', condition);
              // console.log('answer', answer);
              if (condition.questionAnswerOption === answer.order) {
                hidden = true;
              } else {
                hidden = false;
              }
            }
          }
        }
      }
      return hidden;
    }
  }

  public getPlantDetails(): void {
    const spinner = this.spinnerService.show();

    this.managementService
      .getFillByEntity(this.appId, this.template.filledBy.entityType, this.templateId)
      .pipe(
        finalize(() => {
          this.spinnerService.hide(spinner);
        })
      )
      .subscribe({
        next: (result) => {
          if (this.template.filledBy.entityType < 3) {
            if (this.template.filledBy.entityType === ENTITY_TYPES_ENUM.DEPARTMENT) {
              if (this.checklist) {
                for (const dep of this.template.filledBy.filledByItems.filter(
                  (x) => x.entityId === this.checklist.filledByItem.entityId
                )) {
                  this.workingAreaList.push(...result[0].departments.find((x) => x.id === dep.entityId).workingAreas);
                }
              } else {
                for (const dep of this.template.filledBy.filledByItems) {
                  this.workingAreaList.push(...result[0].departments.find((x) => x.id === dep.entityId).workingAreas);
                }
              }

              for (const wa of this.workingAreaList) {
                this.equipmentList.push(...wa.equipments);
              }
            } else if (this.template.filledBy.entityType === ENTITY_TYPES_ENUM.PLANT) {
              for (const dep of result[0].departments) {
                this.workingAreaList.push(...dep.workingAreas);
                for (const wa of dep.workingAreas) {
                  this.equipmentList.push(...wa.equipments);
                }
              }
            } else {
              //solo eq per specifica wa
              if (this.checklist) {
                for (const wa of this.template.filledBy.filledByItems.filter(
                  (x) => x.entityId === this.checklist.filledByItem.entityId
                )) {
                  for (const dep of result[0].departments) {
                    for (const was of dep.workingAreas) {
                      if (wa.entityId === was.id) {
                        this.equipmentList.push(...was.equipments);
                      }
                    }
                  }
                }
              } else {
                for (const wa of this.template.filledBy.filledByItems) {
                  for (const dep of result[0].departments) {
                    for (const was of dep.workingAreas) {
                      if (wa.entityId === was.id) {
                        this.equipmentList.push(...was.equipments);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
  }

  public searchUsers(userName: string): void {
    const fullName = userName;

    if (fullName && fullName.length >= 2) {
      const spinner = this.spinnerService.show();
      this.administrationService
        .getUser(fullName)
        .pipe(
          finalize(() => {
            this.spinnerService.hide(spinner);
          })
        )
        .subscribe((data: UserADResponse) => {
          this.suggestions = data;
          for (const s of this.suggestions.value) {
            this.usersSelected.push(false);
            this.isChecked();
          }
        });
    }
  }

  public displayUserAD(user: UserAD): string {
    return user ? `${user?.displayName} ${user?.surname}` : '';
  }

  public show(i: number) {
    this.setAnswersAndAnctions();

    this.commentVisible[i] = !this.commentVisible[i];
  }

  public showAttachment(i: number) {
    this.attachmentVisible[i] = !this.attachmentVisible[i];
  }
  public getArrayIndexBase(indexBase: number): number {
    // indexBase += this.questions.length;
    // return this.getArrayIndexBase(indexBase);
    return indexBase;
  }

  public onNotApplicablePressed(question: QuestionDTO) {
    this.notApplicableChecked.set(
      this.getChosenAnswerKey(question),
      !this.notApplicableChecked.get(this.getChosenAnswerKey(question))
    );
    this.setChosenAnswer(question, {} as PossibleAnswerDTO);
  }

  public onPossibleAnswerPressed(question: QuestionDTO, option: PossibleAnswerDTO) {
    if (question.answerType.answerType === ANSWER_TYPE_ENUM.MULTIPLE_OPTIONS) {
      const index = this.getChosenAnswers(question).findIndex((x) => x.id === option.id);
      if (index >= 0) {
        this.getChosenAnswers(question).splice(index, 1);
      } else {
        this.getChosenAnswers(question).push(option);
      }
      this.notApplicableChecked.set(this.getChosenAnswerKey(question), this.getChosenAnswers(question).length === 0);
    } /*if (question.answerType.answerType !== ANSWER_TYPE_ENUM.FREE_TEXT)*/ else {
      const newOpt = JSON.parse(JSON.stringify(option));
      if (question.answerType.answerType === ANSWER_TYPE_ENUM.ONE_OPTION) {
        const value = JSON.parse(JSON.stringify(option.order));
        newOpt.possibleAnswerType = value;
      }
      // this.questionGroups[questionGroupIndex].questions[questionIndex].chosenAnswer = option;
      // question.chosenAnswer = option;
      this.setChosenAnswer(question, newOpt);
      this.notApplicableChecked.set(this.getChosenAnswerKey(question), false);
    }
  }

  public compareONE_OPTION(a: PossibleAnswerDTO, b: PossibleAnswerDTO): boolean {
    return a.order === b.order;
  }
  public compareEQUIPMENT(a: EquipmentDTO, b: EquipmentDTO): boolean {
    if (Array.isArray(a) && Array.isArray(b)) {
      return a[0].id === b[0].id;
    } else {
      return a.id === b.id;
    }
  }

  public compareWORKINGAREA(a: WorkingAreaDTO[], b: WorkingAreaDTO[]): boolean {
    return a[0].id === b[0].id;
  }
  public compareUSER(a: UserAD, b: UserAD): boolean {
    return a.mail === b.mail;
  }

  public splitActionsFromDate() {
    this.currentActions = [];
    this.followUpActions = [];
    if (this.actions?.length > 0) {
      for (const action of this.actions) {
        if (typeof action.trackerAction.dueDate === 'string') {
          if (new Date(action.trackerAction.dueDate).getDate() === this.myDate.getDate()) {
            this.currentActions.push(action);
          } else {
            this.followUpActions.push(action);
          }
        } else {
          if (action.trackerAction.dueDate.getDate() === this.myDate.getDate()) {
            this.currentActions.push(action);
          } else {
            this.followUpActions.push(action);
          }
        }
      }
    }
  }
  public isOptionalAction(question: QuestionDTO): boolean {
    return (
      // (
      //   (
      //     question.answerType.answerType === this.answerTypeEnum.FREE_TEXT ||
      //     question.answerType.answerType === this.answerTypeEnum.NUMBER ||
      //     question.answerType.answerType === this.answerTypeEnum.RANGE
      //   ) &&
      //   question.answerType?.actionRequired === this.actionTypeEnum.OPTIONAL
      // ) ||
      ((question.answerType.answerType === this.answerTypeEnum.FREE_TEXT ||
        question.answerType.answerType === this.answerTypeEnum.ZERO_TO_THREE ||
        question.answerType.answerType === this.answerTypeEnum.YES_NO ||
        question.answerType.answerType === this.answerTypeEnum.LOW_MEDIUM_HIGH ||
        question.answerType.answerType === this.answerTypeEnum.ONE_OPTION ||
        question.answerType.answerType === this.answerTypeEnum.MULTIPLE_OPTIONS) &&
        ((question.answerType.answerType === this.answerTypeEnum.FREE_TEXT &&
          question?.answerType?.actionRequired === this.actionTypeEnum.OPTIONAL) ||
          this.getChosenAnswer(question)?.actionRequired === this.actionTypeEnum.OPTIONAL)) ||
      (question.answerType.answerType === this.answerTypeEnum.RANGE &&
        question.answerType?.actionRequired === this.actionTypeEnum.OPTIONAL &&
        // question?.chosenAnswer?.answerNumber &&
        // (question?.chosenAnswer?.answerNumber > question.answerType.rangeMax ||
        //   question?.chosenAnswer?.answerNumber < question.answerType.rangeMin))
        this.getChosenAnswer(question)?.answerNumber &&
        (this.getChosenAnswer(question)?.answerNumber > question.answerType.rangeMax ||
          this.getChosenAnswer(question)?.answerNumber < question.answerType.rangeMin))
    );
  }

  public isMandatoryAction(question: QuestionDTO): boolean {
    return (
      // (
      //   (
      //     question.answerType.answerType === this.answerTypeEnum.FREE_TEXT ||
      //     question.answerType.answerType === this.answerTypeEnum.NUMBER ||
      //     question.answerType.answerType === this.answerTypeEnum.RANGE
      //   ) &&
      //   question.answerType?.actionRequired === this.actionTypeEnum.REQUIRED
      // ) ||
      ((question.answerType.answerType === this.answerTypeEnum.FREE_TEXT ||
        question.answerType.answerType === this.answerTypeEnum.ZERO_TO_THREE ||
        question.answerType.answerType === this.answerTypeEnum.YES_NO ||
        question.answerType.answerType === this.answerTypeEnum.LOW_MEDIUM_HIGH ||
        question.answerType.answerType === this.answerTypeEnum.ONE_OPTION ||
        question.answerType.answerType === this.answerTypeEnum.MULTIPLE_OPTIONS) &&
        // question.chosenAnswer?.actionRequired === this.actionTypeEnum.REQUIRED) ||
        this.getChosenAnswer(question)?.actionRequired === this.actionTypeEnum.REQUIRED) ||
      (question.answerType.answerType === this.answerTypeEnum.RANGE &&
        question.answerType?.actionRequired === this.actionTypeEnum.REQUIRED &&
        // question?.chosenAnswer?.answerNumber &&
        // (question?.chosenAnswer?.answerNumber > question.answerType.rangeMax ||
        //   question?.chosenAnswer?.answerNumber < question.answerType.rangeMin))
        this.getChosenAnswer(question)?.answerNumber &&
        (this.getChosenAnswer(question)?.answerNumber > question.answerType.rangeMax ||
          this.getChosenAnswer(question)?.answerNumber < question.answerType.rangeMin))
    );
  }

  public isMandatoryActionButtonVisible(question: QuestionDTO): boolean {
    return this.isMandatoryAction(question) && !this.actionAdded.get(question.id);
  }

  public isOptionalActionButtonVisible(question: QuestionDTO): boolean {
    return this.isOptionalAction(question) && !this.actionAdded.get(question.id);
  }

  public isEditActionButtonVisible(question: QuestionDTO): boolean {
    return (this.isMandatoryAction(question) || this.isOptionalAction(question)) && this.actionAdded.get(question.id);

    // return (
    //   (
    //     (
    //       (
    //         question.answerType.answerType === this.answerTypeEnum.FREE_TEXT ||
    //         question.answerType.answerType === this.answerTypeEnum.NUMBER ||
    //         question.answerType.answerType === this.answerTypeEnum.RANGE
    //       ) &&
    //       (
    //         question.answerType?.actionRequired === this.actionTypeEnum.OPTIONAL ||
    //         question.answerType?.actionRequired === this.actionTypeEnum.REQUIRED
    //       )
    //     ) ||
    //     (
    //       (
    //         question.answerType.answerType === this.answerTypeEnum.ZERO_TO_THREE ||
    //         question.answerType.answerType === this.answerTypeEnum.YES_NO ||
    //         question.answerType.answerType === this.answerTypeEnum.LOW_MEDIUM_HIGH ||
    //         question.answerType.answerType === this.answerTypeEnum.ONE_OPTION ||
    //         question.answerType.answerType === this.answerTypeEnum.MULTIPLE_OPTIONS
    //       ) &&
    //       (
    //         question.chosenAnswer?.actionRequired === this.actionTypeEnum.OPTIONAL ||
    //         question.chosenAnswer?.actionRequired === this.actionTypeEnum.REQUIRED
    //       )
    //     )
    //   ) &&
    //   question.answerType.actionAdded === false
    // );
  }

  public get isFormValid(): boolean {
    for (const question of this.questions) {
      for (const ck of this.checklistItems) {
        if (ck.question?.answerType?.answerType === ANSWER_TYPE_ENUM.DATE && this.Dates.findIndex((x) => x !== null) === -1) {
          if (!ck.answerValue.dateAnswer && !question.notApplicable) {
            return false;
          }
        }
        if (ck.question?.answerType?.answerType === ANSWER_TYPE_ENUM.DATE_AND_TIME) {
          if (
            !ck.answerValue.datetimeAnswer &&
            !question.notApplicable &&
            this.DatesAndTime.findIndex((x) => x !== null) === -1
          ) {
            return false;
          }
        }
        if (ck.question?.answerType?.answerType === ANSWER_TYPE_ENUM.TIME) {
          if (!ck.answerValue.timeAnswer && !question.notApplicable) {
            return false;
          }
        }
        if (
          ck.question.answerType?.answerType === ANSWER_TYPE_ENUM.EQUIPMENT ||
          ck.question.answerType?.answerType === ANSWER_TYPE_ENUM.EQUIPMENT_MULTIPLE
        ) {
          if (!ck.answerValue?.equipmentsAnswer?.length && !question.notApplicable) {
            return false;
          }
        }
        if (
          ck.question.answerType?.answerType === ANSWER_TYPE_ENUM.WORKINGAREA ||
          ck.question.answerType?.answerType === ANSWER_TYPE_ENUM.WORKINGAREA_MULTIPLE
        ) {
          if (!ck.answerValue?.equipmentsAnswer?.length && !question.notApplicable) {
            return false;
          }
        }
        if (
          ck.question?.answerType?.answerType === ANSWER_TYPE_ENUM.USER ||
          ck.question?.answerType?.answerType === ANSWER_TYPE_ENUM.USER_MULTIPLE
        ) {
          if (!ck.answerValue?.usersAnswer?.length && !question.notApplicable) {
            return false;
          }
        }
      }

      if (
        (question.answerRequired &&
          !this.getChosenAnswer(question)?.possibleAnswerType &&
          this.getChosenAnswer(question)?.possibleAnswerType !== 0 &&
          !this.getChosenAnswer(question)?.answerString &&
          !this.getChosenAnswer(question)?.answerNumber &&
          this.getChosenAnswer(question)?.answerNumber !== 0 &&
          (!this.getChosenAnswers(question) || this.getChosenAnswers(question).length === 0) &&
          // !question.notApplicableChecked) ||
          !this.notApplicableChecked.get(this.getChosenAnswerKey(question))) ||
        //action obbligatoria
        (this.isMandatoryAction(question) && !this.actionAdded.get(question.id))
      ) {
        return false;
      }
    }
    return true;
  }

  public deleteUser(users: userAnswerValueDTO[], index: number) {
    users.splice(index, 1);
    this.isChecked();
  }

  public isChecked() {
    for (const ck of this.checklistItems) {
      for (let i = 0; i < this.suggestions?.value?.length; i++) {
        if (ck?.answerValue?.usersAnswer) {
          if (ck?.answerValue?.usersAnswer?.findIndex((x) => x.email === this.suggestions.value[i].mail) > -1) {
            this.usersSelected[i] = true;
          } else {
            this.usersSelected[i] = false;
          }
        }
      }
    }
  }

  public getUserValue(users: userAnswerValueDTO[]) {
    let value = '';
    if (users) {
      for (const user of users) {
        value += user.fullName + ' ';
      }
    }
    return value;
  }

  public setUser(opt: UserAD, users: userAnswerValueDTO[], questionType: number) {
    if (users.findIndex((x) => x.email === opt.mail) > -1) {
      this.deleteUser(
        users,
        users.findIndex((x) => x.email === opt.mail)
      );
    } else {
      users.push({ fullName: opt?.displayName, email: opt?.mail });
    }
    this.isChecked();
  }

  public selectedTabChange(event: StepperSelectionEvent) {
    if (event) {
      this.tabActive = event.selectedIndex;
    } else {
      this.tabActive = 0;
    }
    if (this.tabActive === 3) {
      // this.drawBatbone();
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    //IF I HAVE TO PRINT A PDF
    if (me.pdfView) {
      setTimeout(() => {
        me.createPagePdf(me.tabActive);
      }, 500);
    }
  }

  public createPagePdf(i: number) {
    if (!!!i || i === 0) {
      if (!!!this.pdf) {
        this.pdf = new jsPDF('p', 'px', 'a4');
      }

      // this.pdf.setFillColor(245, 245, 245, 0);
      //  this.pdf.rect(0, 0, this.pdf.internal.pageSize.getWidth(), this.pdf.internal.pageSize.getHeight(), 'F');
    }

    const node = this.dataToExport.nativeElement;

    const width = this.pdf.internal.pageSize.getWidth();
    const height = this.pdf.internal.pageSize.getHeight();

    const widthRatio = width / node.clientWidth;
    const heightRatio = height / node.clientHeight;

    const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

    this.pdfImages.push({
      width: node.clientWidth * ratio,
      height: node.clientHeight * ratio,
      data: null
    });

    /*
    if (width > this.maxWidth) {
      this.maxWidth = width;
      console.log('this.maxWidth', this.maxWidth);
    }
    if (height > this.maxHeight) {
      this.maxHeight = height;
      console.log('this.maxWidth', this.maxHeight);
    }*/

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;

    domtoimage.toPng(node).then((data) => {
      if (data) {
        me.pdfImages[me.tabActive].data = data;
      }

      const image = me.pdfImages[me.tabActive];
      me.pdf.addImage(image.data, 'JPG', (me.pdf.internal.pageSize.getWidth() - image.width) / 2, 0, image.width, image.height);
      /* var link = document.createElement('a');
      link.download = me.tabActive + '.jpg';
      link.href = data;
      link.click(); */

      if (me.tabGroup.steps.length - 1 === i) {
        me.pdf.save('BPST' + '.pdf');
        me.pdfView = false;
        me.spinnerService.hide(me.spinner);
      } else {
        me.pdf.addPage();
        setTimeout(() => {
          me.tabActive = me.tabActive + 1;
        }, 250);
      }
    });
  }

  public createPdf() {
    this.pdfView = true;
    this.spinner = this.spinnerService.show();
    this.pdfImages = [];
    this.tabActive = 0;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;

    setTimeout(() => {
      const step0 = new StepperSelectionEvent();
      me.selectedTabChange(step0);
    }, 250);
  }

  public setAnswersAndAnctions() {
    for (const question of this.questions) {
      //check visibility
      //if not visible skip
      //  if (this.questionsTemplateComponent.checkConditions(question)) {
      // skip
      //  } else {
      if (question.answerType.answerType !== ANSWER_TYPE_ENUM.SIGNATURE) {
        if (
          (!!this.getChosenAnswer(question) ||
            !!this.getChosenAnswers(question) ||
            this.notApplicableChecked.get(this.getChosenAnswerKey(question))) &&
          !!!this.checklistItems.find((c) => c.question.id === question.id)
        ) {
          const checklist = {} as CheckListItemDTO;
          checklist.question = {} as QuestionDTO;
          checklist.question.id = question.id;
          checklist.answerValue = {} as AnswerValueDTO;

          checklist.actions = [];
          this.checklistItems.push(checklist);
        }

        if (
          question.answerType.answerType === ANSWER_TYPE_ENUM.DATE ||
          question.answerType.answerType === ANSWER_TYPE_ENUM.TIME ||
          question.answerType.answerType === ANSWER_TYPE_ENUM.DATE_AND_TIME
        ) {
          if (this.Dates[question.id]) {
            const i = this.checklistItems.find((x) => x.question.id === question.id);
            i.answerValue.dateAnswer = JSON.parse(JSON.stringify(this.Dates[question.id].toISOString().split('T')[0]));
          }
        }
        if (
          !!this.checklistItems.find((c) => c.question.id === question.id) &&
          this.notApplicableChecked.get(this.getChosenAnswerKey(question))
        ) {
          const ck = this.checklistItems.find((c) => c.question.id === question.id);
          if (
            !ck.answerValue?.dateAnswer &&
            !ck.answerValue?.datetimeAnswer &&
            !ck.answerValue?.equipmentsAnswer?.length &&
            !ck.answerValue?.workingAreasAnswer?.length &&
            !ck.answerValue?.usersAnswer?.length &&
            !ck.answerValue?.timeAnswer
          ) {
            this.checklistItems.find((c) => c.question.id === question.id).answerValue = null;
          }
        }
      }
      //  }
    }

    const removedQuestions = this.questions.filter((x) => x.answerType.answerType === ANSWER_TYPE_ENUM.NA);
    if (removedQuestions?.length > 0) {
      for (const rq of removedQuestions) {
        this.checklistItems = this.checklistItems.filter((c) => c.question.id !== rq.id);
      }
    }

    // questionGroups.questions = questionGroups.questions.filter((x) => x.answerType.answerType !== ANSWER_TYPE_ENUM.NA);
    for (const question of this.questions) {
      //check visibility
      //if not visible skip
      // if (this.questionsTemplateComponent.checkConditions(question)) {
      // skip
      // } else {
      if (
        question.answerType.answerType !== ANSWER_TYPE_ENUM.NA &&
        question.answerType.answerType !== ANSWER_TYPE_ENUM.SIGNATURE
      ) {
        const i = this.checklistItems.findIndex((c) => c.question.id === question.id);
        if (i > -1) {
          if (!!!this.checklistItems[i].answerValue) {
            this.checklistItems[i].answerValue = {} as AnswerValueDTO;
          }
          if (question.answerType.answerType === ANSWER_TYPE_ENUM.MULTIPLE_OPTIONS) {
            if (this.getChosenAnswers(question)?.length > 0) {
              this.checklistItems[i].answerValue.values = [];
              for (const answer of this.getChosenAnswers(question)) {
                this.checklistItems[i].answerValue.values.push({ value: answer.order });
              }
            }
          } else {
            if (
              (question.answerType.answerType === ANSWER_TYPE_ENUM.NUMBER ||
                question.answerType.answerType === ANSWER_TYPE_ENUM.RANGE ||
                question.answerType.answerType === ANSWER_TYPE_ENUM.PERCENTAGE) &&
              !this.notApplicableChecked.get(this.getChosenAnswerKey(question))
            ) {
              this.checklistItems[i].answerValue.numberAnswer = this.chosenAnswer.get('' + question.order).answerNumber;
            } else if (
              (question.answerType.answerType === ANSWER_TYPE_ENUM.LOW_MEDIUM_HIGH ||
                question.answerType.answerType === ANSWER_TYPE_ENUM.ONE_OPTION ||
                question.answerType.answerType === ANSWER_TYPE_ENUM.YES_NO ||
                question.answerType.answerType === ANSWER_TYPE_ENUM.ZERO_TO_THREE) &&
              !this.notApplicableChecked.get(this.getChosenAnswerKey(question))
            ) {
              this.checklistItems[i].answerValue.fixedAnswerValue = this.chosenAnswer.get('' + question.order)?.order;
            } else if (
              (question.answerType.answerType === ANSWER_TYPE_ENUM.USER ||
                question.answerType.answerType === ANSWER_TYPE_ENUM.USER_MULTIPLE) &&
              !this.notApplicableChecked.get(this.getChosenAnswerKey(question))
            ) {
              if (this.chosenAnswer.get('' + question.order).usersAnswer) {
                this.checklistItems[i].answerValue.usersAnswer = [];
                this.checklistItems[i].answerValue.usersAnswer = this.chosenAnswer.get('' + question.order).usersAnswer;
              }
            } else if (
              question.answerType.answerType === ANSWER_TYPE_ENUM.FREE_TEXT &&
              !this.notApplicableChecked.get(this.getChosenAnswerKey(question))
            ) {
              if (this.chosenAnswer.get('' + question.order).answerString) {
                this.checklistItems[i].answerValue.stringAnswer = this.chosenAnswer.get('' + question.order).answerString;
              }
            }
          }
        } else {
          console.log('!!!!! per verificare se ci passa mai di qui');
        }

        // se è safety trigger imposto le priority = alle risposte
        if (this.appTypeId === APP_TYPE_ENUM.SAFETY_TRIGGER) {
          if (this.checklistItems[i]?.actions && this.checklistItems[i]?.actions?.length > 0) {
            this.checklistItems[i].actions.forEach(
              (a) =>
                (a.trackerAction.priority = {
                  id: this.priorityList[this.getChosenAnswer(question).possibleAnswerType]?.id
                })
            );
          }
        }
      }
      // }
    }
  }

  public setAnswersAndAnctionsStage(stageN: number) {
    const summary = this.checklist.stageAnswers[stageN].summary;
    const problemStatement = this.checklist.stageAnswers[stageN].problemStatement;
    this.stageAnswers[stageN] = {} as StageAnswerDTO;
    this.stageAnswers[stageN].stage = this.template.stages[stageN];
    this.stageAnswers[stageN].actions = [];
    this.stageAnswers[stageN].summary = '';

    this.stageAnswers[stageN].checklistItems = [] as CheckListItemDTO[];

    for (const question of this.template.stages[stageN].questions) {
      //check visibility
      //if not visible skip
      //  if (this.questionsTemplateComponent.checkConditions(question)) {
      // skip
      //  } else {
      const checklist = {} as CheckListItemDTO;
      checklist.question = {} as QuestionDTO;
      checklist.question.id = question.id;
      checklist.actions = [];
      checklist.answerValue = {} as AnswerValueDTO;
      if (question.answerType.answerType === ANSWER_TYPE_ENUM.NUMBER) {
        checklist.answerValue.numberAnswer = this.chosenAnswerStage.get(
          this.getChosenAnswerKeyStage(stageN, question)
        ).answerNumber;
      } else if (
        question.answerType.answerType === ANSWER_TYPE_ENUM.LOW_MEDIUM_HIGH ||
        question.answerType.answerType === ANSWER_TYPE_ENUM.ONE_OPTION ||
        question.answerType.answerType === ANSWER_TYPE_ENUM.YES_NO ||
        question.answerType.answerType === ANSWER_TYPE_ENUM.ZERO_TO_THREE
      ) {
        checklist.answerValue.fixedAnswerValue = this.chosenAnswerStage.get(this.getChosenAnswerKeyStage(stageN, question)).order;
      } else if (question.answerType.answerType === ANSWER_TYPE_ENUM.FREE_TEXT) {
        if (this.chosenAnswerStage.get(this.getChosenAnswerKeyStage(stageN, question)).answerString) {
          checklist.answerValue.stringAnswer = this.chosenAnswerStage.get(
            this.getChosenAnswerKeyStage(stageN, question)
          ).answerString;
        }
        /*
        checklist.answerValue.stringAnswer = this.chosenAnswerStage.get(
          this.getChosenAnswerKeyStage(stageN, question)
        ).answerString;*/
      }

      this.stageAnswers[stageN].summary = summary;
      this.stageAnswers[stageN].problemStatement = problemStatement;
      this.stageAnswers[stageN].checklistItems.push(checklist);
      console.log('this.checklist.stageAnswers[stageN]', this.checklist.stageAnswers[stageN]);
      // }
    }
  }

  public setAnswersAndAnctionsBatbone() {
    for (const question of this.template.batboneQuestions) {
      const batboneAnswer = {} as BatBoneAnswerDTO;
      batboneAnswer.question = question;
      batboneAnswer.answer = this.chosenAnswerBatbone.get('' + question.order).answer;
      this.checklist.batboneAnswers.push(batboneAnswer);
    }
  }

  public setAnswersAndAnctionsInformations() {
    for (const question of this.template.informationQuestions) {
      const info = {} as InformationAnswerDTO;
      info.question = question;
      info.answer = this.chosenAnswerInformations.get('' + question.id).answer;
      this.checklist.informationAnswers.push(info);
    }
  }

  public setAnswersAndAnctionsInvestigations() {
    for (const question of this.template.investigationQuestions) {
      if (question.answerType.answerType !== ANSWER_TYPE_ENUM.SIGNATURE) {
        if (
          (!!this.getChosenAnswerInvestigations(question) ||
            !!this.getChosenAnswersInvestigations(question) ||
            this.notApplicableChecked.get(this.getChosenAnswerKey(question))) &&
          !!!this.investigationAnswers.find((c) => c.question.id === question.id)
        ) {
          const checklist = {} as CheckListItemDTO;
          checklist.question = {} as QuestionDTO;
          checklist.question.id = question.id;
          checklist.answerValue = {} as AnswerValueDTO;

          checklist.actions = [];
          this.investigationAnswers.push(checklist);
        }

        if (
          this.investigationAnswers.find((c) => c.question.id === question.id)?.question?.answerType?.answerType ===
            ANSWER_TYPE_ENUM.DATE ||
          this.investigationAnswers.find((c) => c.question.id === question.id)?.question?.answerType?.answerType ===
            ANSWER_TYPE_ENUM.TIME ||
          this.investigationAnswers.find((c) => c.question.id === question.id)?.question?.answerType?.answerType ===
            ANSWER_TYPE_ENUM.DATE_AND_TIME
        ) {
          for (let i = 0; i < this.Dates.length; i++) {
            if (this.Dates[i]) {
              this.investigationAnswers[i].answerValue.dateAnswer = JSON.parse(
                JSON.stringify(this.Dates[i].toISOString().split('T')[0])
              );
            }
          }
        }
        if (
          !!this.investigationAnswers.find((c) => c.question.id === question.id) &&
          this.notApplicableChecked.get(this.getChosenAnswerKey(question))
        ) {
          const ck = this.investigationAnswers.find((c) => c.question.id === question.id);
          if (
            !ck.answerValue?.dateAnswer &&
            !ck.answerValue?.datetimeAnswer &&
            !ck.answerValue?.equipmentsAnswer?.length &&
            !ck.answerValue?.workingAreasAnswer?.length &&
            !ck.answerValue?.usersAnswer?.length &&
            !ck.answerValue?.timeAnswer
          ) {
            this.investigationAnswers.find((c) => c.question.id === question.id).answerValue = null;
          }
        }
      }

      const removedQuestions = this.questions.filter((x) => x.answerType.answerType === ANSWER_TYPE_ENUM.NA);
      if (removedQuestions?.length > 0) {
        for (const rq of removedQuestions) {
          this.investigationAnswers = this.investigationAnswers.filter((c) => c.question.id !== rq.id);
        }
      }
      for (const question of this.template.investigationQuestions) {
        if (
          question.answerType.answerType !== ANSWER_TYPE_ENUM.NA &&
          question.answerType.answerType !== ANSWER_TYPE_ENUM.SIGNATURE
        ) {
          const i = this.investigationAnswers.findIndex((c) => c.question.id === question.id);
          if (i > -1) {
            if (!!!this.investigationAnswers[i].answerValue) {
              this.investigationAnswers[i].answerValue = {} as AnswerValueDTO;
            }
            if (question.answerType.answerType === ANSWER_TYPE_ENUM.MULTIPLE_OPTIONS) {
              if (this.getChosenAnswersInvestigations(question)?.length > 0) {
                this.investigationAnswers[i].answerValue.values = [];
                for (const answer of this.getChosenAnswersInvestigations(question)) {
                  this.investigationAnswers[i].answerValue.values.push({ value: answer.order });
                }
              }
            } else {
              if (
                (question.answerType.answerType === ANSWER_TYPE_ENUM.NUMBER ||
                  question.answerType.answerType === ANSWER_TYPE_ENUM.RANGE ||
                  question.answerType.answerType === ANSWER_TYPE_ENUM.PERCENTAGE) &&
                !this.notApplicableChecked.get(this.getChosenAnswerKey(question))
              ) {
                this.investigationAnswers[i].answerValue.numberAnswer = this.chosenAnswer.get('' + question.order).answerNumber;
              } else if (
                (question.answerType.answerType === ANSWER_TYPE_ENUM.LOW_MEDIUM_HIGH ||
                  question.answerType.answerType === ANSWER_TYPE_ENUM.ONE_OPTION ||
                  question.answerType.answerType === ANSWER_TYPE_ENUM.YES_NO ||
                  question.answerType.answerType === ANSWER_TYPE_ENUM.ZERO_TO_THREE) &&
                !this.notApplicableChecked.get(this.getChosenAnswerKey(question))
              ) {
                this.investigationAnswers[i].answerValue.fixedAnswerValue = this.chosenAnswerInvestigations.get(
                  '' + question.order
                )?.order;
              } else if (
                question.answerType.answerType === ANSWER_TYPE_ENUM.FREE_TEXT &&
                !this.notApplicableChecked.get(this.getChosenAnswerKey(question))
              ) {
                this.investigationAnswers[i].answerValue.stringAnswer = this.chosenAnswerInvestigations.get(
                  '' + question.order
                ).answerString;
              }
            }
          } else {
            console.log('!!!!! per verificare se ci passa mai di qui');
          }

          // se è safety trigger imposto le priority = alle risposte
          if (this.appTypeId === APP_TYPE_ENUM.SAFETY_TRIGGER) {
            if (this.investigationAnswers[i]?.actions && this.checklistItems[i]?.actions?.length > 0) {
              this.investigationAnswers[i].actions.forEach(
                (a) =>
                  (a.trackerAction.priority = {
                    id: this.priorityList[this.getChosenAnswerInvestigations(question).possibleAnswerType]?.id
                  })
              );
            }
          }
        }
      }
    }
  }

  public getQuestionActionDescritpion(): QuestionDTO {
    for (const question of this.questions) {
      if (question.actionDescription) {
        return question;
      }
    }
    return null;
  }
  public viewImmages(immages: FileDTO[]) {
    this.dialog.open(ViewImmagesDialogComponent, {
      data: {
        model: {
          files: immages
        }
      },
      panelClass: 'image-view-modal'
    });
  }

  public getQuestionFiles(question: QuestionDTO, template: boolean): FileDTO[] {
    if (template) {
      if (this.questionFilesTemplate) {
        return this.questionFilesTemplate.get(this.getQuestionUniqueKey(question)) ?? [];
      }
      return [];
    } else {
      if (this.questionFiles) {
        return this.questionFiles.get(this.getQuestionUniqueKey(question)) ?? [];
      }
      return [];
    }
  }

  public getQuestionFromKey(guid: string): QuestionDTO[] {
    return this.questions.filter((x) => x.guid === guid);
  }
  public getQuestionUniqueKey(question: QuestionDTO): string {
    return question.guid;
  }
  public setChosenAnswer(question: QuestionDTO, answer: PossibleAnswerDTO) {
    this.chosenAnswer.set(this.getChosenAnswerKey(question), answer);
  }
  public setChosenAnswers(question: QuestionDTO, answers: PossibleAnswerDTO[]) {
    this.chosenAnswers.set(this.getChosenAnswerKey(question), answers);
  }
  public setChosenAnswerInvestigations(question: QuestionDTO, answer: PossibleAnswerDTO) {
    this.chosenAnswerInvestigations.set(this.getChosenAnswerKey(question), answer);
  }
  public setChosenAnswersInvestigations(question: QuestionDTO, answers: PossibleAnswerDTO[]) {
    this.chosenAnswersInvestigations.set(this.getChosenAnswerKey(question), answers);
  }
  public setChosenAnswerInformations(question: QuestionDTO, answer: InformationAnswerDTO) {
    answer.question = question;
    this.chosenAnswerInformations.set('' + question.id, answer);
  }
  public setChosenAnswersInformations(question: QuestionDTO, answers: InformationAnswerDTO[]) {
    this.chosenAnswersInformations.set('' + question.id, answers);
  }

  public setChosenAnswerBatbone(question: BatboneQuestionDTO, answer: BatBoneAnswerDTO) {
    answer.question = question;
    this.chosenAnswerBatbone.set('' + question.order, answer);
  }
  public setChosenAnswersBatbone(question: BatboneQuestionDTO, answers: BatBoneAnswerDTO[]) {
    this.chosenAnswersBatbone.set('' + question.order, answers);
  }
  public setChosenAnswerStage(stage: number, question: QuestionDTO, answer: PossibleAnswerDTO) {
    this.chosenAnswerStage.set(stage + '_' + question.order, answer);
  }
  public setChosenAnswersStage(stage: number, question: QuestionDTO, answers: PossibleAnswerDTO[]) {
    this.chosenAnswersStage.set(this.getChosenAnswerKeyStage(stage, question), answers);
  }
  public getChosenAnswer(question: QuestionDTO): PossibleAnswerDTO {
    return this.chosenAnswer.get(this.getChosenAnswerKey(question));
  }
  public getChosenAnswers(question: QuestionDTO): PossibleAnswerDTO[] {
    return this.chosenAnswers.get(this.getChosenAnswerKey(question));
  }

  public getChosenAnswerBatbone(question: QuestionDTO): BatBoneAnswerDTO {
    return this.chosenAnswerBatbone.get('' + question.order);
  }
  public getChosenAnswersBatbone(question: QuestionDTO): BatBoneAnswerDTO[] {
    return this.chosenAnswersBatbone.get('' + question.order);
  }
  public getChosenAnswerInvestigations(question: QuestionDTO): PossibleAnswerDTO {
    return this.chosenAnswerInvestigations.get(this.getChosenAnswerKey(question));
  }
  public getChosenAnswersInvestigations(question: QuestionDTO): PossibleAnswerDTO[] {
    return this.chosenAnswersInvestigations.get(this.getChosenAnswerKey(question));
  }
  public getChosenAnswerInformations(question: QuestionDTO): InformationAnswerDTO {
    return this.chosenAnswerInformations.get('' + question.id);
  }
  public getChosenAnswersInformations(question: QuestionDTO): InformationAnswerDTO[] {
    return this.chosenAnswersInformations.get('' + question.id);
  }
  public getChosenAnswerStage(stage: number, question: QuestionDTO): PossibleAnswerDTO {
    return this.chosenAnswerStage.get(this.getChosenAnswerKeyStage(stage, question));
  }
  public getChosenAnswersStage(stage: number, question: QuestionDTO): PossibleAnswerDTO[] {
    return this.chosenAnswersStage.get(this.getChosenAnswerKeyStage(stage, question));
  }
  public getChosenAnswerKey(question: QuestionDTO): string {
    return '' + question.order;
  }

  public getChosenAnswerKeyStage(stage: number, question: QuestionDTO): string {
    return stage + '_' + question.order;
  }

  public addFiles(question: QuestionDTO, fileList: FileList, attachmentindex?: number): void {
    for (let i = 0; i < fileList.length; i++) {
      const file: File = fileList.item(i);
      if (file.size > MAX_FILE_SIZE) {
        this.globalMessage.showError({ message: this.translateService.instant('error.fileSizeLimit', { filename: file.name }) });
      } else {
        if (!this.getQuestionFiles(question, false)) {
          this.questionFiles.set(this.getQuestionUniqueKey(question), []);
        }
        const list = this.getQuestionFiles(question, false);
        list.push({
          data: file,
          thumbnail: file.type.startsWith('image')
            ? (window.URL || window.webkitURL).createObjectURL(file)
            : './assets/img/default-file@3x.png',
          template: true,
          SavedOnAzure: false
        });
        this.questionFiles.set(this.getQuestionUniqueKey(question), list);
      }
    }
    if (fileList.length > 0 && attachmentindex > -1) {
      this.attachmentVisible[attachmentindex] = true;
    }
  }

  public deleteFile(question: QuestionDTO, file: FileDTO, attachmentindex?: number) {
    if (file.SavedOnAzure) {
      // se è già salvato lo cancello dallo storage
      this.azure.getClient().subscribe((client) => {
        const key = this.getQuestionUniqueKey(question);
        client.delete(`${AZURE_CHECKLIST_PATH}${AZURE_QUESTION_PATH}/${key}/${this.checklistId}`, file.data.name, () => {});
      });
    }
    const idxToRemove = this.getQuestionFiles(question, false).indexOf(file);
    this.getQuestionFiles(question, false).splice(idxToRemove, 1);
    if (this.getQuestionFiles(question, false).length === 0 && attachmentindex > -1) {
      //  this.attachmentVisible[attachmentindex] = false;
    }
  }

  private getActions(size?: number, page?: number, sort?: string, filters?: ActionTrackerFilterDTO): void {
    this.actionsList = this.actions;
    this.splitActionsFromDate();
    this.fileToSave = this.files;
    console.log(this.actions, this.fileToSave);
    /* const spinner = this.spinnerService.show();

    this.actionTrackerService
      .searchActionsByPage(size, page, sort, filters)
      .pipe(
        finalize(() => {
          this.spinnerService.hide(spinner);
          this.isLoading = false;
        })
      )
      .subscribe({
        // TODO (FIX): Why `any` type? Doesn't `searchActionsByPage` returns `ActionTrackerDTO[]`?
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        next: (response: any) => {
          this.actionsList = response.content as ActionDTO[];
          this.numberOfElements = response.numberOfElements;
        },
        error: (error: EdmsError) => {
          this.error = error;
        }
      }); */
  }

  private getQuestionFilesTemplateFromAzure(templateId: number): void {
    if (!this.questionFilesTemplate) {
      this.questionFilesTemplate = new Map<string, FileDTO[]>();
    }
    this.azure.getClient().subscribe((client) => {
      this.questions.forEach((question: QuestionDTO) => {
        const key = this.getQuestionUniqueKey(question);
        if (!!key) {
          client.list(`${AZURE_TEMPLATE_PATH}${AZURE_QUESTION_PATH}/${key}`).then((list) => {
            if (list.length > 0) {
              this.questionFilesTemplate.set(this.getQuestionUniqueKey(question), list);
            }
          });
        }
      });
    });
  }

  private getQuestionFilesFromAzure(checklistId: number): void {
    if (!this.questionFiles) {
      this.questionFiles = new Map<string, FileDTO[]>();
    }
    this.azure.getClient().subscribe((client) => {
      this.questions.forEach((question: QuestionDTO, index) => {
        const key = this.getQuestionUniqueKey(question);
        if (!!key) {
          client.list(`${AZURE_CHECKLIST_PATH}${AZURE_QUESTION_PATH}/${key}/${checklistId}`).then((list) => {
            if (list.length > 0) {
              this.questionFiles.set(this.getQuestionUniqueKey(question), list);
              this.attachmentVisible[this.getArrayIndexBase(0) + index] = true;
            }
          });
          if (question.answerType.answerType === ANSWER_TYPE_ENUM.SIGNATURE) {
            client.list(`${AZURE_CHECKLIST_PATH}${AZURE_SIGNATURE_PATH}/${key}/${checklistId}`).then((list) => {
              if (list.length > 0) {
                this.signaturePad.fromDataURL(list[0].thumbnail.toString());
              }
              this.questionFiles.set(key, list);
            });
          }
        }
      });
    });
  }
  private dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }
}
