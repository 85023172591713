import { AfterContentChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-scrollcomponent',
  templateUrl: './scrollcomponent.component.html',
  styleUrls: ['./scrollcomponent.component.scss']
})
export class ScrollcomponentComponent implements AfterContentChecked {
  @ViewChild('target') target: ElementRef;
  protected isVisible = false;
  constructor() {}

  ngAfterContentChecked(): void {
    if (this.target) {
      if (this.isVisible === false && this.target.nativeElement.offsetParent != null) {
        // this.target.nativeElement.scrollIntoView({ behavior: 'smooth' });
        this.target.nativeElement.scrollIntoViewIfNeeded();
        this.isVisible = true;
      } else if (this.isVisible === true && this.target.nativeElement.offsetParent == null) {
        this.isVisible = false;
      }
    }
  }

  public scrollTo(): void {
    if (this.target) {
      if (this.target.nativeElement.offsetParent != null) {
        // this.target.nativeElement.scrollIntoView({ behavior: 'smooth' });
        this.target.nativeElement.scrollIntoViewIfNeeded();
        this.isVisible = true;
      } else if (this.isVisible === true && this.target.nativeElement.offsetParent == null) {
        this.isVisible = false;
      }
    }
  }
}
