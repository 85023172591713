import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV, PROJECT_ENUM } from '@app/constants/global.constants';
import { Env } from '@app/types/env';
import { EdmsError } from '@app/types/error';
import { UserDepartmentDTO } from '@data/models/user-department-dto';
import { UserFiltersDTO } from '@data/models/user-filters-dto';
import UserIdeaDTO from '@data/models/user-idea-dto';
import { UserOperationCenterDTO } from '@data/models/user-operationcenter-dto';
import { UserPlantDTO } from '@data/models/user-plant-dto';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserService } from './user.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class UserFiltersService {
  public readonly PROJECT_KEY = 'project';
  public readonly GET_USER_FILTERS_PATH = '/api/filters/current';
  public readonly GET_USER_ACTIVE_FILTERS_PATH = '/api/filters/current/light';
  public readonly SAVE_USER_FILTERS_PATH = '/api/filters/save';
  public ideaUser = new BehaviorSubject<UserIdeaDTO>(null);

  constructor(
    @Inject(ENV) private env: Env,
    private http: HttpClient,
    private userService: UserService //private cookieService: CookieService
  ) {}

  public setUser(ideaUser: UserIdeaDTO) {
    this.ideaUser.next(ideaUser);
  }
  public getUserFilters(): Observable<UserFiltersDTO> {
    if (localStorage.getItem(this.PROJECT_KEY) === PROJECT_ENUM.ebos.toString()) {
      return this.http
        .get<UserFiltersDTO>(`${this.env.apiBaseUrl}${this.GET_USER_FILTERS_PATH}`)
        .pipe(catchError((error) => throwError(error as EdmsError)));
    } else {
      return this.userService.getLoggedUserInfoIdea().pipe(
        map((x) => {
          const filter = {} as UserFiltersDTO;
          filter.operationCenters = [
            {
              operationCenter: x.data.operationCenter,
              filtered: true,
              plants: [
                {
                  plant: x.data.plant,
                  filtered: true,
                  departments: [
                    {
                      department: x.data.department,
                      filtered: true
                    } as UserDepartmentDTO
                  ]
                } as UserPlantDTO
              ]
            } as UserOperationCenterDTO
          ];
          return filter;
        })
      );
    }
  }

  public getUserActiveFilters(): Observable<UserFiltersDTO> {
    if (localStorage.getItem(this.PROJECT_KEY) === PROJECT_ENUM.ebos.toString()) {
      return this.http
        .get<UserFiltersDTO>(`${this.env.apiBaseUrl}${this.GET_USER_ACTIVE_FILTERS_PATH}`)
        .pipe(catchError((error) => throwError(error as EdmsError)));
    } else {
      return this.userService.getLoggedUserInfoIdea().pipe(
        map((x) => {
          const filter = {} as UserFiltersDTO;
          filter.operationCenters = [
            {
              operationCenter: x.data.operationCenter,
              filtered: true,
              plants: [
                {
                  plant: x.data.plant,
                  filtered: true,
                  departments: [
                    {
                      department: x.data.department,
                      filtered: true
                    } as UserDepartmentDTO
                  ]
                } as UserPlantDTO
              ]
            } as UserOperationCenterDTO
          ];
          return filter;
        })
      );
    }
  }

  public saveUserFilters(userFilters: UserFiltersDTO): Observable<null> {
    if (localStorage.getItem(this.PROJECT_KEY) === PROJECT_ENUM.ebos.toString()) {
      return this.http
        .post<null>(`${this.env.apiBaseUrl}${this.SAVE_USER_FILTERS_PATH}`, userFilters)
        .pipe(catchError((error) => throwError(error as EdmsError)));
    } else {
      return this.http
        .post<null>(`${this.env.apiBaseUrl}${this.SAVE_USER_FILTERS_PATH}`, userFilters)
        .pipe(catchError((error) => throwError(error as EdmsError)));
    }
  }
}
