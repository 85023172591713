import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { GlobalMessageComponent } from '@shared/components/global-message/global-message.component';
import {
  GLOBAL_MESSAGE_DEFAULT_DURATION,
  GLOBAL_MESSAGE_DEFAULT_HORIZONTAL_POSITION,
  GLOBAL_MESSAGE_DEFAULT_VERTICAL_POSITION,
  GLOBAL_MESSAGE_LOADING_CLASS
} from '@shared/constants/global-message';

export type GlobalMessageConfig = {
  /** Message text */
  message: string;

  /** Action text (ex: "Close") */
  actionText?: string;

  /** Should show spinner icon */
  spinner?: boolean;

  /**
   * Duration while the message will be visible.
   * <br>Defaults to: `GLOBAL_MESSAGE_DEFAULT_DURATION`
   */
  duration?: number;

  /**
   * Horizontal position of the message.
   * <br>Valid values: `top` | `bottom`
   * <br>Defaults to: `GLOBAL_MESSAGE_DEFAULT_HORIZONTAL_POSITION`
   */
  horizontalPosition?: string;

  /**
   * Vertical position of the message.
   * <br>Valid values: `start` | `center` | `end` | `left` | `right`
   * <br>Defaults to: `GLOBAL_MESSAGE_DEFAULT_VERTICAL_POSITION`
   */
  verticalPosition?: string;

  /**
   * Action to be executed when clicking on action
   */
  onAction?: () => void;
};

@Injectable()
export class GlobalMessageService {
  constructor(private snackBar: MatSnackBar) {}

  /**
   * Shows a message
   *
   * @param config message configuration options
   */
  public showMessage(config: GlobalMessageConfig): void {
    this.show(null, config);
  }

  /**
   * Shows a message with "loading" styles
   *
   * @param config message configuration options
   */
  public showLoading(config: GlobalMessageConfig): void {
    config.duration = Infinity;
    this.show(GLOBAL_MESSAGE_LOADING_CLASS, {
      ...config,
      spinner: true
    });
  }

  /**
   * Shows a message with "error" styles
   *
   * @param config message configuration options
   */
  public showError(config: GlobalMessageConfig): void {
    this.show(null, config);
  }

  /**
   * Shows a message with "success" styles
   *
   * @param config message configuration options
   */
  public showSuccess(config: GlobalMessageConfig): void {
    this.show(null, config);
  }

  /**
   * Shows a message with "warning" styles
   *
   * @param config message configuration options
   */
  public showWarning(config: GlobalMessageConfig): void {
    this.show(null, config);
  }

  public dismiss(): void {
    this.snackBar.dismiss();
  }

  private show(className: string = '', config: GlobalMessageConfig): void {
    const duration = config.duration || GLOBAL_MESSAGE_DEFAULT_DURATION;
    const horizontalPosition = config.horizontalPosition || GLOBAL_MESSAGE_DEFAULT_HORIZONTAL_POSITION;
    const verticalPosition = config.verticalPosition || GLOBAL_MESSAGE_DEFAULT_VERTICAL_POSITION;

    const snackBarRef = this.snackBar.openFromComponent(GlobalMessageComponent, {
      data: config,
      duration,
      panelClass: className,
      horizontalPosition: horizontalPosition as MatSnackBarHorizontalPosition,
      verticalPosition: verticalPosition as MatSnackBarVerticalPosition
    });

    snackBarRef.onAction().subscribe(() => {
      if (config.onAction) {
        config.onAction();
      }
    });
  }
}
