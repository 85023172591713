import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import UserDTO from '../../data/models/user-dto';

export class Formatter {
  private static labels = {
    closed: marker('apps.actionTracker.closed'),
    inProgress: marker('apps.actionTracker.inProgress'),
    rejected: marker('apps.actionTracker.rejected'),
    unscheduled: marker('apps.actionTracker.unscheduled'),
    unchecked: marker('apps.notifications.unchecked'),
    checked: marker('apps.notifications.checked')
  };

  public static truncate = (input: string, maxLength = 100) =>
    input.length > maxLength ? `${input.substring(0, maxLength)}…` : input;

  public static displayUserNameAndSurname(user: UserDTO, maxLength = 100): string {
    return user ? `${Formatter.truncate(user.firstName + ' ' + user.lastName, maxLength)}` : '';
  }

  public static getActionTrackerStatusIdLabel(statusId: number): string {
    switch (statusId) {
      case 0: {
        return this.labels.unscheduled;
      }
      case 1: {
        return this.labels.inProgress;
      }
      case 2: {
        return this.labels.rejected;
      }
      case 3: {
        return this.labels.closed;
      }
      default:
        return '';
    }
  }

  public static getNotificationStatusIdLabel(statusId: number): string {
    return statusId === 0 ? this.labels.unchecked : this.labels.checked;
  }
}
