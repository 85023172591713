import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProgressSpinnerDialogComponent } from '@shared/components/progress-spinner-dialog/progress-spinner-dialog.component';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class ProgressSpinnerDialogService {
  private instances: {
    [key: string]: MatDialogRef<ProgressSpinnerDialogComponent>;
  } = {};

  constructor(private dialog: MatDialog, private logger: NGXLogger) {}

  /**
   * Open a progress spinner dialog
   *
   * @returns dialog identifier, to use with `close(dialogRefId)`
   */
  public show(noSpinner?: boolean): string {
    const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: noSpinner ? 'hidden' : 'transparent',
      disableClose: true
    });

    this.instances[dialogRef.id] = dialogRef;

    return dialogRef.id;
  }

  /**
   * Close given progress spinner dialog
   *
   * @param dialogRefId dialog identifier, obtained when calling `show()`
   */
  public hide(dialogRefId: string) {
    if (!dialogRefId) {
      this.logger.warn('You tried to use hide() without a dialogRef');
      return;
    }

    const ref = this.instances[dialogRefId];

    if (ref) {
      ref.close();
      delete this.instances[dialogRefId];
    } else {
      this.logger.warn('You tried to use hide() without a valid dialogRef ID. Given ref ID is: ', dialogRefId);
    }
  }
}
