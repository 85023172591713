import { CheckListItemDTO } from '@data/models/checklist-item-dto';
import { ActionDTO } from './../../../data/models/action-dto';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EdmsError } from '@app/types/error';
import { FileDTO } from '@data/models/file-dto';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AzureBlobStorageService } from '@shared/services/azure-blob-storage.service';
import { GlobalMessageService } from '@shared/services/global-message.service';
import { TranslateService } from '@ngx-translate/core';
import { GLOBAL_MESSAGE_5SEC_DURATION } from '@shared/constants/global-message';
import { CreateActionItemDialogComponent } from '../create-action-item-dialog/create-action-item-dialog.component';
import { AddActionDescriptionDialogComponent } from '../add-action-description-dialog/add-action-description-dialog.component';
import { NoteDTO } from '@data/models/note-dto';
import { PriorityDTO } from '@data/models/priority-dto';
import { AppTypeDTO } from '@data/models/app-type-dto';
import { ConfirmDialogConfig } from '../confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from '@shared/services/confirm-dialog.service';
import { FileRefDTO } from '@data/models/file-ref-dto';
import { APP_TYPE_ENUM } from '@shared/constants/app-type.constants';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-create-action-dialog',
  templateUrl: './create-action-dialog.component.html',
  styleUrls: ['./create-action-dialog.component.scss']
})
export class CreateActionDialogComponent implements OnInit {
  // public formGroup: FormGroup;
  // public notesControl: FormControl = new FormControl();
  // public files: FileDTO[] = [];

  public get saveButtonDisabled(): boolean {
    if (
      // descrizione vuota
      this.actionDescription.trim().length === 0 ||
      // se è stata messa almeno una action
      this.actions?.length === 0
    ) {
      return true;
    }
    return false;
  }

  public title: string;

  public labels: { [key: string]: string } = {
    description: marker('createAction.dialog.description'),
    finish: marker('createAction.dialog.finish'),
    addDescription: marker('createAction.dialog.addDescription'),
    actions: marker('createAction.dialog.actions'),
    addAction: marker('createAction.dialog.addAction')
  };

  public error: EdmsError;
  public actions: ActionDTO[];
  public actionDescription = '';

  // public actionTypeId: number;
  //private actionFiles: FileDTO[][] = [];

  private confirmDeletionConfig: ConfirmDialogConfig = {
    title: marker('createAction.dialog.deleteAction'),
    message: '',
    yesActionText: marker('confirmdialog.yesActionText.delete'),
    noActionText: marker('confirmdialog.noActionText.cancel')
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      model: {
        checklistItem: CheckListItemDTO;
        // actions: ActionDTO[];
        questionId: number;
        questionTitle: string;
        // owner: number;
        // actionTypeId: number;
        editDisabled?: boolean;
        isReadOnly?: boolean;
        priorityList: PriorityDTO[];
        appTypeId: number;
        appName: string;
        appId: number;

        actionFiles: Map<string, FileDTO[]>;
        fileRefs: Map<string, FileRefDTO[]>;
      };
    },
    private dialog: MatDialog,
    private globalMessage: GlobalMessageService,
    public translate: TranslateService,
    private confirmDialog: ConfirmDialogService
  ) {}

  ngOnInit() {
    if (!this.actions) {
      this.actions = [];
    }
    this.title = this.data.model.questionTitle;

    if (this.data.model.checklistItem?.actions) {
      this.actions = JSON.parse(JSON.stringify(this.data.model.checklistItem?.actions));
    } else {
      this.data.model.checklistItem.actions = [];
      this.actions = JSON.parse(JSON.stringify(this.data.model.checklistItem?.actions));
    }

    this.actionDescription = this.data.model.checklistItem.description ?? '';

    // const actionTypeSpinner = this.spinnerService.show();

    // this.actionTrackerService.getActionTypes().subscribe((data) => {
    //   if (data) {
    //     this.actionTypeId = data[0].id; //non so se sia giusto
    //     // this.spinnerService.hide(actionTypeSpinner);
    //   }
    // });
  }

  public setChipColorByPriority(priority: number): { chipColor: string; iconName: string } {
    switch (priority) {
      case 1: {
        return { chipColor: 'success', iconName: 'outline-basics-form-validation-check-star' };
      }
      case 2: {
        return { chipColor: 'accent', iconName: 'outline-basics-alerts-information-circle' };
      }
      case 3: {
        return { chipColor: 'warn', iconName: 'outline-basics-alerts-exclamation-diamond' };
      }
      default:
        return { chipColor: '', iconName: 'icons-disable' };
    }
  }

  public updateModel(): void {
    // this.data.model.notes = this.notesControl.value;
  }

  public removeFile(index: number): void {
    // this.files.splice(index, 1);
  }

  public async save(): Promise<void> {
    this.data.model.checklistItem.description = this.actionDescription;
    this.data.model.checklistItem.actions = this.actions;

    this.dialog.getDialogById('create-action-dialog-component').close({
      // actions: this.actions,
      checklistItem: this.data.model.checklistItem,
      actionFiles: this.data.model.actionFiles,
      fileRefs: this.data.model.fileRefs
    });
    // this.globalMessage.showSuccess({
    //   message: this.translate.instant(marker('global.messages.entitySuccesfullyCreated'), {
    //     entity: this.translate.instant(marker('entity.note'))
    //   }),
    //   duration: GLOBAL_MESSAGE_5SEC_DURATION
    // });
  }

  public onAddAction(): void {
    if (this.canAddAction()) {
      const newAction = {
        id: null,
        actionTracker: true,
        title: '',
        trackerAction: {
          id: null,
          priority: {
            id: null
          },
          app: {
            name: this.data.model.appName,
            id: this.data.model.appId
          }
        }
      } as ActionDTO;
      // const note = {
      //   notes: null,
      //   fileInfos: [],
      //   id: null,
      //   action: {
      //     id: null,
      //     actionNotes: [],
      //     trackerAction: {
      //       // actionType: {
      //       //   id: this.data.model.actionTypeId
      //       // },
      //       // owner: {
      //       //   id: this.data.model.owner
      //       // }
      //     }
      //   }
      // } as NoteDTO;
      this.editAction(newAction, -1);
    }
  }
  public onEditActionDescription(): void {
    const dialogRef = this.dialog
      .open(AddActionDescriptionDialogComponent, {
        id: 'add-action-description-dialog-component',
        panelClass: ['add-action-description-dialog', 'full-screen-modal'],
        data: {
          model: {
            description: this.actionDescription,
            appTypeId: this.data.model.appTypeId
          }
        }
      })
      .afterClosed()
      .subscribe((dialogResult: string) => {
        if (dialogResult || dialogResult === '') {
          //this.actions.forEach((a) => (a.title = dialogResult));
          this.actionDescription = dialogResult;
        }
      });
  }

  public editAction(action: ActionDTO, index: number) {
    const dialogRef = this.dialog
      .open(CreateActionItemDialogComponent, {
        id: 'create-action-item-dialog-component',
        panelClass: ['create-action-item-dialog', 'full-screen-modal'],
        data: {
          model: {
            action,
            priorityList: this.data.model.priorityList,
            appTypeId: this.data.model.appTypeId,
            files:
              index === -1 || this.data.model.actionFiles.get(this.getActionNoteUniqueKey(action)).length === 0
                ? []
                : this.data.model.actionFiles.get(this.getActionNoteUniqueKey(action)),
            fileRefs:
              index === -1 || this.data.model.fileRefs.get(this.getActionNoteUniqueKey(action)).length === 0
                ? []
                : this.data.model.fileRefs.get(this.getActionNoteUniqueKey(action))
          }
        }
      })
      .afterClosed()
      .subscribe((dialogResult: { action: ActionDTO; files: FileDTO[]; fileRefs: FileRefDTO[] }) => {
        if (dialogResult) {
          if (index === -1) {
            // è nuovo
            this.actions.push(dialogResult.action);
            //   this.data.model.actionFiles.push(dialogResult.files);
            //     this.data.model.fileRefs.push(dialogResult.fileRefs);
          } else {
            this.actions[index] = dialogResult.action;
          }
          this.data.model.actionFiles.set(this.getActionNoteUniqueKey(dialogResult.action), dialogResult.files);
          this.data.model.fileRefs.set(this.getActionNoteUniqueKey(dialogResult.action), dialogResult.fileRefs);
        }
      });
  }

  public deleteAction(action: ActionDTO, index: number) {
    this.confirmDeletionConfig.message = action.title;

    this.confirmDialog.open(this.confirmDeletionConfig).subscribe((result) => {
      if (result) {
        this.actions.splice(index, 1);
        this.data.model.actionFiles.set(this.getActionNoteUniqueKey(action), []);
        this.data.model.fileRefs.set(this.getActionNoteUniqueKey(action), []);
      }
      this.confirmDeletionConfig.message = '';
    });
  }

  public canAddAction(): boolean {
    return this.actionDescription.trim().length > 0 ? true : false;
  }

  public getActionFileSize(action: ActionDTO): number {
    if (action?.actionNotes?.length > 0) {
      return this.data.model.fileRefs.get(this.getActionNoteUniqueKey(action))?.length;
    } else {
      return null;
    }
  }
  private getActionNoteUniqueKey(action: ActionDTO): string {
    if (!!!action.actionNotes || action.actionNotes.length === 0) {
      action.actionNotes = [];
    }
    if (!!!action.actionNotes[0]) {
      action.actionNotes[0] = {} as NoteDTO;
    }
    if (!!!action.actionNotes[0].guid) {
      action.actionNotes[0].guid = UUID.UUID();
    }
    return action.actionNotes[0].guid;
  }
  // private attachRefreshDetailsHandler<T>(dialogRef: MatDialogRef<T>): void {
  //   dialogRef.afterClosed().subscribe((dialogResult: { result: boolean; data: ActionDTO }) => {
  //     if (dialogResult.result) {
  //       this.getActionDetails();
  //     }
  //     if (dialogResult.data) {
  //       this.openRelatedActionModal(dialogResult.data);
  //     }
  //   });
  // }
}
