import { GenericUserDTO } from './generic-user-dto';
import { IdeaQuestionDTO } from './idea-question-dto';
import UserDTO from './user-dto';

export class IdeaAnswerOptionDTO {
  public id: number;
  public actionRequired: number;
  public possibleAnswer: string;
  public possibleAnswerType?: number;
  public order: number;
  public dateValue: string;
  public isExpectedAnswer: boolean;
  public answerNumber: number;
  public answerString: string;
  public users: GenericUserDTO[];

  constructor(possibleAnswerType?: number, possibleAnswer?: string, order?: number) {
    this.possibleAnswer = possibleAnswer;
    this.order = order;
    this.isExpectedAnswer = false;
    this.possibleAnswerType = possibleAnswerType;
    this.users = [];
  }
}
