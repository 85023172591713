<div class="user-filters">
    <p class="user-filters__title">
        {{ labels.filtersTitle | translate }}
        <mat-icon (click)="closeDrawer()" svgIcon="close"></mat-icon>
    </p>
    <div class="user-filters__content">
        <ng-container *ngIf="showSpinner">
            <div class="overlay-spinner">
                <mat-spinner diameter="26"></mat-spinner>
            </div>
        </ng-container>

        <ng-container *ngIf="error">
            <app-message-block [message]="error.message"></app-message-block>
        </ng-container>

        <ng-container *ngIf="filtersFormGroup && operationCenters && !error">
            <form autocomplete="off" [formGroup]="filtersFormGroup">
                <ng-container
                    *ngIf="
                        (loggedUserInfo$ | async).data?.role?.level === STAKEHOLDER ||
                        (loggedUserInfo$ | async).data?.role?.level === GLOBAL_SUPER_USER ||
                        operationCenters?.length > 1
                    "
                >
                    <!-- Operation Centers -->
                    <p class="mat-title">{{ labels.operationCenter | translate }}</p>

                    <mat-button-toggle-group formControlName="operationCenter">
                        <mat-button-toggle
                            *ngFor="let userOperationCenter of operationCenters"
                            [value]="userOperationCenter.operationCenter.id"
                            >{{ userOperationCenter.operationCenter.description }}</mat-button-toggle
                        >
                    </mat-button-toggle-group>
                </ng-container>

                <!-- Plants -->
                <div [hidden]="!plants">
                    <p class="mat-title">{{ labels.plant | translate }}</p>

                    <!-- For all roles except for global superuser and stakeholder, with only 1 plant -->
                    <div
                        *ngIf="(loggedUserInfo$ | async).data?.role?.level < STAKEHOLDER && plants.length === 1"
                        class="plant-name"
                    >
                        {{ plants[0].plant.literal.translateValue }}
                    </div>

                    <!-- For all roles except for global superuser and stakeholder, with 2 or more plants -->
                    <mat-button-toggle-group
                        formControlName="plants"
                        *ngIf="(loggedUserInfo$ | async).data?.role?.level < STAKEHOLDER && plants.length > 1"
                    >
                        <ng-container *ngFor="let userPlant of plants">
                            <mat-button-toggle [value]="userPlant.plant.id" *ngIf="userPlant.plant.enabled">{{
                                userPlant.plant.literal.translateValue
                            }}</mat-button-toggle>
                        </ng-container>
                    </mat-button-toggle-group>

                    <!-- Only stakeholder and globalSuperUser -->
                    <mat-button-toggle-group
                        formControlName="plants"
                        *ngIf="
                            (loggedUserInfo$ | async).data?.role?.level === STAKEHOLDER ||
                            (loggedUserInfo$ | async).data?.role?.level === GLOBAL_SUPER_USER
                        "
                    >
                        <mat-button-toggle *ngFor="let userPlant of plants" [value]="userPlant.plant.id">{{
                            userPlant.plant.literal.translateValue
                        }}</mat-button-toggle>
                    </mat-button-toggle-group>
                </div>

                <!-- Departments -->
                <div [hidden]="!departments">
                    <p class="mat-title">{{ labels.department | translate }}</p>

                    <mat-button-toggle-group formControlName="department">
                        <mat-button-toggle
                            [value]="0"
                            *ngIf="(loggedUserInfo$ | async).data?.role?.level >= ALLOWED_USERS_LEVEL"
                            >{{ labels.allSelected | translate }}</mat-button-toggle
                        >

                        <ng-container *ngFor="let userDepartment of departments">
                            <mat-button-toggle
                                [value]="userDepartment.department.id"
                                *ngIf="
                                    userDepartment.department.enabled ||
                                    (loggedUserInfo$ | async).data?.role?.level === STAKEHOLDER ||
                                    (loggedUserInfo$ | async).data?.role?.level === GLOBAL_SUPER_USER
                                "
                                >{{ userDepartment.department.literal.translateValue }}</mat-button-toggle
                            >
                        </ng-container>
                    </mat-button-toggle-group>
                </div>

                <!-- All department message -->
                <div *ngIf="allDepartments" class="all-departments-message">
                    <strong>{{ labels.allDepartments | translate }}</strong>
                </div>

                <!-- Working Areas -->
                <div [hidden]="!workingAreas || (workingAreas && workingAreas.length === 0)">
                    <p class="mat-title">{{ labels.workingAreas | translate }}</p>

                    <mat-button-toggle-group multiple="true" formControlName="workingAreas">
                        <ng-container *ngFor="let userWorkingArea of workingAreas">
                            <mat-button-toggle
                                class="toggle-working_area"
                                [value]="userWorkingArea.workingArea.id"
                                *ngIf="
                                    userWorkingArea.workingArea.enabled ||
                                    (loggedUserInfo$ | async).data?.role?.level === STAKEHOLDER ||
                                    (loggedUserInfo$ | async).data?.role?.level === GLOBAL_SUPER_USER
                                "
                                >{{ userWorkingArea.workingArea.literal.translateValue }}</mat-button-toggle
                            >
                        </ng-container>
                    </mat-button-toggle-group>
                    <mat-error class="workingArea-error" *ngIf="filtersFormGroup.controls.workingAreas.invalid">{{
                        labels.workingAreasRequired | translate
                    }}</mat-error>
                </div>

                <div formArrayName="equipmentsFormControls" [hidden]="selectedWorkingAreas?.length <= 0">
                    <p class="mat-title">{{ labels.equipments | translate }}</p>

                    <ng-container *ngFor="let control of equipmentsFormControls.controls; index as i">
                        <div class="sub-label">{{ selectedWorkingAreas[i].workingArea.literal.translateValue }}</div>

                        <mat-form-field appearance="outline">
                            <mat-select
                                #select
                                multiple="true"
                                [formControlName]="i"
                                disableOptionCentering
                                panelClass="user-filter-mat-select-panel"
                            >
                                <mat-select-trigger>
                                    {{ getEquipmentSelectText(i) }}
                                </mat-select-trigger>
                                <div class="mat-options-wrapper">
                                    <div class="mat-select-all">
                                        <mat-checkbox
                                            [checked]="equipmentsAllSelected[i]"
                                            [indeterminate]="equipmentsIndeterminateState[i]"
                                            (change)="onEquipmentToggleSelection(i)"
                                        ></mat-checkbox>
                                        <span
                                            class="mat-option-text"
                                            translate
                                            [translateParams]="{
                                                workingAreaName: selectedWorkingAreas[i].workingArea.literal.translateValue
                                            }"
                                        >
                                            {{ labels.equipment }}
                                        </span>
                                    </div>
                                    <div class="mat-options">
                                        <ng-container *ngFor="let userEquipment of selectedWorkingAreas[i].equipments">
                                            <mat-option
                                                (click)="onEquipmentOptionClick(i)"
                                                [value]="userEquipment.equipment.id"
                                                *ngIf="
                                                    userEquipment.equipment.enabled ||
                                                    (loggedUserInfo$ | async).data?.role?.level === STAKEHOLDER ||
                                                    (loggedUserInfo$ | async).data?.role?.level === GLOBAL_SUPER_USER
                                                "
                                                >{{ userEquipment.equipment.literal.translateValue }}</mat-option
                                            >
                                        </ng-container>
                                    </div>
                                </div>
                            </mat-select>
                            <mat-error *ngIf="control.invalid">{{ labels.equipmentsRequired | translate }}</mat-error>
                        </mat-form-field>
                    </ng-container>
                </div>
            </form>
        </ng-container>
    </div>
    <ng-container *ngIf="filtersFormGroup && departments && !error">
        <div class="user-filters__actions">
            <mat-error *ngIf="saveError"
                ><strong>{{ labels.error | translate }}: </strong>{{ labels.saveError | translate }}</mat-error
            >

            <button
                mat-flat-button
                color="primary"
                class="mat-button-fill mat-big-button"
                [disabled]="!filtersFormGroup.valid && !allDepartments"
                (click)="sendForm()"
            >
                {{ labels.save | translate }}
            </button>
        </div>
    </ng-container>
</div>
