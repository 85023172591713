export const GLOBAL_MESSAGE_LOADING_CLASS = 'global-message--loading';
export const GLOBAL_MESSAGE_ERROR_CLASS = 'global-message--error';
export const GLOBAL_MESSAGE_SUCCESS_CLASS = 'global-message--success';
export const GLOBAL_MESSAGE_WARNING_CLASS = 'global-message--warning';

export const GLOBAL_MESSAGE_DEFAULT_DURATION = 12000;
export const GLOBAL_MESSAGE_DEFAULT_HORIZONTAL_POSITION = 'center';
export const GLOBAL_MESSAGE_DEFAULT_VERTICAL_POSITION = 'bottom';

export const GLOBAL_MESSAGE_2SEC_DURATION = 2000;
export const GLOBAL_MESSAGE_5SEC_DURATION = 5000;
export const GLOBAL_MESSAGE_10SEC_DURATION = 10000;

export const GLOBAL_MESSAGE_RIGHT_HORIZONTAL_POSITION = 'right';
