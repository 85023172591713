import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { GLOBAL_SUPER_USER, LINE_LEAD, SUPER_USER, STAKEHOLDER, TEAM_LEAD, PROCESS_LEAD } from '@app/constants/global.constants';
import { RouteConstants } from '@app/constants/route.constants';
import { EdmsError } from '@app/types/error';
import { ServiceResponse } from '@app/types/service-response';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { GroupDTO } from '@data/models/group-dto';
import UserDTO from '@data/models/user-dto';
import { UserFiltersDTO } from '@data/models/user-filters-dto';
import { UserOperationCenterDTO } from '@data/models/user-operationcenter-dto';
import { UserPlantDTO } from '@data/models/user-plant-dto';
import { ManagementServiceService } from '@data/services/management-service.service';
import { UserFiltersService } from '@data/services/user-filters.service';
import { UserService } from '@data/services/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProgressSpinnerDialogService } from '@shared/services/progress-spinner-dialog.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {
  public labels = {
    menuHomeButton: marker('dashboard.menu.home'),
    menuAppsButton: marker('dashboard.menu.apps'),
    menuAdministrationButton: marker('dashboard.menu.administration'),
    menuManagementButton: marker('dashboard.menu.management'),
    menuFiltersButton: marker('dashboard.menu.filters'),
    menuWeeklyPlanning: marker('dashboard.menu.weeklyPlanning')
  };

  public readonly APPS = '/dashboard/apps';
  public readonly ADMINISTRATION = '/dashboard/administration';
  public readonly MANAGEMENT = '/dashboard/management';
  public readonly WEEKLYPLANNING = '/dashboard/administration/weekly-planning';
  public readonly SUPER_USER = SUPER_USER;
  public readonly GLOBAL_SUPER_USER = GLOBAL_SUPER_USER;
  public readonly TEAM_LEAD = TEAM_LEAD;
  public readonly PROCESS_LEAD = PROCESS_LEAD;
  public readonly LINE_LEAD = LINE_LEAD;
  public readonly STAKEHOLDER = STAKEHOLDER;
  public selectedPlant: UserPlantDTO;
  public userFilter: Observable<UserFiltersDTO>;
  public isLanding = true;
  public currentSelection: string;
  public loggedUserInfo$: Observable<ServiceResponse<UserDTO>>;
  public appHidden = true;
  public appGroups: GroupDTO[];
  public error: EdmsError;

  public templateIcons = new Map([
    ['safety', { icon: 'outline-security-check' }],
    ['quality', { icon: 'outline-basics-celebration-awards' }],
    ['production', { icon: 'icons-admin-product' }],
    ['others', { icon: 'basics-menu-navigation-menu-horizontal' }]
  ]);
  private userFilters$: Observable<UserOperationCenterDTO>;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private spinnerService: ProgressSpinnerDialogService,
    private managementService: ManagementServiceService,

    private userFiltersService: UserFiltersService
  ) {
    this.loggedUserInfo$ = this.userService.loggedUserInfo$;
  }

  ngOnInit(): void {
    this.manageUrl(window.location.href);

    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.manageUrl(event.url);
      }
    });
  }
  public showApps() {
    this.appHidden = JSON.parse(JSON.stringify(!this.appHidden));

    if (!this.appGroups) {
      this.getAppGroups();
    }
  }
  public redirectToDashboard(): void {
    this.router.navigate([RouteConstants.DASHBOARD]);
  }

  public redirectToApps(): void {
    /* this.router.navigate(['./' + RouteConstants.APPS], {
      relativeTo: this.route
    }); */
  }

  public redirectToAdministration(): void {
    this.router.navigate(['./' + RouteConstants.ADMINISTRATION], {
      relativeTo: this.route
    });
  }
  public redirectWeeklyPlanning(): void {
    this.router.navigateByUrl(this.WEEKLYPLANNING);
  }

  public redirectToManagement(): void {
    this.router.navigate(['./' + RouteConstants.MANAGEMENT], {
      relativeTo: this.route
    });
  }

  public hideAppMenu(): void {
    if (!this.appHidden) {
      this.appHidden = true;
    }
  }

  public getAppGroups(): void {
    const spinner = this.spinnerService.show();

    this.userFilters$ = this.userFiltersService.getUserActiveFilters().pipe(
      map((filters: UserFiltersDTO) => {
        const operationCenter: UserOperationCenterDTO = filters.operationCenters.find((i) => i.filtered);
        return operationCenter;
      })
    );

    this.userFilters$.subscribe({
      next: (op: UserOperationCenterDTO) => {
        this.selectedPlant = op.plants.find((x) => x.filtered);

        this.managementService.getGroups(this.selectedPlant.plant.id).subscribe({
          next: (result: GroupDTO[]) => {
            this.appGroups = result;
            this.spinnerService.hide(spinner);
          },
          error: (error: EdmsError) => {
            this.error = error;
            this.spinnerService.hide(spinner);
          }
        });
      }
    });
  }

  public redirectToAppLanding(appName: string, id: number): void {
    const app = { name: appName, id };

    // TODO: remove this and implement a proper routing without 'state'
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    // TODO (REVIEW): I don't like this `state` approach, I would prefer having the `appName` in the URL
    this.router.navigate(['./' + RouteConstants.APPS + '/' + RouteConstants.APP_LANDING], {
      relativeTo: this.route,
      state: app
    });
  }

  private manageUrl(url: string): void {
    if (url.includes(RouteConstants.MANAGEMENT)) {
      this.isLanding = false;
      this.currentSelection = this.MANAGEMENT;
    } else if (url.includes(RouteConstants.APPS)) {
      this.isLanding = false;
      this.currentSelection = this.APPS;
    } else if (url.includes(RouteConstants.LATEST_INFORMATION)) {
      this.isLanding = false;
    } else if (url.includes(RouteConstants.ADMINISTRATION)) {
      this.isLanding = false;
      this.currentSelection = this.ADMINISTRATION;
    } else {
      this.isLanding = true;
      this.currentSelection = '';
    }
  }
}
