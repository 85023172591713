<!-- Header -->
<mat-toolbar class="mat-dialog__header" color="primary">
    <div class="toolbar-title">{{ title | translate }}</div>
    <mat-icon [mat-dialog-close]="false">close</mat-icon>
</mat-toolbar>

<!-- Content -->
<div class="mat-dialog__content confirm-dialog">
    <div mat-dialog-content class="confirm-dialog__content">
        <p class="confirm-dialog__text" innerHTML="{{ message | translate }}"></p>
    </div>

    <div class="mat-dialog__actions">
        <button
            mat-stroked-button
            color="primary"
            (click)="onConfirm()"
            tabindex="-1"
        >
            {{ yesActionText | translate }}
        </button>
        <button
            mat-flat-button
            color="primary"
            (click)="onDismiss()"
        >
            {{ noActionText | translate }}
        </button>
    </div>
</div>
