<div style="height: 100%;">
  <div class="registration-form">
    <div class="welcome-message">
      <img class="login-content-logo" alt="IdeApp" src="/assets/img/new/idea/logo.svg" />
    </div>
    <div>
      <div class="work-information">
        <p class="title"> {{labels.workInfo | translate }}</p>
        <div class="flex-container" fxLayout="row wrap">

          <!-- Operation Center -->

          <div class="select-field" fxFlex="50%">
            <p class="label">{{labels.businesUnit | translate }}</p>
            <mat-form-field class="option-field" appearance="fill">
              <mat-select disableRipple (selectionChange)="getOperationCenterPlants($event.value)"
                [(value)]="user.operationCenter.id">
                <mat-option *ngFor="let bunit of operationData" [value]="bunit.id">{{bunit.description}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Location -->

          <div class="select-field" fxFlex="50%">
            <p class="label">{{labels.plants | translate }}</p>
            <mat-form-field class="option-field" appearance="fill">
              <mat-select disableRipple (selectionChange)="getDepartmentsList($event.value)" [(value)]="user.plant.id">
                <mat-option *ngFor="let plant of plants" [value]="plant.id">{{plant.description |
                  translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Departament -->

          <div class="select-field" fxFlex="50%">
            <p class="label">{{labels.departaments | translate }}</p>
            <mat-form-field class="option-field" appearance="fill">
              <mat-select disableRipple [(value)]="user.department.id">
                <mat-option *ngFor="let department of departments" [value]="department.id">
                  {{ department.description | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>


      <ng-container class="select-preference">
        <p class="title"> {{labels.preferenceSelect | translate }}</p>
        <p class="label">{{labels.language | translate }}</p>
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" (change)="updateModel($event)"
          [value]="user.language">
          <mat-button-toggle [value]="language.id" class="languages-buttons" *ngFor="let language of languageList">
            {{language.label | translate}}</mat-button-toggle>
        </mat-button-toggle-group>
      </ng-container>

      <button mat-button color="primary" (click)="save()" [disabled]="!saveEnable()">{{labels.btnAccess | translate
        }}</button>
    </div>
  </div>
</div>