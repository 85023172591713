import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

// questa superflua assegnazione per via di un errore in console senza senso
// TS2339: Property 'userAgent' does not exist on type 'never'.
const nav = navigator as Navigator;
let hasTouchScreen = false;
if ('maxTouchPoints' in navigator) {
  hasTouchScreen = navigator.maxTouchPoints > 0;
  // } else if ('msMaxTouchPoints' in navigator) {
  //     hasTouchScreen = navigator.msMaxTouchPoints > 0;
} else {
  const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
  if (mQ && mQ.media === '(pointer:coarse)') {
    hasTouchScreen = !!mQ.matches;
  } else if ('orientation' in window) {
    hasTouchScreen = true; // deprecated, but good fallback
  } else if ('userAgent' in navigator) {
    // Only as a last resort, fall back to user agent sniffing
    const UA = nav?.userAgent;
    hasTouchScreen = /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
  }
}
if (hasTouchScreen) {
  document.getElementsByTagName('body')[0].className =
    document.getElementsByTagName('body')[0].className + ' rotate-for-portrait';
}
