<span class="notification-idea">
    <button mat-button [matMenuTriggerFor]="menu1" #t="matMenuTrigger" style="padding-right: 0px"
        (menuOpened)="menuOpened()">
        <mat-icon *ngIf="!!!countNotification" matBadgeSize="small" class="custom-badge" matBadgeColor="accent"
            svgIcon="profile"></mat-icon>
        <mat-icon [matBadge]="countNotification" *ngIf="!!countNotification" matBadgeSize="small" class="custom-badge"
            matBadgeColor="accent" svgIcon="profile">
        </mat-icon>

        <!-- <mat-icon *ngIf="!t.menuOpen" class="expand_icon">expand_more</mat-icon>
        <mat-icon *ngIf="t.menuOpen" class="expand_icon">expand_less</mat-icon> -->
    </button>
    <mat-menu #menu1="matMenu" xPosition="before" class="mat-notification-menu" style="max-height: auto !important;">
        <ng-container>
            <span class="notifications-title">
                <p>{{labels.title | translate}}</p>
                <mat-icon svgIcon="close"></mat-icon>
            </span>
            <div class="notifications-container" *ngIf="notifications?.length == 0">
                <span matBadgeOverlap="false" matBadgeColor="accent"></span>
                <div class="notification_base">
                    <span class="title" ng-style="{color:#454751 }"> {{labels.titleEmpty | translate}} </span>
                    <div class="notification-information">
                        <span class="date"> {{labels.descriptionEmpty | translate}}</span>
                    </div>
                </div>
            </div>
            <div class="notifications-container" *ngIf="notifications?.length != 0">
                <div class="notification_base" [ngClass]="!notification.checked ? 'notread' : 'read'"
                    *ngFor="let notification of notifications"
                    (click)="redirectToDetailIdea(notification.associatedElementId,notification.id)">
                    <span class="title">
                        {{ notification?.ideaTitle }}
                    </span>
                    <div class="notification-information">
                        <span class="date">
                            {{ notification.createDate| date:'mediumDate' }} . {{ notification.createDate|
                            date:'shortTime' }}
                        </span>
                        <span class="user">
                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24"
                                fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                <g fill="none" fill-rule="evenodd">
                                    <g>
                                        <g>
                                            <g>
                                                <path d="M0 0H24V24H0z"
                                                    transform="translate(-968 -40) translate(968 39) translate(0 1)">
                                                </path>
                                                <path fill="#000" fill-rule="nonzero"
                                                    d="M12 0c6.627 0 12 5.373 12 12 0 3.5-1.499 6.65-3.889 8.844-.045.074-.102.144-.17.207-.083.077-.175.137-.273.18C17.588 22.96 14.916 24 12 24c-2.908 0-5.574-1.034-7.651-2.755-.105-.044-.204-.107-.293-.188-.073-.068-.134-.144-.182-.226C1.493 18.638 0 15.493 0 12 0 5.373 5.373 0 12 0zm0 19c-2.255 0-4.303.505-5.61 1.28C7.99 21.367 9.922 22 12 22c2.078 0 4.008-.634 5.608-1.719C16.302 19.505 14.255 19 12 19zm0-17C6.477 2 2 6.477 2 12c0 2.695 1.066 5.142 2.8 6.94C6.464 17.72 9.116 17 12 17c2.884 0 5.536.721 7.2 1.94C20.935 17.141 22 14.694 22 12c0-5.523-4.477-10-10-10zm0 3c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zm0 2c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3z"
                                                    transform="translate(-968 -40) translate(968 39) translate(0 1)">
                                                </path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            {{ notification.createUser.fullName }}
                        </span>
                    </div>
                    <div class="user-action">
                        <span class="link"
                            *ngIf="notification.status!==3 && notification.status!==5 && notification.status!==0 && notification.status!==8">{{getAction(notification)
                            }}</span>
                    </div>
                </div>
            </div>
            <div class="user-action" *ngIf="notifications?.length != 0">
                <button (click)="markAllRead()">
                    <mat-icon>done</mat-icon> {{labels.markAllRead | translate}}
                </button>
            </div>
        </ng-container>
    </mat-menu>
</span>