import { PlantDTO, TimezoneDTO } from '@data/models/plant-dto';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV } from '@app/constants/global.constants';
import { Env } from '@app/types/env';
import { EdmsError } from '@app/types/error';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OperationCenterDTO } from '../models/operation-center-dto';
import { CurrencyDTO } from '@data/models/currency-dto';
import { DepartmentDTO } from '@data/models/department-dto';

@Injectable({ providedIn: 'root' })
export class OperationCenterService {
  public readonly SAVE_OPERATION_CENTER_PATH = '/api/admin/operationCenters/add';
  public readonly SAVE_OPERATION_CENTER_PATH_IDEA = '/api/v1/operationCenters';
  public readonly GET_TIMEZONES_PATH = '/api/v1/timezones';
  public readonly GET_CURRENCY = '/api/v1/currencies';
  constructor(@Inject(ENV) private env: Env, private http: HttpClient) {}

  public save(model: OperationCenterDTO): Observable<OperationCenterDTO> {
    return this.http
      .post<OperationCenterDTO>(`${this.env.apiBaseUrl}${this.SAVE_OPERATION_CENTER_PATH}`, model)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public saveIdea(model: OperationCenterDTO): Observable<OperationCenterDTO> {
    return this.http
      .post<OperationCenterDTO>(`${this.env.apiBaseUrlIdea}${this.SAVE_OPERATION_CENTER_PATH_IDEA}`, model)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getTimezones(): Observable<TimezoneDTO[]> {
    return this.http
      .get<TimezoneDTO[]>(`${this.env.apiBaseUrl}${this.GET_TIMEZONES_PATH}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getCurrency(): Observable<CurrencyDTO[]> {
    return this.http
      .get<CurrencyDTO[]>(`${this.env.apiBaseUrl}${this.GET_CURRENCY}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getOperationCenter(): Observable<OperationCenterDTO[]> {
    return this.http
      .get<OperationCenterDTO[]>(`${this.env.apiBaseUrlIdea}/registration/operationCenters`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getOperationCenterPlants(operationCenterId: number): Observable<PlantDTO[]> {
    return this.http
      .get<PlantDTO[]>(`${this.env.apiBaseUrlIdea}/registration/operationCenters/${operationCenterId}/plants`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getDepartmentsList(plantId: number): Observable<DepartmentDTO[]> {
    return this.http
      .get<DepartmentDTO[]>(`${this.env.apiBaseUrlIdea}/registration/plants/${plantId}/departments`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
}
