import { Component, HostBinding, OnInit } from '@angular/core';
import { EdmsError } from '@app/types/error';
import { OperationCenterService } from '@data/services/operation-center.service';
import { AdministrationService } from '@data/services/administration.service';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { DatePipe } from '@angular/common';
import { OperationCenterDTO } from '@data/models/operation-center-dto';
import { PlantDTO } from '@data/models/plant-dto';
import { DepartmentDTO } from '@data/models/department-dto';
import { LANGUAGES } from '@shared/constants/language.constants';
import { UserService } from '@data/services/user.service';
import { ArchiveChecklistService } from '@data/services/archive-checklist.service';
import { UsersService } from '@data/services/users.service';
import UserIdeaDTO from '@data/models/user-idea-dto';
import { IdeaPlantDTO } from '@data/models/idea-plant-dto';
import { RouteConstants } from '@app/constants/route.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/security/authentication.service';
import { PROJECT_ENUM } from '@app/constants/global.constants';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { UserFiltersService } from '@data/services/user-filters.service';
import { ProgressSpinnerDialogService } from '@shared/services/progress-spinner-dialog.service';
import { GlobalMessageService } from '@shared/services/global-message.service';
import { GLOBAL_MESSAGE_2SEC_DURATION } from '@shared/constants/global-message';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { AssetService } from '@data/services/asset.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-user-default-settings',
  templateUrl: './user-default-settings.component.html',
  styleUrls: ['./user-default-settings.component.scss']
})
export class UserDefaultSettingsComponent implements OnInit {
  @HostBinding('style')
  get myStyle(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(
      'flex-direction: column; box-sizing: border-box; display: flex; place-content: center; align-items: center; height:100%'
    );
  }
  public static readonly MODAL_ID = 'registration-idea-dialog-id';
  public static readonly MODAL_PANEL_CLASS = ['checklist-dialog', 'full-screen-modal', 'full-screen-modal-registration'];
  public labels = {
    // title: marker('idea.notifications.title'),
    businesUnit: marker('administration.operationCenter.supplieCenter'),
    departaments: marker('latestInformation.department'),
    language: marker('administration.usersDialog.language'),
    localLanguage: marker('administration.operationCenter.localLanguage'),
    plants: marker('administration.operationCenter.plants'),
    welcome: marker('administration.operationCenter.welcome'),
    ideApp: marker('administration.operationCenter.ideapp'),
    ebos: marker('administration.operationCenter.ebos'),
    workInfo: marker('administration.operationCenter.workInfo'),
    preferenceSelect: marker('administration.operationCenter.preferenceSelect'),
    choosePlant: marker('administration.operationCenter.choosePlant'),
    chooseLanguage: marker('administration.operationCenter.chooseLanguage'),
    chooseDepartment: marker('administration.operationCenter.chooseDepartment'),
    btnAccess: marker('administration.operationCenter.btnAccess')
  };
  error: EdmsError;
  operationData: OperationCenterDTO[];
  public user: UserIdeaDTO;

  public operationCenters: OperationCenterDTO[];
  public plants: PlantDTO[];
  public departments: DepartmentDTO[];
  public selectedLanguage = {} as { id: string; label: string };
  public languageList: { id: string; label: string }[] = [...LANGUAGES];
  constructor(
    public assetService: AssetService,
    private operationCenterService: OperationCenterService,
    private userService: UsersService,
    private spinnerService: ProgressSpinnerDialogService,
    private authenticationService: AuthenticationService,
    private userFiltersService: UserFiltersService,
    private sanitizer: DomSanitizer,
    private globalMessage: GlobalMessageService,
    private cookieService: CookieService,
    public dialogRef: MatDialogRef<UserDefaultSettingsComponent>
  ) {
    this.user = { uid: localStorage.getItem('uid_' + window.location.hostname) } as UserIdeaDTO;
    this.user.operationCenter = {} as OperationCenterDTO;
    this.user.plant = {} as IdeaPlantDTO;
    this.user.department = {} as DepartmentDTO;
  }

  ngOnInit(): void {
    this.getOperationCenter();
    // this.getOperationCenterPlants(1);
    //   this.getDepartmentsList(1);
  }

  public getOperationCenterPlants(operationCenterId: number): void {
    //  console.log(this.operationCenterService.getOperationCenterPlants(operationCenterId), 'chheck');
    this.operationCenterService.getOperationCenterPlants(operationCenterId).subscribe({
      next: (response: PlantDTO[]) => {
        this.plants = response ?? [];
        console.log(this.plants, 'this.plantsthis.plantsthis.plants');
      },
      error: (error: EdmsError) => {
        this.error = error;
      }
    });
  }

  public getDepartmentsList(plantId: number): void {
    console.log(this.operationCenterService, 'operationCenter service');
    this.operationCenterService.getDepartmentsList(plantId).subscribe({
      next: (response: PlantDTO[]) => {
        this.departments = response;
        console.log(response, this.departments, 'this.departmentsthis.departments');
      },
      error: (error: EdmsError) => {
        this.error = error;
      }
    });
  }
  public save() {
    const spinner = this.spinnerService.show();
    this.userService
      .saveUser(this.user)
      .pipe(
        finalize(() => {
          this.spinnerService.hide(spinner);
        })
      )
      .subscribe({
        next: (result) => {
          this.userFiltersService.setUser(result);
          this.authenticationService.storeUserProject(PROJECT_ENUM.idea);
          this.dialogRef.close();
        },
        error: (error) => {}
      });
  }
  public updateModel(event: MatButtonToggleChange): void {
    this.user.language = event.value;
  }

  public saveEnable(): boolean {
    return !!this.user.operationCenter.id && !!this.user.plant.id && !!this.user.department.id && !!this.user.language;
  }
  private getOperationCenter(): void {
    this.operationCenterService.getOperationCenter().subscribe({
      next: (response: OperationCenterDTO[]) => {
        this.operationData = response;
        console.log(this.operationData, 'this.operationData this.operationData this.operationData');
      },
      error: (error: EdmsError) => {
        this.globalMessage.showError({
          message: error.message,
          duration: GLOBAL_MESSAGE_2SEC_DURATION
        });
        this.error = error;
      }
    });
  }
}
