import { FillByDTO } from '@data/models/fill-by-dto';
import { HttpClient, HttpEvent, HttpSentEvent } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV } from '@app/constants/global.constants';
import { Env } from '@app/types/env';
import { EdmsError } from '@app/types/error';
import { DepartmentDTO } from '@data/models/department-dto';
import { EquipmentDTO } from '@data/models/equipment-dto';
import { FormatCompleteDTO } from '@data/models/format-complete-dto';
import { FormatDTO } from '@data/models/format-dto';
import { FunctionalLocationDTO } from '@data/models/functional-location-dto';
import { OperationCenterDTO } from '@data/models/operation-center-dto';
import { PlantDTO } from '@data/models/plant-dto';
import { ProductEquipmentDTO } from '@data/models/product-equipment-dto';
import { ShiftDTO } from '@data/models/shift-dto';
import { NewSourceDTO, SourceDTO } from '@data/models/source-dto';
import UserDTO from '@data/models/user-dto';
import { WorkingAreaDTO } from '@data/models/workingarea-dto';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ChecklistDTO, ChecklistGroupDTO, YourChecklistDTO } from '@data/models/checklist-dto';
import { PageableDTO } from '@data/models/pageable-dto';
import { FillByItemDTO } from '@data/models/fill-by-item-dto';
import { CheckListItemDTO } from '@data/models/checklist-item-dto';
import { ChecklistSummaryDTO } from '@data/models/checklist-summary-dto';
import { CalendarStopDTO } from '@data/models/calendar-stop-dto';
import { ShiftSopCalendarDTO } from '@data/models/shift-stop-calendar-dto';
import { UserADResponse } from '@data/models/user-AD';
import UserIdeaDTO from '@data/models/user-idea-dto';
import IdeaUserDTO from '@data/models/idea-user-dto';
import { AppDetailDTO } from '@data/models/app-detail-dto';
import { RetentionPeriodsDTO } from '@data/models/retention-periods-dto';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {
  public readonly OPERATION_CENTERS_PATH = '/api/admin/operationCenters';
  public readonly OPERATION_CENTERS_IDEA = '/api/admin/operationCenters/ideApp?associated=false';
  public readonly OPERATION_CENTERS_EBOS = '/api/admin/operationCenters/ideApp?associated=true';
  public readonly APPBYPLANTIDEA = '/api/v1/admin/apps/plants/';
  public readonly APPBYOCIDEA = '/api/v1/admin/apps/operationCenters/';

  public readonly OPERATION_CENTER_DETAILS = '/api/admin/operationCenters/';
  public readonly OPERATION_CENTER_DETAILS_MINI = '/api/admin/operationCenters/mini?operationCenterId=';

  public readonly DISABLE_DEPARTMENT_PATH = '/api/admin/departments/disable/';
  public readonly ENABLE_DEPARTMENT_PATH = '/api/admin/departments/enable/';
  public readonly DELETE_DEPARTMENT_PATH = '/api/admin/departments/delete/';

  public readonly DISABLE_PLANT_PATH = '/api/admin/plants/disable/';
  public readonly ENABLE_PLANT_PATH = '/api/admin/plants/enable/';
  public readonly DELETE_PLANT_PATH = '/api/admin/plants/delete/';

  public readonly DISABLE_OPERATION_CENTER_PATH = '/api/admin/operationCenters/disable/';
  public readonly ENABLE_OPERATION_CENTER_PATH = '/api/admin/operationCenters/enable/';
  public readonly DELETE_OPERATION_CENTER_PATH = '/api/admin/operationCenters/delete/';

  public readonly USERS_BY_DEPARTMENT_ID_PATH = '/api/admin/departments/users/';
  public readonly USERS_BY_FILTER_PATH = '/api/users/allUsersLDAP/';
  public readonly USERS_ENABLE_PATH = '/api/users/enable/';
  public readonly USERS_DISABLE_PATH = '/api/users/disable/';
  public readonly USERS_EDIT_PATH = '/api/users/';
  public readonly USERS_IDEA_EDIT_PATH = '/api/v1/users/';
  public readonly USERS_ADD_PATH = '/api/users/save';
  public readonly USERS_HIERARCHY = '/api/users/hierarchy/';
  public readonly USERS_DELETE_PATH = '/api/users/delete/';

  public readonly PLANTS_BY_OPERATION_CENTER_PATH = '/api/admin/plants/list/';
  public readonly PLANT_DETAILS_PATH = '/api/admin/plants/detail/';

  public readonly WORKING_AREAS_DETAIL = '/api/admin/workingAreas/';
  public readonly WORKING_AREAS_BY_DEPARTMENT_ID_PATH = '/api/admin/workingAreas/list/';

  public readonly EQUIPMENT_DETAIL = '/api/admin/equipments/detail/';

  public readonly EDIT_WORKING_AREA_PATH = '/api/admin/workingAreas/edit/';
  public readonly EDIT_EQUIPMENT_PATH = '/api/admin/equipments/edit/';

  public readonly SAVE_WORKING_AREA_PATH = '/api/admin/workingAreas/add/';
  public readonly SAVE_EQUIPMENT_PATH = '/api/admin/equipments/add/';

  public readonly DELETE_WORKING_AREA_PATH = '/api/admin/workingAreas/delete/';
  public readonly DELETE_EQUIPMENT_PATH = '/api/admin/equipments/delete/';

  public readonly ENABLE_WORKING_AREA_PATH = '/api/admin/workingAreas/enable/';
  public readonly DISABLE_WORKING_AREA_PATH = '/api/admin/workingAreas/disable/';

  public readonly ENABLE_EQUIPMENT_PATH = '/api/admin/equipments/enable/';
  public readonly DISABLE_EQUIPMENT_PATH = '/api/admin/equipments/disable/';

  public readonly SAVE_WORKING_AREA_FORMAT = '/api/admin/formats/save/workingAreaFormat';
  public readonly SAVE_EQUIPMENT_FORMAT = '/api/admin/formats/save/equipmentFormat';

  public readonly EDIT_WORKING_AREA_FORMAT = '/api/admin/formats/edit/workingAreaFormat';
  public readonly EDIT_EQUIPMENT_FORMAT = '/api/admin/formats/edit/equipmentFormat';

  public readonly DELETE_FORMAT = '/api/admin/formats/';
  public readonly GET_PRODUCTS_EQUIPMENTS = '/api/sapIntegrations/products/roots/';
  public readonly GET_PRODUCTS_EQUIPMENTS_HIERARCHY = '/api/sapIntegrations/products/hierarchy/';

  public readonly FORMATS_BY_PLANT_ID_PATH = '/api/admin/formats/';
  public readonly FORMATS_BY_OPERATION_CENTER_ID_PATH = '/api/admin/formats/';

  public readonly FUNCTIONAL_LOCATIONS_BY_CODE_PATH = '/api/admin/functionalLocations/locations';

  public readonly SAVE_SHIFTS_PATH = '/api/admin/shifts/save';

  public readonly SEARCH_CHECKLIST = '/api/v1/admin/checklists/_search';
  public readonly SEARCH_CHECKLIST_EBOS = '/api/v1/admin/checklists/byApp/_search';
  public readonly SEARCH_CHECKLIST_EBOS_YC = '/api/v1/admin/checklists/_search';

  public readonly SEARCH_CHECKLIST_GROUP = '/api/v1/admin/checklistGroups/_search';

  public readonly BASE_SOURCES_PATH = '/api/admin/plants/';

  public readonly GET_CHECKLIST_BY_DEPARTMENT = '/api/v1/admin/checklists/summary';

  public readonly GET_CHECKLIST_ITEMS = '/api/v1/admin/checklists/items';
  public readonly ENABLE_SHIFT = '/api/admin/shifts/enable/';
  public readonly DISABLE_SHIFT = '/api/admin/shifts/disable/';

  public readonly GET_CALENDAR_STOPS = '/api/admin/calendar/operationCenter/';
  public readonly DELETE_CALENDAR_STOPS = '/api/admin/calendar/delete/';
  public readonly CREATE_CALENDAR_STOPS = '/api/admin/calendar';

  public readonly GET_RETENTION_PERIODS = '/api/admin/plants/';
  public readonly SET_RETENTION_PERIODS = '/api/admin/plants/';
  public readonly DELETE_CHECKLIST_FROM_DATE = '/api/admin/plants/';

  public readonly SEND_WEEKLY_PLANNING = '/api/admin/weeklyPlanning/add';
  public readonly GET_SHIFT_PER_WEEK = '/api/admin/weeklyPlanning/plant/';
  public readonly GET_SHIFT_LIST_WEEKLY = '/api/admin/shifts/list/';
  public readonly UPLOAD_SAP_EXCEL_FILE = '/api/sapIntegrations/locations/excel';
  public readonly DOWNLOAD_SAP_EXCEL_FILE = '/api/export/functionalLocationsTemplate';

  public readonly ENABLE_DISABLE_CK = '/api/v1/admin/checklists/';

  public readonly GRAPHS = '/api/users/BacardiAD/';
  private datepipe: DatePipe;
  constructor(@Inject(ENV) private env: Env, private http: HttpClient) {}

  // Operation Centers
  public getAllOperationCenters(): Observable<OperationCenterDTO[]> {
    return this.http
      .get<OperationCenterDTO[]>(`${this.env.apiBaseUrl}${this.OPERATION_CENTERS_PATH}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getOperationCentersIdea(): Observable<OperationCenterDTO[]> {
    return this.http
      .get<OperationCenterDTO[]>(`${this.env.apiBaseUrl}${this.OPERATION_CENTERS_IDEA}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getOperationCentersEbos(): Observable<OperationCenterDTO[]> {
    return this.http
      .get<OperationCenterDTO[]>(`${this.env.apiBaseUrl}${this.OPERATION_CENTERS_EBOS}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getAppIdeaByPlant(plantId: number): Observable<AppDetailDTO> {
    return this.http
      .get<AppDetailDTO>(`${this.env.apiBaseUrl}${this.APPBYPLANTIDEA}${plantId}/ideaApp`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getAppIdeaByOC(operationCenterId: number): Observable<AppDetailDTO[]> {
    return this.http
      .get<AppDetailDTO[]>(`${this.env.apiBaseUrl}${this.APPBYOCIDEA}${operationCenterId}/ideaApp`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getOperationCenterDetails(operationCenterId: number): Observable<OperationCenterDTO> {
    return this.http
      .get<OperationCenterDTO>(`${this.env.apiBaseUrl}${this.OPERATION_CENTER_DETAILS}${operationCenterId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getOperationCenterDetailsMini(operationCenterId: number): Observable<OperationCenterDTO[]> {
    return this.http
      .get<OperationCenterDTO[]>(`${this.env.apiBaseUrl}${this.OPERATION_CENTER_DETAILS_MINI}${operationCenterId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public enableOperationCenterById(operationCenterId: number): Observable<void> {
    const body = {};

    return this.http
      .put<void>(`${this.env.apiBaseUrl}${this.ENABLE_OPERATION_CENTER_PATH}${operationCenterId}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public disableOperationCenterById(operationCenterId: number, users?: UserDTO[]): Observable<void> {
    const body: UserDTO[] = users ? users : [];

    return this.http
      .put<void>(`${this.env.apiBaseUrl}${this.DISABLE_OPERATION_CENTER_PATH}${operationCenterId}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public deleteOperationCenterById(operationCenterId: number): Observable<void> {
    const body = {};

    return this.http
      .delete<void>(`${this.env.apiBaseUrl}${this.DELETE_OPERATION_CENTER_PATH}${operationCenterId}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  // Departments
  public disableDepartmentById(departmentId: number, users?: UserDTO[]): Observable<void> {
    const body: UserDTO[] = users ? users : [];

    return this.http
      .put<void>(`${this.env.apiBaseUrl}${this.DISABLE_DEPARTMENT_PATH}${departmentId}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public enableDepartmentById(departmentId: number): Observable<void> {
    const body = {};

    return this.http
      .put<void>(`${this.env.apiBaseUrl}${this.ENABLE_DEPARTMENT_PATH}${departmentId}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public deleteDepartmentById(departmentId: number): Observable<void> {
    const body = {};

    return this.http
      .delete<void>(`${this.env.apiBaseUrl}${this.DELETE_DEPARTMENT_PATH}${departmentId}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  // Users
  public getUsersByDepartmentId(departmentId: number): Observable<UserDTO[]> {
    return this.http
      .get<UserDTO[]>(`${this.env.apiBaseUrl}${this.USERS_BY_DEPARTMENT_ID_PATH}${departmentId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getUserIdeaDetails(userId: number): Observable<IdeaUserDTO> {
    return this.http
      .get<IdeaUserDTO>(`${this.env.apiBaseUrlIdea}${this.USERS_IDEA_EDIT_PATH}${userId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getUserHierarchy(userId: number): Observable<UserDTO> {
    return this.http
      .get<UserDTO>(`${this.env.apiBaseUrl}${this.USERS_HIERARCHY}${userId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getUsersAutocomplete(filter: string): Observable<UserDTO[]> {
    return this.http
      .get<UserDTO[]>(`${this.env.apiBaseUrl}${this.USERS_BY_FILTER_PATH}${filter}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public enableUserById(userId: number): Observable<void> {
    const body = {};
    return this.http
      .put<void>(`${this.env.apiBaseUrl}${this.USERS_ENABLE_PATH}${userId}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public disableUserById(userId: number): Observable<void> {
    const body = {};
    return this.http
      .put<void>(`${this.env.apiBaseUrl}${this.USERS_DISABLE_PATH}${userId}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public addUser(users: UserDTO[], operationCenters: OperationCenterDTO[]): Observable<void> {
    const body = { users, operationCenters };
    return this.http
      .post<void>(`${this.env.apiBaseUrl}${this.USERS_ADD_PATH}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public editUserIdea(
    user: UserIdeaDTO,
    data: {
      title?: string;
      model: {
        users: UserDTO[];
        operationCenters: OperationCenterDTO[];
      };
      forceLastStep: boolean;
      operationCenterMini: OperationCenterDTO;
      selectedPlantId: number;
      selectedDepartmentId: number;
      selectedOperationCenterId: number;
      selectedEquipment: EquipmentDTO[];
      editData: UserDTO;
      isNew: boolean;
    }
  ): Observable<void> {
    if (data.editData && data.editData.project === 'ideApp') {
      user.department.id = data.selectedDepartmentId;
      user.operationCenter.id = data.selectedOperationCenterId;
      user.plant.id = data.selectedPlantId;
    }
    const url = `${this.env.apiBaseUrlIdea}${this.USERS_IDEA_EDIT_PATH}/${user.id}`;
    return this.http.patch<void>(url, user).pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public editUser(users: UserDTO[], operationCenters: OperationCenterDTO[]): Observable<void> {
    const body = { users, operationCenters };
    const url = `${this.env.apiBaseUrl}${this.USERS_EDIT_PATH}`;
    return this.http.put<void>(url, body).pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public deleteUserById(userId: number): Observable<void> {
    const body = {};
    return this.http
      .put<void>(`${this.env.apiBaseUrl}${this.USERS_DELETE_PATH}${userId}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public deleteUserIdeaById(userId: number): Observable<void> {
    return this.http
      .delete<void>(`${this.env.apiBaseUrlIdea}${this.USERS_IDEA_EDIT_PATH}/${userId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  // Plants
  public enablePlantById(plantId: number): Observable<void> {
    const body = {};

    return this.http
      .put<void>(`${this.env.apiBaseUrl}${this.ENABLE_PLANT_PATH}${plantId}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public disablePlantById(plantId: number, users?: UserDTO[]): Observable<void> {
    const body: UserDTO[] = users ? users : [];

    return this.http
      .put<void>(`${this.env.apiBaseUrl}${this.DISABLE_PLANT_PATH}${plantId}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getPlantsByOperationCenter(operationCenterId: number): Observable<PlantDTO[]> {
    return this.http
      .get<PlantDTO[]>(`${this.env.apiBaseUrl}${this.PLANTS_BY_OPERATION_CENTER_PATH}${operationCenterId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getPlantsByOperationCenterIdea(operationCenterId: number): Observable<PlantDTO[]> {
    return this.http
      .get<PlantDTO[]>(`${this.env.apiBaseUrl}/api/admin/plants/${operationCenterId}/ideApp?associated=false`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getPlantDetails(plantId: number): Observable<PlantDTO> {
    return this.http
      .get<PlantDTO>(`${this.env.apiBaseUrl}${this.PLANT_DETAILS_PATH}${plantId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public deletePlantById(plantId: number): Observable<void> {
    const body = {};

    return this.http
      .delete<void>(`${this.env.apiBaseUrl}${this.DELETE_PLANT_PATH}${plantId}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  // Working Areas
  public getWorkingAreasByDepartmentId(departmentId: number): Observable<DepartmentDTO> {
    return this.http
      .get<DepartmentDTO>(`${this.env.apiBaseUrl}${this.WORKING_AREAS_BY_DEPARTMENT_ID_PATH}${departmentId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getWorkingAreaDetailById(workingAreaId: number): Observable<WorkingAreaDTO> {
    return this.http
      .get<WorkingAreaDTO>(`${this.env.apiBaseUrl}${this.WORKING_AREAS_DETAIL}${workingAreaId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public editWorkingArea(model: WorkingAreaDTO): Observable<WorkingAreaDTO> {
    return this.http
      .post<WorkingAreaDTO>(`${this.env.apiBaseUrl}${this.EDIT_WORKING_AREA_PATH}`, model)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public saveWorkingArea(model: WorkingAreaDTO): Observable<WorkingAreaDTO> {
    return this.http
      .post<WorkingAreaDTO>(`${this.env.apiBaseUrl}${this.SAVE_WORKING_AREA_PATH}`, model)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public enableWorkingAreaById(workingAreaId: number): Observable<void> {
    const body = {};

    return this.http
      .put<void>(`${this.env.apiBaseUrl}${this.ENABLE_WORKING_AREA_PATH}${workingAreaId}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public disableWorkingAreaById(workingAreaId: number, users?: UserDTO[]): Observable<void> {
    const body: UserDTO[] = users ? users : [];

    return this.http
      .put<void>(`${this.env.apiBaseUrl}${this.DISABLE_WORKING_AREA_PATH}${workingAreaId}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public deleteWorkingAreaById(workingAreaId: number): Observable<void> {
    const body = {};

    return this.http
      .delete<void>(`${this.env.apiBaseUrl}${this.DELETE_WORKING_AREA_PATH}${workingAreaId}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  // Equipment
  public editEquipment(model: EquipmentDTO): Observable<EquipmentDTO> {
    return this.http
      .post<EquipmentDTO>(`${this.env.apiBaseUrl}${this.EDIT_EQUIPMENT_PATH}`, model)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getEquipmentDetailById(equipmentId: number): Observable<EquipmentDTO> {
    return this.http
      .get<WorkingAreaDTO>(`${this.env.apiBaseUrl}${this.EQUIPMENT_DETAIL}${equipmentId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public saveEquipment(model: EquipmentDTO): Observable<EquipmentDTO> {
    return this.http
      .post<EquipmentDTO>(`${this.env.apiBaseUrl}${this.SAVE_EQUIPMENT_PATH}`, model)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public enableEquipmentById(equipmentId: number): Observable<void> {
    const body = {};

    return this.http
      .put<void>(`${this.env.apiBaseUrl}${this.ENABLE_EQUIPMENT_PATH}${equipmentId}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public disableEquipmentById(equipmentId: number, users?: UserDTO[]): Observable<void> {
    const body: UserDTO[] = users ? users : [];

    return this.http
      .put<void>(`${this.env.apiBaseUrl}${this.DISABLE_EQUIPMENT_PATH}${equipmentId}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public deleteEquipmentById(equipmentId: number): Observable<void> {
    const body = {};

    return this.http
      .delete<void>(`${this.env.apiBaseUrl}${this.DELETE_EQUIPMENT_PATH}${equipmentId}`, body)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  // Formats
  public saveWorkingAreaFormat(format: FormatCompleteDTO): Observable<void> {
    return this.http
      .post<void>(`${this.env.apiBaseUrl}${this.SAVE_WORKING_AREA_FORMAT}`, format)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public saveEquipmentFormat(format: FormatCompleteDTO): Observable<void> {
    return this.http
      .post<void>(`${this.env.apiBaseUrl}${this.SAVE_EQUIPMENT_FORMAT}`, format)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public editWorkingAreaFormat(format: FormatCompleteDTO): Observable<void> {
    return this.http
      .put<void>(`${this.env.apiBaseUrl}${this.EDIT_WORKING_AREA_FORMAT}`, format)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public editEquipmentFormat(format: FormatCompleteDTO): Observable<void> {
    return this.http
      .put<void>(`${this.env.apiBaseUrl}${this.EDIT_EQUIPMENT_FORMAT}`, format)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public deleteFormat(formatId: number): Observable<FormatDTO> {
    return this.http
      .delete<FormatDTO>(`${this.env.apiBaseUrl}${this.DELETE_FORMAT}${formatId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getSAPproducts(): Observable<ProductEquipmentDTO[]> {
    return this.http
      .get<ProductEquipmentDTO[]>(`${this.env.apiBaseUrl}${this.GET_PRODUCTS_EQUIPMENTS}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getSAPproductsHierarchy(sapId: number): Observable<ProductEquipmentDTO> {
    return this.http
      .get<ProductEquipmentDTO>(`${this.env.apiBaseUrl}${this.GET_PRODUCTS_EQUIPMENTS_HIERARCHY}${sapId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getFormatsByPlantId(
    plantId: number
  ): Observable<{ equipmentFormats: FormatCompleteDTO[]; workingAreasFormat: FormatCompleteDTO[] }> {
    return this.http
      .get<{ equipmentFormats: FormatCompleteDTO[]; workingAreasFormat: FormatCompleteDTO[] }>(
        `${this.env.apiBaseUrl}${this.FORMATS_BY_PLANT_ID_PATH}?plantId=${plantId}`
      )
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getFormatsByOperationCenterId(
    plantId: number
  ): Observable<{ equipmentFormats: FormatCompleteDTO[]; workingAreasFormat: FormatCompleteDTO[] }> {
    return this.http
      .get<{ equipmentFormats: FormatCompleteDTO[]; workingAreasFormat: FormatCompleteDTO[] }>(
        `${this.env.apiBaseUrl}${this.FORMATS_BY_OPERATION_CENTER_ID_PATH}?operationCenterId=${plantId}`
      )
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  // Functional Locations
  public getFunctionalLocationsByCode(code: string, associated: boolean = false): Observable<FunctionalLocationDTO[]> {
    return this.http
      .get<FunctionalLocationDTO[]>(
        `${this.env.apiBaseUrl}${this.FUNCTIONAL_LOCATIONS_BY_CODE_PATH}?flNumber=${code}&getAssociated=${associated}`
      )
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  // Shifts
  public saveShifts(shifts: { shifts: ShiftDTO[][]; workingAreas: number[] }): Observable<EquipmentDTO> {
    return this.http
      .post<EquipmentDTO>(`${this.env.apiBaseUrl}${this.SAVE_SHIFTS_PATH}`, shifts)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public enableShift() {}
  public disableShift() {}

  // Sources
  public getSourcesByPlantId(plantID: number, recent: boolean): Observable<NewSourceDTO> {
    return this.http
      .get<NewSourceDTO>(
        `${this.env.apiBaseUrl}${this.BASE_SOURCES_PATH}${plantID}/sources`
      ) /* ?recent=${recent}  commentato per edms-725*/
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public addSourcesByPlantId(plantID: number, sources: string[]): Observable<SourceDTO> {
    return this.http
      .post<SourceDTO>(`${this.env.apiBaseUrl}${this.BASE_SOURCES_PATH}${plantID}/sources`, sources)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public deleteSourceByPlantId(plantId: number, sourceId: number): Observable<void> {
    return this.http
      .delete<void>(`${this.env.apiBaseUrl}${this.BASE_SOURCES_PATH}${plantId}/sources/${sourceId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public editSourceByPlantId(plantId: number, source: SourceDTO): Observable<SourceDTO> {
    return this.http
      .put<SourceDTO>(`${this.env.apiBaseUrl}${this.BASE_SOURCES_PATH}${plantId}/sources/${source.id}`, source)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public searchChecklists(
    filledBy: FillByDTO,
    completed: boolean,
    appId?: number,
    appSchedule?: string, // eachShift, daily, weekly, monthly, quarterly, annually
    page?: number,
    size?: number
  ): Observable<PageableDTO<ChecklistDTO>> {
    let url = `${this.env.apiBaseUrl}${this.SEARCH_CHECKLIST}?completed=${completed}`;
    if (appId) {
      url += `&appId=${appId}`;
    }
    if (appSchedule) {
      url += `&appSchedule=${appSchedule}`;
    }
    if (page >= 0 && size) {
      url += `&page=${page}&size=${size}`;
    }
    return this.http.post<PageableDTO<ChecklistDTO>>(url, filledBy).pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public searchChecklistsForEbos(
    filledBy: FillByDTO,
    completed: boolean,
    appId?: number,
    appSchedule?: string, // eachShift, daily, weekly, monthly, quarterly, annually
    page?: number,
    size?: number
  ): Observable<PageableDTO<ChecklistDTO>> {
    let url = `${this.env.apiBaseUrl}${this.SEARCH_CHECKLIST_EBOS}?completed=${completed}`;
    if (appId) {
      url += `&appId=${appId}`;
    }

    if (page >= 0 && size) {
      url += `&page=${page}&size=${size}`;
    }
    return this.http.get<PageableDTO<ChecklistDTO>>(url).pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public searchChecklistsForEbosYourChecklist(
    filledBy: FillByDTO,
    completed: boolean,
    appId?: number,
    appSchedule?: string, // eachShift, daily, weekly, monthly, quarterly, annually
    page?: number,
    size?: number
  ): Observable<YourChecklistDTO> {
    let url = `${this.env.apiBaseUrl}${this.SEARCH_CHECKLIST_EBOS_YC}?completed=${completed}`;
    if (appSchedule) {
      url += `&appSchedule=${appSchedule}`;
    }
    return this.http.get<YourChecklistDTO>(url).pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public searchChecklistsGroup(
    filledBy: FillByDTO,
    completed: boolean,
    appId?: number,
    appSchedule?: string, // eachShift, daily, weekly, monthly, quarterly, annually
    page?: number,
    size?: number
  ): Observable<PageableDTO<ChecklistGroupDTO>> {
    let url = `${this.env.apiBaseUrl}${this.SEARCH_CHECKLIST_GROUP}?completed=${completed}`;
    if (appId) {
      url += `&appId=${appId}`;
    }
    if (appSchedule) {
      url += `&appSchedule=${appSchedule}`;
    }
    if (page >= 0 && size) {
      url += `&page=${page}&size=${size}`;
    }
    return this.http
      .post<PageableDTO<ChecklistGroupDTO>>(url, filledBy)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getChecklistByDepartment(filledBy: FillByDTO): Observable<FillByItemDTO[]> {
    return this.http
      .post<FillByItemDTO[]>(`${this.env.apiBaseUrl}${this.GET_CHECKLIST_BY_DEPARTMENT}`, filledBy)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getChecklistByDep(filledBy: FillByDTO): Observable<ChecklistSummaryDTO[]> {
    return this.http
      .post<ChecklistSummaryDTO[]>(`${this.env.apiBaseUrl}${this.GET_CHECKLIST_BY_DEPARTMENT}`, filledBy)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getChecklistItems(filledBy: FillByDTO, page?: number, pageSize?: number): Observable<PageableDTO<CheckListItemDTO[]>> {
    let url = `${this.env.apiBaseUrl}${this.GET_CHECKLIST_ITEMS}`;
    if (page >= 0) {
      url += `?page=${page}&size=${pageSize}`;
    }
    return this.http
      .post<PageableDTO<CheckListItemDTO[]>>(url, filledBy)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  //calendar section
  public getCalendarStops(operationCenterId: number): Observable<CalendarStopDTO[]> {
    return this.http
      .get<CalendarStopDTO[]>(
        `${this.env.apiBaseUrl}${this.GET_CALENDAR_STOPS}${operationCenterId}?year=${new Date().getFullYear()}`
      )
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getCalendarStopsPerWeek(operationCenterId: number, week: number, currentWeek: number): Observable<CalendarStopDTO[]> {
    let year = new Date().getFullYear();
    if (currentWeek < 6) {
      if (week > currentWeek + 6) {
        year = year - 1;
      }
    }
    return this.http
      .get<CalendarStopDTO[]>(`${this.env.apiBaseUrl}${this.GET_CALENDAR_STOPS}${operationCenterId}?year=${year}&week=${week}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public deleteCalendarStop(calendarId: number): Observable<void> {
    return this.http
      .patch<void>(`${this.env.apiBaseUrl}${this.DELETE_CALENDAR_STOPS}${calendarId}`, {})
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public createCalendarStop(calendar: CalendarStopDTO): Observable<void> {
    return this.http
      .post<void>(`${this.env.apiBaseUrl}${this.CREATE_CALENDAR_STOPS}`, calendar)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  //weekly planning
  public sendWeeklyPlanning(shiftStops: ShiftSopCalendarDTO[]): Observable<void> {
    return this.http
      .post<void>(`${this.env.apiBaseUrl}${this.SEND_WEEKLY_PLANNING}`, shiftStops)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getShiftChange(week: number, plantId: number): Observable<PlantDTO> {
    return this.http
      .get<PlantDTO>(
        `${this.env.apiBaseUrl}${this.GET_SHIFT_PER_WEEK}${plantId}/shiftPlan/?week=${week}&year=${new Date().getFullYear()}`
      )
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public getShiftList(week: number, workingAreaId: number, year: number): Observable<ShiftDTO[][]> {
    return this.http
      .get<ShiftDTO[][]>(
        `${this.env.apiBaseUrl}${this.GET_SHIFT_LIST_WEEKLY}?year=${year}&workingAreaId=${workingAreaId}&week=${week}`
      )
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  //upload file excel
  public uploadFileExcelSap(file: FormData): Observable<HttpEvent<void>> {
    return this.http
      .post<void>(`${this.env.apiBaseUrl}${this.UPLOAD_SAP_EXCEL_FILE}`, file, {
        reportProgress: true,
        observe: 'events'
      })
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public downloadFileExcelSap(): Observable<Blob> {
    return this.http
      .get(`${this.env.apiBaseUrl}${this.DOWNLOAD_SAP_EXCEL_FILE}`, { responseType: 'blob' })
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getPeriods(id: number): Observable<RetentionPeriodsDTO> {
    return this.http
      .get<RetentionPeriodsDTO>(`${this.env.apiBaseUrl}${this.GET_RETENTION_PERIODS}${id}/retention`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public setPeriods(id: number, retention: RetentionPeriodsDTO): Observable<RetentionPeriodsDTO> {
    return this.http
      .put<RetentionPeriodsDTO>(`${this.env.apiBaseUrl}${this.GET_RETENTION_PERIODS}${id}/retention`, retention)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public deleteChecklistFromDate(id: number, date: string, appId: number): Observable<void> {
    return this.http
      .post<void>(
        `${this.env.apiBaseUrl}${this.DELETE_CHECKLIST_FROM_DATE}${id}/deleteChecklists?appId=${appId}&date=${date}`,
        {}
      )
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public getUser(filter: string): Observable<UserADResponse> {
    return this.http
      .get<UserADResponse>(
        /*eslint-disable max-len*/
        `${this.env.apiBaseUrl}${this.GRAPHS}${filter}`
      )
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  //enable disable cil cl checklist

  public enableChecklist(id: number): Observable<void> {
    return this.http
      .patch<void>(`${this.env.apiBaseUrl}${this.ENABLE_DISABLE_CK}${id}/enable`, {})
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public disableChecklist(id: number): Observable<void> {
    return this.http
      .patch<void>(`${this.env.apiBaseUrl}${this.ENABLE_DISABLE_CK}${id}/disable`, {})
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
}
