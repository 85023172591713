import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { LogoutButtonComponent } from './dashboard-layout/components/logout-button/logout-button.component';
import { UserFiltersComponent } from './dashboard-layout/components/user-filters/user-filters.component';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { DashboardIdeaLayoutComponent } from './dashboard-idea-layout/dashboard-idea-layout.component';
import { LogoutIdeaButtonComponent } from './dashboard-idea-layout/components/logout-idea-button/logout-idea-button.component';
import { NotificationIdeaComponent } from './dashboard-idea-layout/components/notification-idea/notification-idea.component';
import { UserFiltersIdeaComponent } from './dashboard-idea-layout/components/user-filters-idea/user-filters-idea.component';
import { DashboardIdeaMobileLayoutComponent } from './dashboard-idea-mobile-layout/dashboard-idea-mobile-layout.component';

@NgModule({
  declarations: [
    DefaultLayoutComponent,
    DashboardLayoutComponent,
    LogoutButtonComponent,
    UserFiltersComponent,
    DashboardIdeaLayoutComponent,
    LogoutIdeaButtonComponent,
    NotificationIdeaComponent,
    UserFiltersIdeaComponent,
    DashboardIdeaMobileLayoutComponent
  ],
  imports: [RouterModule, SharedModule],
  exports: [DefaultLayoutComponent, DashboardLayoutComponent, DashboardIdeaLayoutComponent, DashboardIdeaMobileLayoutComponent],
  providers: []
})
export class LayoutModule {}
