<div #target></div>
<form autocomplete="off" class="information-component__form" *ngIf="formGroup" [formGroup]="formGroup">
    <div fxLayout="column" fxLayoutAlign="space-evenly stretch">
        <!-- Priority -->
        <ng-container *ngIf="!isMetricsDDS">
            <mat-label>{{ labels.priority | translate }}</mat-label>
            <mat-button-toggle-group [formControl]="priorityControl" (change)="updateModel()">
                <mat-button-toggle
                    *ngFor="let priority of priorityList"
                    [value]="priority.id"
                    [ngClass]="{
                        'mat-button-toggle__high': priority.id === 3,
                        'mat-button-toggle__medium': priority.id === 2,
                        'mat-button-toggle__low': priority.id === 1
                    }"
                    >{{ priority.literal.translateValue }}</mat-button-toggle
                >
            </mat-button-toggle-group>
            <mat-error class="error" *ngIf="priorityControl.touched && priorityControl.invalid">{{
                labels.requiredError | translate
            }}</mat-error>
        </ng-container>
        <!-- Action type -->
        <div *ngIf="actionTypeList && actionTypeList.length > 1">
            <mat-label>{{ labels.actionType | translate }}</mat-label>
            <mat-button-toggle-group [formControl]="actionTypeControl" (change)="updateModel()">
                <mat-button-toggle *ngFor="let actionType of actionTypeList" [value]="actionType.id">{{
                    actionType.literal.translateValue
                }}</mat-button-toggle>
            </mat-button-toggle-group>
            <mat-error class="error" *ngIf="actionTypeControl.touched && actionTypeControl.invalid">{{
                labels.requiredError | translate
            }}</mat-error>
        </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="space-evenly stretch" class="planning-component__form">
        <!-- Owner -->
        <mat-label *ngIf="CkType !== 'CL'"
            >{{ labels.actionOwner | translate }}
            <span *ngIf="type !== 'DS'" class="muted">({{ labels.optional | translate | lowercase }})</span></mat-label
        >
        <mat-form-field class="autocomplete-full-width" [floatLabel]="'never'" *ngIf="CkType !== 'CL'">
            <!--  [ngClass]="{ invalid: actionOwnerControl.touched && actionOwnerControl.invalid }" -->

            <mat-select
                class="select-owner"
                placeholder="Select owners"
                [formControl]="actionOwnerControl"
                multiple
                (focus)="sort()"
                (openedChange)="searchString?.setValue('')"
            >
                <mat-form-field class="owners__search mat-form-field-rounded" style="width: 90%" appearance="standard">
                    <input
                        type="text"
                        (keydown.Space)="searchString?.setValue(searchString?.value + ' '); $event.preventDefault()"
                        matInput
                        [formControl]="searchString"
                        placeholder="{{ labels.chooseUser | translate }}"
                    />
                    <mat-icon matPrefix fontSet="edms">bold-basics-search-search-alternate</mat-icon>
                    <mat-icon
                        matSuffix
                        *ngIf="searchString?.value"
                        svgIcon="close"
                        (click)="searchString?.setValue('')"
                    ></mat-icon>
                </mat-form-field>

                <ng-container>
                    <mat-option
                        (click)="setUser(user); sort()"
                        [value]="user"
                        *ngFor="let user of filteredUsers | filterUser : searchString.value; let i = index"
                        >{{ user.fullName }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <ng-container *ngIf="isMetricsDDS">
            <mat-checkbox [checked]="data.model.actionTracker" (change)="data.model.actionTracker = !data.model.actionTracker">
                Send directly to action tracker</mat-checkbox
            >
        </ng-container>

        <!-- Due date -->
        <ng-container *ngIf="!isMetricsDDS">
            <mat-label
                >{{ labels.dueDate | translate }}
                <span *ngIf="type !== 'DS'" class="muted">({{ labels.optional | translate | lowercase }})</span></mat-label
            >
            <div class="calendar-wrapper">
                <mat-calendar [(selected)]="dueDate" (selectedChange)="updateModel()"></mat-calendar>
            </div>
        </ng-container>
        <!-- comment for dds action -->
        <ng-container *ngIf="type === 'DS'">
            <mat-label>{{ labels.comment | translate }}</mat-label>
            <mat-form-field
                *ngIf="type === 'DS'"
                [ngClass]="{ invalid: commentControl.touched && commentControl.invalid }"
                appearance="fill"
            >
                <textarea
                    [formControl]="commentControl"
                    trim="blur"
                    (change)="updateModel()"
                    matInput
                    placeholder="{{ labels.descriptionPlaceholder | translate }}"
                ></textarea>
                <mat-error class="error" *ngIf="commentControl.invalid">{{ labels.requiredError | translate }}</mat-error>
            </mat-form-field>
        </ng-container>
    </div>

    <!-- Additional info -->
    <ng-container *ngIf="!isMetricsDDS">
        <div
            *ngIf="type !== 'DS' && CkType !== 'CL'"
            class="hide-show-button"
            (click)="showAdditionalInfo = !showAdditionalInfo"
            fxLayout="row"
            fxLayoutAlign="space-between center"
        >
            <span>{{ labels.additionalInformation | translate }}</span>
            <mat-icon *ngIf="!showAdditionalInfo" fontSet="edms">bold-basics-chevron-down</mat-icon>
            <mat-icon *ngIf="showAdditionalInfo" fontSet="edms">bold-basics-chevron-up</mat-icon>
        </div>

        <div fxLayout="column" fxLayoutAlign="space-evenly stretch" class="others-component__form" *ngIf="showAdditionalInfo">
            <mat-label
                >{{ labels.lossCategory | translate }}
                <span class="muted">({{ labels.optional | translate | lowercase }})</span></mat-label
            >
            <mat-form-field>
                <mat-label>{{ labels.selectLossCategory | translate }}</mat-label>
                <mat-select [formControl]="lossCategoryControl" (blur)="updateModel()">
                    <mat-option *ngFor="let lossCategory of lossCategoryList" [value]="lossCategory.id">{{
                        lossCategory.literal.translateValue
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-label
                >{{ labels.oee | translate }}
                <span class="muted">({{ labels.optional | translate | lowercase }})</span></mat-label
            >
            <mat-form-field>
                <mat-label>{{ labels.typeOEE | translate }}</mat-label>
                <input type="number" [formControl]="oeeControl" (change)="updateModel()" matInput />
                <span matSuffix>%</span>
            </mat-form-field>
            <ng-container *ngIf="workingAreaFormatList?.length">
                <mat-label
                    >{{ labels.workingAreaFormat | translate }}
                    <span class="muted">({{ labels.optional | translate | lowercase }})</span></mat-label
                >
                <mat-form-field>
                    <mat-label>{{ labels.selectFormat | translate }}</mat-label>
                    <mat-select
                        [formControl]="workingAreaFormatControl"
                        (selectionChange)="workingAreaSelected()"
                        (blur)="updateModel()"
                    >
                        <mat-option *ngFor="let format of workingAreaFormatList" [value]="format.format.id">{{
                            format.format.literal.translateValue
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="equipmentFormatList?.length && this?.data?.model?.trackerAction?.equipment?.id">
                <mat-label
                    >{{ labels.equipmentFormat | translate }}
                    <span class="muted">({{ labels.optional | translate | lowercase }})</span></mat-label
                >
                <mat-form-field>
                    <mat-label>{{ labels.selectFormat | translate }}</mat-label>
                    <mat-select
                        [formControl]="equipmentFormatControl"
                        (selectionChange)="equipmentSelected()"
                        (blur)="updateModel()"
                        [disabled]="!equipmentFormatList?.length"
                    >
                        <mat-option *ngFor="let format of equipmentFormatList" [value]="format.format.id">{{
                            format.format.literal.translateValue
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
        </div>
    </ng-container>
</form>
