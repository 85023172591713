<!-- Header -->
<mat-toolbar class="mat-dialog__header" color="primary">
    <div class="toolbar-title">{{ title | translate }}</div>
    <mat-icon [mat-dialog-close]="false">close</mat-icon>
</mat-toolbar>

<!-- Content -->
<div class="mat-dialog__content confirm-dialog confirm-dialog-text">
    <div mat-dialog-content class="confirm-dialog__content">
        <p class="confirm-dialog__text" innerHTML="{{ message | translate }}"></p>
    </div>
</div>
<div class="mat-dialog__content confirm-dialog confirm-dialog-action">
    <div fxLayout="row" fxLayoutAlign="space-between" class="mat-dialog__actions">
        <button mat-stroked-button color="warn" (click)="onConfirm()" tabindex="-1" class="confirm-dialog-button">
            {{ yesActionText | translate }}
        </button>
        <button mat-flat-button color="primary" (click)="onDismiss()" class="confirm-dialog-button">
            {{ noActionText | translate }}
        </button>
    </div>
</div>
