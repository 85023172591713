import { EdmsError } from '@app/types/error';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import {
  ENV,
  GLOBAL_SUPER_USER,
  LINE_LEAD,
  OPERATOR,
  PROCESS_LEAD,
  PROJECT_ENUM,
  STAKEHOLDER,
  SUPER_USER,
  TEAM_LEAD,
  TECNICHIAN
} from '@app/constants/global.constants';
import { LanguageService } from '@app/services/language.service';
import { Env } from '@app/types/env';
import { ServiceResponse } from '@app/types/service-response';
import UserDTO from '@data/models/user-dto';
import { BehaviorSubject, forkJoin, Observable, throwError } from 'rxjs';
import { catchError, filter, flatMap, map, mergeMap, tap } from 'rxjs/operators';
import IdeaUserDTO from '@data/models/idea-user-dto';
import { CookieService } from 'ngx-cookie-service';
import { TEMPLATE_ICONS } from '@shared/constants/app-type.constants';
import { AuthenticationService } from '@app/security/authentication.service';
import { OauthToken } from '@data/models/OauthToken';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public loggedUserInfo$: Observable<ServiceResponse<UserDTO>>;
  public loggedUserInfoLoadingState$: Observable<boolean>;
  public readonly PROJECT_KEY = 'project';
  private readonly EXPIRATION_TOKEN = 'expires_in_' + window.location.hostname;
  private readonly EXPIRATION_JWT_TOKEN = 'jwt_expires_' + window.location.hostname;
  private readonly ACCESS_TOKEN = 'access_token_' + window.location.hostname;
  private loggedUserInfo = new BehaviorSubject<ServiceResponse<UserDTO>>({
    data: null,
    error: null
  });
  private readonly HTTP_OPTIONS = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  };
  private loggedUserInfoLoadingState = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(ENV) private env: Env,
    private http: HttpClient,
    private languageService: LanguageService,
    private ngZone: NgZone // private cookieService: CookieService
  ) {
    this.loggedUserInfoLoadingState$ = this.loggedUserInfoLoadingState.asObservable();

    this.loggedUserInfo$ = this.loggedUserInfo.pipe(
      tap((response) => {
        if (!response || (!response.data && !response.error)) {
          // Perform HTTP call when this observable is not fulfilled yet
          // We don't handle anything here, everything is done inside the
          // pipes of `getLoggedUserInfo()`
          if (localStorage.getItem(this.PROJECT_KEY) === PROJECT_ENUM.ebos.toString()) {
            this.getLoggedUserInfo().subscribe(
              () => {},
              () => {}
            );
          } else {
            this.getLoggedUserInfoIdea().subscribe(
              () => {},
              () => {}
            );
          }
        } else {
        }
      })
    );
  }

  public getLoggedUserInfoTest(): Observable<UserDTO> {
    this.loggedUserInfoLoadingState.next(true);

    return this.http
      .get<UserDTO>(`${this.env.apiBaseUrl}/api/users/detail`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  /**
   * Get eDMS user for current Gigya logged user
   */
  public getLoggedUserInfo(): Observable<ServiceResponse<UserDTO>> {
    return this.http.get<UserDTO>(`${this.env.apiBaseUrl}/api/users/detail`).pipe(
      map((user: UserDTO) => ({
        data: user,
        error: null
      })),
      tap((serviceResponse: ServiceResponse<UserDTO>) => {
        this.loggedUserInfo.next(serviceResponse);
        this.loggedUserInfoLoadingState.next(false);
        this.setupUserLanguage(serviceResponse.data);
      }),
      catchError((error: string) => {
        this.loggedUserInfo.next({
          data: null,
          error: {
            message: error
          } as EdmsError
        });

        this.loggedUserInfoLoadingState.next(false);

        return throwError(error);
      })
    );
  }

  /**
   * Get eDMS user for current Gigya logged user
   */
  public getLoggedUserInfoIdea(): Observable<ServiceResponse<IdeaUserDTO>> {
    this.loggedUserInfoLoadingState.next(true);

    return this.http.get<IdeaUserDTO>(`${this.env.apiBaseUrlIdea}/api/v1/users/detail`).pipe(
      map((user: IdeaUserDTO) => ({
        data: user,
        error: null
      })),
      tap((serviceResponse: ServiceResponse<IdeaUserDTO>) => {
        this.loggedUserInfo.next(serviceResponse);
        this.loggedUserInfoLoadingState.next(false);
        this.setupUserLanguage(serviceResponse.data);
      }),
      catchError((error: string) => {
        this.loggedUserInfo.next({
          data: null,
          error: {
            message: error
          } as EdmsError
        });

        this.loggedUserInfoLoadingState.next(false);

        return throwError(error);
      })
    );
  }

  /**
   * Clears the current logged user and his settings
   */
  public clearLoggedUser(): void {
    this.loggedUserInfo.next({
      data: null,
      error: null
    });

    this.ngZone.run(() => {
      this.languageService.setLanguage(null);
    });
  }
  public isLoggedUserSimpleUser(): Observable<boolean> {
    return this.loggedUserInfo$.pipe(
      filter((response) => response.data !== null),
      map(
        (response) =>
          response.data?.role?.level !== SUPER_USER &&
          response.data?.role?.level !== GLOBAL_SUPER_USER &&
          response.data?.role?.level !== STAKEHOLDER &&
          response.data?.role?.level !== LINE_LEAD
      )
    );
  }
  public isLoggedUserEnabledToManageCILCL(): Observable<boolean> {
    return this.loggedUserInfo$.pipe(
      filter((response) => response.data !== null),
      map(
        (response) =>
          response.data?.role?.level !== TECNICHIAN &&
          response.data?.role?.level !== OPERATOR &&
          response.data?.role?.level !== STAKEHOLDER
      )
    );
  }

  public isLoggedUserSuperUser(): Observable<boolean> {
    return this.loggedUserInfo$.pipe(
      filter((response) => response.data !== null),
      map((response) => response.data?.role?.level === SUPER_USER)
    );
  }

  public isLoggedUserSuperUserOrGlobal(): Observable<boolean> {
    return this.loggedUserInfo$.pipe(
      filter((response) => response.data !== null),
      map((response) => response.data?.role?.level === SUPER_USER || response.data?.role?.level === GLOBAL_SUPER_USER)
    );
  }
  public isLoggedUserCanDeactivate(): Observable<boolean> {
    return this.loggedUserInfo$.pipe(
      filter((response) => response.data !== null),
      map(
        (response) =>
          response.data?.role?.level !== OPERATOR &&
          response.data?.role?.level !== STAKEHOLDER &&
          (response.data?.role?.level !== TECNICHIAN ||
            (response.data?.role?.level === TECNICHIAN && response.data?.role?.name.includes('Team Lead')))
      )
    );
  }
  public isLoggedUserSuperUserOrGlobalArchive(): Observable<boolean> {
    return this.loggedUserInfo$.pipe(
      filter((response) => response.data !== null),
      map(
        (response) =>
          response.data?.role?.level === SUPER_USER ||
          response.data?.role?.level === GLOBAL_SUPER_USER ||
          (response.data?.role?.level === TEAM_LEAD && response.data?.role?.name.includes('Team Lead'))
      )
    );
  }

  public isLoggedUserSuperUserOrGlobalorStakeholder(): Observable<boolean> {
    return this.loggedUserInfo$.pipe(
      filter((response) => response.data !== null),
      map(
        (response) =>
          response.data?.role?.level === SUPER_USER ||
          response.data?.role?.level === GLOBAL_SUPER_USER ||
          response.data?.role?.level === STAKEHOLDER ||
          response.data?.role?.level === LINE_LEAD
      )
    );
  }

  public newAdminManagementControl(): Observable<boolean> {
    return this.loggedUserInfo$.pipe(
      filter((response) => response.data !== null),
      map(
        (response) =>
          response.data?.role?.level === SUPER_USER ||
          response.data?.role?.level === GLOBAL_SUPER_USER ||
          response.data?.role?.level === STAKEHOLDER ||
          response.data?.role?.level === LINE_LEAD ||
          response.data?.role?.level === TEAM_LEAD
      )
    );
  }
  public isTeamLead(): Observable<boolean> {
    return this.loggedUserInfo$.pipe(
      filter((response) => response.data !== null),
      map((response) => response.data?.role?.level === TEAM_LEAD)
    );
  }

  public isLoggedUserLineLeadOrSU(): Observable<boolean> {
    return this.loggedUserInfo$.pipe(
      filter((response) => response.data !== null),
      map((response) => response.data?.role?.level === LINE_LEAD || response.data?.role?.level === SUPER_USER)
    );
  }
  public isLoggedUserLineLead(): Observable<boolean> {
    return this.loggedUserInfo$.pipe(
      filter((response) => response.data !== null),
      map((response) => response.data?.role?.level === LINE_LEAD)
    );
  }

  public usercanDeleteAppOrTemplate(): Observable<boolean> {
    return this.loggedUserInfo$.pipe(
      filter((response) => response.data !== null),
      map((response) => response.data?.role?.level === GLOBAL_SUPER_USER || response.data?.role?.level === SUPER_USER)
    );
  }

  public isLoggedUserGlobalSuperUser(): Observable<boolean> {
    return this.loggedUserInfo$.pipe(
      filter((response) => response.data !== null),
      map((response) => response.data?.role?.level === GLOBAL_SUPER_USER)
    );
  }

  public isLoggedUserAdmin(): Observable<boolean> {
    return this.loggedUserInfo$.pipe(
      filter((response) => response.data !== null),
      map((response) => response.data?.role?.level >= SUPER_USER)
    );
  }

  public setupUserLanguage(user: UserDTO): void {
    this.languageService.setLanguage(user?.defaultLocale);
  }

  public isEbosUser(): boolean {
    return localStorage.getItem('project') === PROJECT_ENUM.ebos.toString();
  }

  public getCurrentUser(): Observable<UserDTO> {
    return this.loggedUserInfo$.pipe(
      filter((response) => response.data !== null),
      map((response) => response.data)
    );
  }
}
