import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-add-action-description-dialog',
  templateUrl: './add-action-description-dialog.component.html',
  styleUrls: ['./add-action-description-dialog.component.scss']
})
export class AddActionDescriptionDialogComponent implements OnInit {
  public labels: { [key: string]: string } = {
    title: marker('addActionDescription.dialog.title'),
    description: marker('addActionDescription.dialog.description'),
    save: marker('global.save'),
    textareaPlaceholder: marker('addActionDescription.dialog.textareaPlaceholder')
  };

  public maxDescriptionTitle = 240;
  public description: string;

  get saveBtnEnabled(): boolean {
    return this.description && this.description.length <= this.maxDescriptionTitle && this.description.trim().length > 0;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: { model: { description: string } }, private dialog: MatDialog) {}

  ngOnInit() {
    this.description = this.data.model.description;
  }

  public async save(): Promise<void> {
    this.dialog.getDialogById('add-action-description-dialog-component').close(this.description);
  }
}
