import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { IdeaAnswerOptionDTO } from '@data/models/idea-answer-option-dto';
import { PossibleAnswerDTO } from '@data/models/possible-answer-dto';
import { ACTION_TRACKER_STATUSES_ENUM } from './status.constants';

export enum ANSWER_TYPE_ENUM {
  'FREE_TEXT' = 0,
  'NUMBER' = 1,
  'RANGE' = 2,
  'ZERO_TO_THREE' = 3,
  'YES_NO' = 4,
  'LOW_MEDIUM_HIGH' = 5,
  'ONE_OPTION' = 6,
  'MULTIPLE_OPTIONS' = 7,
  'PERCENTAGE' = 9,
  'TIME' = 8,
  'NA' = 10,
  'UNSCHEDULE_CLOSE' = 40,
  'SUM' = 11,
  'SUBTRACTION' = 12,
  'AVERAGE' = 13,
  'USER' = 14,
  'USER_MULTIPLE' = 15,
  'WORKINGAREA' = 16,
  'WORKINGAREA_MULTIPLE' = 17,
  'EQUIPMENT' = 18,
  'EQUIPMENT_MULTIPLE' = 19,
  'SIGNATURE' = 20,
  'DATE' = 21,
  'DATE_AND_TIME' = 22,
  'ATTACH_FILE' = 23,
  'PRODUCT' = 24,
  'DIVISION' = 25,
  'MAX' = 26,
  'MIN' = 27,
  'ZERO_NUMBER' = 28,
  'ZERO_RANGE' = 29
}
export enum ANSWER_TYPE_IDEA_ENUM {
  'FREE_TEXT' = 1,
  'NUMBER' = 2,
  'YES_NO' = 3,
  'ONE_OPTION' = 4,
  'MULTIPLE_OPTIONS' = 5,
  'CATEGORY' = 6,
  'DEPARTMENT' = 7,
  'OTHER_CONTRIBUTORS' = 8,
  'ESTIMATED_SAVINGS' = 9,
  'ESTIMATED_COSTS' = 10,
  'AMOUNT' = 11,
  'DATE' = 12,
  'NA' = 13
}
export class AnswerTypeGroup {
  typeGroup: string;
  types: AnswerType[];
}

export interface AnswerType {
  id: number;
  deleted?: boolean;
  name?: string;
  description?: string;
  possibleAnswers?: PossibleAnswerDTO[];
}

export const ANSWER_TYPES: AnswerTypeGroup[] = [
  {
    typeGroup: marker('management.answerTypes.typeGroupInput'),
    types: [
      {
        id: ANSWER_TYPE_ENUM.FREE_TEXT,
        description: marker('management.answerTypes.typeFreeText')
      },
      {
        id: ANSWER_TYPE_ENUM.NUMBER,
        description: marker('management.answerTypes.typeNumber')
      },
      {
        id: ANSWER_TYPE_ENUM.RANGE,
        description: marker('management.answerTypes.typeRange')
      },
      {
        id: ANSWER_TYPE_ENUM.PERCENTAGE,
        description: marker('management.answerTypes.typePercentage')
      },
      {
        id: ANSWER_TYPE_ENUM.SIGNATURE,
        description: marker('management.answerTypes.typeSignature')
      },
      {
        id: ANSWER_TYPE_ENUM.ATTACH_FILE,
        description: marker('management.answerTypes.typeAttachFile')
      },
      {
        id: ANSWER_TYPE_ENUM.ZERO_NUMBER,
        description: marker('management.answerTypes.typeZeroNumber')
      },
      {
        id: ANSWER_TYPE_ENUM.ZERO_RANGE,
        description: marker('management.answerTypes.typeZeroRange')
      }
    ]
  },
  {
    typeGroup: marker('management.answerTypes.typeGroupSelection'),
    types: [
      {
        id: ANSWER_TYPE_ENUM.ZERO_TO_THREE,
        description: marker('management.answerTypes.type0123')
      },
      {
        id: ANSWER_TYPE_ENUM.YES_NO,
        description: marker('management.answerTypes.typeYesNo')
      },
      {
        id: ANSWER_TYPE_ENUM.LOW_MEDIUM_HIGH,
        description: marker('management.answerTypes.typeLowMediumHigh')
      },
      {
        id: ANSWER_TYPE_ENUM.ONE_OPTION,
        description: marker('management.answerTypes.typeListOneOption')
      },
      {
        id: ANSWER_TYPE_ENUM.MULTIPLE_OPTIONS,
        description: marker('management.answerTypes.typeListMultipleOptions')
      },
      {
        id: ANSWER_TYPE_ENUM.DATE,
        description: marker('management.answerTypes.typeDate')
      },
      {
        id: ANSWER_TYPE_ENUM.EQUIPMENT,
        description: marker('management.answerTypes.typeEquipment')
      },
      {
        id: ANSWER_TYPE_ENUM.WORKINGAREA,
        description: marker('management.answerTypes.typeWorkingArea')
      },
      {
        id: ANSWER_TYPE_ENUM.USER,
        description: marker('management.answerTypes.typeUser')
      }
    ]
  },
  {
    typeGroup: marker('management.answerTypes.typeGroupOther'),
    types: [
      {
        id: ANSWER_TYPE_ENUM.NA,
        description: marker('management.answerTypes.typeNotApplicable')
      }
    ]
  },
  {
    typeGroup: marker('management.answerTypes.calculation'),
    types: [
      {
        id: ANSWER_TYPE_ENUM.SUM,
        description: marker('management.answerTypes.sum')
      },
      {
        id: ANSWER_TYPE_ENUM.SUBTRACTION,
        description: marker('management.answerTypes.subtraction')
      },
      {
        id: ANSWER_TYPE_ENUM.AVERAGE,
        description: marker('management.answerTypes.average')
      }
    ]
  }
];
export const ANSWER_TYPES_GENERAL_TABLE: AnswerTypeGroup[] = [
  {
    typeGroup: marker('management.answerTypes.typeGroupInput'),
    types: [
      {
        id: ANSWER_TYPE_ENUM.FREE_TEXT,
        description: marker('management.answerTypes.typeFreeText')
      },
      {
        id: ANSWER_TYPE_ENUM.NUMBER,
        description: marker('management.answerTypes.typeNumber')
      },
      {
        id: ANSWER_TYPE_ENUM.PERCENTAGE,
        description: marker('management.answerTypes.typePercentage')
      },
      {
        id: ANSWER_TYPE_ENUM.SIGNATURE,
        description: marker('management.answerTypes.typeSignature')
      },
      {
        id: ANSWER_TYPE_ENUM.DATE,
        description: marker('management.answerTypes.typeDate')
      }
    ]
  },
  {
    typeGroup: marker('management.answerTypes.typeGroupSelection'),
    types: [
      {
        id: ANSWER_TYPE_ENUM.ONE_OPTION,
        description: marker('management.answerTypes.typeListOneOption')
      },
      {
        id: ANSWER_TYPE_ENUM.EQUIPMENT,
        description: marker('management.answerTypes.typeEquipment')
      },
      {
        id: ANSWER_TYPE_ENUM.WORKINGAREA,
        description: marker('management.answerTypes.typeWorkingArea')
      },
      {
        id: ANSWER_TYPE_ENUM.USER,
        description: marker('management.answerTypes.typeUser')
      }
    ]
  },
  {
    typeGroup: marker('management.answerTypes.calculation'),
    types: [
      {
        id: ANSWER_TYPE_ENUM.SUM,
        description: marker('management.answerTypes.sum')
      },
      {
        id: ANSWER_TYPE_ENUM.SUBTRACTION,
        description: marker('management.answerTypes.subtraction')
      },
      {
        id: ANSWER_TYPE_ENUM.AVERAGE,
        description: marker('management.answerTypes.average')
      },
      {
        id: ANSWER_TYPE_ENUM.DIVISION,
        description: marker('management.answerTypes.division')
      },
      {
        id: ANSWER_TYPE_ENUM.PRODUCT,
        description: marker('management.answerTypes.product')
      },
      {
        id: ANSWER_TYPE_ENUM.MAX,
        description: marker('management.answerTypes.max')
      },
      {
        id: ANSWER_TYPE_ENUM.MIN,
        description: marker('management.answerTypes.min')
      }
    ]
  }
];

export const ANSWER_TYPES_IDEA: AnswerTypeGroup[] = [
  {
    typeGroup: marker('management.answerTypes.typeGroupInput'),
    types: [
      {
        id: ANSWER_TYPE_IDEA_ENUM.FREE_TEXT,
        deleted: false,
        name: marker('management.answerTypes.typeFreeText'),
        possibleAnswers: []
      },
      {
        id: ANSWER_TYPE_IDEA_ENUM.NUMBER,
        deleted: false,
        name: marker('management.answerTypes.typeNumber'),
        possibleAnswers: []
      },
      {
        id: ANSWER_TYPE_IDEA_ENUM.AMOUNT,
        deleted: false,
        name: marker('management.answerTypes.amount'),
        possibleAnswers: []
      },
      {
        id: ANSWER_TYPE_IDEA_ENUM.ESTIMATED_COSTS,
        deleted: false,
        name: marker('management.answerTypes.estimatedCosts'),
        possibleAnswers: []
      },
      {
        id: ANSWER_TYPE_IDEA_ENUM.ESTIMATED_SAVINGS,
        deleted: false,
        name: marker('management.answerTypes.estimatedSavings'),
        possibleAnswers: []
      },
      {
        id: ANSWER_TYPE_IDEA_ENUM.OTHER_CONTRIBUTORS,
        deleted: false,
        name: marker('management.answerTypes.typeOtherContributors'),
        possibleAnswers: []
      },
      {
        id: ANSWER_TYPE_IDEA_ENUM.DATE,
        deleted: false,
        name: marker('management.answerTypes.date'),
        possibleAnswers: []
      }
      /*{
        id: ANSWER_TYPE_ENUM.PERCENTAGE,
        description: marker('management.answerTypes.typePercentage')
      }
      ,
      {
        id: ANSWER_TYPE_ENUM.TIME,
        description: marker('management.answerTypes.typeTime')
      }*/
    ]
  },
  {
    typeGroup: marker('management.answerTypes.typeGroupSelection'),
    types: [
      {
        id: ANSWER_TYPE_IDEA_ENUM.YES_NO,
        deleted: false,
        name: marker('management.answerTypes.typeYesNo'),
        possibleAnswers: []
      },

      {
        id: ANSWER_TYPE_IDEA_ENUM.ONE_OPTION,
        deleted: false,
        name: marker('management.answerTypes.typeListOneOption'),
        possibleAnswers: []
      },
      {
        id: ANSWER_TYPE_IDEA_ENUM.MULTIPLE_OPTIONS,
        deleted: false,
        name: marker('management.answerTypes.typeListMultipleOptions'),
        possibleAnswers: []
      },
      {
        id: ANSWER_TYPE_IDEA_ENUM.CATEGORY,
        deleted: false,
        name: marker('management.answerTypes.category'),
        possibleAnswers: []
      },

      {
        id: ANSWER_TYPE_IDEA_ENUM.DEPARTMENT,
        deleted: false,
        name: marker('management.answerTypes.department'),
        possibleAnswers: []
      }
      // ,{
      //   id: ANSWER_TYPE_ENUM.PERCENTAGE,
      //   description: marker('management.answerTypes.typePercentage')
      // },{
      //   id: ANSWER_TYPE_ENUM.TIME,
      //   description: marker('management.answerTypes.typeTime')
      // }
    ]
  },
  {
    typeGroup: marker('management.answerTypes.typeGroupOther'),
    types: [
      {
        id: ANSWER_TYPE_IDEA_ENUM.NA,
        deleted: false,
        name: marker('management.answerTypes.typeNotApplicable'),
        possibleAnswers: []
      }
    ]
  }
];

export enum POSSIBLE_ANSWERS_ZERO_TO_THREE_ENUM {
  'ZERO' = 0,
  'ONE' = 1,
  'TWO' = 2,
  'THREE' = 3
}

export const POSSIBLE_ANSWERS_ZERO_TO_THREE: PossibleAnswerDTO[] = [
  new PossibleAnswerDTO(
    // POSSIBLE_ANSWERS_ZERO_TO_THREE_ENUM.ZERO,
    POSSIBLE_ANSWERS_ZERO_TO_THREE_ENUM.ZERO,
    marker('answerTypes.zeroToThree.zero'),
    0
  ),
  new PossibleAnswerDTO(
    // POSSIBLE_ANSWERS_ZERO_TO_THREE_ENUM.ONE,
    POSSIBLE_ANSWERS_ZERO_TO_THREE_ENUM.ONE,
    marker('answerTypes.zeroToThree.one'),
    1
  ),
  new PossibleAnswerDTO(
    // POSSIBLE_ANSWERS_ZERO_TO_THREE_ENUM.TWO,
    POSSIBLE_ANSWERS_ZERO_TO_THREE_ENUM.TWO,
    marker('answerTypes.zeroToThree.two'),
    2
  ),
  new PossibleAnswerDTO(
    // POSSIBLE_ANSWERS_ZERO_TO_THREE_ENUM.THREE,
    POSSIBLE_ANSWERS_ZERO_TO_THREE_ENUM.THREE,
    marker('answerTypes.zeroToThree.three'),
    3
  )
];
export const IDEA_POSSIBLE_ANSWERS_ZERO_TO_THREE: IdeaAnswerOptionDTO[] = [
  new IdeaAnswerOptionDTO(
    // POSSIBLE_ANSWERS_ZERO_TO_THREE_ENUM.ZERO,
    POSSIBLE_ANSWERS_ZERO_TO_THREE_ENUM.ZERO,
    marker('answerTypes.zeroToThree.zero'),
    0
  ),
  new IdeaAnswerOptionDTO(
    // POSSIBLE_ANSWERS_ZERO_TO_THREE_ENUM.ONE,
    POSSIBLE_ANSWERS_ZERO_TO_THREE_ENUM.ONE,
    marker('answerTypes.zeroToThree.one'),
    1
  ),
  new IdeaAnswerOptionDTO(
    // POSSIBLE_ANSWERS_ZERO_TO_THREE_ENUM.TWO,
    POSSIBLE_ANSWERS_ZERO_TO_THREE_ENUM.TWO,
    marker('answerTypes.zeroToThree.two'),
    2
  ),
  new IdeaAnswerOptionDTO(
    // POSSIBLE_ANSWERS_ZERO_TO_THREE_ENUM.THREE,
    POSSIBLE_ANSWERS_ZERO_TO_THREE_ENUM.THREE,
    marker('answerTypes.zeroToThree.three'),
    3
  )
];

export const POSSIBLE_ANSWERS_UNSCHEDULE_CLOSE: PossibleAnswerDTO[] = [
  new PossibleAnswerDTO(ACTION_TRACKER_STATUSES_ENUM.UNSCHEDULED, marker('answerTypes.unscheduleClose.unschedule'), 0),
  new PossibleAnswerDTO(ACTION_TRACKER_STATUSES_ENUM.CLOSED, marker('answerTypes.unscheduleClose.close'), 1)
];

export enum POSSIBLE_ANSWERS_YES_NO_ENUM {
  'YES' = 0,
  'NO' = 1
}
export const POSSIBLE_ANSWERS_YES_NO: PossibleAnswerDTO[] = [
  new PossibleAnswerDTO(POSSIBLE_ANSWERS_YES_NO_ENUM.YES, marker('answerTypes.yesNo.yes'), 0),
  new PossibleAnswerDTO(POSSIBLE_ANSWERS_YES_NO_ENUM.NO, marker('answerTypes.yesNo.no'), 1)
];

export const IDEA_POSSIBLE_ANSWERS_YES_NO: IdeaAnswerOptionDTO[] = [
  new IdeaAnswerOptionDTO(POSSIBLE_ANSWERS_YES_NO_ENUM.YES, marker('answerTypes.yesNo.yes'), 0),
  new IdeaAnswerOptionDTO(POSSIBLE_ANSWERS_YES_NO_ENUM.NO, marker('answerTypes.yesNo.no'), 1)
];

export enum POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH_ENUM {
  'LOW' = 0,
  'MEDIUM' = 1,
  'HIGH' = 2
}
export const POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH: PossibleAnswerDTO[] = [
  new PossibleAnswerDTO(
    // POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH_ENUM.LOW,
    POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH_ENUM.LOW,
    marker('answerTypes.lowMediumHigh.low'),
    0
  ),
  new PossibleAnswerDTO(
    // POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH_ENUM.MEDIUM,
    POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH_ENUM.MEDIUM,
    marker('answerTypes.lowMediumHigh.medium'),
    1
  ),
  new PossibleAnswerDTO(
    // POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH_ENUM.HIGH,
    POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH_ENUM.HIGH,
    marker('answerTypes.lowMediumHigh.high'),
    2
  )
];

export const IDEA_POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH: IdeaAnswerOptionDTO[] = [
  new IdeaAnswerOptionDTO(
    // POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH_ENUM.LOW,
    POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH_ENUM.LOW,
    marker('answerTypes.lowMediumHigh.low'),
    0
  ),
  new IdeaAnswerOptionDTO(
    // POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH_ENUM.MEDIUM,
    POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH_ENUM.MEDIUM,
    marker('answerTypes.lowMediumHigh.medium'),
    1
  ),
  new IdeaAnswerOptionDTO(
    // POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH_ENUM.HIGH,
    POSSIBLE_ANSWERS_LOW_MEDIUM_HIGH_ENUM.HIGH,
    marker('answerTypes.lowMediumHigh.high'),
    2
  )
];
