<mat-drawer-container hasBackdrop="true" class="mat-app-background">
    <mat-drawer-content>
        <div class="dashboard-idea-mobile-layout-component"
            [ngClass]="{ 'dashboard-layout-component__landing': isLanding }">
            <mat-toolbar color="primary">
                <div class="dashboard-layout-component__toolbar content-wrapper" fxLayout="row"
                    fxLayoutAlign="space-between center">
                    <!-- TODO (FUTURE): This logo and it's "alt" text should come from the Operation Center of the logged user -->
                    <img class="dashboard-layout-component__logo" width="100" height="33" alt="eDMS Pessione"
                        src="./assets/img/new/idea/logo-header.svg" />
                    <div class="dashboard-layout-component__actions">
                        <app-logout-idea-button></app-logout-idea-button>
                    </div>
                </div>
            </mat-toolbar>

            <div class="dashboard-layout-component__content">
                <router-outlet></router-outlet>
            </div>
        </div>
    </mat-drawer-content>

    <mat-drawer #drawer mode="over" position="end">
        <div class="dashboard-layout-component__drawer">
            <app-user-filters [drawer]="drawer"></app-user-filters>
        </div>
    </mat-drawer>
</mat-drawer-container>