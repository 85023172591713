<div class="safari-how2-old">
    <div class="Message">
        <div fxLayout="row" fxLayoutAlign="space-between center" style="width:  100%">
            <span class="Definition">
                <mat-icon fontSet="edms">basics-alerts-information-circle</mat-icon>
                <span>Add a shortcut to your home screen</span>
            </span>
            <mat-icon fontSet="edms" class="mat-icon notranslate material-icons  close-icon"
                (click)="close();$event.stopPropagation();">icon-close</mat-icon>
        </div>

        <ol class="step-root" fxLayout="column" fxLayoutAlign="space-around start">
            <li class="step">Tap <span style="font-weight: 600;">Shared</span> {{a2hs.whereIsShare}}

                <img class="nudgeImage" height="22" alt="Share Button Image" src="./assets/img/ios.png">

            </li>
            <li class="step">Tap <span style="font-weight: 600;"> Add to Home Screen</span>

                <img class="nudgeImage" height="22" alt="Add to Home Screen button Image" style="margin:0 2px ;" src="data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy5
        3My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNSAzNSI+PHRpdGxlPlNhZmFyaV9BMkhTPC
        90aXRsZT48cmVjdCB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHJ4PSI4IiByeT0iOCIgc3R5bGU9I
        mZpbGw6IzhmOGY4ZiIvPjxsaW5lIHgxPSIyNC43NSIgeTE9IjE3LjUiIHgyPSIxMC4yNSIgeTI9
        IjE3LjUiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiNmZmY7c3Ryb2tlLXdpZHRoOjJweCIvPjx
        saW5lIHgxPSIxNy41IiB5MT0iMTAuMjUiIHgyPSIxNy41IiB5Mj0iMjQuNzUiIHN0eWxlPSJmaW
        xsOm5vbmU7c3Ryb2tlOiNmZmY7c3Ryb2tlLXdpZHRoOjJweCIvPjwvc3ZnPg==">(swipe left if necessary)
            </li>
        </ol>

    </div>


</div>