import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatDrawer } from '@angular/material/sidenav';
import { GLOBAL_SUPER_USER, STAKEHOLDER } from '@app/constants/global.constants';
import { EdmsError } from '@app/types/error';
import { ServiceResponse } from '@app/types/service-response';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { UserDepartmentDTO } from '@data/models/user-department-dto';
import UserDTO from '@data/models/user-dto';
import { UserFiltersDTO } from '@data/models/user-filters-dto';
import { UserOperationCenterDTO } from '@data/models/user-operationcenter-dto';
import { UserPlantDTO } from '@data/models/user-plant-dto';
import { UserWorkingAreaDTO } from '@data/models/user-workingarea-dto';
import { UserFiltersService } from '@data/services/user-filters.service';
import { UserService } from '@data/services/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { GLOBAL_MESSAGE_2SEC_DURATION } from '@shared/constants/global-message';
import { GlobalMessageService } from '@shared/services/global-message.service';
import { requireCheckboxesToBeCheckedValidator } from '@shared/validators/require-checkboxes-to-be-checked.validator';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-user-filters',
  templateUrl: './user-filters.component.html',
  styleUrls: ['./user-filters.component.scss']
})
export class UserFiltersComponent implements OnInit {
  @Input()
  public drawer: MatDrawer;

  @ViewChildren('select')
  public select: QueryList<MatSelect>;

  public labels = {
    allDepartments: marker('filters.allDepartments'),
    allSelected: marker('global.allSelected'),
    department: marker('filters.department'),
    equipment: marker('filters.equipment'),
    equipments: marker('filters.equipments'),
    equipmentsRequired: marker('filters.equipmentsRequired'),
    error: marker('global.error'),
    filtersTitle: marker('filters.title'),
    noneSelected: marker('global.noneSelected'),
    operationCenter: marker('filters.operationCenter'),
    plant: marker('filters.plant'),
    save: marker('global.save'),
    saveError: marker('filters.saveError'),
    saveSuccess: marker('filters.saveSuccess'),
    userHasNoPermissions: marker('filters.userHasNoPermissions'),
    workingAreas: marker('filters.workingAreas'),
    workingAreasRequired: marker('filters.workingAreasRequired')
  };

  public readonly STAKEHOLDER = STAKEHOLDER;
  public readonly GLOBAL_SUPER_USER = GLOBAL_SUPER_USER;
  public readonly ALLOWED_USERS_LEVEL = 40;

  public filtersFormGroup: FormGroup;
  public operationCenters: UserOperationCenterDTO[];
  public plants: UserPlantDTO[] = [];
  public selectedPlant: UserPlantDTO;
  public departments: UserDepartmentDTO[];
  public selectedDepartment: UserDepartmentDTO[] = [];
  public workingAreas: UserWorkingAreaDTO[] = [];
  public selectedWorkingAreas: UserWorkingAreaDTO[] = [];
  public error: EdmsError;
  public saveError = false;
  public allDepartments = false;

  // TODO (FUTURE): Extract equipments select component, and handle "select all" behavior there
  public equipmentsAllSelected: boolean[] = [];
  public equipmentsIndeterminateState: boolean[] = [];

  public loggedUserInfo$: Observable<ServiceResponse<UserDTO>>;

  private currentFilters: UserFiltersDTO;
  private isSaving = false;

  constructor(
    private fb: FormBuilder,
    private userFiltersService: UserFiltersService,
    private translateService: TranslateService,
    private globalMessageService: GlobalMessageService,
    private userService: UserService
  ) {
    this.loggedUserInfo$ = this.userService.loggedUserInfo$;
  }

  ngOnInit() {
    this.drawer.openedChange.pipe(untilDestroyed(this)).subscribe((opened: boolean) => {
      if (opened) {
        this.loadFilters();
      } else {
        this.resetFilters();
      }
    });
  }

  public get equipmentsFormControls() {
    return this.filtersFormGroup.controls.equipmentsFormControls as FormArray;
  }

  // TODO (FUTURE): Extract equipments select component, and handle "select all" behavior there
  public getEquipmentSelectText(index: number, separator: string = ', '): string {
    const allSelected = this.equipmentsAllSelected[index];
    const indeterminate = this.equipmentsIndeterminateState[index];

    if (allSelected) {
      return this.translateService.instant(this.labels.allSelected);
    } else if (indeterminate) {
      return this.getSelectedEquipmentsNamesByIndex(index, separator);
    }

    return this.translateService.instant(this.labels.noneSelected);
  }

  // TODO (FUTURE): Extract equipments select component, and handle "select all" behavior there
  public onEquipmentToggleSelection(index: number) {
    const options = this.select.toArray()[index].options;

    if (!this.equipmentsAllSelected[index]) {
      options?.forEach((item: MatOption) => item.select());
    } else {
      options?.forEach((item: MatOption) => item.deselect());
    }

    this.equipmentsAllSelected[index] = !this.equipmentsAllSelected[index];
    this.equipmentsIndeterminateState[index] = false;
  }

  // TODO (FUTURE): Extract equipments select component, and handle "select all" behavior there
  public onEquipmentOptionClick(index: number) {
    const { allSelected, indeterminate } = this.calculateEquipmentSelectState(index);

    this.equipmentsAllSelected[index] = allSelected;
    this.equipmentsIndeterminateState[index] = indeterminate;
  }

  public sendForm(): void {
    if (!this.allDepartments) {
      this.cleanCurrentFilters();

      const currentOperationCenterId = this.filtersFormGroup.controls.operationCenter.value;
      const currentPlantId = this.filtersFormGroup.controls.plants.value;

      this.assignOperationCentersAndPlants(this.currentFilters.operationCenters, currentOperationCenterId, currentPlantId);

      const currentDepartments = this.currentFilters.operationCenters
        .find((userOperationCenter) => userOperationCenter.operationCenter.id === currentOperationCenterId)
        .plants.find((userPlant) => userPlant.plant.id === currentPlantId).departments;

      currentDepartments?.forEach((userDepartment) => {
        if (userDepartment.department.id === this.filtersFormGroup.value.department) {
          userDepartment.filtered = true;

          userDepartment.workingAreas?.forEach((userWorkingArea) => {
            if (this.filtersFormGroup.value.workingAreas.includes(userWorkingArea.workingArea.id)) {
              userWorkingArea.filtered = true;

              this.filtersFormGroup.value.workingAreas?.forEach((workingAreaId: number, index: number) => {
                if (workingAreaId === userWorkingArea.workingArea.id) {
                  const selectedUserEquipmentsIds = this.filtersFormGroup.value.equipmentsFormControls[index];

                  userWorkingArea.equipments?.forEach((userEquipment) => {
                    userEquipment.filtered = selectedUserEquipmentsIds.includes(userEquipment.equipment.id);
                  });
                }
              });
            } else {
              userWorkingArea.filtered = false;
              userWorkingArea.equipments?.forEach((userEquipment) => {
                userEquipment.filtered = false;
              });
            }
          });
        } else {
          userDepartment.filtered = false;
          userDepartment.workingAreas?.forEach((userWorkingArea) => {
            userWorkingArea.filtered = false;
            userWorkingArea.equipments?.forEach((userEquipment) => {
              userEquipment.filtered = false;
            });
          });
        }
      });
    }

    this.isSaving = true;
    this.saveError = false;

    this.userFiltersService
      .saveUserFilters(this.currentFilters)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe({
        next: (response) => {
          this.closeDrawer();
          this.globalMessageService.showSuccess({
            message: this.translateService.instant(this.labels.saveSuccess),
            duration: GLOBAL_MESSAGE_2SEC_DURATION
          });
          window.location.reload();
        },
        error: (error: EdmsError) => {
          this.error = error;
          this.saveError = true;
        }
      });
  }

  public closeDrawer(): void {
    this.drawer.toggle();
  }

  public get showSpinner(): boolean {
    return (!this.filtersFormGroup || !this.operationCenters || this.isSaving) && !this.error;
  }

  private assignOperationCentersAndPlants(
    operationCenters: UserOperationCenterDTO[],
    currentOperationCenterId: number,
    currentPlantId: number
  ) {
    operationCenters?.forEach((userOperationCenter) => {
      if (userOperationCenter.operationCenter.id === currentOperationCenterId) {
        userOperationCenter.filtered = true;
      }
      userOperationCenter.plants?.forEach((userPlant) => {
        if (userPlant.plant.id === currentPlantId) {
          userPlant.filtered = true;
        }
      });
    });
  }

  private loadFilters(): void {
    this.error = null;
    this.isSaving = false;
    this.saveError = false;

    this.userFiltersService.getUserFilters().subscribe({
      next: (filters) => {
        if (!this.hasFilters(filters)) {
          this.error = {
            message: this.translateService.instant(this.labels.userHasNoPermissions)
          } as EdmsError;
          return;
        }

        this.currentFilters = JSON.parse(JSON.stringify(filters));

        this.operationCenters = [...filters.operationCenters];

        this.createFormGroup();
        this.handleOperationCenterChange();
        this.handlePlantChange();
        this.handleDepartmentChange();
        this.handleWorkingAreaChange();
      },
      error: (error: EdmsError) => {
        this.error = error;
      }
    });
  }

  private resetFilters(): void {
    this.filtersFormGroup = null;
    this.operationCenters = null;
    this.plants = null;
    this.departments = null;
    this.workingAreas = null;
  }

  private createFormGroup(): void {
    const selectedOperationCenter =
      this.operationCenters.find((operationCenter) => operationCenter.filtered) ?? this.operationCenters[0];

    this.filtersFormGroup = this.fb.group({
      operationCenter: [selectedOperationCenter?.operationCenter.id],
      plants: [],
      department: [],
      workingAreas: [null, [requireCheckboxesToBeCheckedValidator()]],
      equipmentsFormControls: this.fb.array([])
    });

    this.loadPlantsFromOperationCenter(selectedOperationCenter);
    this.loadDepartmentsFromPlant(this.selectedPlant);
  }

  private loadPlantsFromOperationCenter(selectedOperationCenter: UserOperationCenterDTO): void {
    this.plants = selectedOperationCenter.plants;
    this.selectedPlant = this.plants?.find((plant) => plant.filtered) ?? this.plants[0];

    this.filtersFormGroup.controls.plants.setValue(this.selectedPlant.plant.id);
  }

  private loadDepartmentsFromPlant(selectedPlant: UserPlantDTO): void {
    this.departments = selectedPlant?.departments;
    this.selectedDepartment = this.departments?.filter((department) => department.filtered);

    // se c'è un solo department in totale
    if (this.selectedDepartment?.length === 1 && this.departments?.length === 1) {
      const department = this.selectedPlant.departments.find((d) => d.filtered);
      const waCount = department.workingAreas?.filter((d) => d.filtered).length;

      // per ogni wa controllo se sono stati selezionati tutti gli equipment
      let allEqSelected = true;
      department.workingAreas?.forEach((wa) => {
        if (wa.equipments?.filter((eq) => eq.filtered).length !== wa.equipments?.length) {
          allEqSelected = false;
        }
      });

      // se sono state selezionate tutte le wa
      // e se sono stati selezionati tutti gli equipment per tutte le wa -> passo il department
      if ((!!!waCount || waCount === department.workingAreas?.length) && allEqSelected) {
        this.filtersFormGroup.controls.department.setValue(0);
        this.allDepartments = true;
      } else {
        this.filtersFormGroup.controls.department.setValue(
          this.selectedDepartment?.map((userDepartment) => userDepartment.department.id)[0]
        );

        this.loadWorkingAreasFromDepartment(this.selectedDepartment[0]);
        this.setEquipmentsCheckboxSelectAllState();
        this.resetEquipmentsFormControls();
      }
    }
    // All departments selected
    else if (this.departments?.length === this.selectedDepartment?.length) {
      this.filtersFormGroup.controls.department.setValue(0);
      this.allDepartments = true;
    } else {
      this.filtersFormGroup.controls.department.setValue(
        this.selectedDepartment?.map((userDepartment) => userDepartment.department.id)[0]
      );

      this.loadWorkingAreasFromDepartment(this.selectedDepartment[0]);
      this.setEquipmentsCheckboxSelectAllState();
      this.resetEquipmentsFormControls();
    }
  }

  private loadWorkingAreasFromDepartment(selectedDepartment: UserDepartmentDTO): void {
    this.workingAreas = selectedDepartment?.workingAreas;
    this.selectedWorkingAreas = this.workingAreas?.filter((workingArea) => workingArea.filtered);

    this.filtersFormGroup.controls.workingAreas.setValue(
      this.selectedWorkingAreas?.map((userWorkingArea) => userWorkingArea.workingArea.id)
    );
  }

  private loadAllDepartmentsAndItsChildren(): void {
    this.cleanCurrentFilters();

    const currentOperationCenterId = this.filtersFormGroup.controls.operationCenter.value;
    const currentPlantId = this.filtersFormGroup.controls.plants.value;

    this.assignOperationCentersAndPlants(this.currentFilters.operationCenters, currentOperationCenterId, currentPlantId);

    const currentDepartments = this.currentFilters.operationCenters
      .find((userOperationCenter) => userOperationCenter.operationCenter.id === currentOperationCenterId)
      .plants.find((userPlant) => userPlant.plant.id === currentPlantId)?.departments;

    currentDepartments?.forEach((userDepartment) => {
      userDepartment.filtered = true;

      userDepartment?.workingAreas?.forEach((userWorkingArea) => {
        userWorkingArea.filtered = true;

        userWorkingArea?.equipments?.forEach((userEquipment) => {
          userEquipment.filtered = true;
        });
      });
    });
  }

  private handleOperationCenterChange(): void {
    this.filtersFormGroup.controls.operationCenter.valueChanges.pipe(untilDestroyed(this)).subscribe((operationCenterId) => {
      const selectedOperationCenter = this.operationCenters.filter(
        (userOperationCenter) => userOperationCenter.operationCenter.id === operationCenterId
      )[0];

      this.loadPlantsFromOperationCenter(selectedOperationCenter);
      this.resetPlantFormControls();
    });
  }

  private handlePlantChange(): void {
    this.filtersFormGroup.controls.plants.valueChanges.pipe(untilDestroyed(this)).subscribe((plantId) => {
      const selectedPlant = this.plants.filter((userPlant) => userPlant.plant.id === plantId)[0];

      this.loadDepartmentsFromPlant(selectedPlant);
      this.resetDepartmentFormControls();
    });
  }

  private handleDepartmentChange(): void {
    this.filtersFormGroup.controls.department.valueChanges.pipe(untilDestroyed(this)).subscribe((departmentId) => {
      const selectedDepartment = this.departments?.filter((userDepartment) => userDepartment.department.id === departmentId)[0];

      this.loadWorkingAreasFromDepartment(selectedDepartment);
      this.setEquipmentsCheckboxSelectAllState();
      this.resetEquipmentsFormControls();

      // All departments option
      if (!selectedDepartment) {
        this.allDepartments = true;
        this.loadAllDepartmentsAndItsChildren();
      } else if (!!!selectedDepartment.workingAreas) {
        this.allDepartments = false;
        this.filtersFormGroup.controls.workingAreas.disable();
      } else {
        this.filtersFormGroup.controls.workingAreas.enable();
        this.allDepartments = false;
      }
    });
  }

  private handleWorkingAreaChange(): void {
    this.filtersFormGroup.controls.workingAreas.valueChanges.pipe(untilDestroyed(this)).subscribe((workingAreaIds: number[]) => {
      this.selectedWorkingAreas = this.workingAreas?.filter((userWorkingArea) =>
        workingAreaIds.includes(userWorkingArea.workingArea.id)
      );

      this.selectedWorkingAreas?.forEach((workingArea, index) => {
        if (workingArea?.equipments) {
          for (const eq of workingArea?.equipments) {
            eq.filtered = true;
          }
        }
      });

      this.setEquipmentsCheckboxSelectAllState();
      this.resetEquipmentsFormControls();
    });
  }

  private resetPlantFormControls(): void {
    this.filtersFormGroup.controls.plants.reset();
    this.resetDepartmentFormControls();
  }

  private resetDepartmentFormControls(): void {
    this.filtersFormGroup.controls.department.reset();
    this.resetEquipmentsFormControls();
  }

  private resetEquipmentsFormControls(): void {
    this.equipmentsFormControls.clear();

    this.selectedWorkingAreas?.forEach((workingArea) => {
      const selectedEquipmentsIds = this.getSelectedEquipmentsIdsFromWorkingArea(workingArea);
      let control;
      if (!!selectedEquipmentsIds) {
        control = new FormControl(selectedEquipmentsIds, [Validators.required]);
      } else {
        control = new FormControl(selectedEquipmentsIds);
      }

      control.markAsTouched();

      this.equipmentsFormControls.push(control);
    });
  }

  private getSelectedEquipmentsIdsFromWorkingArea(userWorkingArea: UserWorkingAreaDTO): number[] {
    return userWorkingArea.equipments
      ?.filter((userEquipment) => userEquipment?.filtered)
      ?.map((userEquipment) => userEquipment?.equipment?.id);
  }

  private setEquipmentsCheckboxSelectAllState(): void {
    this.selectedWorkingAreas?.forEach((workingArea, index) => {
      this.equipmentsAllSelected[index] = workingArea.equipments?.every((equipment) => equipment.filtered);
      this.equipmentsIndeterminateState[index] =
        !this.equipmentsAllSelected[index] && workingArea.equipments?.some((equipment) => equipment.filtered);
    });
  }

  private calculateEquipmentSelectState(index: number): { allSelected: boolean; indeterminate: boolean } {
    const options = this.select.toArray()[index].options.toArray();
    const allSelected = options.every((option) => option.selected);
    const someSelected = options.some((option) => option.selected);

    return {
      allSelected,
      indeterminate: !allSelected && someSelected
    };
  }

  private getSelectedEquipmentsNamesByIndex(index: number, separator: string): string {
    const selectedEquipmentsIds = this.equipmentsFormControls.controls[index].value;

    return this.selectedWorkingAreas
      .map((userWorkingArea) => userWorkingArea.equipments)
      [index].map((userEquipment) => userEquipment.equipment)
      .filter((equipment) => selectedEquipmentsIds.includes(equipment.id))
      .map((equipment) => equipment.literal.translateValue)
      .join(separator);
  }

  private hasFilters(filters: UserFiltersDTO): boolean {
    const hasOperationCenter = filters.operationCenters?.length > 0;
    const hasPlants = hasOperationCenter && filters.operationCenters[0].plants?.length > 0;
    const hasDepartments = hasPlants && filters.operationCenters[0].plants[0].departments?.length > 0;

    return hasDepartments;
  }

  private cleanCurrentFilters(): void {
    this.currentFilters.operationCenters?.forEach((userOperationCenter) => {
      userOperationCenter.filtered = false;

      userOperationCenter.plants?.forEach((userPlant) => {
        userPlant.filtered = false;

        userPlant.departments?.forEach((userDepartment) => {
          userDepartment.filtered = false;

          userDepartment?.workingAreas?.forEach((userWorkingArea) => {
            userWorkingArea.filtered = false;

            userWorkingArea?.equipments?.forEach((userEquipment) => {
              userEquipment.filtered = false;
            });
          });
        });
      });
    });
  }
}
