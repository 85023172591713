import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ManagementIdeaServiceService } from '@data/services/management-idea-service.service';
import { EdmsError } from '@app/types/error';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { DatePipe } from '@angular/common';
import { NotificationDTO } from '@data/models/notification-dto';
import { ProgressSpinnerDialogService } from '@shared/services/progress-spinner-dialog.service';
import { IdeaStatus } from '@data/models/IdeaDTO';
import { TranslateService } from '@ngx-translate/core';
import { RouteConstants } from '@app/constants/route.constants';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-notification-idea',
  templateUrl: './notification-idea.component.html',
  styleUrls: ['./notification-idea.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationIdeaComponent implements OnInit {
  error: EdmsError;
  notifications: NotificationDTO[];
  notSeenYet = true;
  countNotification: number;
  public labels = {
    title: marker('idea.notification.title'),
    titleEmpty: marker('idea.notification.titleEmpty'),
    descriptionEmpty: marker('idea.notification.descriptionEmpty'),
    markAllRead: marker('idea.notification.markAllRead'),
    actionStatusDraft: marker('idea.status.draftAction'),
    actionStatusSubmitted: marker('idea.status.submittedAction'),
    actionStatusApproved: marker('idea.status.approvedAction'),
    actionStatusRejected: marker('idea.status.rejectedAction'),
    actionStatusOnHold: marker('idea.status.onHoldAction'),
    actionStatusCancelled: marker('idea.status.cancelledAction'),
    actionStatusOnGoing: marker('idea.status.onGoingAction'),
    actionStatusImplemented: marker('idea.status.implementedAction'),
    actionStatusClosed: marker('idea.status.closedAction')
  };
  constructor(
    private managementIdeaService: ManagementIdeaServiceService,
    private spinnerService: ProgressSpinnerDialogService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    public datepipe: DatePipe,
    private cookieService: CookieService
  ) {}

  public markAllRead(): void {
    this.managementIdeaService.readAllNotifications().subscribe(() => {});
    this.countNotification = 0;
  }
  public ngOnInit(): void {
    this.managementIdeaService.getNotificationsCount().subscribe((res) => {
      this.countNotification = res.index;
    });
    const checkingIntervalOuaht = setInterval(() => {
      this.managementIdeaService.getNotificationsCount().subscribe((res) => {
        this.countNotification = res.index;
      });
    }, 300000); // 5 min
  }
  public getAction(notification: NotificationDTO): string {
    switch (notification.status) {
      case IdeaStatus.DRAFT:
        return this.translateService.instant(this.labels.actionStatusDraft);
      case IdeaStatus.APPROVED:
        return this.translateService.instant(this.labels.actionStatusApproved);
      case IdeaStatus.CANCELLED:
        return this.translateService.instant(this.labels.actionStatusCancelled);
      case IdeaStatus.CLOSED:
        return this.translateService.instant(this.labels.actionStatusClosed);
      case IdeaStatus.IMPLEMENTED:
        return this.translateService.instant(this.labels.actionStatusImplemented);
      /*case IdeaStatus.ON_GOING:
        return this.translateService.instant(this.labels.actionStatusOnGoing);*/
      case IdeaStatus.ON_HOLD:
        return this.translateService.instant(this.labels.actionStatusOnHold);
      case IdeaStatus.REJECTED:
        return this.translateService.instant(this.labels.actionStatusRejected);
      case IdeaStatus.SUBMITTED:
        return notification.creatorUID === localStorage.getItem('uid_' + window.location.hostname)
          ? this.translateService.instant(this.labels.actionStatusSubmitted)
          : this.translateService.instant(this.labels.actionStatusSubmitted);
    }
  }
  public redirectToDetailIdea(id: number, idNotification: number) {
    const spinner = this.spinnerService.show();
    this.managementIdeaService.checkNotifications(idNotification).subscribe({
      next: (res: NotificationDTO[]) => {
        this.managementIdeaService.getNotificationsCount().subscribe((res) => {
          this.countNotification = res.index;
        });

        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate([`./${RouteConstants.DASHBOARD_IDEA}/${RouteConstants.IDEA_DETAIL}/${id}`], {
            relativeTo: this.route.root
          })
        );

        this.spinnerService.hide(spinner);
      },
      error: (error: EdmsError) => {
        this.error = error;
        this.spinnerService.hide(spinner);
      }
    });
  }
  public menuOpened() {
    const spinner = this.spinnerService.show();
    this.managementIdeaService.getNotifications().subscribe({
      next: (res: NotificationDTO[]) => {
        this.notifications = res;
        this.spinnerService.hide(spinner);
      },
      error: (error: EdmsError) => {
        this.error = error;
        this.spinnerService.hide(spinner);
      }
    });
  }
}

/**
 *  CREATION(0L),
    APPROVE(1L),
    REQUEST_MORE_INFO(2L),
    MORE_INFO_ADDED(3L),
    APPROVED(4L),
    REJECTED(5L),
    ON_HOLD(6L),
    IMPLEMENT(7L),
    CANCELLED(8L),
    IMPLEMENTED(9L),
    TO_EVALUATE(10L),
    CLOSED(11L),
    REOPENED(12L);
 */
