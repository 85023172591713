import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EdmsError } from '@app/types/error';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ActionDTO } from '@data/models/action-dto';
import { UserDepartmentDTO } from '@data/models/user-department-dto';
import { UserEquipmentDTO } from '@data/models/user-equipment-dto';
import { UserFiltersDTO } from '@data/models/user-filters-dto';
import { UserWorkingAreaDTO } from '@data/models/user-workingarea-dto';
import { UserFiltersService } from '@data/services/user-filters.service';
import { TranslateService } from '@ngx-translate/core';
import { ProgressSpinnerDialogService } from '@shared/services/progress-spinner-dialog.service';
import { ScrollcomponentComponent } from '@shared/components/scrollcomponent/scrollcomponent.component';

@Component({
  selector: 'app-at-location',
  templateUrl: './location.component.html'
})
export class LocationComponent extends ScrollcomponentComponent implements OnInit {
  @Output()
  public workingAreaChange: EventEmitter<null> = new EventEmitter();

  public formGroup: FormGroup;

  public departmentControl: FormControl = new FormControl('', [Validators.required]);
  public workingAreaControl: FormControl = new FormControl([]);
  public equipmentControl: FormControl = new FormControl(-1);

  public departmentList: UserDepartmentDTO[] = [];
  public workingAreaList: UserWorkingAreaDTO[] = [];
  public equipmentList: UserEquipmentDTO[] = [];

  public labels: { [key: string]: string } = {
    department: marker('apps.appsLanding.other.dialog.location.department'),
    workingArea: marker('apps.appsLanding.other.dialog.location.workingArea'),
    equipment: marker('apps.appsLanding.other.dialog.location.equipment'),
    optional: marker('global.optional'),
    requiredError: marker('error.required')
  };

  public error: EdmsError;

  private readonly NO_EQUIPMENT_OPTION: UserEquipmentDTO = {
    equipment: {
      id: -1,
      code: '',
      description: '',
      literal: { id: 0, translateValue: this.translate.instant('apps.appsLanding.other.dialog.location.noEquipment') }
    }
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { model: ActionDTO },
    private spinnerService: ProgressSpinnerDialogService,
    private fb: FormBuilder,
    private userFiltersService: UserFiltersService,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.createFormGroup();
    this.loadLists();
    this.loadData();
  }

  public get isFormValid(): boolean {
    return this.formGroup?.valid;
  }

  public updateModel(): void {
    // TODO (IMPORTANT): Could we use the whole object as follows?
    // * `this.departmentList.find(d => d.id === this.departmentControl.value)`
    // *
    // * Same for the other objects. Does the backend need only the id for any reason?
    this.data.model.trackerAction.department = this.departmentControl.value ? { id: this.departmentControl.value } : null;
    if (this.workingAreaControl.value.length) {
      this.data.model.trackerAction.workingAreas = [];
      for (const wa of this.workingAreaControl.value) {
        this.data.model.trackerAction.workingAreas.push({ id: wa });
      }
    } else {
      this.data.model.trackerAction.workingAreas = null;
    }

    this.data.model.trackerAction.equipment =
      this.equipmentControl.value !== -1
        ? {
            id: this.equipmentControl.value
          }
        : null;
  }

  public onDepartmentSelect(index: number): void {
    this.workingAreaControl.setValue([]);
    this.equipmentControl.setValue(-1);

    this.updateModel();
    this.workingAreaChange.emit();

    this.workingAreaList = this.departmentList[index]?.workingAreas;
  }

  public onWorkingAreaSelect(index: number): void {
    this.equipmentControl.setValue(-1);

    this.updateModel();
    this.workingAreaChange.emit();

    if (this.workingAreaControl.value.length === 1) {
      const waid = this.workingAreaControl.value[0];

      this.equipmentList = [this.NO_EQUIPMENT_OPTION].concat(
        this.workingAreaList?.find((x) => x.workingArea.id === waid)?.equipments
      );
    } else {
      this.equipmentList = [];
    }
  }

  public toggleChange(event: MatButtonToggleChange) {
    const toggle = event.source;
    if (toggle) {
      const group = toggle.buttonToggleGroup;
      /*  if (event.value.some((item: number) => item === toggle.value)) {
        group.value = [toggle.value];
      } */
    }
  }

  private createFormGroup(): void {
    this.formGroup = this.fb.group({
      department: this.departmentControl,
      workingArea: this.workingAreaControl,
      equipment: this.equipmentControl
    });
  }

  private loadLists(): void {
    const spinner = this.spinnerService.show();

    this.userFiltersService.getUserActiveFilters().subscribe({
      next: (data: UserFiltersDTO) => {
        if (data && data.operationCenters && data.operationCenters.length > 0) {
          const operatonCenter = data.operationCenters.find((i) => i.filtered);
          if (operatonCenter.plants && operatonCenter.plants.length > 0 && operatonCenter.operationCenter.enabled) {
            operatonCenter.plants.forEach((userPlant) => {
              if (userPlant.departments && userPlant.departments.length > 0 && userPlant.plant.enabled) {
                this.departmentList.push(
                  ...userPlant.departments.filter((userDepartment: UserDepartmentDTO) => userDepartment.department.enabled)
                );
              }
            });

            if (this.data.model.trackerAction?.department) {
              const departmentIndex = this.departmentList.findIndex(
                (userDepartment) => userDepartment.department.id === this.data.model.trackerAction?.department?.id
              );
              this.workingAreaList = this.departmentList[departmentIndex]?.workingAreas;
              if (this.data.model.trackerAction.workingAreas?.length) {
                for (const wa of this.data.model.trackerAction?.workingAreas) {
                  const workingAreaIndex = this.workingAreaList?.findIndex(
                    (userWorkingArea) => userWorkingArea.workingArea?.id === wa?.id
                  );
                  this.equipmentList = [this.NO_EQUIPMENT_OPTION].concat(
                    this.workingAreaList ? this.workingAreaList[workingAreaIndex]?.equipments : []
                  );
                }
              }
            } else {
              const enabledDepartmentIndex = this.departmentList.findIndex((userDepartment) => userDepartment.department.enabled);
              this.departmentControl.setValue(
                enabledDepartmentIndex !== -1
                  ? this.departmentList[enabledDepartmentIndex].department.id
                  : this.departmentList[0].department.id
              );
              this.onDepartmentSelect(enabledDepartmentIndex !== -1 ? enabledDepartmentIndex : 0);
            }
          }
        }

        this.spinnerService.hide(spinner);
      },
      error: (error) => {
        this.error = error;
      }
    });
  }

  private loadData(): void {
    if (this.data) {
      this.departmentControl.setValue(this.data.model.trackerAction?.department?.id);
      this.workingAreaControl.setValue(this.data.model.trackerAction?.workingAreas?.map((x) => x.id));
      this.equipmentControl.setValue(this.data.model.trackerAction?.equipment ? this.data.model.trackerAction.equipment.id : -1);
    }
  }
}
