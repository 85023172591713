import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeIt from '@angular/common/locales/it';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ENV } from '@app/constants/global.constants';
import { CoreModule } from '@app/core.module';
import { translateLoaderFactory } from '@app/locale/translate-loader.factory';
// import { GigyaService } from '@app/security/gigya.service';
import { TokenInterceptor } from '@app/security/token.interceptor';
import { LanguageService } from '@app/services/language.service';
import { environment } from '@env';
import { LayoutModule } from '@layout/layout.module';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { LANGUAGES, LANG_EN, LANG_IT, LANG_ES, LANG_FR } from '@shared/constants/language.constants';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AzureBlobStorageService } from './shared/services/azure-blob-storage.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CookieService } from 'ngx-cookie-service';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE
  // MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { SharedModule } from '@shared/shared.module';

import { A2hsBrowserPromptComponent } from '@shared/components/a2hs/components/a2hs-browser-prompt/a2hs-browser-prompt.component';
// eslint-disable-next-line max-len
import { A2hsIosSafariHow2Component } from '@shared/components/a2hs/components/a2hs-ios-safari-how2/a2hs-ios-safari-how2.component';
import { A2hsComponent } from '@shared/components/a2hs/a2hs.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MAT_DATE_LOCALE } from '@angular/material/core';

// Languages
registerLocaleData(localeEn, LANG_EN);
registerLocaleData(localeIt, LANG_IT);
registerLocaleData(localeEs, LANG_ES);
registerLocaleData(localeFr, LANG_FR);

// eslint-disable-next-line
declare var gigya: any;
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      allowRedirectInIframe: true,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
// export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
//   const protectedResourceMap = new Map<string, Array<string>>();
//   // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
//   protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);

//   return {
//     interactionType: InteractionType.Redirect,
//     protectedResourceMap
//   };
// }

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/login-failed'
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MsalModule,
    ScrollingModule,
    LoggerModule.forRoot({
      enableSourceMaps: true,
      level: environment.logLevel || NgxLoggerLevel.ERROR
    }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),

    CoreModule,
    AppRoutingModule,
    LayoutModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true
    // },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },

    // {
    //   provide: MSAL_INTERCEPTOR_CONFIG,
    //   useFactory: MSALInterceptorConfigFactory
    // },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    CookieService,

    AzureBlobStorageService,
    TranslateService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: ENV, useValue: environment },
    // {
    //   provide: GIGYA_SERVICE,
    //   useFactory: () => new GigyaService(gigya)
    // },
    {
      provide: LOCALE_ID,
      useFactory: () => {
        const navigatorValue = navigator ? navigator.language.substr(0, 2) : LANG_EN;

        // const availableLanguages = LANGUAGES.filter(
        //   (lang) => lang.id === LANG_EN || lang.id === LANG_IT || lang.id === LANG_ES
        // ).map((lang) => lang.id);
        // TODO: uncomment when all languages have their own translation settings
        const availableLanguages = LANGUAGES.map((lang) => lang.id);
        return availableLanguages.includes(navigatorValue) ? navigatorValue : LANG_EN;
      }
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'en-GB',
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
  constructor(private languageService: LanguageService) {
    this.setupAppLanguage();
  }

  private setupAppLanguage(): void {
    this.languageService.setDefaultLanguage();
    this.languageService.setLanguage(null);
  }
}
