import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { GLOBAL_SUPER_USER, LINE_LEAD, SUPER_USER, STAKEHOLDER, ENV } from '@app/constants/global.constants';
import { RouteConstants } from '@app/constants/route.constants';
import { Env } from '@app/types/env';
import { EdmsError } from '@app/types/error';
import { ServiceResponse } from '@app/types/service-response';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { GroupDTO } from '@data/models/group-dto';
import UserDTO from '@data/models/user-dto';
import { UserFiltersDTO } from '@data/models/user-filters-dto';
import { UserOperationCenterDTO } from '@data/models/user-operationcenter-dto';
import { UserPlantDTO } from '@data/models/user-plant-dto';
import { ManagementServiceService } from '@data/services/management-service.service';
import { UserFiltersService } from '@data/services/user-filters.service';
import { UserService } from '@data/services/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProgressSpinnerDialogService } from '@shared/services/progress-spinner-dialog.service';
import { url } from 'inspector';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-idea-layout',
  templateUrl: './dashboard-idea-layout.component.html',
  styleUrls: ['./dashboard-idea-layout.component.scss']
})
export class DashboardIdeaLayoutComponent implements OnInit {
  public labels = {
    menuHomeButton: marker('dashboard.menu.home'),
    menuIdeaTrackerButton: marker('dashboard.menu.IdeaTracke'),
    menuIdeaDetailButton: marker('dashboard.menu.IdeaDetail'),
    menuPowerBiButton: marker('dashboard.menu.PowerBi'),
    menuEbosButton: marker('dashboard.menu.Ebos'),
    menuFiltersButton: marker('dashboard.menu.filters'),
    menuAddIdea: marker('dashboard.menu.AddIdea')
  };

  public readonly APPS = '/dashboard/apps';
  public readonly ADMINISTRATION = '/dashboard/administration';
  public readonly MANAGEMENT = '/dashboard/management';
  public readonly WEEKLYPLANNING = '/dashboard/administration/weekly-planning';
  public readonly SUPER_USER = SUPER_USER;
  public readonly GLOBAL_SUPER_USER = GLOBAL_SUPER_USER;
  public readonly LINE_LEAD = LINE_LEAD;
  public readonly STAKEHOLDER = STAKEHOLDER;

  public isLanding = true;
  public currentSelection: string;
  public loggedUserInfo$: Observable<ServiceResponse<UserDTO>>;
  public appHidden = true;
  public appGroups: GroupDTO[];
  public error: EdmsError;
  public fillBackground: boolean;
  public selectedPlant: UserPlantDTO;
  private userFilters$: Observable<UserOperationCenterDTO>;
  private readonly FILLBACKGROUND: string[] = ['your-ideas'];
  constructor(
    @Inject(ENV) private env: Env,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private spinnerService: ProgressSpinnerDialogService,
    private managementService: ManagementServiceService,
    private userFiltersService: UserFiltersService
  ) {
    this.loggedUserInfo$ = this.userService.loggedUserInfo$;
    router.events.subscribe((val) => {
      if (this.FILLBACKGROUND.some((x) => window.location.pathname.toLowerCase().includes(x))) {
        this.fillBackground = true;
      } else {
        this.fillBackground = false;
      }
    });
  }

  ngOnInit(): void {
    this.manageUrl(window.location.href);

    /* this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.manageUrl(event.url);
      }
    });*/
  }

  public isEbosUser(): boolean {
    return this.userService.isEbosUser();
  }
  public get seeButton(): boolean {
    return !window.location.href.toUpperCase().includes(RouteConstants.YOUR_IDEA.toUpperCase());
  }
  public redirectToDashboard(): void {
    this.router.navigate([RouteConstants.DASHBOARD_IDEA]);
  }
  public redirectToEbosDashboard(): void {
    this.router.navigate([RouteConstants.DASHBOARD]);
  }

  public redirectToActionTracker(): void {
    this.router.navigate(['./' + RouteConstants.IDEA_TRACKER], {
      relativeTo: this.route
    });
  }

  public redirectToAddIdea(): void {
    this.router.navigate(['./' + RouteConstants.YOUR_IDEA], {
      relativeTo: this.route
    });
  }

  public redirectToLocation(): void {
    this.router.navigate(['./' + RouteConstants.IDEA_LOCATION], {
      relativeTo: this.route
    });
  }

  public redirectToPowerBi(): void {
    document.location.href = this.env.powerApplink;
  }

  public redirectToManagement(): void {
    this.router.navigate(['./' + RouteConstants.MANAGEMENT], {
      relativeTo: this.route
    });
  }

  public hideAppMenu(): void {
    if (!this.appHidden) {
      this.appHidden = true;
    }
  }

  public getAppGroups(): void {
    const spinner = this.spinnerService.show();
    this.userFilters$ = this.userFiltersService.getUserActiveFilters().pipe(
      map((filters: UserFiltersDTO) => {
        const operationCenter: UserOperationCenterDTO = filters.operationCenters.find((i) => i.filtered);
        return operationCenter;
      })
    );

    this.userFilters$.subscribe({
      next: (op: UserOperationCenterDTO) => {
        this.selectedPlant = op.plants.find((x) => x.filtered);

        this.managementService.getGroups(this.selectedPlant.plant.id).subscribe({
          next: (result: GroupDTO[]) => {
            this.appGroups = result;
            this.spinnerService.hide(spinner);
          },
          error: (error: EdmsError) => {
            this.error = error;
            this.spinnerService.hide(spinner);
          }
        });
      }
    });
  }

  private manageUrl(url: string): void {
    if (url.includes(RouteConstants.MANAGEMENT)) {
      this.isLanding = false;
      this.currentSelection = this.MANAGEMENT;
    } else if (url.includes(RouteConstants.APPS)) {
      this.isLanding = false;
      this.currentSelection = this.APPS;
    } else if (url.includes(RouteConstants.LATEST_INFORMATION)) {
      this.isLanding = false;
    } else if (url.includes(RouteConstants.ADMINISTRATION)) {
      this.isLanding = false;
      this.currentSelection = this.ADMINISTRATION;
    } else {
      this.isLanding = true;
      this.currentSelection = '';
    }
  }
}
