import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileDTO } from '@data/models/file-dto';
import { MatCarouselComponent } from '@ngbmodule/material-carousel';
@Component({
  selector: 'app-view-immages-dialog',
  templateUrl: './view-immages-dialog.component.html',
  styleUrls: ['./view-immages-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewImmagesDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('carousel', { static: true }) carousel: MatCarouselComponent;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { model: { files: FileDTO[]; index: number } }) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.data.model.index) {
      for (let i = 0; i < this.data.model.index; i++) {
        this.carousel.next();
      }
    }
  }
}
