import { Component, OnInit } from '@angular/core';
import { A2hsService } from '@data/services/a2hs.service';

@Component({
  selector: 'app-a2hs-browser-prompt',
  templateUrl: './a2hs-browser-prompt.component.html',
  styleUrls: ['./a2hs-browser-prompt.component.scss']
})
export class A2hsBrowserPromptComponent implements OnInit {
  public a2hs: A2hsService;
  constructor(public a2hs1: A2hsService) {
    this.a2hs = a2hs1;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.a2hs.close();
    }, 10000);
  }

  close() {
    this.a2hs.close();
  }
}
