import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class UrlService {
  public previousUrl$: Observable<string>;
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor() {
    this.previousUrl$ = this.previousUrl.asObservable();
  }

  public setPreviousUrl(previousUrl: string): void {
    this.previousUrl.next(previousUrl);
  }
}
