import { Directionality } from '@angular/cdk/bidi';
import { SelectionChange } from '@angular/cdk/collections';
import { CdkStepper, MAT_STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectionListChange } from '@angular/material/list';
import { MatSelectChange } from '@angular/material/select';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { ItemStepper } from '@data/models/item-stepper';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [
    {
      provide: MAT_STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ],
  encapsulation: ViewEncapsulation.None
  // providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
})
export class StepperComponent implements OnInit, OnChanges {
  @ViewChild(MatHorizontalStepper)
  public matStepper: MatHorizontalStepper;
  @Input() data: ItemStepper[];
  @Input() valid: boolean;
  @Output() changeEvent = new EventEmitter();

  public forms: FormControl[];
  private changeByButtom = false;

  constructor(private formBuilder: FormBuilder) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.valid) {
      if (!!this.matStepper) {
        this.setState(this.forms[this.matStepper.selectedIndex], !changes.valid.currentValue);
      }
    }
  }

  ngOnInit(): void {
    this.forms = [];
    this.data.forEach((x) => {
      this.forms.push(new FormControl(''));
    });
  }

  /* onClick(index: number): void {
    this.selectedIndex = index;
  }

  complete() {
    this.matStepper.selected.completed = true;
    this.matStepper.selected.editable = false;
    this.matStepper.next();
  }

  public setValue(icon: string) {
    this.newItemEvent.emit(icon);
  }

  getStepper() {
    return this.stepper;
  }*/

  next() {
    this.changeByButtom = true;
    //this.forms[this.matStepper.selectedIndex].controls.enable.setValue('true');
    this.matStepper.selected.completed = true;
    this.matStepper.selected.hasError = false;
    this.matStepper.next();
  }
  previous() {
    this.changeByButtom = true;
    //  this.forms[this.matStepper.selectedIndex].controls.enable.setValue('');
    this.matStepper.selected.completed = true;
    this.matStepper.selected.interacted = false;
    this.matStepper.selected.hasError = true;
    this.matStepper.previous();
  }
  metodo(event: number) {
    if (!this.changeByButtom && this.changeEvent) {
      this.changeEvent.emit(event);
    }
    this.changeByButtom = false;
  }
  private setState(control: FormControl, state: boolean) {
    if (state) {
      control.setErrors({ required: true });
    } else {
      control.reset();
    }
  }
}
