import { Pipe, PipeTransform } from '@angular/core';
import UserDTO from '@data/models/user-dto';

@Pipe({
  name: 'filterUser'
})
export class FilterUserPipe implements PipeTransform {
  transform(value: UserDTO[], searchString: string): unknown {
    let res = [];
    if (searchString === '') {
      res = value;
    } else {
      res = value.filter(
        (x) =>
          x.fullName.toLowerCase().indexOf(searchString?.toLowerCase()) >= 0 ||
          x.fullName.toLowerCase().replace(' ', '').indexOf(searchString?.toLowerCase()) >= 0
      );
    }

    return res?.sort((a: UserDTO, b: UserDTO) => a.fullName.localeCompare(b.fullName));
  }
}
