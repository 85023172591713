<div #dataToExport>
    <mat-horizontal-stepper
        [(selectedIndex)]="tabActive"
        (selectionChange)="selectedTabChange($event)"
        class="mat-step-header-bpst"
        [linear]="true"
        #tabGroup
    >
        <!--  <mat-tab-group class="ddsTabs" id="ddsTabs" [(selectedIndex)]="tabActive" (selectedTabChange)="selectedTabChange()" #tabGroup>-->
        <!-- GENERAL -->

        <mat-step label="{{ labels.general | translate }}">
            <!--<mat-tab label="{{ labels.general | translate }}" preserveContent> -->
            <div class="pv-questions" fxLayout="column" fxLayoutAlign="space-evenly stretch" id="tab0">
                <div class="pv-questions-container">
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="question-group-title">
                        <h2 class="group-title">{{ labels.complete | translate }}</h2>
                    </div>

                    <div>
                        <div *ngIf="questions.length == 0">
                            <span class="group-subtitle">{{ labels.emptyGroup | translate }}</span>
                        </div>

                        <!-- TEMPLATE QUESTIONS -->
                        <app-questions-template
                            [stage]=""
                            [Dates]="Dates"
                            [questions]="questions"
                            [tabActive]="tabActive"
                            [pdfView]="pdfView"
                            [checkQuestions]="questions"
                            [chosenAnswer]="chosenAnswer"
                            [chosenAnswers]="chosenAnswers"
                            [appTypeId]="appTypeId"
                            [appName]="appName"
                            [appId]="appId"
                            [checklistId]="checklistId"
                            [isPreview]="isPreview"
                            [isReadOnly]="isReadOnly"
                            [checklistItems]="checklistItems"
                            [template]="template"
                            [priorityList]="priorityList"
                            [appResults]="appResults"
                            [templateId]="templateId"
                            [checklist]="checklist"
                            [questionFiles]="questionFiles"
                            [files]="files"
                        ></app-questions-template>
                        <br />
                        <br />

                        <!-- PROBLEM STATEMENT -->
                        <div class="pv-question-root">
                            <div class="pv-question">
                                <div class="pv-question__content-outer" fxLayout="column">
                                    <div class="pv-question__content" fxLayout="row" fxLayoutAlign="space-between start">
                                        <div fxLayout="column" fxLayoutAlign="space-between start" class="for-action">
                                            <!-- titolo della domanda -->
                                            <span class="pv-question__type" [innerHtml]="labels.problemStatement | translate">
                                            </span>
                                            <!-- sottotitolo della domanda -->
                                            <p
                                                class="pv-question__subtitle"
                                                [innerHtml]="labels.problemStatementSubtitle | translate"
                                            ></p>
                                        </div>
                                    </div>
                                    <mat-form-field class="pv-question__textarea">
                                        <!--   [(ngModel)]="getChosenAnswer(problemStatement).answerString"-->
                                        <textarea
                                            [placeholder]="!pdfView ? (labels.enterAnswer | translate) : ''"
                                            matInput
                                            [(ngModel)]="checklist.problemStatement"
                                            [readonly]="isReadOnly"
                                            [maxLength]="250"
                                        ></textarea>
                                        <span class="text-length" [hidden]="pdfView">
                                            {{ checklist.problemStatement ? checklist.problemStatement.length : 0 }}/ 250</span
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <br />
                        <br />

                        <!-- INTERIM -->
                        <div class="pv-question-root">
                            <div class="pv-question">
                                <div class="pv-question__content-outer" fxLayout="column">
                                    <div class="pv-question__content" fxLayout="row" fxLayoutAlign="space-between start">
                                        <div fxLayout="column" fxLayoutAlign="space-between start" class="for-action">
                                            <!-- titolo della domanda -->
                                            <span class="pv-question__type" [innerHtml]="labels.interim | translate"> </span>
                                            <!-- sottotitolo della domanda -->
                                            <p
                                                class="pv-question__subtitle"
                                                [innerHtml]="labels.interimtSubtitle | translate"
                                            ></p>
                                        </div>
                                    </div>
                                    <mat-form-field class="pv-question__textarea">
                                        <!--   [(ngModel)]="getChosenAnswer(problemStatement).answerString"-->
                                        <textarea
                                            matInput
                                            cdkTextareaAutosize
                                            #autosize="cdkTextareaAutosize"
                                            cdkAutosizeMinRows="1"
                                            cdkAutosizeMaxRows="5"
                                            [placeholder]="!pdfView ? (labels.enterAnswer | translate) : ''"
                                            [(ngModel)]="checklist.interimOrImmediateAction"
                                            [readonly]="isReadOnly"
                                            [maxLength]="250"
                                        ></textarea>
                                        <span class="text-length" [hidden]="pdfView">
                                            {{
                                                checklist.interimOrImmediateAction
                                                    ? checklist.interimOrImmediateAction.length
                                                    : 0
                                            }}/ 250</span
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <br />
                        <br />

                        <!-- INVESTIGATION -->

                        <div *ngIf="template.investigationQuestions && template.investigationQuestions.length > 0">
                            <div fxLayout="column" fxLayoutAlign="space-between start" class="for-action">
                                <!-- titolo della domanda -->
                                <span class="pv-question__type" [innerHtml]="labels.investigation | translate"> </span>
                                <!-- sottotitolo della domanda -->
                                <p class="pv-question__subtitle" [innerHtml]="labels.investigationSubtitle | translate"></p>
                            </div>

                            <app-questions-template
                                [stage]=""
                                [questions]="template.investigationQuestions"
                                [tabActive]="tabActive"
                                [pdfView]="pdfView"
                                [Dates]="Dates"
                                [checkQuestions]="template.investigationQuestions"
                                [chosenAnswer]="chosenAnswerInvestigations"
                                [chosenAnswers]="chosenAnswersInvestigations"
                                [appTypeId]="appTypeId"
                                [appName]="appName"
                                [appId]="appId"
                                [checklistId]="checklistId"
                                [isPreview]="isPreview"
                                [isReadOnly]="isReadOnly"
                                [checklistItems]="chosenAnswersInvestigations"
                                [template]="template"
                                [priorityList]="priorityList"
                                [appResults]="appResults"
                                [templateId]="templateId"
                                [checklist]="checklist"
                                [questionFiles]="questionFiles"
                                [files]="files"
                            ></app-questions-template>
                        </div>
                    </div>
                </div>
            </div>
        </mat-step>
        <!--          </mat-tab>-->
        <!-- STAGE 1 -->
        <mat-step label="{{ labels.stage1 | translate }}">
            <!--
        <mat-tab label="{{ labels.stage1 | translate }}"> -->
            <div class="pv-questions" fxLayout="column" fxLayoutAlign="space-evenly stretch" id="tab1">
                <div class="pv-questions-container">
                    <!-- STAGE 1 -->
                    <app-questions-template
                        [stage]="template.stages[0]"
                        [questions]="template.stages[0].questions"
                        [stageN]="0"
                        [Dates]="Dates"
                        [problemStatement]="checklist.stageAnswers[0].problemStatement"
                        [tabActive]="tabActive"
                        [pdfView]="pdfView"
                        [checkQuestions]="template.stages[0].questions"
                        [chosenAnswer]="chosenAnswerStage"
                        [chosenAnswers]="chosenAnswersStage"
                        [appTypeId]="appTypeId"
                        [appName]="appName"
                        [appId]="appId"
                        [checklistId]="checklistId"
                        [isPreview]="isPreview"
                        [isReadOnly]="isReadOnly"
                        [checklistItems]="checklist.stageAnswers[0]"
                        [template]="template"
                        [priorityList]="priorityList"
                        [appResults]="appResults"
                        [templateId]="templateId"
                        [checklist]="checklist"
                        [questionFiles]="questionFiles"
                        [files]="files"
                    ></app-questions-template>
                </div>
            </div>
        </mat-step>
        <!-- STAGE 2 -->
        <mat-step
            label="{{ labels.stage2 | translate }}"
            *ngIf="checkConditionsStage(0, template.stages[1].questions[0], template.stages[0].questions)"
        >
            <div class="pv-questions" fxLayout="column" fxLayoutAlign="space-evenly stretch" id="tab2">
                <div class="pv-questions-container">
                    <!-- STAGE 2  -->
                    <app-questions-template
                        [stage]="template.stages[1]"
                        [questions]="template.stages[1].questions"
                        [stageN]="1"
                        [Dates]="Dates"
                        [problemStatement]="checklist.stageAnswers[1].problemStatement"
                        [tabActive]="tabActive"
                        [pdfView]="pdfView"
                        [checkQuestions]="template.stages[0].questions"
                        [chosenAnswer]="chosenAnswerStage"
                        [chosenAnswers]="chosenAnswersStage"
                        [appTypeId]="appTypeId"
                        [appName]="appName"
                        [appId]="appId"
                        [checklistId]="checklistId"
                        [isPreview]="isPreview"
                        [isReadOnly]="isReadOnly"
                        [checklistItems]="checklist.stageAnswers[1]"
                        [template]="template"
                        [priorityList]="priorityList"
                        [appResults]="appResults"
                        [templateId]="templateId"
                        [checklist]="checklist"
                        [questionFiles]="questionFiles"
                        [files]="files"
                    ></app-questions-template>
                    <!--selectRootCausesIdentification only 2 -->
                    <span
                        [hidden]="pdfView"
                        class="pv-question__type"
                        [innerHtml]="labels.selectRootCausesIdentification | translate"
                    >
                    </span>
                    <mat-selection-list [hidden]="pdfView" (selectionChange)="setRootCausesIdentification($event)">
                        <mat-list-option
                            *ngFor="let type of typesOfRootCausesIdentification"
                            [value]="type.value"
                            [selected]="
                                (type.value == 'batbone' && this.tabBatboneVisible == true) ||
                                (type.value == 'cause' && this.tabCauseVisible == true) ||
                                (type.value == 'why' && this.tabWhyVisible == true)
                            "
                        >
                            {{ type.label }}
                        </mat-list-option>
                    </mat-selection-list>
                </div>
            </div>
        </mat-step>
        <!--          </mat-tab>-->

        <mat-step
            label="{{ labels.rootCause | translate }}"
            *ngIf="
                (this.tabBatboneVisible || this.tabCauseVisible || this.tabWhyVisible) &&
                checkConditionsStage(0, template.stages[1].questions[0], template.stages[0].questions)
            "
        >
            <!--
        <mat-tab
            label="{{ labels.Batbone | translate }}"
            *ngIf="
                this.tabBatboneVisible && checkConditionsStage(0, template.stages[1].questions[0], template.stages[0].questions)
            "
        >-->
            <!-- BATBONE -->
            <div
                class="pv-questions"
                fxLayout="column"
                fxLayoutAlign="space-evenly stretch"
                id="tab4"
                *ngIf="
                    this.tabBatboneVisible &&
                    checkConditionsStage(0, template.stages[1].questions[0], template.stages[0].questions)
                "
            >
                <div class="pv-questions-container">
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="question-group-title">
                        <h2 class="group-title">{{ labels.Batbone | translate }}</h2>
                    </div>
                    <div class="pv-question-root">
                        <div class="pv-question">
                            <div class="pv-question__content-outer" fxLayout="column">
                                <div class="pv-question__content" fxLayout="row" fxLayoutAlign="space-between start">
                                    <div fxLayout="column" fxLayoutAlign="space-between start" class="for-action">
                                        <span class="pv-question__type" [innerHtml]="labels.rootCause | translate"> </span>
                                        <p class="pv-question__subtitle" [innerHtml]="labels.BatboneSubtitle | translate"></p>
                                    </div>
                                </div>

                                <div
                                    class=""
                                    fxLayout="column"
                                    fxLayoutAlign="space-between start"
                                    fxFlex
                                    *ngFor="let question of template.batboneQuestions; let qi = index"
                                >
                                    <div class="title-and-button">
                                        <div fxLayout="row" fxLayoutAlign="space-between center" class="for-action">
                                            <span class="pv-question__type" [innerHtml]="question.title"> </span>
                                        </div>
                                    </div>

                                    <mat-form-field class="pv-question__textarea">
                                        <input
                                            matInput
                                            style="padding-top: 0px; margin-top: -10px"
                                            (blur)="drawBatbone()"
                                            [placeholder]="!pdfView ? (labels.enterAnswer | translate) : ''"
                                            [(ngModel)]="getChosenAnswerBatbone(question).answer"
                                            [maxLength]="250"
                                        />
                                        <span class="text-length" [hidden]="pdfView">
                                            {{
                                                getChosenAnswerBatbone(question).answer
                                                    ? getChosenAnswerBatbone(question).answer.length
                                                    : 0
                                            }}/ 250</span
                                        >
                                    </mat-form-field>
                                </div>

                                <!-- <br />
                        <button
                            type="button"
                            mat-stroked-button
                            color="primary"
                            class="action"
                            (click)="drawBatbone()"
                            [disabled]="isReadOnly"
                        >
                            {{ labels.drawBatbone | translate }}
                        </button> -->
                                <br />
                                <canvas
                                    #myCanvas
                                    style="width: 100%; height: 100%"
                                    width="820"
                                    height="550"
                                    [ngClass]="{ hidden: !viewBatbone }"
                                ></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--     </mat-step>     </mat-tab>-->
            <!-- 5 WHY 
        <mat-step
            label="{{ labels.why | translate }}"
            *ngIf="this.tabWhyVisible && checkConditionsStage(0, template.stages[1].questions[0], template.stages[0].questions)"
        >-->
            <!--
        <mat-tab
            label="{{ labels.why | translate }}"
        >-->
            <!--5 why only stage 2-->
            <div
                class="pv-questions"
                fxLayout="column"
                fxLayoutAlign="space-evenly stretch"
                id="tab4"
                *ngIf="
                    this.tabWhyVisible && checkConditionsStage(0, template.stages[1].questions[0], template.stages[0].questions)
                "
            >
                <div class="pv-questions-container">
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="question-group-title">
                        <h2 class="group-title">{{ labels.why | translate }}</h2>
                    </div>
                    <div class="pv-question-root">
                        <div class="pv-question">
                            <div class="pv-question__content-outer" fxLayout="column">
                                <div class="pv-question__content" fxLayout="row" fxLayoutAlign="space-between start">
                                    <div fxLayout="column" fxLayoutAlign="space-between start" class="for-action">
                                        <span class="pv-question__type" [innerHtml]="labels.rootCause | translate"> </span>
                                        <p class="pv-question__subtitle" [innerHtml]="labels.whySubtitle | translate"></p>
                                    </div>
                                </div>
                                <div
                                    class=""
                                    fxLayout="column"
                                    fxLayoutAlign="space-between start"
                                    fxFlex
                                    *ngFor="let whyAnswer of checklist.whyAnswers; let i = index"
                                >
                                    <div _ngcontent-low-c344="" class="title-and-button">
                                        <div
                                            _ngcontent-low-c344=""
                                            fxlayout="row"
                                            fxlayoutalign="space-between center"
                                            class="for-action"
                                            style="
                                                flex-direction: row;
                                                box-sizing: border-box;
                                                display: flex;
                                                place-content: center space-between;
                                                align-items: center;
                                            "
                                        >
                                            <span _ngcontent-low-c344="" class="pv-question__type">{{
                                                (labels.whyQuestion | translate) + " " + (i * 1 + 1)
                                            }}</span>
                                        </div>
                                    </div>
                                    <mat-form-field class="pv-question__textarea">
                                        <input
                                            matInput
                                            [(ngModel)]="whyAnswer.answer"
                                            [placeholder]="!pdfView ? (labels.enterAnswer | translate) : ''"
                                            [maxLength]="250"
                                        />
                                        <span class="text-length" [hidden]="pdfView">
                                            {{ whyAnswer.answer ? whyAnswer.answer.length : 0 }}/ 250</span
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--      </mat-step>     </mat-tab>-
        <mat-step
            label="{{ labels.causeEffect | translate }}"
            *ngIf="this.tabCauseVisible && checkConditionsStage(0, template.stages[1].questions[0], template.stages[0].questions)"
        > -->
            <!--
        <mat-tab
            label="{{ labels.causeEffect | translate }}"
            *ngIf="this.tabCauseVisible && checkConditionsStage(0, template.stages[1].questions[0], template.stages[0].questions)"
        >-->
            <!-- cause effect stage 2-->

            <div
                class="pv-questions"
                fxLayout="column"
                fxLayoutAlign="space-evenly stretch"
                id="tab5"
                *ngIf="
                    this.tabCauseVisible && checkConditionsStage(0, template.stages[1].questions[0], template.stages[0].questions)
                "
            >
                <div class="pv-questions-container">
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="question-group-title">
                        <h2 class="group-title">{{ labels.rootCause | translate }}</h2>
                    </div>
                    <div class="pv-question-root">
                        <div class="pv-question">
                            <div class="pv-question__content-outer" fxLayout="column">
                                <div class="pv-question__content" fxLayout="row" fxLayoutAlign="space-between start">
                                    <div fxLayout="column" fxLayoutAlign="space-between start" class="for-action">
                                        <span class="pv-question__type" [innerHtml]="labels.rootCause | translate"> </span>
                                        <!-- sottotitolo della domanda -->
                                        <p class="pv-question__subtitle" [innerHtml]="labels.causeEffectSubtitle | translate"></p>
                                    </div>
                                </div>
                                <mat-form-field class="pv-question__textarea">
                                    <textarea
                                        [(ngModel)]="checklist.rootCause"
                                        [placeholder]="!pdfView ? (labels.enterAnswer | translate) : ''"
                                        matInput
                                        [readonly]="isReadOnly"
                                        [maxLength]="250"
                                    ></textarea>
                                    <span class="text-length" [hidden]="pdfView">
                                        {{ checklist.rootCause ? checklist.rootCause.length : 0 }}/ 250</span
                                    >
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-step>
        <!--          </mat-tab>-->

        <!-- INFO END -->
        <!--  <mat-tab label="{{ labels.ipsStatus | translate }}"> -->

        <mat-step label="{{ labels.ipsStatus | translate }}">
            <div class="pv-questions" fxLayout="column" fxLayoutAlign="space-evenly stretch" id="tab6">
                <div class="pv-questions-container">
                    <!-- IPS STATUS -->
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="question-group-title">
                        <h2 class="group-title">{{ labels.ipsStatus | translate }}</h2>
                    </div>
                    <div class="pv-question-root">
                        <div class="pv-question">
                            <div class="pv-question__content-outer" fxLayout="column">
                                <div class="pv-question__content" fxLayout="row" fxLayoutAlign="space-between start">
                                    <div fxLayout="column" fxLayoutAlign="space-between start" class="for-action">
                                        <span class="pv-question__type" [innerHtml]="labels.status | translate"> </span>
                                    </div>
                                    <mat-form-field appearance="standard" class="mat-form-field-rounded question-select-preview">
                                        <input
                                            matInput
                                            [hidden]="!pdfView"
                                            [value]="
                                                checklist.state == 1
                                                    ? labels.Completed
                                                    : checklist.state == 2
                                                    ? labels.Draft
                                                    : checklist.state == 3
                                                    ? labels.ActionsCompleted
                                                    : checklist.state == 4
                                                    ? labels.AnalysisCompleted
                                                    : ''
                                            "
                                        />

                                        <mat-select
                                            [hidden]="pdfView"
                                            [placeholder]="!pdfView ? (labels.select | translate) : ''"
                                            [(ngModel)]="checklist.state"
                                        >
                                            <mat-option [value]="2"> {{ labels.Draft | translate }} </mat-option>
                                            <mat-option [value]="3"> {{ labels.ActionsCompleted | translate }} </mat-option>

                                            <mat-option [value]="4"> {{ labels.AnalysisCompleted | translate }} </mat-option>
                                            <mat-option [value]="1"> {{ labels.Completed | translate }} </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pv-question-root">
                        <div class="pv-question">
                            <div class="pv-question__content-outer" fxLayout="column">
                                <div class="pv-question__content" fxLayout="row" fxLayoutAlign="space-between start">
                                    <div fxLayout="column" fxLayoutAlign="space-between start" class="for-action">
                                        <span class="pv-question__type" [innerHtml]="labels.category | translate"> </span>
                                    </div>
                                    <mat-form-field appearance="standard" class="mat-form-field-rounded question-select-preview">
                                        <input
                                            matInput
                                            [hidden]="!pdfView"
                                            [value]="checklist.category && checklist.category.name ? checklist.category.name : ''"
                                        />
                                        <mat-select
                                            [hidden]="pdfView"
                                            [placeholder]="!pdfView ? (labels.select | translate) : ''"
                                            [(ngModel)]="checklist.category"
                                        >
                                            <mat-option *ngFor="let category of categories; let qi = index" [value]="category">
                                                {{ category.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pv-question-root">
                        <div class="pv-question">
                            <div class="pv-question__content-outer" fxLayout="column">
                                <div class="pv-question__content" fxLayout="row" fxLayoutAlign="space-between start">
                                    <div fxLayout="column" fxLayoutAlign="space-between start" class="for-action">
                                        <!-- titolo della domanda -->
                                        <span class="pv-question__type" [innerHtml]="labels.closingDate | translate"> </span>
                                    </div>

                                    <div fxLayout="column" class="numeric-field-box">
                                        <mat-form-field
                                            fxFlex
                                            class="mat-form-field-rounded numeric-field no-background"
                                            appearance="standard"
                                        >
                                            <input *ngIf="checklistItems?.length" matInput [matDatepicker]="picker" /><!--  -->
                                            <input matInput [matDatepicker]="picker" />
                                            <mat-hint style="margin-top: 13px">MM/DD/YYYY</mat-hint>
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- ADDITIONAL INFORMATION -->

                    <div fxLayout="row" fxLayoutAlign="space-between center" class="question-group-title">
                        <h2 class="group-title">{{ labels.additionalInformation | translate }}</h2>
                    </div>
                    <div *ngFor="let question of template.informationQuestions; let qi = index" class="pv-question-root">
                        <div class="pv-question">
                            <div class="pv-question__content-outer" fxLayout="column">
                                <div class="" fxLayout="column" fxLayoutAlign="space-between start" fxFlex>
                                    <div class="title-and-button">
                                        <div fxLayout="row" fxLayoutAlign="space-between center" class="for-action">
                                            <span class="pv-question__type" [innerHtml]="question.title"> </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="" fxLayout="column" fxLayoutAlign="space-between start" fxFlex>
                                    <!-- risposta + check OTHER 
                                             -->
                                    <mat-form-field class="pv-question__textarea">
                                        <textarea
                                            [placeholder]="!pdfView ? (labels.enterAnswer | translate) : ''"
                                            matInput
                                            [(ngModel)]="getChosenAnswerInformations(question).answer"
                                            [readonly]="isReadOnly"
                                            [maxLength]="250"
                                        ></textarea>
                                        <span class="text-length" [hidden]="pdfView">
                                            {{
                                                getChosenAnswerInformations(question).answer
                                                    ? getChosenAnswerInformations(question).answer.length
                                                    : 0
                                            }}/ 250</span
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-step>
        <!--  </mat-tab>-->
        <!--  </mat-tab-group>-->
    </mat-horizontal-stepper>
</div>
