<ng-container *ngIf="data.type !== 'DS'">
    <!-- Header -->
    <mat-toolbar class="new-action-tracker-dialog_header" color="primary">
        <div class="toolbar-title">
            {{ labels.title | translate }}
        </div>
        <mat-icon (click)="close()">close</mat-icon>
    </mat-toolbar>

    <div>
        <app-stepper #mystepper [data]="subtitle" (changeEvent)="changeIndex($event)" [valid]="isCurrentFormValid"></app-stepper>
    </div>

    <div class="full-screen-modal__content-wrapper">
        <!-- Content -->
        <div
            class="new-action-tracker-dialog_content full-screen-modal__content"
            style="overflow: auto; overflow-y: hidden; display: contents"
        >
            <app-at-action [files]="files" [ngClass]="{ hidden: tabIndex !== 0 }"></app-at-action>
            <app-at-location
                [ngClass]="{ hidden: tabIndex !== 1 }"
                (workingAreaChange)="informationComponent.reloadFormats(); informationComponent.clearFormat()"
            ></app-at-location>

            <app-at-information [CkType]="data.type" [ngClass]="{ hidden: tabIndex !== 2 }"></app-at-information>
            <!-- <app-at-planning [ngClass]="{ hidden: tabIndex !== 3 }"></app-at-planning>
        <app-at-others [ngClass]="{ hidden: tabIndex !== 4 }"></app-at-others> -->
        </div>

        <!-- Actions -->
        <div class="new-action-tracker-dialog_actions full-screen-modal__actions">
            <button
                class="next"
                *ngIf="tabIndex < subtitle.length - 1"
                mat-flat-button
                color="primary"
                (click)="next()"
                [disabled]="!isCurrentFormValid"
            >
                {{ labels.next | translate }}
            </button>
            <button
                class="save"
                *ngIf="tabIndex === subtitle.length - 1 || (tabIndex === 2 && data.type === 'CL')"
                mat-flat-button
                color="primary"
                (click)="save()"
                [disabled]="!allFormsAreValid"
            >
                {{ labels.save | translate }}
            </button>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="data.type === 'DS'">
    <!-- Header -->
    <mat-toolbar class="new-action-tracker-dialog_header" color="primary">
        <div class="toolbar-title">
            {{ labels.title | translate }}
        </div>
        <mat-icon (click)="close()">close</mat-icon>
    </mat-toolbar>

    <!-- Subheader 
    <mat-toolbar class="new-action-tracker-dialog_subheader" color="primary">
        <div class="mat-title">{{ subtitle[tabIndex] | translate }}</div>
    </mat-toolbar>-->

    <div class="full-screen-modal__content-wrapper content-wrapper-new-action">
        <!-- Content -->
        <div class="new-action-tracker-dialog_content full-screen-modal__content">
            <app-at-action [checklist]="data.ddsChecklist" [type]="data.type" [files]="data.files"></app-at-action>

            <app-at-information></app-at-information>
        </div>

        <!-- Actions -->
        <div class="new-action-tracker-dialog_actions full-screen-modal__actions">
            <button
                *ngIf="data.type !== 'DS'"
                class="save"
                mat-flat-button
                color="primary"
                (click)="save()"
                [disabled]="!allFormsAreValid"
            >
                {{ labels.save | translate }}
            </button>
            <button
                *ngIf="data.type === 'DS' && !data.isMetricsDDS"
                class="save"
                mat-flat-button
                color="primary"
                (click)="saveDDS()"
                [disabled]="!allFormsAreValid"
            >
                {{ labels.save | translate }}
            </button>
            <button
                *ngIf="data.isMetricsDDS"
                class="save"
                mat-flat-button
                color="primary"
                (click)="saveDDSMetrics()"
                [disabled]="!allFormsAreValid"
            >
                {{ labels.save | translate }}
            </button>
        </div>
    </div>
</ng-container>
