import {
  HttpClient,
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpHeaders,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ENV, PROJECT_ENUM } from '@app/constants/global.constants';
import { RouteConstants } from '@app/constants/route.constants';
import { Env } from '@app/types/env';
import { EdmsError } from '@app/types/error';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { JwtError } from '@data/models/jtw-token-error';
import { AuthenticationResult } from '@azure/msal-browser';

type ObservableResponse = Observable<
  HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<unknown> | HttpUserEvent<unknown>
>;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public static readonly JWT_KEY = 'jwt';
  public static readonly UID_KEY = 'uid';
  public static readonly APIKEY_KEY = 'apikey';
  public static readonly UTC_DATE = 'utcDate';
  public static readonly DATE_TIMEZONEOFFSET = 'timezoneOffset';
  public static readonly AUTHORIZATION = 'Authorization';
  public static readonly LEVEL = 'ConsistencyLevel';

  private readonly BYPASS_URLS: string[] = ['/assets/', 'accounts.logout', 'oauthv2/cors/token', 'login.microsoftonline'];

  private readonly BYPASS_URLS_HANDLER: string[] = ['users/detail'];
  private readonly TOKEN_BEARER_URLS: string[] = ['graph.microsoft'];
  constructor(
    @Inject(ENV) private env: Env,
    private router: Router,
    private authenticationService: AuthenticationService,
    private cookieService: CookieService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): ObservableResponse {
    if (this.bypassInterceptor(req)) {
      return next.handle(req);
    }
    let uid = '';
    if (this.authenticationService.getProject() === PROJECT_ENUM.idea.toString() && req.url.includes(this.env.apiBaseUrl)) {
      //    uid = !!this.env.ideaUid ? this.env.ideaUid : 'aWRhdWlk';
      uid = 'aWRhdWlk';
    } else {
      uid = this.authenticationService.getUID();
    }

    const utcDate = this.getUTCMillis(new Date());

    const timezoneOffset = new Date().getTimezoneOffset().toString();
    if (this.NeedTokenBearerMicrosoft(req)) {
      this.authenticationService
        .getGraphToken()
        .subscribe((res) =>
          next
            .handle(this.addTokenHeadersBearer(req, res.access_token, uid, utcDate.toString(), timezoneOffset))
            .pipe(catchError((error): ObservableResponse => this.handleError(error, req.url)))
        );
    } else {
      if (this.authenticationService.tokenExpired()) {
        this.authenticationService.getTokenSubscribe().subscribe((res) => {
          this.authenticationService.setTokenSubscribe(res);
          console.log('barer');
          return this.manageJWTToken(req, next, uid, utcDate, timezoneOffset);
        });
      } else {
        return this.manageJWTToken(req, next, uid, utcDate, timezoneOffset);
      }
    }
  }

  private manageJWTToken(req: HttpRequest<unknown>, next: HttpHandler, uid: string, utcDate: number, timezoneOffset: string) {
    if (this.authenticationService.tokenJWTExpired()) {
      this.authenticationService.getJWTTokenRefresh().subscribe(
        (res) => {
          this.authenticationService.setJWTTokenRefresh(res);
          console.log('jwt');
          if (this.authenticationService.getJWT() && uid && utcDate) {
            return next
              .handle(this.addTokenHeaders(req, this.authenticationService.getJWT(), uid, utcDate.toString(), timezoneOffset))
              .pipe(catchError((error): ObservableResponse => this.handleError(error, req.url)));
          } else {
            return next.handle(req).pipe(catchError((error): ObservableResponse => this.handleError(error, req.url)));
          }
        },
        (error: JwtError) => {
          if (error.errorMessage.includes('AADSTS50058') || error.errorMessage.includes('AADSTS700084')) {
            this.authenticationService.getJWTTokenWithoutSub().subscribe(
              (res: AuthenticationResult) => {
                this.authenticationService.setJWTTokenRefresh(res);
                if (this.authenticationService.getJWT() && uid && utcDate) {
                  return next
                    .handle(
                      this.addTokenHeaders(req, this.authenticationService.getJWT(), uid, utcDate.toString(), timezoneOffset)
                    )
                    .pipe(catchError((error): ObservableResponse => this.handleError(error, req.url)));
                } else {
                  return next.handle(req).pipe(catchError((error): ObservableResponse => this.handleError(error, req.url)));
                }
              },
              (error: JwtError) => {
                if (error.errorMessage.includes('AADSTS50058') || error.errorMessage.includes('AADSTS700084')) {
                  this.authenticationService.logout();
                }
              }
            );
          }
        }
      );
    }
    else {
      if (this.authenticationService.getJWT() && uid && utcDate) {
        return next
          .handle(this.addTokenHeaders(req, this.authenticationService.getJWT(), uid, utcDate.toString(), timezoneOffset))
          .pipe(catchError((error): ObservableResponse => this.handleError(error, req.url)));
      } else {
        return next.handle(req).pipe(catchError((error): ObservableResponse => this.handleError(error, req.url)));
      }
    }
  }

  private addTokenHeaders(
    req: HttpRequest<unknown>,
    jwt: string,
    uid: string,
    utcDate: string,

    timezoneOffset: string
  ): HttpRequest<unknown> {
    if (!!this.authenticationService.getAccessToken()) {
      return req.clone({
        setHeaders: {
          [TokenInterceptor.UID_KEY]: uid,
          [TokenInterceptor.JWT_KEY]: jwt,
          [TokenInterceptor.UTC_DATE]: utcDate,
          [TokenInterceptor.AUTHORIZATION]: 'Bearer ' + this.authenticationService.getAccessToken(),
          [TokenInterceptor.APIKEY_KEY]: this.env.apiManagmentKey,
          [TokenInterceptor.DATE_TIMEZONEOFFSET]: timezoneOffset
        }
      });
    } else {
      return req.clone({
        setHeaders: {
          [TokenInterceptor.UID_KEY]: uid,
          [TokenInterceptor.JWT_KEY]: jwt,
          [TokenInterceptor.UTC_DATE]: utcDate,

          [TokenInterceptor.APIKEY_KEY]: this.env.apiManagmentKey,
          [TokenInterceptor.DATE_TIMEZONEOFFSET]: timezoneOffset
        }
      });
    }
  }

  private addTokenHeadersBearer(
    req: HttpRequest<unknown>,
    jwt: string,
    uid: string,
    utcDate: string,
    timezoneOffset: string
  ): HttpRequest<unknown> {
    return req.clone({
      setHeaders: {
        [TokenInterceptor.UID_KEY]: uid,
        [TokenInterceptor.AUTHORIZATION]: 'Bearer ' + this.authenticationService.getGraphToken(),
        [TokenInterceptor.LEVEL]: 'eventual',
        [TokenInterceptor.UTC_DATE]: utcDate,
        [TokenInterceptor.APIKEY_KEY]: this.env.apiManagmentKey,
        [TokenInterceptor.DATE_TIMEZONEOFFSET]: timezoneOffset
      }
    });
  }

  private bypassInterceptor(req: HttpRequest<unknown>): boolean {
    return this.BYPASS_URLS.some((url) => req.url.includes(url));
  }

  private NeedTokenBearerMicrosoft(req: HttpRequest<unknown>): boolean {
    return this.TOKEN_BEARER_URLS.some((url) => req.url.includes(url));
  }

  private logout(error: EdmsError): ObservableResponse {
    this.authenticationService.logout();
    // this.router.navigate([RouteConstants.LOGIN]);

    return throwError(error);
  }

  private handleError(error: HttpErrorResponse | unknown, url: string): ObservableResponse {
    if (!(error instanceof HttpErrorResponse)) {
      return throwError(error);
    } else if (
      this.BYPASS_URLS_HANDLER.some((x) => {
        url.includes(x);
      })
    ) {
      return throwError(error);
    }

    switch (error.status) {
      /*case 0:
        return this.logout(error.error);*/
      case 401:
        return this.logout(error.error);
      case 403:
        return this.handle403Error(error.error);
      case 404:
        return throwError(new EdmsError(error));
      case 504:
        return this.handle403Error(error.error);
      default:
        const e = new EdmsError(error);
        return throwError(e);
    }
  }

  private handle403Error(error: EdmsError): ObservableResponse {
    this.router.navigate([RouteConstants.DASHBOARD]);

    return throwError(error);
  }

  private getUTCMillis(date: Date): number {
    let offset = date.getTimezoneOffset();
    offset = offset * 60000;
    return date.getTime() - offset;
  }
}
