<span class="logout-button">
    <button mat-button [matMenuTriggerFor]="menu" #t="matMenuTrigger" fxLayout="row"
        fxLayoutAlign="space-between center">
        <mat-icon svgIcon="profile"></mat-icon>
        <ng-container *ngIf="loggedUserInfo$ | async as response" style="    margin-top: -0.5rem;">
            <div class="user-information__content" *ngIf="response.data">
                <span>{{ response.data.firstName }} {{ response.data.lastName }}</span>
                <span class="user-operation-center" *ngIf="!!response.data.operationCenter">{{
                    response.data.operationCenter?.description }}
                </span>

                <span class="user-operation-center"
                    *ngIf="!!!response.data.operationCenter && !!response.data.operationCenterFiltered">
                    {{ response.data.operationCenterFiltered?.description }}</span>
            </div>

            <ng-container *ngIf="response.error">
                {{ labels.errorLoadingUser | translate }}
            </ng-container>

            <ng-container *ngIf="!response.error && !response.data">
                {{ labels.loading | translate }}
            </ng-container>
        </ng-container>

        <mat-icon *ngIf="!t.menuOpen" class="expand_icon">expand_more</mat-icon>
        <mat-icon *ngIf="t.menuOpen" class="expand_icon">expand_less</mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before" class="mat-elevation-z10">

        <button mat-menu-item (click)="logout()">
            <mat-icon svgIcon="logout"></mat-icon>
            {{ labels.logout | translate }}
        </button>
    </mat-menu>

</span>