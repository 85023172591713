import { AbstractControl, ValidatorFn } from '@angular/forms';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const typeValidator = (example: any): ValidatorFn => {
  const validate = (control: AbstractControl) => {
    if (!control.value || (control.value && typeof control.value === typeof example)) {
      return null;
    }
    return { invalidType: true };
  };

  return validate;
};
