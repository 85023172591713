import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV } from '@app/constants/global.constants';
import { Env } from '@app/types/env';
import { EdmsError } from '@app/types/error';
import { ActionDTO } from '@data/models/action-dto';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActionTrackerDetailsService {
  public readonly ACTIONS_DETAILS_PATH = '/api/trackerActions/detail/';
  public readonly SEND_TO_SAP_PATH = '/api/trackerActions/sendToSAPActionTracker/';
  public readonly SEND_TO_SQS_PATH = '/api/trackerActions/sendToSQS';
  public readonly REJECT_ACTION_PATH = '/api/trackerActions/reject';
  public readonly CLOSE_ACTION_PATH = '/api/trackerActions/close';
  public readonly REOPEN_ACTION_PATH = '/api/trackerActions/open';

  constructor(@Inject(ENV) private env: Env, private http: HttpClient) {}

  public getDetails(actionId: string): Observable<ActionDTO> {
    return this.http
      .get<ActionDTO>(`${this.env.apiBaseUrl}${this.ACTIONS_DETAILS_PATH}${actionId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public sendToSap(actionId: string): Observable<null> {
    return this.http
      .get<null>(`${this.env.apiBaseUrl}${this.SEND_TO_SAP_PATH}${actionId}`)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
  public sendToSQS(action: ActionDTO): Observable<void> {
    return this.http
      .post<void>(`${this.env.apiBaseUrl}${this.SEND_TO_SQS_PATH}`, action)
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public rejectAction(model: ActionDTO, reason: string): Observable<void> {
    const body = { ...model, trackerAction: { ...model.trackerAction, finalStateReason: reason } };

    return this.http
      .put<void>(`${this.env.apiBaseUrl}${this.REJECT_ACTION_PATH}`, body, {})
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public closeAction(model: ActionDTO, reason: string): Observable<void> {
    const body = { ...model, trackerAction: { ...model.trackerAction, finalStateReason: reason } };

    return this.http
      .put<void>(`${this.env.apiBaseUrl}${this.CLOSE_ACTION_PATH}`, body, {})
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }

  public reopenAction(model: ActionDTO): Observable<void> {
    const body = { ...model };

    return this.http
      .put<void>(`${this.env.apiBaseUrl}${this.REOPEN_ACTION_PATH}`, body, {})
      .pipe(catchError((error) => throwError(error as EdmsError)));
  }
}
