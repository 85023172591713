import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-message-block',
  templateUrl: './message-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageBlockComponent {
  @Input()
  public message: string;

  @Input()
  public type = 'error';

  constructor() {}
}
