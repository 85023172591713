import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export enum APP_TYPE_ENUM {
  'DATA_GATHERING' = 1,
  'SAFETY_TRIGGER' = 2,
  'CIL' = 3,
  'CENTER_LINE' = 4,
  'HEALTH_CHECK' = 5,
  'TABLE_LOG' = 6,
  'TABLE_EQUIPMENT' = 7,
  'FORMS' = 8,
  'DEFECT_HANDLING' = 9,
  'IDEA' = 10,
  'HYPERLINK' = 11,
  'DDS' = 12,
  'PST' = 13,
  'QR' = 14
}

export const TEMPLATE_ICONS = new Map([
  [
    APP_TYPE_ENUM.DATA_GATHERING,
    { icon: 'combined-shape-1', description: marker('management.appDialog.typeDesc.dataGatheringDescription') }
  ],
  [APP_TYPE_ENUM.SAFETY_TRIGGER, { icon: 'icon-safety', description: marker('management.appDialog.typeDesc.safetyDescription') }],
  [APP_TYPE_ENUM.CIL, { icon: 'combined-shape-4', description: marker('management.appDialog.typeDesc.CILDescription') }],
  [
    APP_TYPE_ENUM.CENTER_LINE,
    { icon: 'icon-center-line', description: marker('management.appDialog.typeDesc.centerLineDescription') }
  ],
  [
    APP_TYPE_ENUM.HEALTH_CHECK,
    { icon: 'combined-shape-5', description: marker('management.appDialog.typeDesc.healtDescription') }
  ],
  [APP_TYPE_ENUM.TABLE_LOG, { icon: 'icon-formats', description: marker('management.appDialog.typeDesc.tableLogDescription') }],
  [
    APP_TYPE_ENUM.TABLE_EQUIPMENT,
    { icon: 'icon-formats', description: marker('management.appDialog.typeDesc.tableEquipmentDescription') }
  ],
  [APP_TYPE_ENUM.FORMS, { icon: 'combined-shape-2', description: marker('management.appDialog.typeDesc.formsDescription') }],
  [
    APP_TYPE_ENUM.DEFECT_HANDLING,
    { icon: 'combined-shape-6', description: marker('management.appDialog.typeDesc.defectDescription') }
  ],
  [APP_TYPE_ENUM.IDEA, { icon: 'combined-shape-7', description: marker('management.appDialog.typeDesc.ideaDescription') }],
  [
    APP_TYPE_ENUM.HYPERLINK,
    { icon: 'icon-hyperlink', description: marker('management.appDialog.typeDesc.hyperlinkDescription') }
  ],
  [APP_TYPE_ENUM.DDS, { icon: 'combined-shape', description: marker('management.appDialog.typeDesc.DDSDescription') }],

  [APP_TYPE_ENUM.PST, { icon: 'bpsticon', description: marker('management.appDialog.typeDesc.BPSTDescription') }],
  [APP_TYPE_ENUM.QR, { icon: 'bold-tech-camera-default', description: marker('management.appDialog.typeDesc.QRDescription') }]
]);
