import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssetService {
  public logo = new BehaviorSubject<string>(null);
  public title = new BehaviorSubject<string>(null);
  public Ebos = new BehaviorSubject<boolean>(null);
  constructor() {}

  setLogo(logo: string) {
    this.logo.next(logo);
  }
  getLogo(): string {
    return this.logo.getValue();
  }
  setIsEbos(value: boolean) {
    this.Ebos.next(value);
  }
  getIsEbos(): boolean {
    return this.Ebos.getValue();
  }

  setTitle(title: string) {
    this.title.next(title);
  }
  getTitle(): string {
    return this.title.getValue();
  }
}
