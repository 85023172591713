export enum ENTITIES_ENUM {
  PLANT,
  DEPARTMENT,
  WORKINGAREA,
  EQUIPMENT
}

export enum ENTITY_TYPES_ENUM {
  'PLANT' = 0,
  'DEPARTMENT' = 1,
  'WORKINGAREA' = 2,
  'EQUIPMENT' = 3
}
