// import { QuestionsListComponent } from './components/questions-list/questions-list.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DirectiveModule } from '@shared/directives/directive.module';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { AutocompleteFilterComponent } from './components/autocomplete-filter/autocomplete-filter.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ButtonToggleFilterComponent } from './components/button-toggle-filter-component/button-toggle-filter.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { GlobalMessageComponent } from './components/global-message/global-message.component';
import { GoBackComponent } from './components/go-back/go-back.component';
import { MessageBlockComponent } from './components/message-block/message-block.component';
// eslint-disable-next-line max-len
import { MultiSelectCheckboxFilterComponent } from './components/multiselect-checkbox-filter-component/multiselect-checkbox-filter.component';
import { ProgressSpinnerDialogComponent } from './components/progress-spinner-dialog/progress-spinner-dialog.component';
import { MaterialModule } from './material.module';
import { PipeModule } from './pipes/pipe.module';
import { ConfirmDialogService } from './services/confirm-dialog.service';
import { GlobalMessageService } from './services/global-message.service';
import { ProgressSpinnerDialogService } from './services/progress-spinner-dialog.service';
import { UrlService } from './services/url.service';
import { NgxCleaveDirectiveModule } from 'ngx-cleave-directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { QuestionsTemplateComponent } from './components/questions-bpst-preview/questions-template';
import { GojsAngularModule } from 'gojs-angular';

import { QuestionsBpstPreviewComponent } from './components/questions-bpst-preview/questions-bpst-preview.component';
import { QuestionsListPreviewComponent } from './components/questions-list-preview/questions-list-preview.component';
// eslint-disable-next-line max-len
import { CreateActionDialogComponent } from './components/create-action-dialog/create-action-dialog.component';
import { CreateActionItemDialogComponent } from './components/create-action-item-dialog/create-action-item-dialog.component';
// eslint-disable-next-line max-len
import { AddActionDescriptionDialogComponent } from './components/add-action-description-dialog/add-action-description-dialog.component';
import { QuestionOtherPreviewComponent } from './components/question-other-preview/question-other-preview.component';
// eslint-disable-next-line max-len
import { CilTasksComponent } from '@modules/dashboard/management/templates/dialogs/data-gathering-dialog/components/cil-tasks/cil-tasks.component';
import { CilTasksPreviewComponent } from './components/cil-tasks-preview/cil-tasks-preview.component';
// eslint-disable-next-line max-len
import { ClParametersComponent } from '@modules/dashboard/management/templates/dialogs/data-gathering-dialog/components/cl-parameters/cl-parameters.component';
import { ClParametersPreviewComponent } from './components/cl-parameters-preview/cl-parameters-preview.component';
import { DdsKpisPreviewComponent } from './components/dds-kpis-preview/dds-kpis-preview.component';
import { ViewImmagesDialogComponent } from './components/view-immages-dialog/view-immages-dialog.component';
// import { QuestionComponent } from './components/question/question.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ConfirmDeleteDialogComponent } from './components/confirm-delete-dialog/confirm-delete-dialog.component';
import { ConfirmDeleteDialogService } from './services/confirm-delete-dialog.service';
import { UploadFileDirective } from './directives/upload-file.directive';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ScrollcomponentComponent } from './components/scrollcomponent/scrollcomponent.component';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { GeneralTablePreviewComponent } from './components/general-table-preview/general-table-preview.component';
import { AddRowDialogComponent } from './components/general-table-preview/add-row-dialog/add-row-dialog.component';
import { LocationStepComponent } from './components/general-table-preview/add-row-dialog/location-step/location-step.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { UserDefaultSettingsComponent } from './components/user-default-settings/user-default-settings.component';
import { IdeaAutocompleteFilterComponent } from './components/idea-autocomplete-filter/idea-autocomplete-filter.component';
import { IdeaQuestionListPreviewComponent } from './components/idea-questions-list-preview/idea-questions-list-preview.component';
// eslint-disable-next-line max-len
import { IdeaQuestionsListPreviewMobileComponent } from './components/idea-questions-list-preview-mobile/idea-questions-list-preview-mobile.component';
import { ConfirmIdeaResultComponent } from './components/confirm-idea-result/confirm-idea-result.component';
import { A2hsComponent } from './components/a2hs/a2hs.component';
import { A2hsIosSafariHow2Component } from './components/a2hs/components/a2hs-ios-safari-how2/a2hs-ios-safari-how2.component';
import { A2hsBrowserPromptComponent } from './components/a2hs/components/a2hs-browser-prompt/a2hs-browser-prompt.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { QrReaderComponent } from './components/qr-reader/qr-reader.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { NgxCaptureModule } from 'ngx-capture';

@NgModule({
  declarations: [
    ProgressSpinnerDialogComponent,
    ConfirmDialogComponent,
    GlobalMessageComponent,
    MessageBlockComponent,
    BreadcrumbsComponent,
    DateFilterComponent,
    AutocompleteComponent,
    AutocompleteFilterComponent,
    ButtonToggleFilterComponent,
    MultiSelectCheckboxFilterComponent,
    GoBackComponent,
    // QuestionsListComponent,
    // QuestionComponent
    QrReaderComponent,
    QuestionsListPreviewComponent,
    QuestionsBpstPreviewComponent,
    QuestionsTemplateComponent,
    IdeaQuestionListPreviewComponent,
    CreateActionDialogComponent,
    CreateActionItemDialogComponent,
    AddActionDescriptionDialogComponent,
    QuestionOtherPreviewComponent,
    CilTasksComponent,
    CilTasksPreviewComponent,
    ClParametersComponent,
    ClParametersPreviewComponent,
    DdsKpisPreviewComponent,
    ViewImmagesDialogComponent,
    ConfirmDeleteDialogComponent,
    UploadFileDirective,
    ProgressBarComponent,
    ScrollcomponentComponent,
    GeneralTablePreviewComponent,
    AddRowDialogComponent,
    LocationStepComponent,
    StepperComponent,
    UserDefaultSettingsComponent,
    IdeaAutocompleteFilterComponent,
    IdeaQuestionsListPreviewMobileComponent,
    ConfirmIdeaResultComponent,
    A2hsComponent,
    A2hsIosSafariHow2Component,
    A2hsBrowserPromptComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    DirectiveModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxTrimDirectiveModule,
    PipeModule,
    NgxCleaveDirectiveModule,
    DragDropModule,
    CKEditorModule,
    GojsAngularModule,
    AngularSignaturePadModule,
    InfiniteScrollModule,
    NgxScannerQrcodeModule,
    NgxCaptureModule
  ],
  providers: [GlobalMessageService, ConfirmDialogService, ConfirmDeleteDialogService, ProgressSpinnerDialogService, UrlService],
  exports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MessageBlockComponent,
    BreadcrumbsComponent,
    NgxTrimDirectiveModule,
    DirectiveModule,
    DateFilterComponent,
    AutocompleteComponent,
    AutocompleteFilterComponent,
    ButtonToggleFilterComponent,
    MultiSelectCheckboxFilterComponent,
    PipeModule,
    GoBackComponent,
    NgxCleaveDirectiveModule,
    DragDropModule,
    // QuestionsListComponent,
    // QuestionComponent
    QrReaderComponent,
    QuestionsListPreviewComponent,
    QuestionsBpstPreviewComponent,
    QuestionsTemplateComponent,
    IdeaQuestionListPreviewComponent,
    IdeaQuestionsListPreviewMobileComponent,
    CreateActionDialogComponent,
    CreateActionItemDialogComponent,
    AddActionDescriptionDialogComponent,
    QuestionOtherPreviewComponent,
    CilTasksComponent,
    CilTasksPreviewComponent,
    ClParametersComponent,
    ClParametersPreviewComponent,
    GeneralTablePreviewComponent,
    DdsKpisPreviewComponent,
    CKEditorModule,
    UploadFileDirective,
    ProgressBarComponent,
    AngularSignaturePadModule,
    InfiniteScrollModule,
    AddRowDialogComponent,
    StepperComponent,
    ConfirmIdeaResultComponent,
    A2hsComponent,
    A2hsIosSafariHow2Component,
    A2hsBrowserPromptComponent
  ]
})
export class SharedModule {}
