import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export interface ActionType {
  id: number;
  title: string;
  description1: string;
  description2: string;
}

export enum ACTION_TYPE_ENUM {
  'NOACTION' = 0,
  'OPTIONAL' = 1,
  'REQUIRED' = 2
}

export enum ACTION_INFO_ENUM {
  'QUESTION'= 0,
  'ACTION_AREA'= 1,
  'ACTION_TITLE'= 2,
  'ACTION_STATUS' = 3,
  'ACTION_DESCRIPTION' = 4,
  'ACTION_PRIORITY' = 5
}

export const ACTION_TYPES: ActionType[] = [
  /* {
  id: ACTION_TYPE_ENUM.NOACTION,
  title:  marker('management.actionType.noAction'),
  description1 : marker('management.actionType.notAllowPrimos'),
  description2 : marker('management.actionType.toAddAction')
}, */ {
    id: ACTION_TYPE_ENUM.OPTIONAL,
    title: marker('management.actionType.optional'),
    description1: marker('management.actionType.allowPrimosDecide'),
    description2: marker('management.actionType.toCreateAnAtionOrNot')
  },
  {
    id: ACTION_TYPE_ENUM.REQUIRED,
    title: marker('management.actionType.required'),
    description1: marker('management.actionType.primosMust'),
    description2: marker('management.actionType.createAnAction')
  }
];
