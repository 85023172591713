<mat-drawer-container hasBackdrop="true" class="mat-app-background">
    <mat-drawer-content>
        <div class="dashboard-layout-component" [ngClass]="{ 'dashboard-layout-component__landing': isLanding }">
            <mat-toolbar color="primary">
                <div class="dashboard-layout-component__toolbar content-wrapper">
                    <!-- TODO (FUTURE): This logo and it's "alt" text should come from the Operation Center of the logged user -->
                    <img
                        (click)="redirectToDashboard()"
                        class="dashboard-layout-component__logo"
                        width="84"
                        height="60"
                        alt="eDMS Pessione"
                        src="./assets/img/new/ebos/logo-header.svg"
                    />

                    <div class="dashboard-layout-component__menu">
                        <!-- Home -->
                        <!--       <button mat-button (click)="redirectToDashboard()" id="main-menu-home">
                            <mat-icon svgIcon="factory"></mat-icon>
                            {{ labels.menuHomeButton | translate }}
                        </button> -->

                        <!-- Apps -->
                        <div class="app-menu" appClickOutside (clickOutside)="hideAppMenu()">
                            <button
                                mat-button
                                id="main-menu-app"
                                (click)="showApps()"
                                [ngClass]="{ current: currentSelection === this.APPS }"
                            >
                                <mat-icon fontSet="edms">apps</mat-icon>
                                {{ labels.menuAppsButton | translate }}
                            </button>
                            <div class="app-menu__inner" [hidden]="appHidden" *ngIf="appGroups">
                                <div class="content-wrapper">
                                    <div class="app-menu__row">
                                        <mat-card
                                            class="app-menu__link"
                                            id="app-group-{{ appGroup.name | lowercase }}"
                                            *ngFor="let appGroup of appGroups"
                                            (click)="redirectToAppLanding(appGroup.name?.toUpperCase(), appGroup.id)"
                                        >
                                            <div class="mat-icon-wrapper">
                                                <mat-icon fontSet="edms">
                                                    {{ templateIcons.get(appGroup?.name?.toLowerCase())?.icon }}
                                                </mat-icon>
                                            </div>
                                            <span class="app-menu__label">{{
                                                "appGroupsQuickAccess." + appGroup.name.toLowerCase() | translate
                                            }}</span>
                                        </mat-card>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Administration -->
                        <button
                            *ngIf="
                                (loggedUserInfo$ | async).data?.role?.level === SUPER_USER ||
                                (loggedUserInfo$ | async).data?.role?.level === GLOBAL_SUPER_USER ||
                                (loggedUserInfo$ | async).data?.role?.level === STAKEHOLDER ||
                                (loggedUserInfo$ | async).data?.role?.level === LINE_LEAD
                            "
                            mat-button
                            (click)="redirectToAdministration()"
                            [ngClass]="{ current: currentSelection === this.ADMINISTRATION }"
                            id="main-menu-administration"
                        >
                            <mat-icon fontSet="edms">icon-admin</mat-icon>
                            {{ labels.menuAdministrationButton | translate }}
                        </button>

                        <!-- Management -->
                        <button
                            *ngIf="
                                (loggedUserInfo$ | async).data?.role?.level === SUPER_USER ||
                                (loggedUserInfo$ | async).data?.role?.level === GLOBAL_SUPER_USER ||
                                (loggedUserInfo$ | async).data?.role?.level === STAKEHOLDER ||
                                (loggedUserInfo$ | async).data?.role?.level === TEAM_LEAD ||
                                (loggedUserInfo$ | async).data?.role?.level === LINE_LEAD
                            "
                            mat-button
                            (click)="redirectToManagement()"
                            [ngClass]="{ current: currentSelection === this.MANAGEMENT }"
                            id="main-menu-management"
                        >
                            <mat-icon fontSet="edms">icon-mgmt</mat-icon>
                            {{ labels.menuManagementButton | translate }}
                        </button>
                    </div>

                    <div class="dashboard-layout-component__actions">
                        <button id="location-filter" mat-button (click)="drawer.toggle()">
                            <mat-icon svgIcon="filters"></mat-icon>
                            {{ labels.menuFiltersButton | translate }}
                        </button>

                        <app-logout-button></app-logout-button>
                    </div>
                </div>
            </mat-toolbar>

            <div class="dashboard-layout-component__content">
                <router-outlet></router-outlet>
            </div>
        </div>
    </mat-drawer-content>

    <mat-drawer #drawer mode="over" position="end">
        <div class="dashboard-layout-component__drawer">
            <app-user-filters [drawer]="drawer"></app-user-filters>
        </div>
    </mat-drawer>
</mat-drawer-container>
