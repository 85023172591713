<div class="create-action-dialog">
    <div class="create-action-dialog__title">
        <h1 class="mat-title">
            {{ title }}
        </h1>
        <mat-icon [mat-dialog-close]="false">close</mat-icon>
    </div>
    <div class="description-container" fxLayout="column" fxLayoutAlign="space-evenly stretch">
        <mat-label class="field-title">
            {{ labels.description | translate | uppercase }}
        </mat-label>

        <div class="add-description" fxLayout="row" fxLayoutAlign="space-between center">
            <!-- placeholder -->
            <mat-label class="add-description-placeholder" *ngIf="!actionDescription"
                (click)="onEditActionDescription()">{{
                labels.addDescription | translate
                }}</mat-label>

            <!-- titolo -->
            <mat-label *ngIf="actionDescription && actionDescription.length > 0">{{ actionDescription }}</mat-label>

            <mat-icon class="icon-edit" fontSet="edms" *ngIf="!data.model?.editDisabled"
                (click)="onEditActionDescription()">
                pencil
            </mat-icon>
        </div>

        <mat-label class="field-title">
            {{ labels.actions | translate | uppercase }}
        </mat-label>

        <!-- lista action-->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayout="row wrap">
            <div class="action-container" *ngFor="let action of actions; index as i" fxLayout="column"
                fxLayoutAlign="space-between stretch">
                <mat-label class="action-title">
                    {{ action.title }}
                </mat-label>

                <div fxLayout="row" fxLayoutAlign="start center" *ngIf="!!getActionFileSize(action)">
                    <mat-icon class="icon-attach" fontSet="edms">outline-basics-documents-file</mat-icon>
                    <span class="action-attach">
                        {{ getActionFileSize(action) }}
                    </span>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div>
                        <mat-chip-list class="content-priority" *ngIf="action.trackerAction.priority?.id">
                            <mat-chip [color]="setChipColorByPriority(action.trackerAction.priority?.id).chipColor"
                                selected>
                                <mat-icon fontSet="edms">{{
                                    setChipColorByPriority(action.trackerAction.priority?.id).iconName
                                    }}</mat-icon>{{ action.trackerAction.priority?.literal?.translateValue }}
                            </mat-chip>
                        </mat-chip-list>
                    </div>

                    <div fxFlex="nogrow" fxFlexAlign="end">
                        <mat-icon class="footer-icon" fontSet="edms" (click)="editAction(action, i)" fxFlex="nogrow"
                            fxFlexAlign="end" *ngIf="!data.model?.editDisabled">
                            pencil
                        </mat-icon>
                        <mat-icon class="footer-icon" fontSet="edms" (click)="deleteAction(action, i)" fxFlex="nogrow"
                            fxFlexAlign="end" *ngIf="!data.model?.editDisabled">
                            outline-basics-delete-bin
                        </mat-icon>
                    </div>
                </div>
            </div>

            <!-- aggiungi action -->
            <div [ngClass]="{'add-action-container':canAddAction(),
                            'add-action-disabled':!canAddAction()}" (click)="onAddAction()" fxLayout="row"
                fxLayoutAlign="center center" *ngIf="!data.model?.isReadOnly">
                <mat-icon class="plus-icon" fontSet="edms">bold-basics-add-simple</mat-icon>
                <mat-label class="add-action">
                    {{ labels.addAction | translate }}
                </mat-label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="create-action-dialog__actions" *ngIf="!data.model?.isReadOnly">
        <button mat-flat-button color="primary" [disabled]="saveButtonDisabled" class="save" (click)="save()">
            {{ labels.finish | translate }}
        </button>
    </div>
</div>