export const AZURE_ACTION_PATH = '/action';
export const AZURE_NOTES_PATH = '/note';
export const AZURE_TEMPLATE_PATH = '/template';
export const AZURE_CHECKLIST_PATH = '/checklist';
export const AZURE_QUESTION_PATH = '/question';
export const AZURE_PARAMETER_PATH = '/parameter';
export const AZURE_TASK_PATH = '/task';
export const AZURE_SIGNATURE_PATH = '/signature';
export const AZURE_IDEA_PATH = '/idea';
export const AZURE_IDEA_EVALUATOR_PATH = '/evaluator';
export const AZURE_IDEA_IMPLEMENTOR_PATH = '/implementor';
