<div class="add-action-description-dialog">
    <div class="add-action-description-dialog__title">
        <h1 class="mat-title">
            {{ labels.title | translate }}
        </h1>
        <mat-icon [mat-dialog-close]="false">close</mat-icon>
    </div>
  
    <div class="description-container" fxLayout="column" fxLayoutAlign="space-evenly stretch">
        <mat-label class="field-title">
            {{ labels.description | translate | uppercase }}
        </mat-label>
        
        <div class="description-container-inner" fxLayout="column" fxLayoutAlign="start end"
            [ngClass]="{ error: description && description.length > maxDescriptionTitle }">
            <mat-form-field class="action-description" appearance="none">
                <textarea
                    class="action-description__textarea"
                    placeholder="{{labels.textareaPlaceholder | translate}}"
                    matInput
                    [(ngModel)]="description"
                    (blur)="(false)"
                    #questionTitleField
                ></textarea>
            </mat-form-field>
            <span class="text-length">
                {{description ? description.length : 0}}/{{maxDescriptionTitle}}
            </span>
        </div>
         
    </div>

    <div mat-dialog-actions class="add-action-description-dialog__actions">
        <button mat-flat-button color="primary" [disabled]="!saveBtnEnabled" class="save" (click)="save()">
            {{ labels.save | translate }}
        </button>
    </div>
</div>
