import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RouteConstants } from '@app/constants/route.constants';
import { AuthenticationService } from '@app/security/authentication.service';
import { ServiceResponse } from '@app/types/service-response';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import UserDTO from '@data/models/user-dto';
import { UserService } from '@data/services/user.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-logout-idea-button',
  templateUrl: './logout-idea-button.component.html',
  styleUrls: ['./logout-idea-button.component.scss']
})
export class LogoutIdeaButtonComponent {
  public labels = {
    loading: marker('global.loading'),
    logout: marker('global.logout'),
    errorLoadingUser: marker('logout.userNotRetrieved'),
    menuFiltersButton: marker('dashboard.menu.filters')
  };

  public loggedUserInfo$: Observable<ServiceResponse<UserDTO>>;
  public isSuperUser: boolean;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.loggedUserInfo$ = this.userService.loggedUserInfo$;
    this.userService.isLoggedUserSuperUserOrGlobal().subscribe((x) => {
      this.isSuperUser = x;
    });
  }

  public logout(): void {
    this.authenticationService.logout();
    // this.document.location.reload();
  }

  public redirectToLocation(): void {
    this.router.navigate(['./' + RouteConstants.IDEA_LOCATION], {
      relativeTo: this.route
    });
  }
}
