import { DOCUMENT } from '@angular/common';
import { ApplicationRef, Inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { GLOBAL_MESSAGE_10SEC_DURATION } from '@shared/constants/global-message';
import { GlobalMessageService } from '@shared/services/global-message.service';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {
  private labels = {
    pwaUpdateTitle: marker('pwa.update.title'),
    pwaUpdateAction: marker('pwa.update.action')
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    private globalMessageService: GlobalMessageService,
    private translateService: TranslateService
  ) {}

  /**
   * Start a polling to check for PWA app updates
   *
   * @param checkIntervalInMillis Milliseconds between checks (defaults to 1 hour)
   */
  public startTimedUpdateChecker(checkIntervalInMillis: number = 1 * 60 * 60 * 1000) {
    if (this.updates.isEnabled) {
      const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
      const timer$ = interval(checkIntervalInMillis);
      const timerOnceAppIsStable$ = concat(appIsStable$, timer$);

      timerOnceAppIsStable$.subscribe(() => this.updates.checkForUpdate());
    }
  }

  /**
   * Subscribe to new PWA updates available, if there is any, prompts the user to reload the page
   */
  public checkForUpdate(): void {
    if (this.updates.isEnabled) {
      this.updates.available.subscribe(() => {
        this.showUpdateMessage();
      });
    }
  }

  private showUpdateMessage(): void {
    const message = this.translateService.instant(this.labels.pwaUpdateTitle);
    const actionText = this.translateService.instant(this.labels.pwaUpdateAction);

    this.globalMessageService.showMessage({
      message,
      actionText,
      duration: GLOBAL_MESSAGE_10SEC_DURATION,
      onAction: () => {
        this.updateApp();
      }
    });
  }

  private updateApp(): void {
    this.updates.activateUpdate().then(() => {
      this.document.location.reload();
    });
  }
}
