import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MAX_FILE_SIZE, MAX_VIDEO_SIZE } from '@app/constants/global.constants';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ActionDTO } from '@data/models/action-dto';
import { ActionTrackerDTO } from '@data/models/action-tracker-dto';
import { AnswerValueDTO } from '@data/models/answer-value-dto';
import { AppDetailDTO } from '@data/models/app-detail-dto';
import { DDSChecklistDTO } from '@data/models/dds-checklist-dto';
import { KpiDTO } from '@data/models/dds-template-dto';
import { FileDTO } from '@data/models/file-dto';
import { SourceDTO } from '@data/models/source-dto';
import { UserFiltersDTO } from '@data/models/user-filters-dto';
import { AdministrationService } from '@data/services/administration.service';
import { UserFiltersService } from '@data/services/user-filters.service';
import { TranslateService } from '@ngx-translate/core';
import { APP_TYPE_ENUM } from '@shared/constants/app-type.constants';
import { GlobalMessageService } from '@shared/services/global-message.service';
import { ProgressSpinnerDialogService } from '@shared/services/progress-spinner-dialog.service';

import { map } from 'rxjs/operators';

@Component({
  selector: 'app-at-action',
  templateUrl: './action.component.html'
})
export class ActionComponent implements OnInit {
  @Input()
  public edit = false;

  @Input()
  public files: FileDTO[][] = [];
  @Input()
  public type: string;
  @Input()
  public checklist: DDSChecklistDTO;
  public readonly ACTION_MAXLENGTH = 240;
  public kpi: KpiDTO;
  public kpiValue: AnswerValueDTO;
  public formGroup: FormGroup;
  public alreadyInit = false;

  public sourceControl: FormControl;
  public descriptionControl: FormControl = new FormControl('', [Validators.required]);
  public actionControl: FormControl = new FormControl('', Validators.required);

  public sourceList: SourceDTO[] = [];
  public appSourceList: AppDetailDTO[] = [];
  public isMetricsDDS = false;

  public labels: { [key: string]: string } = {
    source: marker('apps.appsLanding.other.dialog.action.source'),
    description: marker('apps.appsLanding.other.dialog.action.description'),
    action: marker('apps.appsLanding.other.dialog.action.action'),
    files: marker('global.files'),
    optional: marker('global.optional'),
    browseFiles: marker('global.browseFiles'),
    fileSizeLimit: marker('global.messages.fileSizeLimit'),
    requiredError: marker('error.required'),
    descriptionPlaceholder: marker('apps.appsLanding.other.dialog.action.descriptionPlaceholder'),
    actionPlaceholder: marker('apps.appsLanding.other.dialog.action.actionPlaceholder'),
    descriptionSubDds: marker('apps.appsLanding.other.dialog.action.descriptionSubDds')
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { model: ActionDTO; type?: string },
    private spinnerService: ProgressSpinnerDialogService,
    private fb: FormBuilder,
    private globalMessage: GlobalMessageService,
    private translate: TranslateService,
    private administrationService: AdministrationService,
    private userFiltersService: UserFiltersService
  ) {}

  public ngOnInit() {
    if (this.type !== 'DS') {
      this.sourceControl = new FormControl('', [Validators.required]);
    }
    this.createFormGroup();
    this.loadLists();
    this.loadData();
    this.searchKpis();
  }

  public get isFormValid(): boolean {
    //console.log(this.formGroup);
    return this.formGroup?.valid;
  }

  public updateModel(isApp?: boolean): void {
    if (this.type !== 'DS') {
      if (!this.data.model.trackerAction.app && this.data.model.trackerAction.source !== null) {
        this.data.model.trackerAction.source = { id: this.sourceControl.value };
      }
      this.data.model.trackerAction.description = this.descriptionControl.value;
      this.data.model.title = this.actionControl.value;
    }
    if (this.type === 'DS') {
      this.data.model.trackerAction.description = this.descriptionControl.value;
      this.data.model.title = this.actionControl.value;
    }
    if (this.type !== 'DS' && isApp) {
      this.data.model.trackerAction.app = { id: this.sourceControl.value };
      this.data.model.trackerAction.source = null;
    }
  }

  public addFiles(fileList: FileList): void {
    if (!this.alreadyInit) {
      this.files.push([]);
      this.alreadyInit = true;
    }
    for (let i = 0; i < fileList.length; i++) {
      const file: File = fileList.item(i);

      if (
        (file.type.startsWith('video') && file.size > MAX_VIDEO_SIZE) ||
        (!file.type.startsWith('video') && file.size > MAX_FILE_SIZE)
      ) {
        this.globalMessage.showError({ message: this.translate.instant('error.fileSizeLimit', { filename: file.name }) });
      } else {
        this.files[this.files.length - 1].push({
          data: file,
          thumbnail:
            file.type.startsWith('video') || file.type.startsWith('image')
              ? (window.URL || window.webkitURL).createObjectURL(file)
              : './assets/img/default-file@3x.png',
          template: false,
          SavedOnAzure: false
        });
      }
    }
  }

  public removeFile(index: number): void {
    this.files[this.files.length - 1].splice(index, 1);
  }

  /* todo */
  public searchKpis() {
    if (this.checklist?.checklistItems) {
      for (const checklistItems of this.checklist.checklistItems) {
        this.kpi = checklistItems.kpi;
      }
      for (const target of this.kpi.targets) {
        this.kpiValue = target.target;
      }
    }
    console.log(this.kpi, this.kpiValue);
  }

  private createFormGroup(): void {
    if (this.type !== 'DS') {
      this.formGroup = this.fb.group({
        source: this.sourceControl,
        description: this.descriptionControl,
        action: this.actionControl
      });
    }
    if (this.type === 'DS') {
      this.formGroup = this.fb.group({
        description: this.descriptionControl,
        action: this.actionControl
      });
    }
  }

  // TODO (FIX): Use async pipe to load this observable directly in the template
  private loadLists(): void {
    const spinner = this.spinnerService.show();

    this.userFiltersService.getUserActiveFilters().subscribe({
      next: (filters: UserFiltersDTO) => {
        const plant = filters.operationCenters.find((i) => i.filtered).plants.find((i) => i.filtered).plant;

        this.administrationService.getSourcesByPlantId(plant.id, true).subscribe((data) => {
          this.sourceList = data.sources.filter((s) => s.selectable);
          this.appSourceList = data.apps;
          this.spinnerService.hide(spinner);
          if (this?.data?.type === 'CL' || this.type === 'CL') {
            if (!this.data.model.trackerAction.app) {
              this.data.model.trackerAction = {} as ActionTrackerDTO;
              this.data.model.trackerAction.app = {} as AppDetailDTO;
            }
            this.data.model.trackerAction.app.id = this.appSourceList?.find(
              (x) => x.appType?.id === APP_TYPE_ENUM.CENTER_LINE
            )?.id;
            this.sourceControl.setValue(this.data.model.trackerAction.app?.id);
          }
        });
      },
      error: () => {
        this.spinnerService.hide(spinner);
      }
    });
  }

  private loadData(): void {
    if (this.data) {
      if (this?.data?.type !== 'DS' || this.type !== 'DS') {
        this.sourceControl.setValue(this.data.model.trackerAction.source?.id);
        this.descriptionControl.setValue(this.data.model.trackerAction?.description);
        this.actionControl.setValue(this.data.model.title);
      }
      if (this?.data?.type === 'DS' || this.type === 'DS') {
        this.descriptionControl.setValue(this.data.model.trackerAction?.description);
        this.actionControl.setValue(this.data.model.title);
      }
    }
  }
}
