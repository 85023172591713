<form autocomplete="off" class="action-component__form" *ngIf="formGroup" [formGroup]="formGroup">
    <div fxLayout="column" fxLayoutAlign="space-evenly stretch">
        <!-- if not dds actions -->
        <mat-label *ngIf="!edit && type !== 'DS'">{{ labels.source | translate }}</mat-label>
        <mat-form-field *ngIf="!edit && type !== 'DS'" [ngClass]="{ invalid: sourceControl.touched && sourceControl.invalid }">
            <mat-select [formControl]="sourceControl">
                <mat-option *ngFor="let source of sourceList" [value]="source.id" (click)="updateModel()">{{
                    source.literal.translateValue
                }}</mat-option>

                <mat-option *ngFor="let app of appSourceList" [value]="app.id" (click)="updateModel(true)">{{
                    app?.name
                }}</mat-option>
            </mat-select>
            <mat-error class="error" *ngIf="sourceControl.invalid">{{ labels.requiredError | translate }}</mat-error>
        </mat-form-field>
        <mat-label>{{ labels.description | translate }}</mat-label>
        <!-- <p class="muted" *ngIf="type === 'DS'">{{ labels.descriptionSubDds | translate }}</p> -->
        <mat-form-field
            *ngIf="type === 'DS'"
            [ngClass]="{ invalid: descriptionControl.touched && descriptionControl.invalid }"
            appearance="fill"
        >
            <textarea
                [formControl]="descriptionControl"
                trim="blur"
                (change)="updateModel()"
                matInput
                placeholder="{{ labels.descriptionPlaceholder | translate }}"
            ></textarea>
            <mat-error class="error" *ngIf="descriptionControl.invalid">{{ labels.requiredError | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field
            *ngIf="type !== 'DS'"
            [ngClass]="{ invalid: descriptionControl.touched && descriptionControl.invalid }"
            appearance="fill"
        >
            <textarea
                [formControl]="descriptionControl"
                trim="blur"
                (change)="updateModel()"
                matInput
                placeholder="{{ labels.descriptionPlaceholder | translate }}"
            ></textarea>
            <mat-error class="error" *ngIf="descriptionControl.invalid">{{ labels.requiredError | translate }}</mat-error>
        </mat-form-field>
        <mat-label *ngIf="!isMetricsDDS">{{ labels.action | translate }}</mat-label>
        <mat-form-field
            *ngIf="!isMetricsDDS"
            [ngClass]="{ bold: edit, invalid: actionControl.touched && actionControl.invalid }"
            appearance="fill"
        >
            <textarea
                [formControl]="actionControl"
                trim="blur"
                (change)="updateModel()"
                [maxLength]="ACTION_MAXLENGTH"
                matInput
                placeholder="{{ labels.actionPlaceholder | translate }}"
            ></textarea>
            <div class="character-counter">{{ actionControl?.value?.length }} / {{ ACTION_MAXLENGTH }}</div>
            <mat-error class="error" *ngIf="actionControl.invalid">{{ labels.requiredError | translate }}</mat-error>
        </mat-form-field>
        <mat-label *ngIf="!edit && !isMetricsDDS"
            >{{ labels.files | translate }} <span class="muted">({{ labels.optional | translate | lowercase }})</span></mat-label
        >
        <input class="hidden" type="file" #file (change)="addFiles($event.target.files)" multiple />
        <button
            type="button"
            *ngIf="!edit && !isMetricsDDS"
            mat-stroked-button
            color="primary"
            class="browse-files-content-button"
            (click)="file.value = ''; file.click()"
        >
            {{ labels.browseFiles | translate }}
        </button>
        <p *ngIf="!edit && !isMetricsDDS" class="muted">{{ labels.fileSizeLimit | translate }}</p>
        <div *ngIf="!edit && !isMetricsDDS" class="action-component__thumbnail_list">
            <div class="thumbnail" *ngFor="let file of files[files.length - 1]; let index = index">
                <img *ngIf="!file.data.type.startsWith('video')" [src]="file.thumbnail | safe" alt="{{ file.data.name }}" />
                <video *ngIf="file.data.type.startsWith('video')" controls preload="metadata">
                    <source *ngIf="file.thumbnail" [src]="file.thumbnail | safe" type="{{ file.data.type }}" />
                    <track label="edms-video" kind="descriptions" />
                    <track label="subtitles" kind="subtitles" srclang="en" src="" />
                </video>
                <span *ngIf="!file.data.type.startsWith('video') && !file.data.type.startsWith('image')" class="filename">{{
                    file.data.name
                }}</span>
                <span class="delete" (click)="removeFile(index)"> <mat-icon fontSet="edms">icons-trash</mat-icon></span>
            </div>
        </div>
    </div>
</form>
