import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { FileDTO } from '@data/models/file-dto';

export class AzureClient {
  constructor(private root: string, private client: BlobServiceClient) {}

  public async list(relativePath: string): Promise<FileDTO[]> {
    const result: FileDTO[] = [];
    const blobList = this.getContainer('').listBlobsFlat({ prefix: relativePath.slice(1, relativePath.length) });

    for await (const blob of blobList) {
      const path = blob.name.split('/');

      result.push({
        data: new File([], path[path.length - 1], { type: blob.properties.contentType }),
        thumbnail:
          blob.properties.contentType.startsWith('image') || blob.properties.contentType.startsWith('video')
            ? this.parseFileURL(blob.name)
            : './assets/img/default-file.png',
        template:relativePath.toLowerCase().includes('template'),
        SavedOnAzure:true
      });
    }

    return result;
  }

  public async download(relativePath: string, name: string, handler: (blob: Blob) => void): Promise<void> {
    const blobClient = this.getContainer(relativePath).getBlobClient(name);

    blobClient.download().then((response) => {
      response.blobBody?.then((blob) => {
        handler(blob);
      });
    });
  }

  public async delete(relativePath: string, name: string, handler: () => void): Promise<void> {
    const blobClient = this.getContainer(relativePath);

    blobClient.deleteBlob(name).then(() => {
      handler();
    });
  }

  public async upload(relativePath: string, content: Blob, name: string, handler: () => void) {
    const blockBlobClient = this.getContainer(relativePath).getBlockBlobClient(name);

    blockBlobClient.uploadData(content, { blobHTTPHeaders: { blobContentType: content.type } }).then(() => handler());
  }

  private parseFileURL(relativePath: string): string {
    return this.client.url.replace('?', `${this.root}/${relativePath}?`);
  }

  private getContainer(relativePath: string): ContainerClient {
    return this.client.getContainerClient(`${this.root}${relativePath}`);
  }
}
