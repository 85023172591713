import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ActionDTO } from '@data/models/action-dto';
import { ActionTypeDTO } from '@data/models/action-type-dto';
import { FormatCompleteDTO } from '@data/models/format-complete-dto';
import { FormatDTO } from '@data/models/format-dto';
import { LossCategoryDTO } from '@data/models/loss-category-dto';
import { PriorityDTO } from '@data/models/priority-dto';
import UserDTO from '@data/models/user-dto';
import { ActionTrackerService } from '@data/services/action-tracker.service';
import { ProgressSpinnerDialogService } from '@shared/services/progress-spinner-dialog.service';
import { AutocompleteFilter } from '@shared/utils/autocomplete-filter';
import { Formatter } from '@shared/utils/formatter';
import { typeValidator } from '@shared/validators/type.validator';
import { forkJoin, Observable } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';
import { ScrollcomponentComponent } from '@shared/components/scrollcomponent/scrollcomponent.component';
import { ActionTrackerDetailsService } from '@data/services/action-tracker-details.service';

@Component({
  selector: 'app-at-information',
  templateUrl: './information.component.html'
})
export class InformationComponent extends ScrollcomponentComponent implements OnInit {
  @Input() CkType: string;

  public type: string;
  public formGroup: FormGroup;
  public commentControl: FormControl = new FormControl();
  public priorityControl: FormControl = new FormControl('', [Validators.required]);
  public actionTypeControl: FormControl = new FormControl('', [Validators.required]);
  public actionOwnerControl: FormControl = new FormControl([], [typeValidator([] as UserDTO[])]);
  public lossCategoryControl: FormControl = new FormControl();
  public oeeControl: FormControl = new FormControl();
  public workingAreaFormatControl: FormControl = new FormControl();
  public equipmentFormatControl: FormControl = new FormControl();
  public usersSelected = [] as boolean[];
  public priorityList: PriorityDTO[] = [];
  public actionTypeList: ActionTypeDTO[] = [];
  public userList: UserDTO[] = [];
  public filteredUsers = [] as UserDTO[];
  public lossCategoryList: LossCategoryDTO[] = [];
  public workingAreaFormatList: FormatCompleteDTO[] = [];
  public equipmentFormatList: FormatCompleteDTO[] = [];
  public searchString: FormControl = new FormControl('');
  public isMetricsDDS = false;

  public readonly displayUserNameAndSurname = Formatter.displayUserNameAndSurname;

  public showAdditionalInfo = false;
  public dueDate: Date = null;

  public labels: { [key: string]: string } = {
    priority: marker('apps.appsLanding.other.dialog.information.priority'),
    actionType: marker('apps.appsLanding.other.dialog.information.actionType'),
    requiredError: marker('error.required'),
    actionOwner: marker('apps.appsLanding.other.dialog.planning.actionOwner'),
    dueDate: marker('apps.appsLanding.other.dialog.planning.dueDate'),
    startTyping: marker('apps.appsLanding.other.dialog.planning.startTyping'),
    optional: marker('global.optional'),
    typeError: marker('error.type'),
    lossCategory: marker('apps.appsLanding.other.dialog.others.lossCategory'),
    oee: marker('apps.appsLanding.other.dialog.others.oee'),
    workingAreaFormat: marker('apps.appsLanding.other.dialog.others.workingAreaFormat'),
    equipmentFormat: marker('apps.appsLanding.other.dialog.others.equipmentFormat'),
    selectLossCategory: marker('apps.appsLanding.other.dialog.others.selectLossCategory'),
    selectFormat: marker('apps.appsLanding.other.dialog.others.selectFormat'),
    typeOEE: marker('apps.appsLanding.other.dialog.others.typeOEE'),
    additionalInformation: marker('apps.appsLanding.other.dialog.others.additionalInformation'),
    comment: marker('apps.appsLanding.other.dialog.others.comment')
  };

  private readonly MAX_PERCENTAGE = 100;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { model: ActionDTO },
    private spinnerService: ProgressSpinnerDialogService,
    private fb: FormBuilder,
    private actionTrackerService: ActionTrackerService,
    private changeDetectorRef: ChangeDetectorRef,
    private actionTrackerDetailsService: ActionTrackerDetailsService
  ) {
    super();
  }

  ngOnInit() {
    this.createFormGroup();
    this.loadLists();
    // this.loadData();
    if (!this.isMetricsDDS) {
      this.isMetricsDDS = this.data.model?.isDDS;
    }
  }

  public get isFormValid(): boolean {
    if (this.type === 'DS') {
      if (this.dueDate !== null && this.data.model.trackerAction?.owners?.length && this.formGroup?.valid) {
        return true;
      }
      return false;
    }
    return this.formGroup?.valid;
  }

  public updateModel(): void {
    this.data.model.trackerAction.priority = { id: this.priorityControl.value };
    this.data.model.trackerAction.actionType = { id: this.actionTypeControl.value };
    for (const ow of this.actionOwnerControl.value) {
      if (ow) {
        if (!this.data.model.trackerAction.owners) {
          this.data.model.trackerAction.owners = [];
        }
        /*   if (user) {
          this.setOwnerFormControlValue(user);
        } */
        if (this.data.model.trackerAction.owners.findIndex((x) => x.id === ow.id) === -1) {
          this.data.model.trackerAction.owners.push(ow);
        }
      } else {
        this.data.model.trackerAction.owners = null;
      }
    }

    this.data.model.trackerAction.dueDate = this.dueDate;

    if (this.oeeControl.value < 0) {
      this.oeeControl.setValue(0);
    } else if (this.oeeControl.value > this.MAX_PERCENTAGE) {
      this.oeeControl.setValue(this.MAX_PERCENTAGE);
    }

    this.data.model.trackerAction.lossCategory = this.lossCategoryControl.value ? { id: this.lossCategoryControl.value } : null;
    this.data.model.trackerAction.oee = this.oeeControl.value;
    this.data.model.trackerAction.workingAreaFormat = this.workingAreaFormatControl.value
      ? { id: this.workingAreaFormatControl.value }
      : null;
    this.data.model.trackerAction.equipmentFormat = this.equipmentFormatControl.value
      ? { id: this.equipmentFormatControl.value }
      : null;
  }

  public setUser(opt: UserDTO) {
    if (this.data.model.trackerAction.owners.findIndex((x) => x.id === opt.id) > -1) {
      this.deleteUser(
        this.data.model.trackerAction.owners,
        this.data.model.trackerAction.owners.findIndex((x) => x.id === opt.id)
      );
    } else {
      this.data.model.trackerAction.owners.push(opt);
      this.actionOwnerControl.setValue(this.data.model.trackerAction.owners);
    }
  }

  public sort() {
    for (const opt of this.data.model.trackerAction.owners) {
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions, space-before-function-paren
      this.filteredUsers.sort(function (a, b) {
        if (a.id !== opt.id) {
          return 1;
        } else if (b.id !== opt.id) {
          return -1;
        } else {
          return 0;
        }
      });
    }
  }

  public getUserValue() {
    let value = '';
    if (this?.data?.model?.trackerAction?.owners) {
      for (const user of this?.data?.model?.trackerAction?.owners) {
        value += user.fullName + ', ';
      }
    }
    return value;
  }

  public deleteUser(users: UserDTO[], index: number) {
    users.splice(index, 1);
    this.actionOwnerControl.setValue(users);
  }

  public workingAreaSelected() {
    const waFormat = {} as FormatDTO;
    waFormat.id = this.workingAreaFormatControl.value;
    const spinner = this.spinnerService.show();
    this.equipmentFormatList = [];
    this.actionTrackerService.relatedFormats(waFormat.id, this?.data?.model?.trackerAction?.equipment?.id).subscribe((data) => {
      this.equipmentFormatList = data.equipmentFormats;
      this.spinnerService.hide(spinner);
      this.changeDetectorRef.detectChanges();
    });
  }

  public equipmentSelected() {
    const waFormat = {} as FormatDTO;
    const eqFormat = {} as FormatDTO;
    waFormat.id = this.workingAreaFormatControl.value;
    eqFormat.id = this.equipmentFormatControl.value;
    const spinner = this.spinnerService.show();
    this.actionTrackerService.relatedFormats(waFormat.id, eqFormat.id).subscribe((data) => {
      // this.equipmentFormatList = data.equipmentFormats;
      this.spinnerService.hide(spinner);
    });
  }

  public reloadFormats(): void {
    this.workingAreaFormatList = [];
    this.equipmentFormatList = [];

    if (this.data.model.trackerAction.workingAreas?.length) {
      const spinner = this.spinnerService.show();
      for (const wa of this.data.model.trackerAction.workingAreas) {
        this.actionTrackerService.getFormats(wa.id).subscribe((data) => {
          this.workingAreaFormatList = data.workingAreasFormat;
          // this.equipmentFormatList = data.equipmentFormats;
          this.spinnerService.hide(spinner);
        });
      }
    }
  }

  public clearFormat(): void {
    this.workingAreaFormatControl.reset();
    this.equipmentFormatControl.reset();
    this.data.model.trackerAction.workingAreaFormat = null;
    this.data.model.trackerAction.equipmentFormat = null;
  }

  public checkInList(st: string) {
    this.filteredUsers = this.userList.filter(
      (x) => x?.fullName?.includes(st) || x?.email?.includes(st) || x?.firstName?.includes(st) || x?.lastName?.includes(st)
    );
    this.usersSelected = [];
    for (const fu of this.filteredUsers) {
      this.usersSelected.push(false);
    }
  }
  private createFormGroup(): void {
    /* this.actionOwnerControl.valueChanges.pipe(
      startWith(''),
      map((value) =>
        AutocompleteFilter.find<UserDTO>(
          value,
          this.userList,
          (user: UserDTO, filter: string) =>
            user?.firstName?.toLowerCase()?.indexOf(filter?.toLowerCase()) !== -1 ||
            user?.lastName?.toLowerCase()?.indexOf(filter?.toLowerCase()) !== -1 ||
            this.displayUserNameAndSurname(user)?.toLowerCase()?.indexOf(filter.toLowerCase()) !== -1
        )
      )
    ); */

    this.formGroup = this.fb.group({
      priority: this.priorityControl,
      actionType: this.actionTypeControl,
      actionOwner: this.data.model.trackerAction.owners,
      lossCategory: this.lossCategoryControl,
      oee: this.oeeControl,
      workingAreaFormat: this.workingAreaFormatControl,
      equipmentFormat: this.equipmentFormatControl,
      comment: this.commentControl
    });
  }

  private loadLists(): void {
    const spinner = this.spinnerService.show();

    const requests = [];

    requests.push(this.actionTrackerService.getPriorities());
    requests.push(this.actionTrackerService.getActionTypes());
    requests.push(this.actionTrackerService.getActionOwners(ActionTrackerService.ACTIONS_OWNERS_FILTER_ENABLE));
    requests.push(this.actionTrackerService.getLossCategories());

    this.workingAreaFormatList = [];
    // this.equipmentFormatList = [];

    if (this.data.model.trackerAction?.workingAreas?.length) {
      for (const wa of this.data.model.trackerAction?.workingAreas) {
        requests.push(this.actionTrackerService.getFormats(wa.id));
      }
    }

    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.spinnerService.hide(spinner);
        })
      )
      .subscribe({
        next: (data) => {
          this.priorityList = data[0] as PriorityDTO[];

          this.actionTypeList = data[1] as ActionDTO[];
          if (this.actionTypeList.length === 1) {
            this.actionTypeControl.setValue(this.actionTypeList[0].id);
          }
          this.userList = data[2] as UserDTO[];
          this.filteredUsers = this.userList;
          this.usersSelected = [];

          for (let i = 0; i < this.userList.length; i++) {
            this.usersSelected[i] = false;
          }

          this.lossCategoryList = data[3] as LossCategoryDTO[];

          if (this.data.model.trackerAction?.workingAreas?.length) {
            const data4 = data[4] as { equipmentFormats: FormatCompleteDTO[]; workingAreasFormat: FormatCompleteDTO[] };
            this.workingAreaFormatList = data4.workingAreasFormat;
            // this.equipmentFormatList = data4.equipmentFormats;
          }

          this.loadData();
        }
      });
  }

  private loadData(): void {
    if (this.data) {
      this.priorityControl.setValue(this.data.model.trackerAction?.priority?.id);
      if (this.data.model.trackerAction?.actionType) {
        this.actionTypeControl.setValue(this.data.model.trackerAction?.actionType?.id);
      }
      const ul = [];
      if (this.data.model.trackerAction?.owners) {
        for (const owner of this.data.model.trackerAction?.owners) {
          const find = this.userList.find((user) => user.id === owner.id);
          if (!!find) {
            ul.push(find);
          }
        }
      } else {
        this.data.model.trackerAction.owners = [];
      }

      this.actionOwnerControl.setValue(ul);
      this.dueDate = this.data.model.trackerAction?.dueDate ? new Date(this.data.model.trackerAction?.dueDate) : null;
      this.lossCategoryControl.setValue(this.data.model.trackerAction?.lossCategory?.id);
      this.oeeControl.setValue(this.data.model.trackerAction?.oee);
      this.workingAreaFormatControl.setValue(this.data.model.trackerAction?.workingAreaFormat?.id);
      this.equipmentFormatControl.setValue(this.data.model.trackerAction?.equipmentFormat?.id);
    }
  }
}
