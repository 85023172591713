import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteConstants } from '@app/constants/route.constants';
import { AuthGuardEbos } from '@app/security/guards/auth-guard-ebos.guard';
import { AuthGuardIdeaMobile } from '@app/security/guards/auth-guard-idea-mobile.guard';
import { AuthGuardIdea } from '@app/security/guards/auth-guard-idea.guard';
import { AuthGuard } from '@app/security/guards/auth.guard';
import { DashboardIdeaLayoutComponent } from '@layout/dashboard-idea-layout/dashboard-idea-layout.component';
import { DashboardIdeaMobileLayoutComponent } from '@layout/dashboard-idea-mobile-layout/dashboard-idea-mobile-layout.component';

import { DashboardLayoutComponent } from '@layout/dashboard-layout/dashboard-layout.component';
import { DefaultLayoutComponent } from '@layout/default-layout/default-layout.component';

const routes: Routes = [
  {
    path: RouteConstants.LOGIN,
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: RouteConstants.DASHBOARD,
    canActivate: [AuthGuardEbos],
    component: DashboardLayoutComponent,
    loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule)
  },
  {
    path: RouteConstants.DASHBOARD_IDEA,
    canActivate: [AuthGuardIdea],
    component: DashboardIdeaLayoutComponent,
    loadChildren: () => import('./modules/dashboard-idea/dashboard-idea.module').then((m) => m.DashboardIdeaModule)
  },
  {
    path: RouteConstants.DASHBOARD_IDEA_MOBILE,
    canActivate: [AuthGuardIdeaMobile],
    component: DashboardIdeaMobileLayoutComponent,
    loadChildren: () =>
      import('./modules/dashboard-idea-mobile/dashboard-idea-mobile.module').then((m) => m.DashboardIdeaMobileModule)
  },
  { path: '**', pathMatch: 'full', redirectTo: RouteConstants.DASHBOARD }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
