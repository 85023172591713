import { marker } from '@biesbjerg/ngx-translate-extract-marker';
export const LANG_EN = 'en';
export const LANG_IT = 'it';
export const LANG_ES = 'es';
export const LANG_FR = 'fr';

export const LANGUAGES = [
  { id: LANG_EN, label: marker('language.english') },
  { id: LANG_IT, label: marker('language.italian') },
  { id: LANG_ES, label: marker('language.spanish') },
  { id: LANG_FR, label: marker('language.french') }
];
