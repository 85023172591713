import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { PROJECT_ENUM } from '@app/constants/global.constants';
import { RouteConstants } from '@app/constants/route.constants';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardIdea implements CanActivate {
  constructor(private authenticationService: AuthenticationService, private router: Router, private route: ActivatedRoute) {}

  canActivate(): boolean {
    if (this.authenticationService.isUserLogged()) {
      if (window.innerWidth > 768) {
        return true;
      } else {
        this.router.navigate([`${RouteConstants.DASHBOARD_IDEA_MOBILE}`], {
          relativeTo: this.route.root,
          state: {
            user: null
          }
        });
      }
    } else {
      this.router.navigate([RouteConstants.LOGIN]);
      return false;
    }
  }
}
