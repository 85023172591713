import { NgModule } from '@angular/core';
import { SafePipe } from './safe.pipe';
import { FilterPipe } from './filter.pipe';
import { FormatFilterPipe } from './formatFilter/format-filter.pipe';
import { FormatSapFilterPipe } from './formatSapFilter/format-sap-filter.pipe';
import { FillByFilterPipe } from './fillByFilter/fill-by-filter.pipe';
import { FilterUserPipe } from './userFilter/filter-user.pipe';
import { DDSFilterPipe } from './ddsFilter/dds-filter.pipe';

@NgModule({
  exports: [SafePipe, FilterPipe, FormatFilterPipe, FormatSapFilterPipe, FillByFilterPipe, FilterUserPipe, DDSFilterPipe],
  declarations: [SafePipe, FilterPipe, FormatFilterPipe, FormatSapFilterPipe, FillByFilterPipe, FilterUserPipe, DDSFilterPipe]
})
export class PipeModule {}
