<!-- titolo della domanda -->

<div
    *ngIf="stage"
    [ngClass]="{ hidden: checkConditions(questions[0]) }"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="question-group-title"
>
    <h2 class="group-title">{{ stage.title }}</h2>
</div>
<div *ngIf="stageN == 1" style="margin-top: -40px; margin-bottom: 20px" class="pv-question__subtitle">
    {{ labels.stage2subtitle | translate }}
</div>

<div
    *ngFor="let question of questions; let qi = index"
    class="pv-question-root"
    [ngClass]="{ hidden: checkConditions(question) }"
>
    <div class="pv-question">
        <div class="pv-question__content-outer" fxLayout="column">
            <div class="pv-question__content" fxLayout="row" fxLayoutAlign="space-between start">
                <!--  question.answerType.answerType !== answerTypeEnum.SIGNATURE -->
                <div
                    class=""
                    fxLayout="column"
                    fxLayoutAlign="space-between start"
                    fxFlex
                    *ngIf="question && question.answerType && question.answerType.answerType !== answerTypeEnum.SIGNATURE"
                >
                    <div class="title-and-button">
                        <!-- punto esclamativo giallo -->
                        <mat-icon
                            fontSet="edms"
                            class="question-icon question-icon__yellow"
                            *ngIf="isOptionalActionButtonVisible(question)"
                        >
                            basics-alerts-alert-circle
                        </mat-icon>

                        <!-- punto esclamativo rosso -->
                        <mat-icon
                            fontSet="edms"
                            class="question-icon question-icon__red"
                            *ngIf="isMandatoryActionButtonVisible(question)"
                        >
                            basics-alerts-alert-circle
                        </mat-icon>

                        <!-- Info -->
                        <mat-icon
                            fontSet="edms"
                            class="question-icon"
                            color="primary"
                            *ngIf="question && question.answerType && question.answerType.answerType === answerTypeEnum.NA"
                        >
                            bold-basics-alerts-information-circle
                        </mat-icon>

                        <div fxLayout="row" fxLayoutAlign="space-between center" class="for-action">
                            <!-- titolo della domanda -->
                            <span class="pv-question__type" [innerHtml]="question.title">
                                <!-- {{ getAnswerTypeDescription(question.answerType.answerType) | translate}}
                            {{ question.title }} -->
                            </span>

                            <div class="btn-header" *ngIf="question.actionDescription">
                                <button class="btn-for-action">
                                    <span class="btn-text">{{ labels.describeIssueBtn | translate }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- sottotitolo della domanda -->
                    <p class="pv-question__subtitle">{{ question.subtitle }}</p>

                    <div
                        class="questions-thumbnail-lists"
                        *ngIf="attachmentVisible[qi] || question.answerType.answerType === answerTypeEnum.ATTACH_FILE"
                        fxLayout="row"
                        fxLayoutAlign="start start"
                        fxLayout="row wrap"
                    >
                        <div *ngFor="let file of getQuestionFiles(question, false); let index = index">
                            <div class="img-container">
                                <img
                                    *ngIf="!file.data.type.startsWith('video')"
                                    [src]="file.thumbnail | safe"
                                    alt="{{ file.data.name }}"
                                    style="position: inherit"
                                />
                            </div>
                            <video *ngIf="file.data.type.startsWith('video')" controls preload="metadata">
                                <source *ngIf="file.thumbnail" [src]="file.thumbnail | safe" type="{{ file.data.type }}" />
                                <track label="edms-video" kind="descriptions" />
                                <track label="subtitles" kind="subtitles" srclang="en" src="" />
                            </video>
                            <span
                                *ngIf="!file.data.type.startsWith('video') && !file.data.type.startsWith('image')"
                                class="filename"
                                >{{ file.data.name }}</span
                            >
                            <span
                                class="delete"
                                style="margin-left: 0px; width: 45px; position: inherit"
                                (click)="deleteFile(question, file, qi)"
                            >
                                <mat-icon fontSet="edms" style="font-size: 24px">icons-trash</mat-icon>
                            </span>
                        </div>
                    </div>

                    <div *ngIf="getQuestionFiles(question, true).length > 0" class="questions-thumbnail-lists">
                        <div *ngFor="let file of getQuestionFiles(question, true); let index = index">
                            <div class="img-container">
                                <img
                                    *ngIf="!file.data.type.startsWith('video')"
                                    [src]="file.thumbnail | safe"
                                    alt="{{ file.data.name }}"
                                    (click)="viewImmages(getQuestionFiles(question, true))"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- risposta + check OTHER -->
                    <mat-form-field
                        class="pv-question__textarea"
                        *ngIf="question && question.answerType && question.answerType.answerType === answerTypeEnum.FREE_TEXT"
                    >
                        <textarea
                            matInput
                            [placeholder]="!pdfView ? (labels.enterAnswer | translate) : ''"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="5"
                            [(ngModel)]="getChosenAnswer(question).answerString"
                            [maxLength]="500"
                        ></textarea>
                        <span class="text-length" [hidden]="pdfView">
                            {{ getChosenAnswer(question).answerString ? getChosenAnswer(question).answerString.length : 0 }}/
                            500</span
                        >
                    </mat-form-field>

                    <!-- action -->
                    <!-- Edit action 
                    <button
                        type="button"
                        mat-stroked-button
                        color="primary"
                        class="action"
                        (click)="onCreateAction(question)"
                        *ngIf="isEditActionButtonVisible(question)"
                        [disabled]="isReadOnly"
                    >
                        {{ labels.editAction | translate }}
                    </button> -->
                    <!-- Edit action after save 
                    <button
                        type="button"
                        mat-stroked-button
                        color="primary"
                        class="action"
                        (click)="onCreateAction(question)"
                        *ngIf="actionAdded.get(question.id) && !isEditActionButtonVisible(question)"
                        [disabled]="isReadOnly"
                    >
                        {{ labels.editAction | translate }}
                    </button> 
                    <button
                        type="button"
                        mat-stroked-button
                        class="action action-optional"
                        (click)="onCreateAction(question)"
                        *ngIf="isOptionalActionButtonVisible(question)"
                        [disabled]="isReadOnly"
                    >
                        {{ labels.optionalAction | translate }}
                    </button> 
                    <button
                        type="button"
                        mat-stroked-button
                        class="action action-required"
                        (click)="onCreateAction(question)"
                        *ngIf="isMandatoryActionButtonVisible(question)"
                    >
                        {{ labels.requiredAction | translate }}
                    </button> -->
                </div>

                <!--  question.answerType.answerType === answerTypeEnum.ATTACH_FILE -->
                <div
                    fxLayout="column"
                    *ngIf="question && question.answerType && question.answerType.answerType === answerTypeEnum.ATTACH_FILE"
                >
                    <div fxLayout="row">
                        <div>
                            <input
                                class="hidden"
                                type="file"
                                #file
                                multiple
                                (click)="$event.target.value = null"
                                (change)="addFiles(question, $event.target.files, qi)"
                            />
                            <button type="button" mat-stroked-button (click)="file.click()" class="browse">
                                {{ labels.browseFiles | translate }}
                            </button>
                        </div>
                    </div>
                </div>

                <!--  HERE ALL COMBO -->
                <div
                    *ngIf="
                        question &&
                        question.answerType &&
                        (question.answerType.answerType === answerTypeEnum.NUMBER ||
                            question.answerType.answerType === answerTypeEnum.PERCENTAGE ||
                            question.answerType.answerType === answerTypeEnum.RANGE ||
                            question.answerType.answerType === answerTypeEnum.ZERO_TO_THREE ||
                            question.answerType.answerType === answerTypeEnum.YES_NO ||
                            question.answerType.answerType === answerTypeEnum.LOW_MEDIUM_HIGH ||
                            question.answerType.answerType === answerTypeEnum.ONE_OPTION ||
                            question.answerType.answerType === answerTypeEnum.MULTIPLE_OPTIONS ||
                            question.answerType.answerType === answerTypeEnum.EQUIPMENT ||
                            question.answerType.answerType === answerTypeEnum.WORKINGAREA ||
                            question.answerType.answerType === answerTypeEnum.USER ||
                            question.answerType.answerType === answerTypeEnum.EQUIPMENT_MULTIPLE ||
                            question.answerType.answerType === answerTypeEnum.WORKINGAREA_MULTIPLE ||
                            question.answerType.answerType === answerTypeEnum.USER_MULTIPLE ||
                            question.answerType.answerType === answerTypeEnum.DATE ||
                            question.answerType.answerType === answerTypeEnum.TIME ||
                            question.answerType.answerType === answerTypeEnum.DATE_AND_TIME ||
                            question.answerType.answerType === answerTypeEnum.SIGNATURE)
                    "
                    [ngClass]="{
                        boxes: question.answerType.answerType !== answerTypeEnum.ZERO_TO_THREE,
                        'boxes-multiple': question.answerType.answerType === answerTypeEnum.ZERO_TO_THREE
                    }"
                >
                    <div
                        fxLayout="column"
                        class="numeric-field-box"
                        *ngIf="
                            question.answerType.answerType === answerTypeEnum.USER ||
                            question.answerType.answerType === answerTypeEnum.USER_MULTIPLE
                        "
                    >
                        <!-- fxLayoutAlign="space-around start" -->

                        <div
                            class="user-input"
                            *ngIf="
                                question.answerType.answerType === answerTypeEnum.USER ||
                                question.answerType.answerType === answerTypeEnum.USER_MULTIPLE
                            "
                        >
                            <mat-icon
                                class="search-user-icon"
                                style="margin: 5px 8px 0 0; cursor: pointer"
                                fontSet="edms"
                                [hidden]="pdfView"
                                #refEl
                                (click)="searchUsers(userSearch?.value, refEl)"
                            >
                                bold-basics-search-search-alternate
                            </mat-icon>

                            <input
                                style="background: white; height: 50px; text-align: left; padding-left: 55px"
                                #userSearch
                                name="userSearch"
                                [placeholder]="pdfView ? '' : 'Search other...'"
                                trim="blur"
                                matInput
                                [value]="
                                    checklistItems[qi] && checklistItems[qi].answerValue
                                        ? getUserValue(checklistItems[qi].answerValue.usersAnswer)
                                        : ''
                                "
                                [matAutocomplete]="auto"
                            />

                            <mat-autocomplete
                                #auto="matAutocomplete"
                                [displayWith]="displayUserAD"
                                (closed)="suggestions.value = []"
                            >
                                <ng-container
                                    *ngIf="
                                        question && question.answerType && question.answerType.answerType === answerTypeEnum.USER
                                    "
                                >
                                    <mat-option
                                        *ngFor="let option of suggestions?.value"
                                        [value]="{
                                            displayName: option?.displayName,
                                            email: option?.mail,
                                            surname: option?.surname
                                        }"
                                        (click)="setUser(option, question, question.answerType.answerType)"
                                    >
                                        {{ option?.displayName }}</mat-option
                                    >
                                </ng-container>

                                <ng-container
                                    *ngIf="
                                        question &&
                                        question.answerType &&
                                        question.answerType.answerType === answerTypeEnum.USER_MULTIPLE
                                    "
                                >
                                    <mat-checkbox
                                        (click)="setUser(option, question, question.answerType.answerType)"
                                        *ngFor="let option of suggestions?.value; let i = index"
                                    >
                                        <mat-option
                                            [value]="{
                                                displayName: option?.displayName,
                                                email: option?.mail,
                                                surname: option?.surname
                                            }"
                                        >
                                            {{ option?.displayName }}
                                        </mat-option></mat-checkbox
                                    >
                                </ng-container>
                            </mat-autocomplete>
                        </div>

                        <p
                            class="user-name"
                            *ngIf="
                                question.answerType.answerType === answerTypeEnum.USER && getChosenAnswer(question)?.usersAnswer
                            "
                        >
                            <mat-icon class="search-user-icon" fontSet="edms" [hidden]="pdfView"> icons-profile </mat-icon>
                            {{ getChosenAnswer(question).usersAnswer[0]?.fullName }}
                            ·
                            {{ getChosenAnswer(question).usersAnswer[0]?.email }}
                            <mat-icon (click)="deleteUser(getChosenAnswer(question).usersAnswer, 0)" svgIcon="close"></mat-icon>
                        </p>
                    </div>
                    <ng-container
                        *ngIf="
                            question.answerType.answerType === answerTypeEnum.USER_MULTIPLE &&
                            getChosenAnswer(question).usersAnswer?.length
                        "
                    >
                        <ng-container *ngFor="let user of getChosenAnswer(question).usersAnswer; let i = index">
                            <p>
                                <mat-icon class="search-user-icon" fontSet="edms" [hidden]="pdfView"> icons-profile </mat-icon>
                                {{ user?.fullName }}
                                ·
                                {{ user?.email }}
                                <mat-icon
                                    (click)="deleteUser(getChosenAnswer(question).usersAnswer, i)"
                                    svgIcon="close"
                                ></mat-icon>
                            </p>
                        </ng-container>
                    </ng-container>

                    <div
                        fxLayout="column"
                        class="numeric-field-box"
                        *ngIf="question && question.answerType && question.answerType.answerType === answerTypeEnum.DATE"
                    >
                        <mat-form-field fxFlex class="mat-form-field-rounded numeric-field no-background" appearance="standard">
                            <input
                                *ngIf="checklistItems?.length"
                                matInput
                                [matDatepicker]="picker"
                                [placeholder]="pdfView ? '' : 'Select date'"
                                [(ngModel)]="Dates[question.id]"
                            /><!--  -->
                            <input
                                *ngIf="!checklistItems?.length"
                                matInput
                                [matDatepicker]="picker"
                                [placeholder]="pdfView ? '' : 'Select date'"
                            />
                            <mat-hint [hidden]="pdfView" style="margin-top: 13px">MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div
                        fxLayout="column"
                        class="numeric-field-box"
                        *ngIf="question && question.answerType && question.answerType.answerType === answerTypeEnum.TIME"
                    >
                        <mat-form-field fxFlex class="mat-form-field-rounded numeric-field no-background" appearance="standard">
                            <input
                                style="color: rgba(18, 18, 18, 0.54)"
                                *ngIf="checklistItems?.length"
                                matInput
                                type="time"
                                [placeholder]="pdfView ? '' : 'Select Time...'"
                                [(ngModel)]="checklistItems[qi].answerValue.timeAnswer"
                            />
                            <!--   -->
                            <input
                                style="color: rgba(18, 18, 18, 0.54)"
                                *ngIf="!checklistItems?.length"
                                matInput
                                type="time"
                                [placeholder]="pdfView ? '' : 'Select Time...'"
                            />
                            <mat-hint style="margin-top: 13px">HH:MM</mat-hint>
                        </mat-form-field>
                    </div>
                    <div
                        fxLayout="row"
                        class="numeric-field-box"
                        *ngIf="question && question.answerType && question.answerType.answerType === answerTypeEnum.DATE_AND_TIME"
                    >
                        <mat-form-field
                            fxFlex
                            class="mat-form-field-rounded numeric-field no-background"
                            appearance="standard"
                            style="width: 150px"
                        >
                            <input
                                *ngIf="checklistItems?.length"
                                matInput
                                [matDatepicker]="picker"
                                [placeholder]="pdfView ? '' : 'Select Date'"
                                [(ngModel)]="DatesAndTime[qi]"
                            />
                            <input
                                *ngIf="!checklistItems?.length"
                                matInput
                                [matDatepicker]="picker"
                                [placeholder]="pdfView ? '' : 'Select Date'"
                            />
                            <mat-hint style="margin-top: 13px">MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field
                            fxFlex
                            class="mat-form-field-rounded numeric-field no-background"
                            appearance="standard"
                            style="width: 150px"
                        >
                            <input
                                style="color: rgba(18, 18, 18, 0.54)"
                                *ngIf="checklistItems?.length"
                                matInput
                                type="time"
                                [placeholder]="pdfView ? '' : 'Select Time'"
                                [(ngModel)]="TimesAndDate[qi]"
                            />
                            <!-- checklistItems[getArrayIndexBase( 0) + qi].answerValue.timeAnswer -->
                            <input
                                style="color: rgba(18, 18, 18, 0.54)"
                                *ngIf="!checklistItems?.length"
                                matInput
                                type="time"
                                [placeholder]="pdfView ? '' : 'Select Time'"
                            />
                            <mat-hint style="margin-top: 13px">HH:MM</mat-hint>
                        </mat-form-field>
                    </div>

                    <!-- numerico o range -->
                    <div
                        *ngIf="
                            question.answerType.answerType === answerTypeEnum.NUMBER ||
                            question.answerType.answerType === answerTypeEnum.RANGE ||
                            question.answerType.answerType === answerTypeEnum.PERCENTAGE
                        "
                        class="numeric-field-box"
                    >
                        <mat-form-field fxFlex class="mat-form-field-rounded numeric-field no-background" appearance="standard">
                            <input
                                placeholder="{{
                                    notApplicableChecked.get(this.getChosenAnswerKey(question)) || pdfView
                                        ? ''
                                        : (labels.enterAnswer | translate)
                                }}"
                                trim="blur"
                                matInput
                                (change)="(false)"
                                (blur)="(false)"
                                [disabled]="notApplicableChecked.get(this.getChosenAnswerKey(question)) || isReadOnly"
                                [(ngModel)]="getChosenAnswer(question).answerNumber"
                                type="number"
                            />
                        </mat-form-field>
                        <button
                            type="button"
                            mat-stroked-button
                            *ngIf="question.notApplicable"
                            [ngClass]="{
                                'not-applicable': !notApplicableChecked.get(this.getChosenAnswerKey(question)),
                                'not-applicable-selected': notApplicableChecked.get(this.getChosenAnswerKey(question))
                            }"
                            (click)="onNotApplicablePressed(question)"
                            [disabled]="isReadOnly"
                        >
                            {{ labels.notApplicable | translate }}
                        </button>
                    </div>

                    <!-- 0-3 o yes/no o low/high 
                                        o scelta singola +  appResult
                                    -->
                    <div
                        *ngIf="
                            question.answerType.answerType === answerTypeEnum.ZERO_TO_THREE ||
                            question.answerType.answerType === answerTypeEnum.YES_NO ||
                            question.answerType.answerType === answerTypeEnum.LOW_MEDIUM_HIGH ||
                            (question.answerType.answerType === answerTypeEnum.ONE_OPTION && appResults)
                        "
                        class="numeric-field-box"
                    >
                        <button
                            type="button"
                            mat-stroked-button
                            *ngFor="let option of question.answerType.possibleAnswers"
                            [disabled]="isReadOnly"
                            [ngClass]="{
                                option: true,
                                'option-selected-green':
                                    (getChosenAnswer(question) &&
                                        (getChosenAnswer(question).possibleAnswerType == option.possibleAnswerType ||
                                            getChosenAnswer(question)?.possibleAnswer === option?.possibleAnswer) &&
                                        getChosenAnswer(question).expectedAnswer) ||
                                    (getChosenAnswer(question) &&
                                        question.answerType.answerType === answerTypeEnum.LOW_MEDIUM_HIGH &&
                                        appTypeId === appTypeEnum.SAFETY_TRIGGER &&
                                        (getChosenAnswer(question)?.possibleAnswer === 'answerTypes.lowMediumHigh.low' ||
                                            getChosenAnswer(question)?.possibleAnswer === 'LOW') &&
                                        (option.possibleAnswer === 'LOW' ||
                                            option.possibleAnswer === 'answerTypes.lowMediumHigh.low')),
                                'option-selected-orange':
                                    getChosenAnswer(question) &&
                                    question.answerType.answerType === answerTypeEnum.LOW_MEDIUM_HIGH &&
                                    appTypeId === appTypeEnum.SAFETY_TRIGGER &&
                                    (getChosenAnswer(question)?.possibleAnswer === 'answerTypes.lowMediumHigh.medium' ||
                                        getChosenAnswer(question)?.possibleAnswer === 'MED') &&
                                    (option.possibleAnswer === 'MED' ||
                                        option.possibleAnswer === 'answerTypes.lowMediumHigh.medium'),
                                'option-selected-red':
                                    getChosenAnswer(question) &&
                                    question.answerType.answerType === answerTypeEnum.LOW_MEDIUM_HIGH &&
                                    appTypeId === appTypeEnum.SAFETY_TRIGGER &&
                                    (getChosenAnswer(question)?.possibleAnswer === 'answerTypes.lowMediumHigh.high' ||
                                        getChosenAnswer(question)?.possibleAnswer === 'HIGH') &&
                                    (option.possibleAnswer === 'HIGH' ||
                                        option.possibleAnswer === 'answerTypes.lowMediumHigh.high'),
                                'option-selected-gray':
                                    (getChosenAnswer(question) &&
                                        getChosenAnswer(question).possibleAnswerType == option.possibleAnswerType &&
                                        !getChosenAnswer(question).expectedAnswer &&
                                        question.answerType.answerType !== answerTypeEnum.ONE_OPTION &&
                                        appTypeId != appTypeEnum.SAFETY_TRIGGER) ||
                                    (getChosenAnswer(question) &&
                                        question.answerType.answerType === answerTypeEnum.ONE_OPTION &&
                                        getChosenAnswer(question).id === option.id)
                            }"
                            (click)="onPossibleAnswerPressed(question, option)"
                        >
                            {{ option.possibleAnswer | translate }}
                        </button>

                        <button
                            type="button"
                            mat-stroked-button
                            *ngIf="question.notApplicable"
                            [disabled]="isReadOnly"
                            [ngClass]="{
                                'not-applicable': !notApplicableChecked.get(this.getChosenAnswerKey(question)),
                                'not-applicable-selected': notApplicableChecked.get(this.getChosenAnswerKey(question))
                            }"
                            (click)="onNotApplicablePressed(question)"
                        >
                            {{ labels.notApplicable | translate }}
                        </button>
                    </div>

                    <!-- scelta singola (no appResult) o multipla definite dall'utente -->
                    <div
                        *ngIf="
                            (question.answerType.answerType === answerTypeEnum.ONE_OPTION && !appResults) ||
                            question.answerType.answerType === answerTypeEnum.MULTIPLE_OPTIONS
                        "
                    >
                        <mat-form-field appearance="standard" class="mat-form-field-rounded question-select-preview">
                            <mat-select
                                placeholder="{{ pdfView ? '' : (labels.select | translate) }}"
                                *ngIf="
                                    question &&
                                    question.answerType &&
                                    question.answerType.answerType === answerTypeEnum.MULTIPLE_OPTIONS
                                "
                                [attr.disabled]="isReadOnly"
                                [value]="getChosenAnswers(question)"
                                multiple
                                [compareWith]="compareONE_OPTION"
                            >
                                <!-- <mat-option [value]=""> </mat-option> -->

                                <mat-option
                                    *ngFor="let option of question.answerType.possibleAnswers"
                                    [value]="option"
                                    (click)="onPossibleAnswerPressed(question, option)"
                                >
                                    {{ option.possibleAnswer | translate }}
                                </mat-option>

                                <mat-option
                                    *ngIf="question.notApplicable"
                                    (click)="onNotApplicablePressed(question)"
                                    [value]="notApplicableOption"
                                >
                                    {{ labels.notApplicable | translate }}
                                </mat-option>
                            </mat-select>

                            <input
                                matInput
                                [hidden]="!pdfView"
                                [value]="getChosenAnswer(question).possibleAnswer ? getChosenAnswer(question).possibleAnswer : ''"
                            />

                            <mat-select
                                placeholder="{{ pdfView ? '' : (labels.select | translate) }}"
                                *ngIf="
                                    question &&
                                    question.answerType &&
                                    question.answerType.answerType === answerTypeEnum.ONE_OPTION
                                "
                                [disabled]="isReadOnly"
                                [hidden]="pdfView"
                                [value]="getChosenAnswer(question)"
                                [compareWith]="compareONE_OPTION"
                            >
                                <!-- <mat-option [value]=""> </mat-option> -->

                                <mat-option
                                    *ngFor="let option of question.answerType.possibleAnswers"
                                    [value]="option"
                                    (click)="onPossibleAnswerPressed(question, option)"
                                >
                                    {{ option.possibleAnswer | translate }}
                                </mat-option>

                                <mat-option
                                    *ngIf="question.notApplicable"
                                    (click)="onNotApplicablePressed(question)"
                                    [value]="notApplicableOption"
                                >
                                    {{ labels.notApplicable | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div
                        *ngIf="
                            question.answerType.answerType === answerTypeEnum.EQUIPMENT ||
                            question.answerType.answerType === answerTypeEnum.WORKINGAREA ||
                            question.answerType.answerType === answerTypeEnum.WORKINGAREA_MULTIPLE ||
                            question.answerType.answerType === answerTypeEnum.EQUIPMENT_MULTIPLE
                        "
                    >
                        <mat-form-field appearance="standard" class="mat-form-field-rounded question-select-preview">
                            <!--  MULTIPLE EQUIPMENT -->
                            <mat-select
                                placeholder="{{ pdfView ? '' : (labels.select | translate) }}"
                                *ngIf="
                                    question.answerType.answerType === answerTypeEnum.EQUIPMENT_MULTIPLE &&
                                    this.checklistItems?.length
                                "
                                [attr.disabled]="isReadOnly"
                                multiple
                                [(ngModel)]="checklistItems[qi].answerValue.equipmentsAnswer"
                                [compareWith]="compareEQUIPMENT"
                            >
                                <mat-option *ngFor="let option of equipmentList" [value]="{ id: option?.id }">
                                    {{ option.translatedValue }}
                                </mat-option>
                            </mat-select>
                            <mat-select
                                placeholder="{{ pdfView ? '' : (labels.select | translate) }}"
                                *ngIf="
                                    question.answerType.answerType === answerTypeEnum.EQUIPMENT_MULTIPLE &&
                                    !this.checklistItems?.length
                                "
                                [attr.disabled]="isReadOnly"
                                multiple
                            >
                                <mat-option *ngFor="let option of equipmentList" [value]="{ id: option?.id }">
                                    {{ option.translatedValue }}
                                </mat-option>
                            </mat-select>
                            <!--   SINGLE EQUIPMENT -->
                            <mat-select
                                placeholder="{{ pdfView ? '' : (labels.select | translate) }}"
                                *ngIf="
                                    question &&
                                    question.answerType &&
                                    question.answerType.answerType === answerTypeEnum.EQUIPMENT &&
                                    this.checklistItems?.length
                                "
                                [attr.disabled]="isReadOnly"
                                [(ngModel)]="checklistItems[qi].answerValue.equipmentsAnswer"
                                [compareWith]="compareEQUIPMENT"
                            >
                                <mat-option *ngFor="let option of equipmentList" [value]="[{ id: option?.id }]">
                                    <!-- (click)="input(getArrayIndexBase( 0) + qi, option)"  (ngModelChange)="
                                    checklistItems[getArrayIndexBase( 0) + qi].answerValue.equipmentsAnswer
                                " -->
                                    {{ option.translatedValue }}
                                </mat-option>
                            </mat-select>

                            <mat-select
                                placeholder="{{ pdfView ? '' : (labels.select | translate) }}"
                                *ngIf="
                                    question.answerType.answerType === answerTypeEnum.EQUIPMENT && !this.checklistItems?.length
                                "
                                [attr.disabled]="isReadOnly"
                            >
                                <mat-option *ngFor="let option of equipmentList" [value]="[{ id: option?.id }]">
                                    <!-- (click)="input(getArrayIndexBase( 0) + qi, option)"  (ngModelChange)="
                                    checklistItems[getArrayIndexBase( 0) + qi].answerValue.equipmentsAnswer
                                " -->
                                    {{ option.translatedValue }}
                                </mat-option>
                            </mat-select>
                            <!-- MULTIPLE WA -->
                            <mat-select
                                placeholder="{{ pdfView ? '' : (labels.select | translate) }}"
                                *ngIf="
                                    question.answerType.answerType === answerTypeEnum.WORKINGAREA_MULTIPLE &&
                                    !this.checklistItems?.length
                                "
                                [disabled]="isReadOnly"
                                multiple
                            >
                                <mat-option *ngFor="let option of workingAreaList" [value]="{ id: option?.id }">
                                    {{ option.translatedValue }}
                                </mat-option>
                            </mat-select>
                            <mat-select
                                placeholder="{{ pdfView ? '' : (labels.select | translate) }}"
                                *ngIf="
                                    question.answerType.answerType === answerTypeEnum.WORKINGAREA_MULTIPLE &&
                                    this.checklistItems?.length
                                "
                                [disabled]="isReadOnly"
                                multiple
                                [(ngModel)]="checklistItems[qi].answerValue.workingAreasAnswer"
                                [compareWith]="compareWORKINGAREA"
                            >
                                <mat-option *ngFor="let option of workingAreaList" [value]="{ id: option?.id }">
                                    {{ option.translatedValue }}
                                </mat-option>
                            </mat-select>
                            <!-- SINGLE WA -->
                            <mat-select
                                placeholder="{{ pdfView ? '' : (labels.select | translate) }}"
                                *ngIf="
                                    question.answerType.answerType === answerTypeEnum.WORKINGAREA && this.checklistItems?.length
                                "
                                [disabled]="isReadOnly"
                                [(ngModel)]="checklistItems[qi].answerValue.workingAreasAnswer"
                                [compareWith]="compareWORKINGAREA"
                            >
                                <mat-option *ngFor="let option of workingAreaList" [value]="[{ id: option?.id }]">
                                    {{ option.translatedValue }}
                                </mat-option>
                            </mat-select>
                            <mat-select
                                placeholder="{{ pdfView ? '' : (labels.select | translate) }}"
                                *ngIf="
                                    question.answerType.answerType === answerTypeEnum.WORKINGAREA && !this.checklistItems?.length
                                "
                                [disabled]="isReadOnly"
                            >
                                <mat-option *ngFor="let option of workingAreaList" [value]="[{ id: option?.id }]">
                                    {{ option.translatedValue }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="pv-question__footer" *ngIf="question.commentToAnswer || question.allowAddFiles">
                <div
                    class="pv-question__footer-block"
                    *ngIf="question.allowAddFiles && appTypeId !== appTypeEnum.DATA_GATHERING && appTypeId !== appTypeEnum.FORMS"
                >
                    <input
                        class="hidden"
                        type="file"
                        #file1
                        multiple
                        (click)="$event.target.value = null"
                        (change)="addFiles(question, $event.target.files, qi)"
                    />

                    <div
                        fxLayout="column"
                        fxLayoutAlign="space-around end"
                        fxFlex="noshrink"
                        style="cursor: pointer"
                        (click)="getQuestionFiles(question, false).length === 0 ? file1.click() : showAttachment(qi)"
                    >
                        <mat-icon fontSet="edms" class="desc" *ngIf="!attachmentVisible[qi]">icons-hide </mat-icon>
                        <mat-icon fontSet="edms" class="desc" *ngIf="attachmentVisible[qi]">icons-view </mat-icon>
                    </div>
                    <span fontSet="edms" class="icon-label">{{ labels.browseFiles | translate }}</span>
                </div>
                <div class="pv-question__footer-block" *ngIf="question.commentToAnswer">
                    <div
                        fxLayout="column"
                        fxLayoutAlign="space-around end"
                        fxFlex="noshrink"
                        style="cursor: pointer"
                        (click)="show(qi)"
                    >
                        <mat-icon fontSet="edms" class="desc" *ngIf="!commentVisible[qi]">icons-hide </mat-icon>
                        <mat-icon fontSet="edms" class="desc" *ngIf="commentVisible[qi]">icons-view</mat-icon>
                    </div>
                    <span fontSet="edms" class="icon-label">Add comment</span>
                </div>
            </div>
            <!--File Attachment-->
        </div>
    </div>

    <div
        class="pv-question no-margin"
        *ngIf="
            question.allowAddFiles === true &&
            attachmentVisible[qi] &&
            question.answerType.answerType !== answerTypeEnum.ATTACH_FILE
        "
    >
        <div class="pv-question__content-outer" fxLayout="column">
            <div class="file-attach-area">
                <div class="title-and-button-files">
                    <div>
                        <p>
                            <span class="title">{{ labels.file | translate }}</span>
                            <span class="title__optional"> {{ labels.optionalWithBraces | translate }} </span>
                        </p>
                        <span class="pv-question__subtitle">{{ labels.fileInfo | translate }}</span>
                    </div>
                    <div>
                        <!--Add file checklist-->
                        <input
                            class="hidden"
                            type="file"
                            #file
                            multiple
                            (click)="$event.target.value = null"
                            (change)="addFiles(question, $event.target.files)"
                        />
                        <button mat-button (click)="file.click()">{{ labels.browseFiles | translate }}</button>
                    </div>
                </div>
                <div class="questions-thumbnail-lists">
                    <div
                        *ngFor="let file of getQuestionFiles(question, false); let index = index"
                        class="checklistImage-container"
                    >
                        <img [src]="file.thumbnail | safe" (click)="viewImmages(getQuestionFiles(question, false))" />
                        <span class="delete1" (click)="deleteFile(question, file, qi)">
                            <mat-icon fontSet="edms" class="mat-icon-delete">icons-trash</mat-icon>
                        </span>
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    </div>
</div>

<!--ONLY FOR STAGES  stage.problemStatement -->
<div *ngIf="stage">
    <!--problem refocus only 2 -->
    <div class="pv-question-root" *ngIf="stageN == 1" [ngClass]="{ hidden: checkConditions(questions[0]) }">
        <div class="pv-question">
            <div class="pv-question__content-outer" fxLayout="column">
                <div class="pv-question__content" fxLayout="row" fxLayoutAlign="space-between start">
                    <div fxLayout="column" fxLayoutAlign="space-between start" class="for-action">
                        <!-- titolo della domanda -->
                        <span class="pv-question__type" [innerHtml]="labels.problemRefocus | translate"> </span>
                        <!-- sottotitolo della domanda -->
                        <p class="pv-question__subtitle" [innerHtml]="labels.problemRefocusSubtitle | translate"></p>
                    </div>
                </div>
                <mat-form-field class="pv-question__textarea">
                    <textarea
                        [(ngModel)]="problemStatement"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="5"
                        placeholder="{{ pdfView ? '' : (labels.enterAnswer | translate) }}"
                        matInput
                        [readonly]="isReadOnly"
                        [maxLength]="250"
                    ></textarea>
                    <span class="text-length" [hidden]="pdfView"> {{ problemStatement ? problemStatement.length : 0 }}/ 250</span>
                </mat-form-field>
            </div>
        </div>
    </div>
    <br />
    <!--summary   -->
    <div class="pv-question-root" [ngClass]="{ hidden: checkConditions(questions[0]) }">
        <div class="pv-question">
            <div class="pv-question__content-outer" fxLayout="column">
                <div class="pv-question__content" fxLayout="row" fxLayoutAlign="space-between start">
                    <div fxLayout="column" fxLayoutAlign="space-between start" class="for-action">
                        <!-- titolo della domanda -->
                        <span class="pv-question__type" [innerHtml]="(labels.summary | translate) + ' ' + (stageN * 1 + 1)">
                        </span>
                        <!-- sottotitolo della domanda -->
                        <p class="pv-question__subtitle" [innerHtml]="labels.summarySubtitle | translate"></p>
                    </div>
                </div>
                <mat-form-field class="pv-question__textarea">
                    <textarea
                        [(ngModel)]="checklist.stageAnswers[stageN].summary"
                        placeholder="{{ pdfView ? '' : (labels.enterAnswer | translate) }}"
                        matInput
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="5"
                        [readonly]="isReadOnly"
                        [maxLength]="250"
                    ></textarea>
                    <span class="text-length" [hidden]="pdfView">
                        {{ checklist.stageAnswers[stageN].summary ? checklist.stageAnswers[stageN].summary.length : 0 }}/
                        250</span
                    >
                </mat-form-field>
            </div>
        </div>
    </div>

    <!--action plan -->
    <div class="pv-question-root" [ngClass]="{ hidden: checkConditions(questions[0]) }">
        <div class="pv-question">
            <div class="pv-question__content-outer" fxLayout="column">
                <div class="pv-question__content" fxLayout="row" fxLayoutAlign="space-between start">
                    <div fxLayout="column" fxLayoutAlign="space-between start" class="for-action">
                        <!-- titolo della domanda -->
                        <span
                            class="pv-question__type"
                            [innerHtml]="(labels.actionPlanStage | translate) + ' ' + (stageN * 1 + 1)"
                        >
                        </span>
                        <!-- sottotitolo della domanda -->
                        <p class="pv-question__subtitle" [innerHtml]="labels.actionPlanStageSubtitle | translate"></p>
                    </div>
                </div>

                <div class="action-tracker">
                    <div class="action-tracker__actions" *ngIf="true">
                        <div class="daily-actions">
                            {{ labels.actions | translate }}
                        </div>
                        <!-- Action card -->
                        <ng-container *ngIf="checklist.stageAnswers && checklist.stageAnswers[stageN].actions.length === 0">
                            <mat-card class="no-actions">
                                <div class="no-actions__label">
                                    {{ labels.noActions | translate }}
                                </div>
                                <!-- (click)="viewActionDetails(parentAction.id)" -->
                            </mat-card>
                        </ng-container>
                        <ng-container *ngIf="checklist.stageAnswers && checklist.stageAnswers[stageN].actions.length > 0">
                            <mat-card
                                class="mat-card__selectable"
                                *ngFor="let parentAction of checklist.stageAnswers[stageN].actions"
                            >
                                <ng-container *ngIf="parentAction.trackerAction as action">
                                    {{ checklist.stageAnswers[stageN].actions | json }}

                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <div
                                            class="action-priority"
                                            [ngClass]="{
                                                'action-priority-high': action.priority.id == prioritiesEnum.HIGH,
                                                'action-priority-medium': action.priority.id == prioritiesEnum.MEDIUM,
                                                'action-priority-low': action.priority.id == prioritiesEnum.LOW
                                            }"
                                        ></div>
                                        <div class="action-content">
                                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                                <div fxLayout="column" fxLayoutAlign="space-between start">
                                                    <div fxLayout="row" fxLayoutAlign="start center">
                                                        <div class="action-content__title">{{ parentAction.title }}</div>
                                                    </div>
                                                    <div fxLayout="row" fxLayoutAlign="start center">
                                                        <div class="action-content__information" *ngIf="action?.atnum">
                                                            {{ action.atnum }}&nbsp;&nbsp;·&nbsp;&nbsp;
                                                        </div>
                                                        <div
                                                            class="action-content__information"
                                                            *ngIf="action?.department?.literal?.translateValue"
                                                        >
                                                            {{
                                                                action?.department?.literal?.translateValue
                                                            }}&nbsp;&nbsp;·&nbsp;&nbsp;
                                                        </div>
                                                        <div
                                                            class="action-content__information"
                                                            *ngIf="action?.workingArea?.literal?.translateValue"
                                                        >
                                                            {{
                                                                action?.workingArea?.literal?.translateValue
                                                            }}&nbsp;&nbsp;·&nbsp;&nbsp;
                                                        </div>
                                                        <div
                                                            class="action-content__information"
                                                            *ngIf="action?.equipment?.literal?.translateValue"
                                                        >
                                                            {{
                                                                action?.equipment?.literal?.translateValue
                                                            }}&nbsp;&nbsp;·&nbsp;&nbsp;
                                                        </div>
                                                        <div class="action-content__information">
                                                            <span *ngIf="action?.dueDate"
                                                                >{{ labels.due | translate }}&nbsp;</span
                                                            >

                                                            <span *ngIf="!action?.dueDate">
                                                                {{ labels.due | translate }}:&nbsp;{{
                                                                    labels.notDefined | translate
                                                                }}
                                                            </span>

                                                            {{ action?.dueDate | date }}&nbsp;&nbsp;·&nbsp;&nbsp;
                                                        </div>
                                                        <div class="action-content__information">
                                                            <mat-icon fontSet="edms">icons-profile</mat-icon>
                                                            <span *ngIf="!action?.owner?.firstName"
                                                                >{{ labels.owner | translate }}:&nbsp;{{
                                                                    labels.notDefined | translate
                                                                }}</span
                                                            >
                                                            {{ action?.owner?.firstName }}&nbsp;{{ action?.owner?.lastName }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div fxLayout="column" fxLayoutAlign="center end">
                                                        <div class="action-content__status" [statusIdToLabel]="action.state"></div>
                                                        <div class="action-content__sap" *ngIf="action?.qmnum">
                                                            {{ labels.sapId | translate }}: {{ action?.qmnum }}
                                                        </div>
                                                    </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </mat-card>
                        </ng-container>
                    </div>
                </div>

                <button
                    type="button"
                    mat-stroked-button
                    color="primary"
                    [hidden]="pdfView"
                    class="action"
                    (click)="onCreateAction(questions[0])"
                    [disabled]="isReadOnly"
                >
                    {{ labels.addAction | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
