import { Component, Inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ENV } from '@app/constants/global.constants';
import { AppUpdateService } from '@app/services/app-update.service';
import { Env } from '@app/types/env';
import { A2hsService } from '@data/services/a2hs.service';
import { AssetService } from '@data/services/asset.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',

})
export class AppComponent implements OnInit {
  public fillBackground: boolean;
  private readonly IDEA_URLS: string[] = ['IDEAPP', 'IDEAAPP'];
  private readonly FILLBACKGROUND: string[] = ['your-ideas'];

  constructor(
    @Inject(ENV) private env: Env,
    private meta: Meta,
    private appUpdateService: AppUpdateService,
    public a2hs: A2hsService,
    private assetService: AssetService,
    private titleService: Title,
    private router: Router
  ) {
    // A2HS - START
    a2hs.checkUserAgent();
    a2hs.trackStandalone();
    window.addEventListener('beforeinstallprompt', (e) => {
      // show the add button
      a2hs.promptIntercepted = true;
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      // no matter what, the snack-bar shows in 68 (06/16/2018 11:05 AM)
      e.preventDefault();
      // Stash the event so it can be displayed when the user wants.
      a2hs.deferredPrompt = e;
      a2hs.promptSaved = true;
    });
    window.addEventListener('appinstalled', (evt) => {
      a2hs.trackInstalled();
      // hide the add button
      // a2hs.promptIntercepted = false;
    });
    // A2HS - END
    router.events.subscribe((val) => {
      if (this.FILLBACKGROUND.some((x) => window.location.pathname.toLowerCase().includes(x))) {
        this.fillBackground = true;
      } else {
        this.fillBackground = false;
      }
    });
  }

  ngOnInit() {
    this.appUpdateService.startTimedUpdateChecker();
    this.appUpdateService.checkForUpdate();
    this.addBuildInfoMetatag();
    this.updateAssetInfo();
  }

  private addBuildInfoMetatag(): void {
    this.meta.updateTag({
      name: 'version',
      content: `${this.env.appVersion}`
    });
  }

  private updateAssetInfo() {
    const element = document.createElement('link');
    element.setAttribute('rel', 'manifest');

    if (this.IDEA_URLS.some((x) => window.location.hostname.toUpperCase().includes(x))) {
      this.assetService.setLogo('./assets/img/new/idea/logo.svg');
      this.titleService.setTitle('ideApp | Bacardí');
      this.assetService.setIsEbos(false);
      element.setAttribute('href', 'manifest-idea.webmanifest');
    } else {
      this.assetService.setLogo('./assets/img/new/ebos/logo.svg');
      this.titleService.setTitle('eBOS | Bacardí');
      this.assetService.setIsEbos(true);
      element.setAttribute('href', 'manifest-ebos.webmanifest');
    }
    document.querySelector('head').appendChild(element);
  }
}
