import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PermissionsByRoleDirective } from './permissions-by-role.directive';
import { StatusIdToLabelDirective } from './status-id-to-label.directive';
import { ClickOutsideDirective } from './click-outside.directive';


@NgModule({
  imports: [CommonModule],
  exports: [PermissionsByRoleDirective, StatusIdToLabelDirective, ClickOutsideDirective],
  declarations: [PermissionsByRoleDirective, StatusIdToLabelDirective, ClickOutsideDirective],
  providers: []
})
export class DirectiveModule {}
