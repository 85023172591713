import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { AuthenticationService } from '@app/security/authentication.service';
import { ServiceResponse } from '@app/types/service-response';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import UserDTO from '@data/models/user-dto';
import { UserService } from '@data/services/user.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-logout-button',
  templateUrl: './logout-button.component.html',
  styleUrls: ['./logout-button.component.scss']
})
export class LogoutButtonComponent {
  public labels = {
    loading: marker('global.loading'),
    logout: marker('global.logout'),
    errorLoadingUser: marker('logout.userNotRetrieved')
  };

  public loggedUserInfo$: Observable<ServiceResponse<UserDTO>>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authenticationService: AuthenticationService,
    private userService: UserService
  ) {
    this.loggedUserInfo$ = this.userService.loggedUserInfo$;
  }

  public logout(): void {
    this.authenticationService.logout();
    // this.document.location.reload();
  }
}
